import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const NewsletterInput = ({
  label,
  error,
  subscriber,
  buttonClass,
  buttonValue,
  handleChange,
}) => {
  const firstFieldRef = useRef();

  useEffect(() => {
    firstFieldRef.current.focus();
  }, []);

  const hasError = () => Object.keys(error).length >= 1;

  return (
    <>
      {label && <label>{label}</label>}
      <input
        className={`form-control ${hasError() ? "is-invalid" : ""}`}
        error={error.email}
        name="email"
        onChange={(e) => handleChange(e)}
        placeholder="E-Mail-Adresse"
        ref={firstFieldRef}
        type="text"
        value={subscriber.email}
      />

      {hasError() && (
        <span className="form-text invalid-feedback">
          {error.serverError || error.email}
        </span>
      )}
      <button aria-label="Newsletter abonnieren" className={buttonClass}>
        {buttonValue}
      </button>
    </>
  );
};

NewsletterInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.object,
  subscriber: PropTypes.object,
  buttonClass: PropTypes.string,
  buttonValue: PropTypes.node,
  handleChange: PropTypes.func,
};

export default NewsletterInput;
