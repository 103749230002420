const getDonationUrl = (context, source, signableId) => {
  const baseUrl = "//verein.innn.it/";

  const PATHS = {
    general: "spenden",
    petition: "psf-petitionen",
    initiative: "psf-initiativen",
  };

  const hasParameter = source || signableId;
  const hasParameters = source && signableId;

  const url = baseUrl +
    (PATHS[context] || context) +
    (hasParameter ? "?" : "") +
    (source ? "donation_custom_field_2304=" + source : "") +
    (hasParameters ? "&" : "") +
    (signableId ? "donation_custom_field_8875=" + signableId : "");

  return url;
};

export default getDonationUrl;
