import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import de from "./locales/de.json";

const namespace = "innnit-ui";

const loadLocales = (i18next) => {
  i18next.addResourceBundle("de", namespace, de);
};

const options = {
  lng: "de",
  ns: namespace,
  defaultNS: namespace,

  interpolation: {
    escapeValue: false
  },
  react: {
    useSuspense: false,
  },
  returnEmptyString: false,
};

const i18n = i18next.createInstance();

i18n
  .use(initReactI18next)
  .init(options);

loadLocales(i18n);

export default i18n;
