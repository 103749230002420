import { createContext } from "react";

const HeadContentContext = createContext({
  description: "",
  image: "",
  pageTitle: "",
  title: "",
  url: "",
  setHeadContent: () => {},
});

export default HeadContentContext;
