import PropTypes from "prop-types";
import React, { useState } from "react";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import api from "../../../../helpers/api";

const StepTwo = ({
  formErrors,
  handleChange,
  handleNextStep,
  initiative,
  model,
}) => {

  const [locations, setLocations] = useState([]);
  const [isLoadingLocations, setIsLoadingLocations] = useState(false);

  const getLevel = (level) => {
    switch (level) {
      case "locality":
        return "Ort";
        break;

      case "level2":
        return "Kreis"
        break;

      case "level1":
        return "Land"
        break;

      default:
        return;
        break;
    }
  }

  const getLocations = async (query) => {
    setIsLoadingLocations(true);
    const params = {
      country: "DE",
      limit: 6,
      search: query,
    }

    await api.getLocations(params, async (_status, data) => {
      if (!data || !Array.isArray(data)) {
        setLocations([]);
        return false;
      }

      setLocations(data.map((location) => (
        {
          name: location.name,
          id: location.id,
          level: location.level,
        }
      )));
    });

    setIsLoadingLocations(false);
  }

  return (
    <>
      {"initiative" == model && <div className="mb-5">
        <label className="h3 text-primary">Gebiet</label>
        {/* <div className="mb-4">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.
        </div> */}
        <AsyncTypeahead
          filterBy={() => true}
          id="basic-typeahead-single"
          labelKey="name"
          className="location-selector-container"
          onChange={(value) => {
            if (value.length === 0) { return }
            handleChange({ target: { name: "location", value: { id: value[0].id, name: value[0].name } } })
          }}
          onInputChange={(text, _event) => {
            if (!text) {
              handleChange({ target: { name: "location", value: { id: "", name: "" } } })
            }
          }}
          options={locations}
          isLoading={isLoadingLocations}
          maxHeight="330px"
          minLength={3}
          onSearch={getLocations}
          placeholder="Ort"
          defaultSelected={[initiative?.location?.name || ""]}
          isInvalid={!!formErrors.location}
          renderMenuItemChildren={(option, props, _index) => {
            return (
              <div className="clearfix">
                <div className="float-start text-wrap">
                  <Highlighter search={props.text}>
                    {option.name}
                  </Highlighter>
                </div>
                <small className="float-end ms-3">{getLevel(option.level)}</small>
              </div>
            )
          }}
        />
      </div>}

      {"initiative" == model && <div>
        <div className="mb-5">
          <label className="h3 text-primary mb-3">Art der Sammlung</label>
          {/* <div className="mb-4">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.
        </div> */}
          <div>
            <label className="text-primary">Art der Sammlung</label>
            <select
              className="form-select form-control mb-3"
              name="initiativeType"
              onChange={handleChange}
              value={initiative.initiativeType || 0}
            >
              <option disabled={true} value={0}>Bitte auswählen</option>
              <option value="buergerbegehren">Bürgerbegehren</option>
              <option value="einwohnerinnen-antrag">
                Einwohner:innen-Antrag
              </option>
              <option value="volksantrag">Volksantrag</option>
              <option value="volksbegehren">Volksbegehren</option>
              <option value="volksinitiative">Volksinitiative</option>
            </select>
          </div>
        </div>

        <div className="mb-5">
          <label className="text-primary">Stage</label>
          <select
            className="form-select form-control mb-3"
            name="stage"
            onChange={handleChange}
            value={initiative.stage || 0}
          >
            <option disabled={true} value={0}>Bitte auswählen</option>
            <option value="first-collection">1. Sammlung</option>
            <option value="second-collection">2. Sammlung</option>
            <option value="support">Unterstützung</option>
          </select>
        </div>

        <div className="mb-5">
          <label className="text-primary">Status</label>
          <select
            className="form-select form-control mb-3"
            name={`initiativeStatuses.${initiative.stage}`}
            onChange={handleChange}
            value={initiative.initiativeStatuses?.[initiative.stage] ? initiative.initiativeStatuses[initiative.stage] : 0}
          >
            <option disabled={true} value={0}>Bitte auswählen</option>
            <option value="active">Active</option>
            <option value="canceled">Canceled</option>
            <option value="ended">Ended</option>
          </select>

          {"ended" === initiative.initiativeStatuses?.[initiative.stage] && (
            <div className="form-check">
              <input
                checked={initiative.success}
                className="form-check-input"
                id="initiativeSuccess"
                name="success"
                onChange={handleChange}
                type="checkbox"
              />
              <label
                className="form-check-label text-primary"
                htmlFor="initiativeSuccess"
              >
                Initiative als erfolgreich markieren
              </label>
            </div>
          )}
        </div>
      </div>}

      {"petition" == model && <div className="mb-5">
        <label className="h3 text-primary mb-3">Status</label>
        <select
          className="form-select form-control mb-3"
          name="initiativeStatus"
          onChange={handleChange}
          value={initiative.initiativeStatus ? initiative.initiativeStatus : 0}
        >
          <option disabled={true} value={0}>Bitte auswählen</option>
          <option value="active">Active</option>
          <option value="canceled">Canceled</option>
          <option value="ended">Ended</option>
        </select>

        {"ended" === initiative.initiativeStatus && (
          <div className="form-check">
            <input
              checked={initiative.success}
              className="form-check-input"
              id="initiativeSuccess"
              name="success"
              onChange={handleChange}
              type="checkbox"
            />
            <label
              className="form-check-label text-primary"
              htmlFor="initiativeSuccess"
            >
              Petition als erfolgreich markieren
            </label>
          </div>
        )}
      </div>}

      {initiative.id && <div className="mb-5">
        <label className="h3 text-primary">Publish status</label>
        <select
          className="form-select form-control mb-3"
          name="status"
          onChange={handleChange}
          value={initiative.status}
        >
          <option disabled={true} value={0}>Bitte auswählen</option>
          <option value="draft">Draft</option>
          <option value="publish">Publish</option>
          <option value="trash">Trash</option>
        </select>

        <div className="form-check">
          <input
            checked={initiative.hidden}
            className="form-check-input"
            id="initiativeHidden"
            name="hidden"
            onChange={handleChange}
            type="checkbox"
          />
          <label
            className="form-check-label text-primary"
            htmlFor="initiativeHidden"
          >
            Hide petition on lists
          </label>
        </div>
      </div>}

      <div className="text-center text-md-end">
        <button
          className="btn btn-lg btn-primary"
          onClick={() => handleNextStep()}
          type="button"
        >
          Weiter
        </button>
      </div>
    </>
  );
};

StepTwo.propTypes = {
  formErrors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  initiative: PropTypes.object.isRequired,
};

export default StepTwo;
