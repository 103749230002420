import React from "react";

const Controls = () => {
  return (
    <section className="mt-5">
      <h2 className="mb-4"> Controls</h2>
      <div className="d-flex flex-wrap justify-content-between">
        <div>
          <h5>Radios</h5>
          <div className="initiative">
            <div className="form-check mb-3">
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" readOnly />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
               Initiative Unchecked Radio
              </label>
            </div>
            <div className="form-check mb-3">
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" defaultChecked />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                Initiative Checked Radio
              </label>
            </div>
            <div className="form-check mb-3">
              <input className="form-check-input" type="radio" name="flexRadioDisabled" id="flexRadioDisabled" disabled />
              <label className="form-check-label" htmlFor="flexRadioDisabled">
                Initiative Disabled Radio
              </label>
            </div>
          </div>
          <div className="petition">
            <div className="form-check mb-3">
              <input className="form-check-input" type="radio" name="flexRadioDefault-petition" id="flexRadioDefault1-petition" />
              <label className="form-check-label" htmlFor="flexRadioDefault1-petition">
                Petition Unchecked Radio
              </label>
            </div>
            <div className="form-check mb-3">
              <input className="form-check-input" type="radio" name="flexRadioDefault-petition" id="flexRadioDefault2-petition" defaultChecked />
              <label className="form-check-label" htmlFor="flexRadioDefault2-petition">
                Petition Checked Radio
              </label>
            </div>
            <div className="form-check mb-3">
              <input className="form-check-input" type="radio" name="flexRadioDisabled-petition" id="flexRadioDisabled-petition" disabled />
              <label className="form-check-label" htmlFor="flexRadioDisabled-petition">
                Petition Disabled Radio
              </label>
            </div>
          </div>
        </div>
        <div>
          <h5>Checkboxes</h5>
          <div className="initiative">
            <div className="form-check mb-3">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Initiative Unchecked Checkbox
              </label>
            </div>
            <div className="form-check mb-3">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" defaultChecked/>
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Initiative Checked Checkbox
              </label>
            </div>
            <div className="form-check mb-3">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckDisabled" disabled />
              <label className="form-check-label" htmlFor="flexCheckDisabled">
                Initiative Disabled Checkbox
              </label>
            </div>
          </div>
          <div className="petition">
            <div className="form-check mb-3">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault-petition" />
              <label className="form-check-label" htmlFor="flexCheckDefault-petition">
                Petition Unchecked Checkbox
              </label>
            </div>
            <div className="form-check mb-3">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" defaultChecked/>
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Petition Checked Checkbox
              </label>
            </div>
            <div className="form-check mb-3">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckDisabled" disabled />
              <label className="form-check-label" htmlFor="flexCheckDisabled">
                Petition Disabled Checkbox
              </label>
            </div>
          </div>
        </div>
        <div>
          <h5>Switches</h5>
          <div className="initiative">
            <div className="form-check form-switch mb-3">
              <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
              <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Initiative Unchecked Switch </label>
            </div>
            <div className="form-check form-switch mb-3">
              <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" defaultChecked />
              <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Initiative Checked Switch </label>
            </div>
            <div className="form-check form-switch mb-3">
              <input className="form-check-input" type="checkbox" id="flexSwitchCheckDisabled" disabled />
              <label className="form-check-label" htmlFor="flexSwitchCheckDisabled">Initiative Disabled Switch </label>
            </div>
          </div>
          <div className="petition">
            <div className="form-check form-switch mb-3">
              <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault-petition" />
              <label className="form-check-label" htmlFor="flexSwitchCheckDefault-petition">Petition Unchecked Switch </label>
            </div>
            <div className="form-check form-switch mb-3">
              <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" defaultChecked />
              <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Petition Checked Switch </label>
            </div>
            <div className="form-check form-switch mb-3">
              <input className="form-check-input" type="checkbox" id="flexSwitchCheckDisabled" disabled />
              <label className="form-check-label" htmlFor="flexSwitchCheckDisabled">Petition Disabled Switch </label>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Controls;
