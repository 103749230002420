import { Helmet, HelmetProvider } from 'react-helmet-async';
import React, { useContext, useEffect } from "react";
import HeadContentContext from "../HeadContentContext";

const HeadContent = () => {
  const { headContent, setHeadContent } = useContext(HeadContentContext);

  useEffect(() => {
    setHeadContent(HeadContentContext);
  }, []);

  return (
    <HelmetProvider context={headContent}>
      <Helmet>
        <meta property="og:description" content={headContent.description} />
        <meta property="og:image" content={headContent.image} />
        <meta property="og:site_name" content="innn.it" />
        <meta property="og:title" content={headContent.title} />
        <meta property="og:url" content={headContent.url} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={headContent.description} />
        <meta name="twitter:image" content={headContent.image} />
        <meta name="twitter:image:alt" content={headContent.title} />
        <meta name="twitter:site" content="@innn_it" />
        <meta name="twitter:title" content={headContent.title} /> 
      </Helmet>
    </HelmetProvider>
  );
};

export default HeadContent;
