import React, { useContext, useEffect } from "react";
import FaqImage from "../styles/images/faq-image-2.svg";
import { ThemeContext } from "../ThemeContext";

const Hero = () => {
  return (
    <div className="hero hero-image bg-gradient-horizontal bg-swirl background pt-5 mb-5">
      <div className="hero-container">
        <div className="hero-row">
          <div className="hero-body">
            <h1 className="hero-title text-primary">Häufig gestellte Fragen</h1>
            <h3 className="text-black mb-5">
              Hier findest du Antworten auf Fragen rund um innn.it
            </h3>
          </div>
          <div className="hero-img">
            <div className="hero-img-container">
              <img src={FaqImage} alt="innn.it-img"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const faqData = [
  {
    question: "Was bedeutet der Name innn.it? ",
    answer: (
      <>
        <p>innn.it soll Menschen ermutigen, Initiative zu ergreifen. Der Name innn.it ist ein Kunstbegriff, der sich von dem lateinischen Wort "initiare" ("beginnen") ableitet. Der Begriff wird in vielen Sprachen verstanden und vermittelt Menschen schnell eine Idee von Initiativen und Aktion. Die drei "n" stehen übrigens für die drei Stufen der Volksgesetzgebung: Volksinitiative, Volksbegehren, Volksentscheid. </p>
        <p>Wusstest Du, dass du direktdemokratische Initiativen auch auf innn.it unterschreiben kannst? <a href="https://innn.it/initiatives">Hier geht's zu den Initiativen</a></p>
      </>
    )
  },
  {
    question: "Was unterscheidet Euch von anderen Plattformen? ",
    answer: (
      <>
        <p>Wir denken Petitionen weiter. Die Petition ist für uns der Start einer Kampagne. Mit einem großem Team voller Expert*innen setzen wir uns jeden Tag dafür ein, dass Kampagnen Wirkung entfalten und zu einer positiven sozialen Veränderung beitragen. Dazu entwickeln wir mit den Initiator*innen Mobilisierungsstrategien. Wir produzieren Social Media Inhalte und Videos, um Kampagnen sichtbarer zu machen und zeigen Wege auf, Parlamente und Entscheider:innen zu erreichen. Wir helfen Initiator*innen, die Presse für ihre Themen zu begeistern und geben Interview-Trainings. Wir beraten bei der Organisation von Demos oder Kundgebungen, überlegen uns neue Aktionsformen. Unsere Plattform entwickeln wir kontinuierlich weiter, damit Petitionen die volle Wirkung entfalten. </p>
        <p>Wo andere aufgeben, machen wir weiter. Dazu braucht es ein erfahrenes und diverses Team mit der nötigen Expertise und einem langem Atem. Denn wir wollen, dass Kampagnen auf innn.it gewinnen. Veränderung ist möglich!</p>
        <p>Wir senken ferner die Schwelle, rechtsverbindliche Initiativen zu starten und von Zuhause aus zu unterschreiben. Durch einen schlanken und selbsterklärenden Prozess wollen wir die Ressourcen für demokratische Mitbestimmung für alle Bürger:innen zugänglich machen. Deshalb setzen wir uns für digitale Volksinitiativen- und Entscheide ein. <a href="https://innn.it/initiatives">Hier geht's zu den Initiativen</a></p>
      </>
    )
  },
  {
    question: "Was unterscheidet innn.it von Change.org?",
    answer: (
      <>
        <p>In den letzten Jahren haben wir gelernt, wie wichtig es ist, die Initiator*innen von Petitionen von Anfang an Schritt für Schritt zu begleiten. Wir denken weit über über Petitionen hinaus, damit sie tatsächlich in Gesetzgebung münden und Veränderung herbeiführen, wie z.B. die Senkung der Tamponsteuer, die Erhöhung des gesetzlichen Mindestlohns auf 12 Euro, das Upskirting-Verbot oder der Streichung von §219a. Dazu braucht es ein großes, erfahrenes und diverses Team mit der nötigen Expertise und einem langem Atem. Denn wir wollen, dass Kampagnen auf <a href="https://innn.it/">innn.it</a> gewinnen. innn.it ist ferner als Verein in Berlin registriert, zu 100% spendenfinanziert und auf Servern in Deutschland gehostet.</p>
        <p>Mit innn.it senken wir ferner die Schwelle, rechtsverbindliche Initiativen zu starten und von Zuhause aus zu unterschreiben. Durch einen schlanken und selbsterklärenden Prozess wollen wir die Ressourcen für demokratische Mitbestimmung für alle Bürger:innen zugänglich machen. Deshalb setzen wir uns für digitale Volksinitiativen- und Entscheide ein. <a href="https://innn.it/initiatives">Hier geht's zu den Initiativen</a></p>
        <p>Change.org wurde 2007 in San Francisco gegründet und ist seit 2017 als Public Benefit Corporation (PBC) registriert. Nach dem Übergang in eine gemeinnützige Stiftung hat die PBC einen Prozess zur Anpassung ihrer Strategie eingeleitet. Was die Unterstützung von Kampagnen betrifft, wird Change.org eine Strategie im Stil eines „Customer Service" (Kundendienst) einführen, die bereits in 8 Ländern angewandt wird.</p>
      </>
    )
  },
  {
    question: "Warum hat das Team Change.org verlassen und innn.it gestartet?",
    answer: (
      <>
        <p>Deutschland war das einzige Land, in dem Change.org einen externen Partner (unseren in Berlin registrierten innn.it e.V.) für die lokale Kampagnen-Unterstützung beauftragte. Change.org hat seinen Betrieb weltweit umstrukturiert und folglich die Partnerschaft mit unserem lokalen e.V. beendet. Unser innn.it e.V. hat nun die eigene Plattform innn.it ins Leben gerufen und hat die Kampagnenunterstützung auf der Plattform Change.org eingestellt.</p>
      </>

    )
  },
  {
    question: "Wie geht es mit den Petitionen auf Change.org weiter?",
    answer: (
      <>
        <p>Du hast weiterhin Zugang zu Deinem Change.org-Profil und allen Funktionen. Leider können und dürfen wir die Petitionen die auf Change liegen, nicht weiter bearbeiten. Daher bitten wir Dich, zukünftige Anfragen an den Change Helpdesk zu richten: <a href="mailto:hilfe@change.org">hilfe@change.org</a>! Change.org als Webseite, bleibt weiterhin bestehen - nur eben ohne uns.</p>
      </>
    ),
  },
  {
    question: "Was passiert mit meiner Förderung an Change.org e.V.?",
    answer: (
      <>
        <p>Da wir lediglich unseren Namen geändert, nicht aber einen neuen Verein gegründet haben, bleibt Deine Förderung an den Verein bestehen und läuft ganz normal weiter. Auf Deinem Kontoauszug wirst Du zukünftig unseren neuen Namen "<a href="https://innn.it/">innn.it</a> e.V." finden.</p>
      </>
    )
  },
  {
    question:
      "Kann ich weiter für innn.it e.V. spenden?",
    answer: (
      <>
        <p>Natürlich! Wir freuen uns über jede Unterstützung. Auf <a href="https://verein.innn.it/spenden/" target="_blank" rel="noreferrer">verein.innn.it/spenden/</a> kannst Du einfach eine einmalige Spende oder Förderung abschließen.</p>
        <p>Du hast weitere Fragen? Komme gerne auf uns zu. Du kannst Dich jederzeit per E-Mail oder Telefon zu unseren Sprechzeiten bei uns melden. Wir freuen uns, von Dir zu hören!</p>
        <p><b>E-Mail:</b> spenden[at]innn.it
          <br /><b>Telefon:</b> 030 – 629 330 15
          <br /><b>Telefon-Sprechzeiten:</b>
          <br />Di 11-13 Uhr, Mi und Do 16-18 Uhr
        </p>
      </>
    )
  },
  {
    question: "Hat innn.it eine politische Ausrichtung?",
    answer: (
      <>
        <p>innn.it ist eine inklusive Plattform für Petitionen und Initiativen. Unser e.V. fördert das demokratische Staatswesen, Bildung und bürgerschaftliches Engagement. Wir stehen für Vielfalt, Weltoffenheit und Toleranz. Wir begrüßen alle Petitionen und Initiativen, unabhängig von ihrem Anliegen, solange sie nicht gegen demokratische Grundwerte und Menschenrechte verstoßen. Hier findest du <a href="https://verein.innn.it/moderationsregeln/" target="_blank" rel="noreferrer">unsere Moderationsregeln</a>!</p>
      </>
    )
  },
  {
    question: "Warum benutzt das innn.it Team den Gender-Doppelpunkt?",
    answer: (
      <>
        <p>Wir benutzen den Gender-Doppelpunkt, weil wir es wichtig finden, alle Geschlechter respektvoll anzusprechen und die Welt so divers zu zeigen, wie sie ist. Denn: Sprache ist Macht. Und Sprache erzeugt die Welt, in der wir leben.</p>
        <p>Das generische <a href="https://www.duden.de/sprachwissen/sprachratgeber/generische-verwendungsweise-maskuliner-formen" target="_blank" rel="noreferrer">Maskulinum verwendet</a> Substantive und Pronomen männlich, auch wenn Menschen anderen Geschlechts „mitgemeint“ sind, wie zum Beispiel bei „die Ärzte“. Die Verwendung des generischen Maskulinums erzeugt im Kopf vor allem männliche Bilder, <a href="https://journals.sagepub.com/doi/10.1177/0261927X01020004004" target="_blank" rel="noreferrer">was nachweislich zur Ungleichheit</a> beiträgt. <a href="https://www.genderleicht.de/leschs-kosmos-ueber-gendern-wahn-oder-wissenschaft/" target="_blank" rel="noreferrer">Wissenschaftliche Studien</a> zeigen, dass das generische Maskulinum Frauen und Menschen, die intergeschlechtlich, trans*, nichtbinär bzw. genderqueer sind, unsichtbar macht.</p>
        <p>Belegt ist umgekehrt auch, dass die Anwendung geschlechtergerechter Sprache (= „gendern“) viele positive Effekte hat: Gendern hilft beispielsweise dabei, Geschlechterstereotype zu reduzieren. <a href="https://www.genderleicht.de/leschs-kosmos-ueber-gendern-wahn-oder-wissenschaft/" target="_blank" rel="noreferrer">Verschiedenste Studien</a> zeigen: <b>Wird gegendert, werden alle Geschlechter gedanklich mehr einbezogen. Das verändert die Realität aktiv zum Positiven.</b></p>
        <p><b>Gendern macht alle Menschen sichtbar</b>. Da Sprache unsere Realität abbildet, ist dies ein Beitrag zur universellen Gerechtigkeit und Inklusion. Flexibel sein und bleiben - im Kopf und im Herz. Das ist manchmal nicht so einfach. Unser Credo aber lautet: „Veränderung ist möglich“. Und die Welt verändert sich – ob mit oder ohne uns. <b>innn.it ist eine inklusive Plattform. Deswegen finden Sie den Gender-Doppelpunkt auch bei uns.</b></p>
      </>
    )
  },
  {
    question: "Wie erreiche ich innn.it e.V. ?",
    answer: (
      <>
        <p>Bitte schreib uns via <a href="mailto:hallo@innn.it">hallo@innn.it</a>.
          <br />Du willst unser Kampagnen-Team erreichen? Das geht am besten über <a href="mailto:kampagnen@innn.it">kampagnen@innn.it</a>
        </p>
        <p>Bei allen Fragen zu Spenden und Förderungen, komme gerne auf uns zu. Du kannst Dich jederzeit per E-Mail oder Telefon zu unseren Sprechzeiten bei uns melden. Wir freuen uns, von Dir zu hören!</p>
        <p><b>E-Mail:</b> <a href="mailto:spenden@innn.it">spenden@innn.it</a>
          <br /><b>Telefon:</b> +49 (0) 30 - 6293 3015
          <br /><b>Telefon-Sprechzeiten:</b>
          <br />Di 11-13 Uhr, Mi und Do 16-18 Uhr
        </p>
      </>
    )
  },
];

const FaqData = () => {
  const { setTheme } = useContext(ThemeContext);
  return (
    <div className="container pt-3">
      <div className="row">
        <div className="col-12 col-md-8 offset-md-2">
          <div className="accordion accordion-flush mt-5 mb-5" id="faq">
            {faqData.map((faq, index) => {
              return (
                <div key={index} className="accordion-item">
                  <h3 className="accordion-header" id={`#heading${index}`}>
                    <button
                      key={index}
                      className="accordion-button collapsed text-start"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="false"
                      aria-controls={`collapse${index}`}
                    >
                      {faq.question}
                    </button>
                  </h3>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#faq"
                  >
                    <div className="accordion-body">{faq.answer}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const Faq = () => {
  const { setTheme } = useContext(ThemeContext);

  useEffect(() => {
    setTheme("petition");
  }, []);

  return (
    <section className="pb-5">
      <Hero />
      <FaqData />
    </section>
  );
};

export default Faq;
