import React from "react";

const Pagination = () => {
  const PAGES = [
    [
      { number: 1, active: true },
      { number: 2 },
      { number: 3 },
      { number: 4 },
      { number: 5 },
    ],
    [
      { number: 1, checked: true },
      { number: 2, active: true },
      { number: 3 },
      { number: 4 },
      { number: 5 },
    ],
    [
      { number: 1, checked: true },
      { number: 2, checked: true },
      { number: 3, active: true },
      { number: 4 },
      { number: 5 },
    ]
  ]
  return (
    PAGES.map((pagination, i) => {
      return (
        <nav aria-label="..." className="mb-5" key={i.toString()}>
          <ul className="pagination pagination-petition">
            {
              pagination.map((page)=> {
                let className = "page-item";
                
                if(page.active){
                  className += " active"
                }
                if(page.checked){
                  className += " checked"
                }
                return  <li className={className} key={page.number.toString()}><a className="page-link page-link-petition" href="#">{page.checked ? "" : page.number}</a></li>
              })
            }
          </ul>
        </nav>
      )
    }) 
  );
};
export default Pagination;
