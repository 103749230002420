import { useMatomo } from "@datapunt/matomo-tracker-react";
import React, { createRef, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { faCheck, faBullhorn, faMapMarkerAlt, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../helpers/api";
import addCommasAndAnd from "../../helpers/add-commas-and-and";
import Attachment from "../../components/Attachment";
import DonationBanner from "../../components/DonationBanner";
import { getMe } from "../../redux/users";
import HeadContentContext from "../../HeadContentContext";
import InitiativeEndDate from "./InitiativeEndDate";
import InitiativeGauge from "./InitiativeGauge";
import InitiativeShare from "./InitiativeShare";
import InitiativeSignatureForm from "./InitiativeSignatureForm";
import InitiativeSignatureModal from "./InitiativeSignatureModal";
import InitiativeStage from "../../components/InitiativeStage";
import InitiativeType from "../../components/InitiativeType";
import NotFound from "../NotFound";
import PetitionBanner from "../../components/PetitionBanner";
import Tabs from "./InitiativeTabs";
import { ThemeContext } from "../../ThemeContext";
import SocialShareIcon from "../../components/SocialShareIcon";
import { DecemberChallengeBanner2023 } from "../../components/DecemberChallenge2023";

const Index = () => {
  const PATHS = {
    initiative: "initiatives",
    petition: "petitions",
  };

  const { trackEvent } = useMatomo();
  const { setHeadContent } = useContext(HeadContentContext);

  const [initiative, setInitiative] = useState({});
  const [status, setStatus] = useState("");
  const [shareUrl, setShareUrl] = useState("");
  const [isFirstPublish, setIsFirstPublish] = useState(false);

  const users = useSelector((state) => state.users);
  const capabilities = users.me?.capabilities;
  const dispatch = useDispatch();

  let history = useHistory();
  let { id, updateId } = useParams();
  let location = useLocation();
  const { setTheme } = useContext(ThemeContext);
  const shareRef = createRef();
  const updateRef = createRef();

  const goToTab = (tab) => {
    tab.click();
    tab.scrollIntoView({
      behavior: "smooth",
    });
  };

  const isSharePath = () => {
    return location.hash === "#share";
  };

  const isUpdatesPath = () => {
    return location.pathname.includes("/updates");
  };

  const shouldRedirect = () => {
    const paths = location.pathname.split("/");

    return (
      initiative.id === id || // If URL is valid, but contains the ID
      (initiative.name && (
        initiative.name !== paths?.[1] || // If URL is not the exact slug (especially case)
        location.pathname.includes("/initiatives/") // If URL contains "/initiatives"
      ))
    );
  };

  useEffect(() => {
    (async () => {
      try {
        await api.getInitiative(id, (status, data) => {
          setInitiative(data);
        });
      } catch (error) {
        if (error.response) {
          // server responded with a status code that falls out of the range of 2xx
          if (error.response.status === 404) {
            setStatus(404);
          }
        } else if (error.request) {
          // client never received a response, or request never left
        } else {
          // anything else
        }
      }
    })();
  }, [id]);

  useEffect(() => {
    if (isUpdatesPath() && updateRef.current) {
      goToTab(updateRef.current);
      return;
    }

    if (isSharePath() && shareRef.current) {
      goToTab(shareRef.current);
      return;
    }
  }, [location, updateRef, shareRef]);

  useEffect(() => {
    if (!initiative.id) {
      return;
    }

    let pathname = `/${initiative.name}`;

    if (isUpdatesPath()) {
      pathname += "/updates";

      if (updateId) {
        pathname += `/${updateId}`;
      }
    }

    if (shouldRedirect()) {
      window.history.replaceState({ state: location?.state }, "", pathname);
    }

    setShareUrl(window.location.href);
    setHeadContent({
      description: initiative.summary,
      image: initiative.featuredImage
        ? initiative.featuredImage.urls?.original
        : "",
      pageTitle: initiative.title,
      title: initiative.title,
      url: shareUrl,
    });

    setTheme(initiative.type || "petition");

    trackEvent({
      category: initiative.type,
      action: `${initiative.type}Loaded`,
      customDimensions: [
        {
          id: 1,
          value: initiative.id,
        },
      ],
    });
  }, [initiative]);

  const fetchInitiatorsAndTargetsLabel = () => {
    let initiators = initiative.initiators;
    if (!initiators || initiators.length < 1) {
      initiators = initiative._initiators;
    }

    const targets = initiative.targets;

    if (!initiators || !targets) {
      return false;
    }

    const parsedInitiators = initiators.map((e) => {
      if (e.name) {
        return e.name;
      } else if (e.displayName && "verified" == e.status) {
        return e.displayName;
      }

      return false;
    });

    const validInitiators = parsedInitiators.filter((e) => e);
    const initiatorLabels = addCommasAndAnd(validInitiators);
    const targetsLabel = addCommasAndAnd(targets.map((e) => e.name));

    if (!initiatorLabels || !targetsLabel) {
      return false;
    }

    const LABELS = {
      singularInitiator: `${initiatorLabels} hat diese Petition gestartet und richtet sie an ${targetsLabel}.`,
      pluralInitiator: `${initiatorLabels} haben diese Petition gestartet und richten sie an ${targetsLabel}.`,
    };

    if (validInitiators.length == 1) {
      return LABELS.singularInitiator;
    } else {
      return LABELS.pluralInitiator;
    }
  };

  const fetchLocation = () => {
    return initiative.location?.name || initiative.location;
  };

  const signatureProgress = Math.floor(
    (initiative.signatureCount / initiative.signatureTarget) * 100
  );

  const handlePublish = async () => {
    try {
      let action;

      if ("draft" == initiative.status) {
        action = "publish";
      } else if (!initiative.status) {
        action = "draft";
      } else {
        action = "saved";
      }

      await api.updateInitiative(
        initiative.id,
        { status: "publish", type: initiative.type },
        (_status, data) => {
          setIsFirstPublish(true);
          setInitiative(data);
          history.push(`/${initiative.name}`);
        }
      );

      if (initiative?.type === 'petition') {
        console.log('Step 5 and publishing', {
          category: 'SAP',
          action: `SAPpublished`,
        });
        trackEvent({
          category: 'SAP',
          action: `SAPpublished`,
        });
      }
    } catch (error) {
      if (error.response) {
        // Handle error
      }
    }
  };

  const isInitiative = "initiative" === initiative.type;
  const isPetition = "petition" === initiative.type;
  const isPreviewPetition = "draft" === initiative.status && "petition" === initiative.type;
  const initiatorsAndTargetsLabel = fetchInitiatorsAndTargetsLabel();
  const signableLocation = fetchLocation();

  if (status === 404) {
    return <NotFound />;
  }

  return (
    <main>
      <DecemberChallengeBanner2023 signable={initiative} />
      {initiative?.id && (
        <div>
          {/* Preview options */}
          {("preview" == location?.state?.action || "draft" == initiative.status) && (
            <section className="bg-secondary py-4">
              <div className="container d-flex flex-wrap justify-content-between align-items-center">
                <h3 className="text-primary mb-md-0 mb-4">Vorschau</h3>
                <div className="ms-md-0 ms-5">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary mb-md-0 mb-3 px-5"
                    onClick={handlePublish}
                  >
                    Veröffentlichen
                  </button>
                  <Link
                    to={"initiative" == initiative.type ?
                      `/dashboard/${PATHS[initiative.type]}/${initiative.name}` :
                      `/petition-starten/${initiative.id}`
                    }
                    className="bg-white btn btn-sm btn-outline ms-md-3"
                  >
                    Zurück zum Bearbeiten
                  </Link>
                </div>
              </div>
            </section>
          )}
          {"publish" == initiative.status && isFirstPublish && (
            <section className="bg-secondary py-4">
              <div className="container">
                <div className="d-flex justify-content-md-between align-items-md-center mb-md-4 mb-3">
                  <FontAwesomeIcon className="icon icon-left text-white" icon={faCheck} size="lg" />
                  <div className="d-flex justify-content-md-between flex-wrap flex-md-nowrap flex-grow-1">
                    <span className="h3 mb-md-0 mb-3 text-initiative-primary">
                      Deine Petition ist jetzt online.
                    </span>
                  </div>
                  <span onClick={(e) => setIsFirstPublish(false)}>
                    <FontAwesomeIcon className="text-primary" icon={faTimes} size="xl" />
                  </span>
                </div>
                <h4 className="mb-3">
                  Teilen führt zu mehr Unterschriften! Informiere jetzt Deine Familie und Freund:innen um mehr Unterschriten zu sammeln!
                </h4>
                <SocialShareIcon
                  background="white"
                  color="secondary"
                  initiative={initiative}
                  shareUrl={shareUrl}
                />
              </div>
            </section>
          )}

          <div className="container pt-5 pt-md-6">
            {/* Title */}
            <section>
              <div className="mb-5 mb-md-6">
                {isPetition && initiatorsAndTargetsLabel && (
                  <div className="d-flex ms-n1 ms-md-0">
                    <FontAwesomeIcon
                      className="text-primary"
                      icon={faBullhorn}
                      size="xl"
                    />
                    <div className="mx-3">
                      <span className="fs-4">{initiatorsAndTargetsLabel}</span>
                    </div>
                  </div>
                )}

                {initiative && signableLocation && (
                  <span className="me-3 mb-md-0 mb-3 d-block d-md-inline">
                    <FontAwesomeIcon
                      className="icon icon-left text-primary"
                      icon={faMapMarkerAlt}
                    />
                    <span>{signableLocation}</span>
                  </span>
                )}

                {isInitiative && (
                  <>
                    <InitiativeType initiative={initiative} />
                    <InitiativeStage initiative={initiative} />
                  </>
                )}
              </div>

              {isInitiative && (
                <h4 className="text-secondary mb-5 mb-md-4">
                  {initiative.subtitle}
                </h4>
              )}
              <h2 className="text-primary mb-5 mb-md-6">{initiative.title}</h2>
            </section>

            <div className="row mb-5">
              {/* Content */}
              <div className="col-12 col-md-8 order-1 order-md-0 mb-7">
                <section>
                  <div className="mt-lg-0">
                    {initiative.featuredImage && (
                      <Attachment
                        alt={initiative.title}
                        attachment={initiative.featuredImage}
                        className="w-100 mb-5"
                        size="lg"
                      />
                    )}
                    <p className="h4 mb-5 mb-md-6 text-primary">
                      {initiative.summary}
                    </p>
                  </div>
                </section>

                <Tabs initiative={initiative}
                  shareRef={shareRef}
                  shareUrl={shareUrl}
                  updateId={updateId}
                  updateRef={updateRef}
                />

                {isInitiative && (
                  <section ref={shareRef}>
                    <InitiativeShare shareUrl={shareUrl} initiative={initiative} />
                  </section>
                )}

                {("preview" != location?.state?.action && "draft" != initiative.status) && isPetition && (
                  <section>
                    <PetitionBanner
                      linkClass="btn btn-sm btn-primary btn-block"
                      linkLabel="Jetzt Petition starten"
                      signableId={initiative.id}
                      source="petition_bottom"
                    />
                  </section>
                )}

              </div>

              {/* Sidebar */}
              <div className={`col-12 col-md-4 order-0 order-md-1 ${isPreviewPetition ? "d-none" : ""}`}>
                <InitiativeGauge initiative={initiative} />
                {isInitiative && (
                  <InitiativeEndDate initiative={initiative} />
                )}

                <div className="container d-grid d-md-none mb-5 position-fixed start-0 bottom-0 zindex-dropdown">
                  <button
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#signatureFormModal"
                  >
                    Jetzt unterschreiben!
                  </button>
                </div>

                <div className={`d-none d-md-block mb-4  pt-3 top-0 ${"initiative" === initiative.type ? "position-sticky" : ""}`}>
                  <InitiativeSignatureForm
                    initiative={initiative}
                    shareUrl={shareUrl}
                  />
                </div>

                <InitiativeSignatureModal
                  initiative={initiative}
                  shareUrl={shareUrl}
                />
              </div>
            </div>

            {isInitiative && (
              <section>
                <DonationBanner
                  linkClass="btn btn-gradient btn-block"
                  linkLabel="Jetzt spenden"
                  signableId={initiative.id}
                  source="initiative_bottom"
                />
              </section>
            )}
          </div>
        </div>
      )}
    </main>
  );
};

export default Index;
