import React, { useState } from "react";
import propTypes from "prop-types";
import useValidateStep from "../../../custom-hooks/use-validate-step";
import StepComponent from "./steps/StepComponent";

const InitiativeForm = ({ handleSubmit, initiative, model, setInitiative }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formErrors, setFormErrors] = useState({});
  const MAX_LENGTHS = {
    location: 100,
    subtitle: 70,
    summary: 200,
    title: 100,
  };
  const [counter, setCounter] = useState({ ...MAX_LENGTHS });

  const cleanUpEditor = () => {
    const sourceEditorButton = document.querySelector(
      ".ck-source-editing-button"
    );
    if (sourceEditorButton && sourceEditorButton.classList.contains("ck-on")) {
      sourceEditorButton.click();
    }
  };

  const handleNextStep = (step) => {
    cleanUpEditor();
    const nextStep = parseInt(step) || currentStep + 1;

    if (nextStep <= currentStep) {
      setCurrentStep(nextStep);
      return;
    }

    const [isValid, errors] = useValidateStep(currentStep, initiative);

    if (isValid) {
      setCurrentStep(nextStep);
    } else {
      setFormErrors({ ...formErrors, ...errors });
    }
  };

  const handleChange = (e) => {
    const { name, type } = e.target;
    const value = type === "checkbox" ? e.target.checked : e.target.value;

    if (MAX_LENGTHS[name]) {
      if (value.length > MAX_LENGTHS[name]) {
        return;
      }

      setCounter({
        ...counter,
        [name]: MAX_LENGTHS[name] - value.length,
      });
    }
    const [property, childProperty] = name.split(".");

    if (childProperty) {
      setInitiative({
        ...initiative,
        [property]: {
          ...initiative[property],
          [childProperty]: value,
        },
      });
    } else if ("displayName" == property) {
      const initiator = initiative.initiators?.[0];

      if (initiator && Object.keys(initiator).length > 0) {
        initiator.displayName = value;

        setInitiative({
          ...initiative,
          initiators: [initiator],
        });
      }
    } else {
      setInitiative({
        ...initiative,
        [property]: value,
      });
    }
  };

  const STEPS = {
    1: "Wer?",
    2: "Wo?",
    3: "Was?",
    4: "Kontakt",
    5: "Zahlen",
    6: "Bilder",
  };

  return (
    <section className="container-narrow-sm">
      <ol className="indicators indicators-dark mb-md-8 mb-6">
        {Object.entries(STEPS).map(([step, action]) => (
          <StepIndicator
            action={action}
            currentStep={currentStep}
            handleNextStep={handleNextStep}
            key={step}
            step={step}
          />
        ))}
      </ol>
      <form className="mt-6">
        <StepComponent
          counter={counter}
          formErrors={formErrors}
          handleChange={handleChange}
          handleNextStep={handleNextStep}
          handleSubmit={handleSubmit}
          initiative={initiative}
          maxLengths={MAX_LENGTHS}
          model={model}
          setFormErrors={setFormErrors}
          step={currentStep}
        />
      </form>
    </section>
  );
};

const StepIndicator = ({ action, currentStep, handleNextStep, step }) => {
  return (
    <li
      className={`indicator${currentStep == step ? " active" : ""}`}
      onClick={() => handleNextStep(step)}
    >
      <label className="indicator-label">{action}</label>
      <span className="indicator-icon"></span>
    </li>
  );
};

InitiativeForm.propTypes = {
  counter: propTypes.object,
  handleSubmit: propTypes.func.isRequired,
  initiative: propTypes.object.isRequired,
  MAX_LENGTHS: propTypes.object,
};

export default InitiativeForm;
