import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { Modal } from "bootstrap";
import InitiativeCard from "../components/InitiativeCard";

const initiative = {
  title: "Active initiative",
  location: "Berlin",
  type: "volksinitiative",
  stage: "first-collection",
  subtitle: "This is just a regular initiative",
}

const Modals = () => {
  return (
    <section className="mt-5">
      <h2>Modals</h2>
      <div className="row">
        <div className="col">
          <h6>Text modal</h6>
          <button
            type="button"
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#textModal"
          >
            Launch Text Modal
          </button>
          <div
            className="modal fade"
            id="textModal"
            tabIndex="-1"
            aria-labelledby="textModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="textModalLabel">
                    Title goes here
                  </h4>
                  <button className="btn-close" data-bs-dismiss="modal">
                    <FontAwesomeIcon className="text-secondary" icon={faTimes} />
                  </button>
                </div>
                <div className="modal-body">
                  Here is a message that is going to be two lines, so we the
                  text as it wraps.
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-link text-primary"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="button" className="btn btn-primary">
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <h6>Modal with image</h6>
          <button
            type="button"
            className="btn btn-outline-primary"
            data-bs-toggle="modal"
            data-bs-target="#imageModal"
          >
            Launch modal with image
          </button>
          <div
            className="modal fade"
            id="imageModal"
            tabIndex="-1"
            aria-labelledby="#imageModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h4
                    className="modal-title text-secondary"
                    id="imageModalLabel"
                  >
                    Unterstütze diese Initiative in deiner Umgebung
                  </h4>
                  <button className="btn-close" data-bs-dismiss="modal">
                    <FontAwesomeIcon className="text-secondary" icon={faTimes} />
                  </button>
                </div>
                <InitiativeCard initiative={initiative} />
                <div className="modal-footer">
                  <button
                    data-bs-dismiss="modal"
                    className="btn btn-outline-primary"
                  >
                    Abbrechen
                  </button>
                  <button type="button" className="btn btn-primary">
                    Jetzt unterstützen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Modals;
