import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import ChangeEmailAddress from "./ChangeEmailAddress";
import Form from "./Form";
import ResetPassword from "./ResetPassword";
import { ThemeContext } from "../../../ThemeContext";
import { useSelector } from "react-redux";
import { populateLocation } from "../../../helpers/populateLocation";

const Profile = () => {
  const { setTheme } = useContext(ThemeContext);
  const [ user, setUser ] = useState();
  const users = useSelector((state) => state.users);

  useEffect(() => {
    setTheme("petition");
  }, []);

  useEffect(async () => {
    if (users?.me?.id) {
      const user = await populateLocation(users.me);
      setUser(user);
    }
  }, [users?.me?.id]);

  return (
    <section className="container">
      <div className="pointer align-items-center d-flex mb-4 pt-5">
        <FontAwesomeIcon
          className="me-2 text-primary"
          fixedWidth
          icon={faChevronLeft}
        />
        <Link className="pointer text-primary" to="/profile/petitions">Zurück zur Übersicht</Link>
      </div>
      <div className="flex-column mb-5 row">
        <Form user={user} setUser={setUser} />
        <ChangeEmailAddress user={user} setUser={setUser} />
        <ResetPassword user={user} setUser={setUser} />
      </div>
      <div className="small text-black">
        <p>*Diese Felder müssen ausgefüllt werden.</p>
        <p>
          Wir verarbeiten Deine Daten gemäß unseren{" "}
          <Link to="/datenschutz">Datenschutzbestimmungen</Link>.
        </p>
      </div>
    </section>
  );
};



export default Profile;