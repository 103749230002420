import React, {useState} from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import logo from "../../../styles/images/logoInitiatives.png";
import PostVerificationMessage from "./PostVerificationMessage";

const EmailTemplate = () => {
  const [open, setOpen] = useState(true);

  let { id } = useParams();
  let location = useLocation();
  const {action, update} = location.state;

  const paths = location.pathname?.split("/");
  const modelPath = paths[2];

  return (
    <main className="dashboard">
      {location.state?.action && open && (
        <section className="bg-secondary mb-5 mt-n5 px-9">
          <div className="container d-flex py-4">
          <FontAwesomeIcon className="icon icon-left text-success mb-1" icon={faCheck} size="lg" />
            <div className="d-flex flex-wrap flex-md-nowrap flex-grow-1">
              <span className="h3 mb-md-0 mb-5 text-primary">
                Deine Update wurde gepostet!
              </span>
            </div>
            <span onClick={(e) => setOpen(false)}>
              <FontAwesomeIcon className="text-primary" icon={faTimes} size="lg" />
            </span>
          </div>
        </section>
      )}
      <section className="container-narrow-sm" style={{ width: "40%" }}>
        <h3 className="text-primary mb-5">E-Mail an Supporter senden</h3>
        <div className="mb-2">
          <label className="text-primary">Betreff</label>
          <input
            type="text"
            className="form-control mb-3"
            name="betreff"
            placeholder="This is an example title"
          />
          <span className="d-block text-end">100</span>
        </div>
        <section>
          <label className="text-primary">Vorschau</label>
          <section className="overflow-hidden border rounded-3  border-primary pb-2">
            <div className="d-flex justify-content-center">
              <img className="w-25" src={logo} alt="innn.it" />
            </div>
            <div className="px-3">
              <h3 className="text-primary mb-4">{update.title}</h3>
              <div className="mb-5">
                {update.content && parse(update.content)}
              </div>
            </div>

            <PostVerificationMessage />
          </section>

          <div className="mt-5 mb-4">
            <Link
              to={{
                pathname: `/dashboard/${modelPath}/${id}/updates`,
                state: { action: action }
              }}
              className="btn btn-lg btn-primary px-6"
            >
              E-Mail senden
            </Link>
          </div>
          <div>
            <Link
              to={`/dashboard/${modelPath}/${id}/updates/${update.id}`}
              className="btn btn-lg btn-outline-primary"
            >
              Zurück zum Bearbeiten
            </Link>
          </div>
          <div className="my-4">
            <Link
              to="/dashboard"
              className="btn btn-lg btn-outline-primary px-5"
            >
              Keine E-Mail senden
            </Link>
          </div>
        </section>
      </section>
    </main>
  );
};

export default EmailTemplate;
