import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import api from "../../../helpers/api";
import Card from "./Card";
import PetitionBanner from "./PetitionBanner";
import Updates from "./Updates";


const PetitionCards = ({ isActive, initiatives, setInitiatives, setIsShowPetitionButton }) => {
  const [offset, setOffset] = useState(0);
  const [selectedPetition, setSelectedPetition] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
  const limit = 9;
  const type = "petition";

  const loadMore = async () => {
    getInitiatives(limit, offset, type);
  };

  const getInitiatives = async (limit, offset, type) => {
    if (isLoading) {
      return false;
    };

    setIsLoading(true);

    await api.getInitiatives(
      { initiator: "me", limit: limit, offset: offset, status: ["draft", "publish"], type: type },
      (_status, data) => {

        if (data === undefined || data.length < 3) {
          setShowLoadMoreButton(false);
        };

        if (Array.isArray(data)) {
          setInitiatives([...initiatives, ...data]);
        };

        setOffset(offset + data.length);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    setIsShowPetitionButton(!selectedPetition);

  }, [setIsShowPetitionButton, selectedPetition]);
  

  useEffect(() => {
    (async () => {
      try {
        await getInitiatives(limit, offset, type);
      } catch (error) {
        if (error.response) {
          // Handle error
        };
      }
    })();
  }, []);
  
  const activeClass= isActive ? "active show" : "";
  
  return (
    <div className={`card-holder tab-pane fade ${activeClass}`} id="initiativeTabsContentPetitions"  aria-labelledby="initiativeTabsNavPetitions">
      { initiatives && initiatives.length > 0 ?
        !selectedPetition ? 
          <div className="card-container">
            <div className="row">
              {initiatives && 
                Array.isArray(initiatives) &&
                initiatives.map((initiative, index) => (
                  <div className="col-12 col-md-4 mb-4" key={index}>
                    <Card 
                      initiative={initiative} 
                      setSelectedPetition={setSelectedPetition}   
                    />
                  </div>
                ))
              }
            </div>
          </div>
        :
        <Updates selectedPetition={selectedPetition} setSelectedPetition={setSelectedPetition} />
      :
        <PetitionBanner />
      }
      {initiatives.length > 0 && showLoadMoreButton && !selectedPetition &&  ( 
        <div className="d-flex mb-4 justify-content-center">
          {isLoading ? (
            <FontAwesomeIcon
              className="text-secondary"
              icon={faSpinner}
              size="2x"
              spin
            />
          ) : (
            <button className="btn btn-primary" onClick={loadMore}>
              Mehr laden
            </button>
          )}
        </div>
      )} 
    </div>
  );
};

PetitionCards.propTypes = {
  initiatives: PropTypes.array.isRequired,
  setInitiatives: PropTypes.func,
  setIsShowPetitionButton: PropTypes.func
};


export default PetitionCards;