import React, { createRef, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import DonationLink from "./DonationLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import logoInitiatives from "../styles/images/logoInitiatives.png";
import logoPetitions from "../styles/images/logoPetitions.png";
import NewsletterWidget from "./NewsletterWidget";
import { ThemeContext } from "../ThemeContext";
import UserNavbar from "./UserNavbar";

const Header = () => {
  const [actionContainerHeight, setActionContainerHeight] = useState(0);
  const actionContainerRef = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuHeight, setMenuHeight] = useState(0);
  const [menuLeftPosition, setMenuLeftPosition] = useState(0);
  const menuButtonRef = useRef();
  const menuRef = useRef();
  const newsletterTriggerRef = useRef();
  const { theme } = useContext(ThemeContext);

  let location = useLocation();

  const isNewsletterPath = () => {
    return location.hash === "#newsletter";
  }

  const repositionMenu = (event) => {
    if (menuRef.current) {
      const { height } = menuRef.current.getBoundingClientRect();
      setMenuHeight(height);
    }

    const menuButton = menuButtonRef.current;
    if (menuButton && menuButton.getBoundingClientRect()) {
      const menuButtonPosition = menuButton.getBoundingClientRect();
      const menuWidth = menuRef.current.offsetWidth;
      const left = menuButtonPosition.right + 12 - menuWidth;
      setMenuLeftPosition(left > 0 ? left : 0);
    }
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', repositionMenu);
    return () => window.removeEventListener('resize', repositionMenu);
  }, []);

  useEffect(() => {
    if(isNewsletterPath() && newsletterTriggerRef.current){
      newsletterTriggerRef.current.click();
      return;
    }
  }, [newsletterTriggerRef]);
  
  useEffect(() => {
    if (actionContainerRef.current && actionContainerRef.current.offsetHeight) {
      setActionContainerHeight(actionContainerRef.current.offsetHeight);
    }
  }, [actionContainerRef?.current?.offsetHeight]);

  useEffect(() => {
    repositionMenu();
  }, [menuRef?.current?.offsetHeight, isMenuOpen]);

  useEffect(() => {
    repositionMenu();
  }, [menuButtonRef?.current?.offsetRight]);

  return (
    <header className="header">
      <UserNavbar />
      <nav className="header-navbar">
        <div className="header-navbar-container">
          {/* Logo */}
          <Link className="header-brand" to="/">
            {"petition" == theme && <img src={logoPetitions} alt="innn.it" />}
            {"initiative" == theme && (
              <img src={logoInitiatives} alt="innn.it" />
            )}
          </Link>

          {/* Menu toggler */}
          <div
            className={`header-toggler ${isMenuOpen ? " open" : ""}`}
            onClick={() => setIsMenuOpen((prev) => !prev)}
            ref={menuButtonRef}
          >
            <div className="bar-one"></div>
            <div className="bar-two"></div>
            <div className="bar-three"></div>
          </div>
        </div>
      </nav>

      {/* Call to action */}
      <div
        className={`header-action ${isMenuOpen ? " open" : ""}`}
        style={{
          top: isMenuOpen ? "100%" : `calc(100% - ${menuHeight}px)`,
          height: menuHeight,
          left: menuLeftPosition,
        }}
      >
        <div ref={actionContainerRef} style={{ left: "10px" }}>
          <button
            className={`header-button${isMenuOpen ? " open" : ""}`}
            data-bs-toggle="modal"
            data-bs-target="#newsletterWidget"
            ref={newsletterTriggerRef}
          >
            {" "}
            Newsletter abonnieren
          </button>
        </div>
      </div>

      {/* Menu */}
      <Menu
        actionContainerHeight={actionContainerHeight}
        isMenuOpen={isMenuOpen}
        menuButtonRef={menuButtonRef}
        menuHeight={menuHeight}
        menuLeftPosition={menuLeftPosition}
        menuRef={menuRef}
        repositionMenu={repositionMenu}
        setIsMenuOpen={setIsMenuOpen}
      />
      <NewsletterWidget />
    </header>
  );
};

const Menu = ({
  actionContainerHeight,
  isMenuOpen,
  menuButtonRef,
  menuHeight,
  menuLeftPosition,
  menuRef,
  repositionMenu,
  setIsMenuOpen,
}) => {
  const handleClickOutside = (event) => {
    if (
      menuButtonRef.current &&
      !menuButtonRef.current.contains(event.target) &&
      menuRef.current &&
      !menuRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
    }
  };

  const menuContent = [
    { label: "Startseite", url: "/" },
    { label: "Petitionen durchsuchen", url: "/", state: {scrollTo: "petitions"} },
    { label: "Petition starten", url: "/petition-starten" },
    { label: "Über uns", url: "/about" },
    { label: "Newsroom", url: "//verein.innn.it/newsroom", blank: true },
    { label: "Häufige Fragen", url: "/faq" },
    {
      label: "innn.it Initiativen",
      items: [
        { label: "Alle Initiativen", url: "/initiatives" },
        { label: "Initiative starten", url: "/contact" },
        { label: "Häufige Fragen", url: "/initiatives", state: {scrollTo: "faq"} },
      ],
    },
  ];

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });
  
  const fetchMenuItem = (item, index) => {
    return (
      <li key={index} onClick={() => repositionMenu()}>
        {item.items?.length ? (
          <>
            <button
              type="button"
              data-bs-toggle="dropdown"
              className="header-menu-item"
              aria-expanded="false"
            >
              {item.label}
              <FontAwesomeIcon
                className="float-end icon px-3 text-initiative-primary"
                icon={faChevronDown}
              />
            </button>
            <ul className="dropdown-menu header-submenu">
              {item.items.map(fetchMenuItem)}
            </ul>
          </>
        ) : (
          <Link
            className="header-menu-item"
            onClick={() => setIsMenuOpen((prev) => !prev)}
            rel={item.blank ? "noopener noreferrer" : ""}
            target={item.blank ? "_blank" : ""}
            to={{pathname: item.url, state: item.state}}
          >
            {item.label}
          </Link>
        )}
      </li>
    )
  };

  return (
    <ul
      className={`header-menu${isMenuOpen ? " open" : ""}`}
      ref={menuRef}
      style={{ top: isMenuOpen ? "100%" : `calc(100% - ${menuHeight}px)`, left: menuLeftPosition }}
    >
      {menuContent.map(fetchMenuItem)}

      <li>
        <DonationLink className="header-button mt-4 mx-3" source="menu">
          Spenden ❤
        </DonationLink>
      </li>
      <li
        className="header-menu-action"
        style={{ height: actionContainerHeight }}
      />
    </ul>
  );
};

export default Header;
