import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "./Modal"
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";

const ResetPasswordRequestMessageModal = () => {
  return (
    <Modal id="resetPasswordRequestMessageModal">
      <span className="d-flex mb-3">
        <FontAwesomeIcon
          className="icon icon-left text-petition-primary"
          icon={faEnvelope}
          size="3x"
        />
        <span className="ps-4">
          <h4 className="text-petition-primary mb-3">
            Wir haben Dir eine E-Mail gesendet
          </h4>
          <span className="text-black">
            Klicke einfach auf den Link den wir Dir per E-Mail gesendet haben um
            Dein Passwort zu aktualisieren.
          </span>
        </span>
      </span>
    </Modal>
  )
}

export default ResetPasswordRequestMessageModal;
