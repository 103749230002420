import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { faLink } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const SocialShareIcon = ({ background, color, initiative, shareUrl }) => {
  const CONTENT = {
    initiative: `Ich habe gerade die Unterschriftenliste für die Initiative “${initiative.title}” heruntergeladen. Gehe jetzt auf {{site_url}}, um Deine Liste zu bekommen.`,
    petition: `Ich habe soeben die Petition für die Kampagne “${initiative.title}” unterschrieben. Gehe jetzt auf {{site_url}}, falls du auch die Kampagne unterstützen willst!`
  };

  const shareText = `${CONTENT?.[initiative.type]} ${shareUrl}`;

  const socialMedia = [
    {
      label: "Facebook",
      name: faFacebook,
      url: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
    },
    {
      label: "Twitter",
      name: faTwitter,
      url: `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText.replace("{{site_url}}", "@innn_it"))}`,
    },
    {
      label: "WhatsApp",
      name: faWhatsapp,
      url: `https://api.whatsapp.com/send?text=${encodeURIComponent(shareText.replace("{{site_url}}", "innn.it"))}`,
    },
    {
      label: "E-Mail",
      name: faEnvelope,
      url: `mailto:?subject=innn.it&body=${encodeURIComponent(shareText.replace("{{site_url}}", "innn.it"))}`,
    },
  ];

  const { trackEvent } = useMatomo();

  const handleClick = (e, medium, url) => {
    e.preventDefault();

    trackEvent({
      category: "document",
      action: "shareInitiative",
      customDimensions: {
        initiativeId: initiative.id,
        medium: medium,
      },
    });
    window.open(url, "_blank")
  }

  const items = socialMedia.map((medium) => {
    return (
      <li className="col-6 col-md-auto mb-3" key={medium.label}>
        <a
          className="align-items-center d-flex flex-column"
          onClick={(e) => handleClick(e, medium.label, medium.url)}
        >
          <span className={`btn btn-icon mb-2 bg-${background}`}>
            <FontAwesomeIcon className={`text-${color}`} icon={medium.name} size="lg" />
          </span>
          <span className="d-block text-black">{medium.label}</span>
        </a>
      </li>
    );
  });

  return (
    <ul className="row p-0 w-100 list-unstyled">
      {items}
      <li className="col-6 col-md-auto mb-3">
        <CopyToClipboard
          text={`${shareUrl}`}
        >
          <a className="align-items-center d-flex flex-column">
            <span className={`btn btn-icon mb-2 bg-${background}`}>
              <FontAwesomeIcon className={`text-${color}`} icon={faLink} size="xl" />
            </span>
            <span className="d-block text-black">Link kopieren</span>
          </a>
        </CopyToClipboard>
      </li>
    </ul>
  );
};

SocialShareIcon.propTypes = {
  background: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default SocialShareIcon;