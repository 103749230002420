import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faHeart, faPen, faUser } from "@fortawesome/pro-solid-svg-icons";

const Start = () => {
  const CONTENTS = [
    {
      icon: faBullhorn,
      text: "Eine konkrete Forderung"
    },
    {
      icon: faUser,
      text: "Die richtigen Entscheider:innen"
    },
    {
      icon: faPen,
      text: "Ein überzeugender Text"
    },
    {
      icon: faHeart,
      text: "Ein starkes Foto"
    }
  ];

  return (
    <div>
        <h2 className="mb-5 text-primary">
          4 Erfolgsfaktoren für Deine Petition
        </h2>
      {
        CONTENTS.map(({icon,text}) => {
          return (
            <div className="align-items-center d-flex mb-4" key={text}>
              <FontAwesomeIcon 
                className="icon icon-left text-primary" 
                icon={icon} 
                size="lg"
              />
              <span className="fs-3 fw-bold">{text}</span>
            </div>
          )
        })
      }
      
    </div>
  )
}

export default Start