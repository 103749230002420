import React from "react";

const Shadows = () => {
  return (
    <section className="mt-5">
      <h2>Elevation</h2>
      <div className="row">
        <div className="col-md-4">
          <div className="p-3 mb-3 shadow-sm rounded">
            Elevation 1
            <br /><code>.shadow-sm</code>
          </div>
        </div>
        <div className="col-md-4">
          <div className="p-3 mb-3 shadow rounded">
            Elevation 2
            <br /><code>.shadow</code>
          </div>
        </div>
        <div className="col-md-4">
          <div className="p-3 mb-3 shadow-lg rounded">
            Elevation 3
            <br /><code>.shadow-lg</code>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Shadows;
