import React from "react";

const Tabs = () => {
  return (
    <div className="mt-5">
      <h2>Tabs</h2>
      <ul className="nav nav-pills nav-fill">
        <li className="nav-item">
          <a className="nav-link active" href="/">
            <span>Project-Details</span>
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/">
            <span>Updates</span>
          </a>
        </li>
        <li className="nav-item md-down">
          <a className="nav-link" href="/">
          <span>Vernetz dich!</span>
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/">
          <span>Kontaktdaten</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Tabs;
