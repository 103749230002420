import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faSpinner,
} from "@fortawesome/pro-solid-svg-icons";
import api from "../helpers/api";
import CardImageSm from "../styles/images/card-bg-sm.svg";
import DonationLink from "../components/DonationLink";
import IconBox from "../components/IconBox";
import iconBoxImageMegaphone from "../styles/images/icon-box/megaphone-alternate.svg";
import iconBoxImageSignature from "../styles/images/icon-box/signature-alternate.svg";
import iconBoxImageUnited from "../styles/images/icon-box/united-alternate.svg";
import iconBoxImageDonation from "../styles/images/icon-box/donation-alternate.svg";
import iconBoxImageSecurity from "../styles/images/icon-box/security-alternate.svg";
import InitiativeCard from "../components/InitiativeCard";
import { ThemeContext } from "../ThemeContext";

const Home = () => {
  const initiativesRef = useRef();
  const location = useLocation();
  const { setTheme } = useContext(ThemeContext); 

  useEffect(() => {
    setTheme("petition");
  }, []);

  useEffect(() => {
    if(location?.state?.scrollTo === "petitions"){
      goToInitiatives();
    }    
  }, [location]);

  const goToInitiatives = () => {
    initiativesRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <main className="page-home">
      <Hero goToInitiatives={goToInitiatives}/>
      <IconBoxes />
      <PetitionCards initiativesRef={initiativesRef} />
      {/* <Testimonials testimonials={testimonials} /> */}
      <PetitionBanner />
    </main>
  );
};

const Hero = ({ goToInitiatives }) => {
  const heroTitle = "Ergreif Initiative und starte Deine Petition";
  const heroBody =
    "Veränderung ist möglich! Dafür setzen wir uns Tag für Tag ein und unterstützen Menschen mit Erfahrung, Expertise und Leidenschaft. Was willst Du verändern?";

  return (
    <div className="hero-holder">
      <div className="hero hero-image bg-gradient-horizontal-petition background">
        <div className="hero-container">
          <div className="hero-row">
            <div className="hero-body content">
              <h1 className="hero-title text-initiative-primary">
                {heroTitle}
              </h1>
              <p className="fw-2 mb-5">{heroBody}</p>
              <div className="hero-button-container">
                <button className="btn btn-outline bg-white" onClick={goToInitiatives}>
                  Petitionen entdecken
                </button>
                <Link
                  className="btn btn-primary"
                  to="/petition-starten"
                >
                  Petition starten
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="page-home-image page-home-image__petition"> </div>
      </div>
    </div>
  );
};

const IconBoxes = () => {
  const iconBoxImages = [
    iconBoxImageSignature,
    iconBoxImageUnited,
    iconBoxImageDonation,
    iconBoxImageSecurity,
  ];

  const iconBoxTitles = [
    "Die Petition ist „nur“ der Anfang",
    "Ein erfahrenes Expert:innen-Team",
    "Zu 100% durch Spenden finanziert",
    "Datenschutz hat höchste Priorität",
  ];

  const iconBoxBodyTexts = [
    "Wir helfen Dir, Dein Anliegen in die Medien, auf die Straße und in die Parlamente zu tragen.",
    "In den letzten Jahren haben wir unzählige Kampagnen zum Erfolg geführt.",
    "Hinter innn.it steht der innn.it e.V. mit Sitz in Berlin.",
    "innn.it ist auf deutschen Servern zu Hause und speichert nur die nötigsten Informationen.",
  ];
  return (
    <div className="box-icon">
      <div className="banner banner-features">
        <h2 className="banner-title">Das ist innn.it</h2>
        <div className="banner-container">
          <div className="banner-row">
            {iconBoxTitles.map((title, index) => {
              return (
                <div className="banner-feature" key={title}>
                  <IconBox
                    img={iconBoxImages[index]}
                    imgAlt={title}
                    title={title}
                    text={iconBoxBodyTexts[index]}
                    key={title}
                  ></IconBox>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const PetitionCards = ({ initiativesRef }) => {
  const [initiatives, setInitiatives] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
  const limit = 3;
  const type = "petition";

  const loadMore = async () => {
    getInitiatives(limit, offset, type);
  };

  const getInitiatives = async (limit, offset, type) => {
    if (isLoading) {
      return false;
    }

    setIsLoading(true);

    await api.getInitiatives(
      { limit: limit, offset: offset, type: type },
      (status, data) => {
        if (data === undefined || data.length < 3) {
          setShowLoadMoreButton(false);
        }

        if (Array.isArray(data)) {
          setInitiatives(initiatives.concat(data));
        }

        setOffset(offset + data.length);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    (async () => {
      try {
        await getInitiatives(limit, offset, type);
      } catch (error) {
        if (error.response) {
          // Handle error
        }
      }
    })();
  }, []);

  return (
    <div className="card-holder" ref={initiativesRef}>
      <div className="card-container mt-5">
        <h1 className="text-center">Aktuelle Petitionen</h1>

        <div className="row">
          {initiatives &&
            Array.isArray(initiatives) &&
            initiatives.map((initiative, index) => (
              <div className="col-12 col-md-4 mb-4" key={index}>
                <InitiativeCard initiative={initiative} />
              </div>
            ))}
        </div>
      </div>
      {showLoadMoreButton && (
        <div className="d-flex mb-4 justify-content-center">
          {isLoading ? (
            <FontAwesomeIcon
              className="text-secondary"
              icon={faSpinner}
              size="2x"
              spin
            />
          ) : (
            <button className="btn btn-primary" onClick={loadMore}>
              Mehr Petitionen anzeigen
            </button>
          )}
        </div>
      )}
    </div>
  );
};

const PetitionBanner = () => {
  return (
    <section className="container mt-5">
      <div className="banner-background bg-gradient-horizontal bg-rounded-2 shadow">
        <div className="align-items-center banner-row flex-row-reverse mx-auto p-md-3 w-75">
          <div className="banner-img text-center pt-5 pt-md-0 d-flex align-items-center">
            <img src={iconBoxImageMegaphone} alt="icon-for-initiative" />
          </div>

          <div className="banner-body">
            <div className="banner-text p-md-0">
              <h2 className="banner-title">
                Starte jetzt Deine eigene Petition
              </h2>

              <h5>
                Ergreif Initiative! Wir unterstützen Dich, damit Deine Kampagne
                Wirkung entfaltet.
              </h5>

              <div className="banner-footer">
                <Link
                  className="btn btn-sm btn-primary btn-block"
                  to="/petition-starten"
                >
                  Jetzt Petition starten
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Testimonials = () => {
  const testimonials = [
    {
      name: "Olivia Eklund, Designer",
      caption:
        "I think design systems will replace many design jobs as we know them today — they already have.",
    },
    {
      name: "testimonial 2",
      caption:
        "I think design systems will replace many design jobs as we know them today — they already have.",
    },
    {
      name: "testimonial 3",
      caption:
        "I think design systems will replace many design jobs as we know them today — they already have.",
    },
    {
      name: "testimonial 4",
      caption:
        "I think design systems will replace many design jobs as we know them today — they already have.",
    },
  ];

  return (
    <div
      id="carouselTestimonials"
      className="carousel slide container"
      data-bs-ride="false"
    >
      <div className="carousel-indicators">
        {testimonials.map((testimonial, index) => (
          <TestimonialIndicator
            active={index == 0}
            index={index}
            key={index}
            testimonial={testimonial}
          />
        ))}
      </div>
      <div className="carousel-inner mb-5">
        {testimonials.map((testimonial, index) => (
          <TestimonialItem active={index == 0} key={index} testimonial={testimonial} />
        ))}
      </div>
      <button
        className="carousel-prev"
        data-bs-target="#carouselTestimonials"
        data-bs-slide="prev"
        type="button"
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>

      <button
        className="carousel-next"
        data-bs-target="#carouselTestimonials"
        data-bs-slide="next"
        type="button"
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
    </div>
  );
};

const TestimonialItem = ({ active, testimonial }) => {
  return (
    <div className={`carousel-item  ${active ? "active" : ""}`}>
      <div className="testimonial">
        <div className="testimonial-image">
          <img src={CardImageSm} />
        </div>

        <div className="testimonial-details">
          <h3>
            <blockquote>“{testimonial.caption}“</blockquote>
          </h3>
          <p className="h5">{testimonial.name}</p>
        </div>
      </div>
    </div>
  );
};

const TestimonialIndicator = ({ active, index, testimonial }) => {
  return (
    <button
      className={`dot dot-sm ${active ? "active" : ""}`}
      aria-label={testimonial.name}
      aria-current={active ? "true" : ""}
      data-bs-target="#carouselTestimonials"
      data-bs-slide-to={index}
    ></button>
  );
};

export default Home;
