import React, { useState } from "react";

const useToolTip = () => {
  const [showTipBox, setShowTipBox] = useState(false);

  const showTip = () => {
    setShowTipBox(true);
  };

  const hideTip = () => {
    setShowTipBox(false);
  };

  return {hideTip, showTip, showTipBox};
};

export default useToolTip;
