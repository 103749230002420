import React from "react";
import { Link } from "react-router-dom";
import getDonationUrl from "../helpers/get-donation-url";

const DonationLink = (props) => {
  const context = props.context || "general";
  const source = props.source;
  const signableId = props.signableId;

  const url = getDonationUrl(context, source, signableId);

  return (
    <Link className={props.className} to={url} target="_blank">{props.children}</Link>
  );
};

export default DonationLink;
