import React, { useContext, useEffect } from "react";
import banner1Img from "../styles/images/banner-image-signature.svg";
import banner2Img from "../styles/images/hero-image-2.svg";
import banner3Img from "../styles/images/innnit-team-cutout-24.png";
import banner4Img from "../styles/images/banner-datenschutz-2.svg";
import DonationLink from "../components/DonationLink";
import headingImg from "../styles/images/innnit-team-24-web.jpg";
import { ThemeContext } from "../ThemeContext";

const Heading = () => {
  return (
    <section
      className="hero hero-headline hero-lg bg-primary"
      style={{ backgroundImage: `url(${headingImg})` }}
    >
      <div className="hero-container">
        <div className="hero-row">
          <div className="hero-body">
            <h1 className="hero-title text-center text-white">
              Wir sind für alle kostenlos. Das soll auch so bleiben.
            </h1>
            <DonationLink
              className="btn btn-outline-primary bg-white"
              source="about"
            >
              Jetzt spenden ❤️
            </DonationLink>
          </div>
        </div>
      </div>
    </section>
  );
};

const Hero = () => {
  return (
    <section className="hero hero-headline bg-secondary mt-n5 mb-6">
      <div className="hero-container">
        <div className="hero-row">
          <div className="hero-body">
            <h2 className="hero-title text-center text-white">
              Wir ermöglichen es Bürger:innen, Entscheidungen über die Zukunft
              zu treffen, diese selbst einzubringen und mitzugestalten.
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
};

const Banner = () => {
  const bannerData = [
    {
      title: "innn.it ist die neue Kampagnenplattform.",
      className: "banner-image-right",
      description: (
        <>
          <p>
            Wir ermutigen Menschen, Initiative zu ergreifen und eine Kampagne zu starten.
            Dafür stellen wir nicht nur eine kostenfreie und leistungsfähige Plattform bereit,
            sondern bieten handfesten Support für alle, die sich für positiven sozialen Wandel
            einsetzen wollen. Diese Menschen unterstützen wir mit unserer langjährigen Erfahrung,
            Expertise und Leidenschaft.
          </p>
          <p>
            Wir senken ferner die Schwelle, rechtsverbindliche Initiativen zu starten
            und von Zuhause aus zu unterschreiben.
          </p>
        </>
      ),
      image: (
        <BannerImage bannerImage={banner1Img} alt="Wir senken die Schwelle, verbindliche Initiativen zu starten und zu unterschreiben" />
      )
    },
    {
      title: "Mit innn.it wollen wir der selbstbestimmten Gesellschaft ein Stück näher kommen.",
      className: "banner-image-left",
      description: (
        <>
          <p className="mb-4">
            In Zeiten der digitalen Vernetzung kann ein einzelner Mensch ganze soziale Bewegungen
            auslösen. Wir glauben, dass Menschen durch gemeinsames Handeln beginnen, ihren eigenen
            Möglichkeiten wieder stärker zu vertrauen und die Gesellschaft voranzubringen: Veränderung ist möglich!
          </p>
          <a className="btn btn-primary" href="https://verein.innn.it/satzung/" target="_blank" rel="noreferrer">
            Unsere Satzung lesen
          </a>
        </>
      ),
      image: (
        <BannerImage bannerImage={banner2Img} alt="Mit innn.it wollen wir der selbstbestimmten Gesellschaft ein Stück näher kommen." />
      )
    },
    {
      title: "In innn.it stecken die Erfahrung und Expertise eines diversen Teams.",
      className: "banner-image-right",
      description: (
        <>
          <p>
            Mit einem großem Team voller Expert:innen setzen wir uns jeden Tag dafür ein,
            dass Kampagnen Wirkung entfalten und zu einer positiven sozialen Veränderung beitragen.
              </p>
          <p>
            Wir sind ein eingetragener Verein – der innn.it e.V. – mit Sitz in Berlin und zu 100% spendenfinanziert.
          </p>
          <a className="btn btn-primary" href="https://verein.innn.it" target="_blank" rel="noreferrer">
            Über uns
          </a>
        </>
      ),
      image: (
        <div className="banner-img">
          <img
            src={banner3Img}
            alt="In innn.it stecken die Erfahrung und Expertise eines diversen Teams."
          />
        </div>
      )
    },
    {
      title: "Datenschutz und Transparenz leiten unser Handeln.",
      className: "banner-image-left",
      description: (
        <>
         innn.it liegt auf Servern von Ionos in Berlin. Alle Spender:innen-Daten liegen ebenfalls in Deutschland bei Fundraisingbox. Beteiligst Du Dich an Initiativen bei innn.it, fragen wir so wenig Daten wie möglich ab.        
        </>
      ),
      image: (
        <BannerImage bannerImage={banner4Img} alt="Datenschutz und Transparenz leiten unser Handeln" />
      )
    },
  ]

  return (
    <section>
      {bannerData.map((banner, index) => {
        return (
          <div className="container mb-6" key={`banner_${index}`}>
            <div className={`banner banner-image ${banner.className}`} >
              <div className="banner-row">
                {banner.image}
                <div className="banner-body">
                  <div className="banner-text">
                    <h2 className="text-primary mb-4">
                      {banner.title}
                    </h2>
                    {banner.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );

}

const BannerImage = (props) => {
  return (
    <div className="banner-img">
      <div className="page-about-background">
      </div>
      <div className="page-about-image">
        <img
          src={props.bannerImage}
          alt={props.alt}
        />
      </div>
    </div>
  )
}

const Quote = () => {
  return (
    <section className="hero hero-headline bg-gradient-horizontal">
      <div className="hero-container">
        <div className="hero-row">
          <div className="hero-body text-center">
            <h2 className="text-primary mb-4">
              Warum wir Dich brauchen
              </h2>
            <p className="mb-5">
              Werde Förder*in des innn.it e.V. und trage dazu bei, damit wir dieses
              einzigartige Angebot deutschlandweit kostenfrei anbieten können.
              innn.it e.V. ist ein Verein mit Haltung - und Sitz in Berlin.
            </p>
            <DonationLink
              className="btn btn-outline-primary bg-white"
              source="aboutBottom"
            >
              Jetzt spenden ❤️
            </DonationLink>
          </div>
        </div>
      </div>
    </section>
  );
};

const ModerationData = () => {
  const moderationsData = [
    {
      question: "Welche Petitionen und Initiativen werden nicht veröffentlicht?",
      answer: (
        <>
          <p>
            Insbesondere veröffentlichen wir keine Petitionen und Initiativen auf innn.it, die
          </p>
          <ul>
            <li>
              falsche Tatsachenbehauptungen enthalten
            </li>
            <li>Beleidigungen, Beschimpfungen oder menschenverachtende Formulierungen enthalten</li>
            <li>
              Diskriminierungen aufgrund des Geschlechts (einschließlich der Geschlechtsidentität),
              der Sexualität, der Herkunft, der Sprache, der ethnischen Zugehörigkeit, einer Behinderung,
              des religiösen Bekenntnisses oder der politischen Ansichten enthalten
            </li>
            <li>
              Menschen aufgrund einer der vorgenannten Diskriminierungsdimensionen nicht
              respektieren oder lächerlich machen
            </li>
            <li>
              sich an Privatpersonen richten, ohne Bezug auf ihren Beruf oder ihre politische Rolle
            </li>
            <li>
              Spam sind
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "Wie reagiert das Moderationsteam von innn.it?",
      answer: (
        <>
          <p>
            Unser Moderationsteam behält sich das Recht vor, solche Petitionen und Initiativen zu löschen
            oder sie gar nicht erst auf der Seite zu veröffentlichen. Auch Kommentare zu Petitionen und
            Initiativen werden von unserem Team auf der Grundlage dieses Kodexes moderiert.
          </p>
          <p>Wir ermutigen dazu, Quellen zu verlinken, wenn Quellen verwendet wurden.</p>
        </>
      )
    },
    {
      question: "Bitte melde Verstöße oder Probleme",
      answer: (
        <>
          Wenn Du Verstöße gegen diesen Kodex melden möchtest, schreib uns bitte eine kurze
          E-Mail an: <a href="mailto:hallo@innn.it">hallo@innn.it</a> oder verwende
          das unten stehende Formular.
        </>
      )
    }
  ];

  return (
    <div className="container pt-3">
      <div className="row">
        <div className="col-12 col-md-8 offset-md-2">
          <div className="accordion accordion-flush mb-5" id="moderation">
            <div className="accordion-body">
              <h2 className="mb-4">
                <a id="moderationsregeln" className="text-primary">Leitfaden für Moderationsregeln</a>
              </h2>
              <p>innn.it versteht sich als eine inklusive Plattform für Petitionen und Initiativen. Unser e.V.
              fördert das demokratische Staatswesen, Bildung und bürgerschaftliches Engagement.
              Wir stehen für Vielfalt, Weltoffenheit und Toleranz. Wir begrüßen alle Petitionen und Initiativen,
              unabhängig von ihrem Anliegen, solange sie nicht gegen demokratische
              Grundwerte und Menschenrechte verstoßen.</p>
            </div>
            {moderationsData.map((moderation, index) => {
              return (
                <div key={index} className="accordion-item">
                  <h3 className="accordion-header" id={`#heading${index}`}>
                    <button
                      key={index}
                      className="accordion-button collapsed text-start"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="false"
                      aria-controls={`collapse${index}`}
                    >
                      {moderation.question}
                    </button>
                  </h3>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#moderation"
                  >
                    <div className="accordion-body">{moderation.answer}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const About = () => {
  const { setTheme } = useContext(ThemeContext);

  useEffect(() => {
    setTheme("petition");
  }, []);

  return (
    <main className="page-about">
      <Heading />
      <Hero />
      <Banner />
      <Quote />
      <ModerationData />
    </main>
  );
};

export default About;
