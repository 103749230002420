import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import api from "../../../helpers/api";

const DeleteSignatureModal = ({ petitionName, signatureId, setSignatures }) => {
  const [isDeleted, setIsDeleted] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    const handleClose = () => {
      setIsDeleted(false);
    };
  
    const modal = modalRef.current;

    modal.addEventListener("hidden.bs.modal", handleClose);

    return () => {
      modal.removeEventListener("hidden.bs.modal", handleClose);
    };
  }, []);

  const handleDeleteSignature = async() => {
    try {
      await api.deleteSignature(signatureId, (_status, _data) => {
        setSignatures(prevSignatures => prevSignatures.filter(signature => signature.id != signatureId));
        setIsDeleted(true);
      });
    } catch (err) {
      console.log({err});
    }
  };
  
  return (
    <div
      className="modal fade"
      id="deleteSignature"
      tabIndex="-1"
      aria-labelledby="deletedeleteSignatureLabel"
      aria-hidden="true"
      ref={modalRef}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header container">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <FontAwesomeIcon
                className="text-petition-primary"
                fixedWidth
                icon={faTimes}
                size="2x"
              />
            </button>
          </div>
          <section className="modal-body pt-0 px-4">
            {isDeleted ? 
              <div className="px-3">
                <div className="align-item-center d-flex">
                  <FontAwesomeIcon className="icon icon-left text-primary" icon={faCheck} size="2x" />
                  <h3 className="text-primary">Deine Unterschrift wurde gelöscht.</h3>
                </div>
                <div className="d-flex float-end mt-5 mb-4">
                  <button 
                    className="btn btn-lg btn-primary"
                    aria-label="Close"
                    data-bs-dismiss="modal"  
                  >
                    Schließen
                  </button>
                </div>
              </div> 
            :
              <div>
                <h3 className="text-black">Willst Du Deine Unterschrift zu der Petition {" "}
                  <span className="text-primary">{petitionName}</span> {" "}
                  wirklich löschen?
                </h3>
                <div className="d-flex flex-md-row flex-column justify-content-between mb-4 mt-4">
                  <button 
                    className="btn btn-lg btn-outline-secondary text-primary" 
                    aria-label="Close"
                    data-bs-dismiss="modal"
                  >
                    Abbrechen
                  </button>
                  <button 
                    className="btn btn-lg btn-primary mt-md-0 mt-4"
                    type="button"
                    onClick={handleDeleteSignature}  
                  >
                    Jetzt löschen
                  </button>
                </div> 
              </div>    
            }
          </section>
        </div>
      </div>
    </div>
  );
};

export default DeleteSignatureModal;