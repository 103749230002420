import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";

const SuccessMessage = ({ modalRef, type }) => {
  
  const CONTENT = {
    email: {
      ariaLabel:"emailConfirmationLabel",
      id:"emailConfirmation",
      icon: faEnvelope,
      title: "Wir haben Dir eine E-Mail gesendet",
      message: "Klicke einfach auf den Link den wir Dir per E-Mail gesendet haben um Deine E-Mail Adresse zu aktualisieren."
    },
    update: {
      ariaLabel:"linkConfirmationTextLabel",
      id:"linkConfirmationText",
      icon: faCheck,
      title: "Der direkte Link zum Update wurde kopiert.",
      buttonText: "Schließen"
    },
    userProfile: {
      ariaLabel:"userConfirmationLabel",
      id:"userConfirmation",
      icon: faCheck,
      title: "Deine Änderungen wurden gespeichert.",
      buttonText: "Schließen"
    },
  };
  
  return (
    <div
      className="modal fade"
      id={CONTENT[type].id}
      tabIndex="-1"
      aria-labelledby={CONTENT[type].ariaLabel}
      aria-hidden="true"
      ref={modalRef}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header container">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <FontAwesomeIcon
                className="text-petition-primary"
                fixedWidth
                icon={faTimes}
                size="2x"
              />
            </button>
          </div>
          <section className="modal-body pt-0 px-4">
            <div className="px-md-3 px-0">
              <div className="align-item-center d-flex">
                <FontAwesomeIcon className="icon icon-left text-primary" icon={CONTENT[type].icon} size="2x" />
                <h3 className="text-primary">{CONTENT[type].title}</h3>
              </div>
              {type === "email" ?
                <div className="mt-3 px-5">
                  <p className="text-black">
                    {CONTENT[type].message}
                  </p>
                </div>
              :
                <div className="d-flex float-end mb-4 mt-4">
                  <button 
                    className="btn btn-lg btn-primary"
                    aria-label="Close"
                    data-bs-dismiss="modal"  
                  >
                    {CONTENT[type].buttonText}
                  </button>
                </div>
              }
            </div> 
          </section>
        </div>
      </div>
    </div>
  );
};

export default SuccessMessage;