import React, { useContext, useEffect } from "react";
import { ThemeContext } from "../ThemeContext";

const Nutzungsbedingungen = () => {
  const { setTheme } = useContext(ThemeContext);

  useEffect(() => {
    setTheme("initiative");
  }, []);

  return (
    <main>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            <h2 className="text-primary py-4">Nutzungsbedingungen</h2>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <p>innn.it ist eine Plattform für Petitionen und Initiativen, die vom <a href="https://innn.it/impressum">Verein innn.it e.V.</a> mit Sitz in Berlin bereitgestellt wird. Es gelten die folgenden Nutzungsbedingungen:</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Nutzung von innn.it</h3>
            <p>Auf innn.it kannst Du:</p>
            <ul>
              <li>Petitionen unterschreiben</li>
              <li>Initiativen unterstützen</li>
              <li>Initiativen veröffentlichen</li>
              <li>Updates an Unterstützer*innen via E-Mail versenden</li>
              <li>Petitionen und Initiativen teilen (via E-Mail, Whatsapp, etc.)</li>
            </ul>
            <p>Die Nutzung steht allen Privatpersonen und Organisationen offen, die unsere Moderationsregeln beachten.</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Zulässige Inhalte und Empfehlungen </h3>
            <p>Unsere <a href="https://verein.innn.it/moderationsregeln/" target="_blank" rel="noreferrer">Moderationsregeln</a> bestimmen, welche Inhalte zulässig bzw. unzulässig sind. innn.it ist eine inklusive Plattform für Petitionen und Initiativen. Wir stehen für Vielfalt, Weltoffenheit und Toleranz. Wir begrüßen alle Petitionen und Initiativen, unabhängig von ihrem Anliegen, solange sie nicht gegen demokratische Grundwerte und Menschenrechte verstoßen.</p>
            <p>Insbesondere veröffentlichen wir keine Petitionen und Initiativen auf innn.it, die</p>
            <ul>
              <li>falsche Tatsachenbehauptungen enthalten</li>
              <li>Beleidigungen, Beschimpfungen oder menschenverachtende Formulierungen enthalten</li>
              <li>Diskriminierungen aufgrund des Geschlechts (einschließlich der Geschlechtsidentität), der Sexualität, der Herkunft, der Sprache, der ethnischen Zugehörigkeit, einer Behinderung, des religiösen Bekenntnisses oder der politischen Ansichten enthalten</li>
              <li>Menschen aufgrund einer der vorgenannten Diskriminierungsdimensionen nicht respektieren oder lächerlich machen</li>
              <li>sich an Privatpersonen richten, ohne Bezug auf ihren Beruf oder ihre politische Rolle</li>
              <li>Spam sind</li>
            </ul>
            <p>Unser Moderationsteam behält sich das Recht vor, solche Petitionen und Initiativen zu löschen oder sie gar nicht erst auf der Seite zu veröffentlichen. Auch Kommentare zu Petitionen und Initiativen werden von unserem Team auf der Grundlage dieses Kodexes moderiert.</p>
            <p>Wir ermutigen dazu, Quellen zu verlinken, wenn Quellen verwendet wurden.</p>
            <p>Petitionen können vom Kampagnenteam im Namen de:r Petitionsstarter:in als Empfehlung im Newsletter vorgestellt werden. Es besteht kein Anspruch darauf.</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Kostenlose Nutzung</h3>
            <p>Die Nutzung unserer Plattform ist kostenlos und das soll auch so bleiben. Deswegen finanziert sich der innn.it e.V. über Spenden und Förderbeiträge von Nutzer:innen.</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Nutzer:innen-Konto</h3>
            <p>Für die Teilnahme an Petitionen und Initiativen ist ein Nutzer:innen-Konto auf innn.it erforderlich. Ein Konto wird automatisch erstellt, indem Du eine Petition auf innn.it unterschreibst oder Dich aktiv registrierst. Folgende Angaben sind für die Einrichtung des Kontos notwendig:</p>
            <ul>
              <li>Vorname</li>
              <li>Nachname</li>
              <li>E-Mail-Adresse</li>
              <li>ggf. Postleitzahl</li>
              <li>ggf. Ort</li>
              <li>Land</li>
            </ul>
            <p>Nach Erstellung des Kontos, kannst Du Dich jederzeit mit einem Passwort anmelden und die Angaben im Nutzer:innen-Konto eigenständig verwalten. Wenn Du Dich nicht aktiv registriert hast, bekommst Du nach der ersten Unterschrift eine Bestätigungsmail mit einem Link. Du kannst das Passwort sowie die Angaben oben jederzeit ändern. Du kannst außerdem einen Anzeigenamen wählen, wenn Du Petitionen nicht mit Deinem echten Namen unterschreiben oder veröffentlichen möchtest.</p>
            <p>Im Nutzer:innen-Konto kannst Du von Dir veröffentlichte und unterschriebene Petitionen und Initiativen einsehen. Du kannst die Unterschrift zu einer Petition eigenständig entfernen.</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Bearbeitung und Updates zu einer Petition</h3>
            <p>Du kannst eine Petition nach Veröffentlichung bearbeiten, z.B. ein Foto hochladen, Fehler berichtigen oder Quellen hinzufügen. Die nachträgliche Änderung der Petitionsforderung ist nicht zulässig. Du kannst außerdem Neuigkeiten als Updates veröffentlichen. Updates können vom Kampagnenteam an die Unterstützer:innen Deiner Petition verschickt werden. Es besteht kein Anspruch darauf.</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Redaktionelle Bearbeitung</h3>
            <p>Das innn.it-Team kann ohne vorherige Zustimmung Inhalte in Petitionen und Initiativen bearbeiten und löschen, wenn:</p>
            <ul>
              <li>Inhalte gegen die Moderationsregeln, Urheberrecht und geltendes Recht verstoßen</li>
              <li>Links fehlerhaft sind oder zu Webseiten führen, die unsere Moderationsregeln verletzen</li>
              <li>Fotos oder Videos irreführend sind, die Privatsphäre einer Person verletzen oder gegen die Moderationsregeln verstoßen</li>
              <li>Inhalte Rechtschreibfehler aufweisen</li>
            </ul>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Haftung</h3>
            <p>Die Nutzer:innen sind für die auf innn.it eingestellten Inhalte selbst verantwortlich. Für den Inhalt, die Richtigkeit, die Aktualität, die Vollständigkeit sowie die Rechtmäßigkeit der eingestellten Inhalte haftet der innn.it e.V. nicht.</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Geltendes Recht</h3>
            <p>Für die Nutzungsbedingungen und Nutzung der Plattform innn.it gilt das Recht der Bundesrepublik Deutschland.</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Änderungen</h3>
            <p>Der innn.it e.V. behält sich das Recht vor, die Nutzungsbedingungen ohne Vorankündigung zu ändern oder zu ergänzen. Aktueller Stand: 24.04.2024.</p>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Nutzungsbedingungen;