import React from "react";
import { Link, useHistory } from "react-router-dom";
import Attachment from "../../../components/Attachment.js";
import hero from "../../../styles/images/hero-image.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faCheck, faGenderless, faPen } from "@fortawesome/pro-solid-svg-icons";
import formatNumber from "../../../helpers/format-number.js";
import getTarget from "../../../helpers/get-target";


const Card = ({ initiative, setSelectedPetition }) => {
  let history = useHistory();

  const formatDate = (rawDate) => {
    if (!rawDate) {
      return false;
    };

    const dateObject = new Date(rawDate);

    if (!dateObject instanceof Date || isNaN(dateObject)) {
      return false;
    };

    const options = { year: "numeric", month: "2-digit", day: "2-digit" };

    return dateObject.toLocaleDateString("de-DE", options);
  };

  const STATUS_MAP = {
    online: {
      icon: faCheck,
      text: "Online"
    },
    draft: {
      icon: faPen,
      text: "Entwurf"
    },
    ended: {
      icon: faGenderless,
      text: "Deaktiviert"
    },
    success: {
      icon: faBullhorn,
      text: "Erfolg"
    }
  };


  const InitiativeImage = () => {
    if (initiative.featuredImage) {
      return (
        <Attachment
          alt={initiative.title}
          attachment={initiative.featuredImage}
          className="card-img-top"
        />
      );
    } else {
      return <img className="card-img-placeholder" src={hero}></img>;
    }
  };


  const getProgress = (count, target) => {
    return (count / target) * 100;
  };


  const status = () => {
    if (initiative.status === "publish" && initiative.initiativeStatus === "active" && initiative.success) {
      return "success";
    } else if (initiative.status === "publish" && initiative.initiativeStatus === "active") {
      return "online";
    } else if (initiative.status === "publish" && initiative.initiativeStatus === "active") {
      return "ended";
    } else if (initiative.status === "draft") {
      return "draft";
    } else {
      return false;
    };
  };


  return (
    <div className="card h-100">
      <div className="position-relative overflow-hidden" style={{ height: "18rem" }}>
        <div className="bg-white d-flex justify-content-between px-3 pt-2 position-absolute top-0 w-100">
          <h4>{formatDate(initiative.createdAt)}</h4>
          <div>
            {status() && (
              <div>
                <FontAwesomeIcon
                  className="icon-left me-1 text-secondary"
                  fixedWidth
                  icon={STATUS_MAP[status()].icon}
                />
                <span className="h4 text-black">{STATUS_MAP[status()].text}</span>
              </div>
            )}
          </div>
        </div>
        <InitiativeImage />
      </div>
      <div className="card-body">
        {initiative.type === "petition" && (
          <div className="mb-1">
            <div className="d-flex align-items-center mb-1">
              <FontAwesomeIcon
                className="icon-left text-primary"
                fixedWidth
                icon={faPen}
                size="xl"
              />
              <p className="h5 mb-0">
                {formatNumber(initiative.signatureCount)}{" "}
                Unterschriften auf innn.it
              </p>
            </div>
            <div className="progress progress-sm">
              <div
                className="progress-bar"
                style={{
                  width: getProgress(
                    initiative.signatureCount,
                    getTarget(initiative.signatureCount)
                  ) + "%",
                }}
                aria-valuenow="50"
              ></div>
            </div>
          </div>
        )}
        <h2 className="h3 card-title text-black">{initiative.title}</h2>
      </div>
      <div className="card-footer">
        <div className="align-item-center d-flex flex-column w-50">
          <Link
            className="btn btn-sm btn-outline mb-3"
            to={`/petition-starten/${initiative.id}`}
          >
            Bearbeiten
          </Link>

          {/* <Link>
          Updates
        </Link> */}

          <Link
            className={`btn btn-sm btn-outline mb-3 ${initiative.status === "draft" ? "disabled" : ""}`}
            to={`/${initiative.name}/#share`}
          >
            Teilen
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card;
