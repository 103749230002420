import { formatDistanceToNow } from "date-fns";
import { de } from "date-fns/locale";
import parse from "html-react-parser";
import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faCalendar, faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { faChevronDown, faChevronUp, faComment, faGlobe, faMapMarkerAlt, faPhone } from "@fortawesome/pro-solid-svg-icons";
import api from "../../helpers/api";
import Attachment from "../../components/Attachment";
import formatDate from "../../helpers/format-date";
import { getMe } from "../../redux/users";
import InitiativeShare from "./InitiativeShare";

const InitiativeTabs = ({ initiative, shareUrl,  updateId, ...rest }) => {
  const endDateFormatted = formatDate(initiative.endDates?.[initiative.stage]);
  const tabsProps = [
    {
      active: true,
      id: "content",
      label: "Infos zur Initiative",
      types: ["initiative"],
    },
    {
      active: true,
      id: "content",
      label: "Infos zur Petition",
      types: ["petition"],
    },
    {
      className: "",
      id: "updates",
      label: "Updates",
      types: ["initiative", "petition"],
      ref: "updateRef",
      statuses: ["publish"],
    },
    {
      className: "",
      id: "contact",
      label: "Kontaktdaten",
      types: ["initiative"],
    },
    {
      className: "",
      id: "share",
      label: "Teile diese Petition",
      types: ["petition"],
      ref: 'shareRef',
      statuses: ["publish"],
    }
  ];

  const navItems = tabsProps.map((tab, index) => {
    if (
      !tab.types.includes(initiative.type) ||
      (tab.statuses && !tab.statuses.includes(initiative.status))
    ) {
      return;
    }

    const tabTitleCase = tab.id.charAt(0).toUpperCase() + tab.id.substr(1).toLowerCase();

    let extraAttributes = {};
    if (tab.ref) {
      extraAttributes.ref = rest[tab.ref];
    }

    return (
      <li key={tab.id} className="nav-item" role="presentation">
        <button
          aria-controls={tab.id}
          aria-selected={0 == index ? "true" : "false"}
          className={`nav-link ${tab.active ? "active" : ""}`}
          data-bs-toggle="tab"
          data-bs-target={`#initiativeTabsContent${tabTitleCase}`}
          id={`initiativeTabsNav${tabTitleCase}`}
          role="tab"
          type="button"
          {...extraAttributes}
        >
          <span>{tab.label}</span>
        </button>
      </li>
    );
  });

  const Nav = () => {
    return (
      <ul
        className="nav nav-tabs nav-pills nav-fill mb-5"
        id="initiativeTabsNav"
        role="tablist"
      >
        {navItems}
      </ul>
    )
  }

  const getTabContent = (tab) => {
    switch (tab) {
      case "content":
        return (
          <>
            {initiative.content && parse(initiative.content)}

            {endDateFormatted &&
              initiative.optionalFields &&
              initiative.optionalFields.endDate && (
                <p className="text-primary fw-bolder">
                  Einsendeschluss Unterschriften: {endDateFormatted}
                </p>
              )}
          </>
        );
        break;
      case "updates":
        return <Updates initiative={initiative} updateId={updateId} updateRef={rest.updateRef} />
        break;
      case "contact":
        if (!initiative.contact) {
          return;
        }

        return (
          <>
            {"string" == typeof initiative.contact && (
              parse(initiative.contact)
            )}

            {"object" == typeof initiative.contact && (
              <>
                {initiative.contact.address && (
                  <div className="d-flex mb-3">
                    <FontAwesomeIcon aria-label="Addresse" className="icon icon-left text-primary" icon={faMapMarkerAlt} />
                    <span style={{ whiteSpace: "pre-wrap" }}>{initiative.contact.address}</span>
                  </div>
                )}
                {initiative.contact.phone && (
                  <div className="d-flex mb-3">
                    <FontAwesomeIcon aria-label="Telefonnummer" className="icon icon-left text-primary" icon={faPhone} />
                    <a href={`tel:${initiative.contact.phone}`}>{initiative.contact.phone}</a>
                  </div>
                )}
                {initiative.contact.url && (
                  <div className="d-flex mb-3">
                    <FontAwesomeIcon aria-label="Webseite" className="icon icon-left text-primary" icon={faGlobe} />
                    <a
                      href={initiative.contact.url}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {initiative.contact.url}
                    </a>
                  </div>
                )}
                {initiative.contact.email && (
                  <div className="d-flex mb-3">
                    <FontAwesomeIcon aria-label="E-Mail-Addresse" className="icon icon-left text-primary" icon={faEnvelope} />
                    <a href={`mailto:${initiative.contact.email}`}>{initiative.contact.email}</a>
                  </div>
                )}
                {initiative.contact.facebook && (
                  <div className="d-flex mb-3">
                    <FontAwesomeIcon aria-label="Facebook" className="icon icon-left text-primary" icon={faFacebook} />
                    <a
                      href={`https://facebook.com/${initiative.contact.facebook}`}
                      rel="noreferrer"
                      target="_blank">
                      /{initiative.contact.facebook}
                    </a>
                  </div>
                )}
                {initiative.contact.twitter && (
                  <div className="d-flex mb-3">
                    <FontAwesomeIcon aria-label="Twitter" className="icon icon-left text-primary" icon={faTwitter} />
                    <a
                      href={`https://twitter.com/${initiative.contact.twitter}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      @{initiative.contact.twitter}
                    </a>
                  </div>
                )}
                {initiative.contact.instagram && (
                  <div className="d-flex mb-3">
                    <FontAwesomeIcon aria-label="Instagram" className="icon icon-left text-primary" icon={faInstagram} />
                    <a
                      href={`https://instagram.com/${initiative.contact.instagram}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      @{initiative.contact.instagram}
                    </a>
                  </div>
                )}
              </>
            )}
          </>
        )
        break;
      case "share":
        return (
          <InitiativeShare shareUrl={shareUrl} initiative={initiative} />
        )
        break;
      default:
        break;
    }
  }

  const tabItems = tabsProps.map((tab, index) => {
    if (!tab.types.includes(initiative.type)) {
      return;
    }

    const tabTitleCase = tab.id.charAt(0).toUpperCase() + tab.id.substr(1).toLowerCase();

    return (
      <section
        aria-labelledby={`initiativeTabsNav${tabTitleCase}`}
        className={`tab-pane fade mb-6 px-0 ${tab.active ? "show active" : ""}`}
        id={`initiativeTabsContent${tabTitleCase}`}
        key={tab.id}
        role="tabpanel"
      >
        {getTabContent(tab.id)}
      </section>
    )
  })

  const Tabs = () => {
    return (
      <div className="tab-content" id="initiativeTabsContent">
        {tabItems}
      </div>
    )
  }

  return (
    <>
      <Nav />
      <Tabs />
    </>
  );
};

const Updates = ({ initiative, updateId }) => {
  const PATHS = {
    initiative: "initiatives",
    petition: "petitions",
  };

  const refs = [];
  const [updates, setUpdates] = useState([]);
  const [expandedContent, setExpandedContent] = useState()
  const [hasNextPage, setHasNextPage] = useState(true);
  const [offset, setOffset] = useState(0);

  let history = useHistory();

  const users = useSelector(state => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!users.me) {
      dispatch(getMe());
    }
  });

  useEffect(() => {
    let unmounted = false;
    (async () => {
      if (!initiative || !initiative.id) {
        return
      }

      try {
        if (updateId) {
          await api.getUpdate(
            initiative.id,
            updateId,
            (_status, data) => {
              if (!unmounted) {
                setHasNextPage(false)
                setUpdates([data]);
              }
            }
          );
        } else {
          await api.getUpdates(
            initiative.id,
            { limit: 5, offset },
            (_status, data) => {
              if (data.length === 0 && !unmounted) {
                setHasNextPage(false)
              }
              if (!unmounted) {
                setUpdates(prevUpdates => [...prevUpdates, ...data]);
              }
            }
          );
        }
      } catch (error) {
        if (error.response) {
          // server responded with a status code that falls out of the range of 2xx
          if (error.response.status === 404 && updateId) {
            goToUpdates();
          }
        } else if (error.request) {
          // client never received a response, or request never left
        } else {
          // anything else
        }
      }
    })();
    return () => {
      unmounted = true;
    }
  }, [offset, initiative]);

  const goToUpdates = () => {
    history.replace({
      pathname: `/${initiative.name}/updates`,
    });
  }

  const loadMore = () => {
    if (updateId) {
      goToUpdates();
    } else {
      setOffset(prev =>  prev + 5);
    }  
  }

  const parseDate = (rawDate) => {
    if (!rawDate) {
      return false;
    }
    return new Date(rawDate);
  };

  return (
    <>
      <h3 className="text-secondary">Neuste Nachrichten</h3>
      {users.me?.id && <Link to={`/dashboard/${PATHS[initiative.type]}/${initiative.name}/updates/new`} className="btn btn-outline-primary mb-5">
        Neues Update hinzufügen
      </Link>
      }

      {updates && Array.isArray(updates) && updates.length > 0 ? (
        <div>
          {updates.map((update) => {
            const hasCustomAuthor = update.customAuthor?.enabled && update.customAuthor?.name;
            const isContentExpanded = expandedContent?.[update.id];
            const ref = createRef();
            refs.push(ref);
            return (
              <div className="card-body" key={update.id} ref={ref}>
                <div className="card card-large mb-6 pb-3">
                  {update.featuredImage && (
                    <Attachment
                      className="w-100 mb-5"
                      attachment={update.featuredImage}
                      alt={update.title}
                    />
                  )}
                  <div className="px-4">
                    <h3 className="card-title text-primary">{update.title}</h3>
                    <div className="card-text">{(isContentExpanded || update.content.length < 280) ? parse(update.content) : parse(`${update.content.substr(0, 280)}...`)}</div>
                    <div className="d-flex flex-wrap justify-content-between mt-5">
                      <div className="align-items-center d-flex mb-md-0 mb-4">
                        <FontAwesomeIcon className="text-primary" icon={hasCustomAuthor ? faCalendar : faComment} />
                        <div className="ms-2">
                          {hasCustomAuthor ? <span>{update.customAuthor.name}</span> : <span>{initiative.title}</span>}
                          <div>{formatDistanceToNow(parseDate(update.createdAt), { addSuffix: true, locale: de })}</div>
                        </div>
                      </div>

                      {update.content?.length >= 280 && (
                        isContentExpanded ?
                          <div role="button" onClick={() => {
                            setExpandedContent({ ...expandedContent, [update.id]: false });
                            const yOffset = -80;
                            const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: "smooth" });
                          }}>
                            <FontAwesomeIcon className="text-primary" icon={faChevronUp} />
                            <span className="ms-2 text-primary">Weniger anzeigen</span>
                          </div>
                          :
                          <div role="button" onClick={() => setExpandedContent({ ...expandedContent, [update.id]: true })}>
                            <FontAwesomeIcon className="text-primary" icon={faChevronDown} />
                            <span className="ms-2 text-primary">Mehr anzeigen</span>
                          </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}

          {hasNextPage && (
            <div className="mt-5 text-center">
              <button className="btn btn-primary" onClick={loadMore}>
                Mehr laden
              </button>
            </div>
          )}
          {updateId && (
            <div className="mt-5 text-center">
              <button className="btn btn-primary" onClick={loadMore}>
                Aktuelle Updates anzeigen
              </button>
            </div>
          )}
        </div>
      )
        :
        <h3 className="text-primary">Noch keine Updates vorhanden</h3>
      }
    </>
  )
}

export default InitiativeTabs;
