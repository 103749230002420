import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import notfoundImage from "../styles/images/not-found.svg";
import { ThemeContext } from "../ThemeContext";

const NotFound = () => {
  const { setTheme } = useContext(ThemeContext);

  useEffect(() => {
    setTheme("initiative");
  }, []);

  return (
    <div className="row bg-secondary text-center pt-5 pb-5">
      <div className="container pt-4">
        <div className="mb-5">
          <img src={notfoundImage} alt="innn.it-img"></img>
        </div>
        <div className="text-center">
          <h1 className="text-primary mb-5">
            Oha! Hier ist etwas schief gelaufen.
          </h1>
          <p className="text-primary pb-4">
            Demokratie ist manchmal ein bisschen ruckelig. Wir arbeiten dran.
          </p>
          <Link to="/" className="btn btn-primary mb-5">
            Zurück zur Startseite
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
