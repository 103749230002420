import React from "react";

const InitiativeType = (props) => {
  const initiative = props.initiative;

  const fetchInitiativeType = () => {
    switch (initiative.initiativeType) {
      case "buergerbegehren":
        return "Bürgerbegehren";
        break;

      case "einwohnerinnen-antrag":
        return "Einwohner:innen-Antrag";
        break;

      case "volksantrag":
        return "Volksantrag";
        break;

      case "volksbegehren":
        return "Volksbegehren";
        break;

      case "volksinitiative":
        return "Volksinitiative";
        break;

      default:
        return false;
        break;
    }
  };

  const initiativeType = fetchInitiativeType();

  return (
    initiativeType && (<span className="status status-bg">{initiativeType}</span>)
  );
};

export default InitiativeType;
