import React, {useState, useEffect} from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronLeft, faTimes, faUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import api from "../../../helpers/api";

const InitiativeUpdates = () => {
  let history = useHistory();
  let { id } = useParams();
  let location = useLocation();
  const [open, setOpen] = useState(true);
  const [updates, setUpdates] = useState([]);

  const paths = location.pathname?.split("/");
  const modelPath = paths[2];

  const formatDate = (rawDate) => {
    if (!rawDate) {
      return false;
    }

    const dateObject = new Date(rawDate);

    if (!dateObject instanceof Date || isNaN(dateObject)) {
      return false;
    }

    const options = {year: 'numeric', month: 'numeric', day: 'numeric' }
    
    return dateObject.toLocaleDateString("de-DE", options);
  };

  useEffect(() => {
    (async () => {
      try {
        await api.getUpdates(
          id,
          {status: ["publish", "draft"]},
          (_status, data) => {
            setUpdates(data);
          }
        );
      } catch (error) {}
    })();
  }, [id]);

  const handlePublish = async (updateId) => {
    try {
      await api.updateUpdate(
        id,
        updateId,
        { status: "publish" },
        (_status, data) => {
          setUpdates(prevUpdates => {
            return prevUpdates.map(update => {
              if (update.id === updateId){
                return data
              }
              return update;
            });
          })

          history.push({
            pathname: `/dashboard/${modelPath}/${id}/updates`,
            state: {
              action: "publish",
              update: data,
            }
          });
        }
      );
    } catch (error) {
      if (error.response) {
        // Handle error
      }
    }
  };
  
  const messages = {
    draft: "Dein Entwurf wurde gespeichert",
    email: "Deine E-Mail wurde versendet",
    publish: "Dein Entwurf wurde veröffentlicht",
    save: "Deine Änderungen wurden gespeichert",
  };

  return (
    <main className="dashboard">
      {Object.keys(messages).includes(location.state?.action) && open && (
        <section className="bg-secondary mb-5 mt-n5">
          <div className="container d-flex py-4">
            <FontAwesomeIcon className="icon icon-left text-success mb-1" icon={faCheck} size="xl"/>
            <div className="d-flex flex-wrap flex-md-nowrap flex-grow-1">
              <span className="h3 mb-md-0 mb-5 text-primary">
                {messages[location.state?.action]}
              </span>
            </div>
            <span onClick={(e) => setOpen(false)} role="button">
              <FontAwesomeIcon className="text-primary" icon={faTimes} size="lg"/>
            </span>
          </div>
        </section>
      )}
      <section className=" align-items-center container d-flex justify-content-between">
        <div onClick={(e) => history.push(`/dashboard/${modelPath}`)}>
          <FontAwesomeIcon className="icon icon-left text-primary mb-1" icon={faChevronLeft} size="sm"/>
          <span className="mb-md-0 mb-5 text-primary">
            Zurück zur Übersicht
          </span>
        </div>
        <Link
          to={`/dashboard/${modelPath}/${id}/updates/new`}
          className="btn btn-lg btn-primary"
        >
          Neues Update posten
        </Link>
      </section>
      <section className="mt-5  px-7">
        {(!updates || !Array.isArray(updates) || 0 == updates.length) && (
          <p>No updates here</p>
        )}

        {updates && Array.isArray(updates) && updates.map((update) => (
          <div className="container m-auto border-bottom d-flex align-items-center justify-content-between py-2" key={update.id}>
            <Link to={`/dashboard/${modelPath}/${id}/updates/${update.id}`} className="col-md-4">{update.title}</Link>
            <span className="col-md-2">{formatDate(update.createdAt)}</span>
            <span className="col-md-2">
              <Link to={`/${id}/updates/${update.name}`} target="_blank" rel="noopener noreferrer">
                Link <FontAwesomeIcon icon={faUpRightFromSquare} />
              </Link>
            </span>
            {update.status === "draft" 
              ? 
                <button
                  type="button"
                  className="col-md-3 btn btn-outline-primary"
                  onClick={() => handlePublish(update.id)}
                >
                  Veröffentlichen
                </button>
              :
                <Link to={`/dashboard/${modelPath}/${id}/updates/${update.id}`} className="col-md-3 btn btn-outline-primary">
                  Bearbeiten
                </Link>
            } 
          </div>
        ))}
      </section>
    </main>
  );
};

export default InitiativeUpdates;
