import React from "react";

const Backgrounds = () => {
  return (
    <section className="mt-5">
      <h2>Text</h2>
      <div className="row">
        <div className="col-md-6 petition">
          <h3>Petitions</h3>
          <div className="row">
            <div className="col-md-6">
              <div className="ratio ratio-21x9 mb-2">
                <div className="text-primary text-center d-flex align-items-center justify-content-center h-100 img-thumbnail round-3 w-100">Lorem ipsum dolor sit amet</div>
              </div>
              Petition primary
              <br /><code>.text-petition-primary</code>
              <br /><code>.petition .text-primary</code>
            </div>
            <div className="col-md-6">
              <div className="ratio ratio-21x9 mb-2">
                <div className="text-secondary text-center d-flex align-items-center justify-content-center h-100 img-thumbnail round-3 w-100">Lorem ipsum dolor sit amet</div>
              </div>
              Petition secondary
              <br /><code>.text-petition-secondary</code>
              <br /><code>.petition .text-secondary</code>
            </div>
          </div>
        </div>
        <div className="col-md-6 initiative">
          <h3>Initiatives</h3>
          <div className="row">
            <div className="col-md-6">
              <div className="ratio ratio-21x9 mb-2">
                <div className="text-primary text-center d-flex align-items-center justify-content-center h-100 img-thumbnail round-3 w-100">Lorem ipsum dolor sit amet</div>
              </div>
              Initiative primary
              <br /><code>.text-initiative-primary</code>
              <br /><code>.initiative .text-primary</code>
            </div>
            <div className="col-md-6">
              <div className="ratio ratio-21x9 mb-2">
                <div className="text-secondary text-center d-flex align-items-center justify-content-center h-100 img-thumbnail round-3 w-100">Lorem ipsum dolor sit amet</div>
              </div>
              Initiative secondary
              <br /><code>.text-initiative-secondary</code>
              <br /><code>.initiative .text-secondary</code>
            </div>
          </div>
        </div>
      </div>

      <h2>Backgrounds</h2>
      <div className="row">
        <div className="col-md-6 petition">
          <h3>Petitions</h3>
          <div className="row">
            <div className="col-md-6">
              <div className="ratio ratio-21x9 mb-2">
                <div className="bg-primary h-100 img-thumbnail round-3 w-100"></div>
              </div>
              Petition primary
              <br /><code>.bg-petition-primary</code>
              <br /><code>.petition .bg-primary</code>
            </div>
            <div className="col-md-6">
              <div className="ratio ratio-21x9 mb-2">
                <div className="bg-secondary h-100 img-thumbnail round-3 w-100"></div>
              </div>
              Petition secondary
              <br /><code>.bg-petition-secondary</code>
              <br /><code>.petition .bg-secondary</code>
            </div>
          </div>
        </div>
        <div className="col-md-6 initiative">
          <h3>Initiatives</h3>
          <div className="row">
            <div className="col-md-6">
              <div className="ratio ratio-21x9 mb-2">
                <div className="bg-primary h-100 img-thumbnail round-3 w-100"></div>
              </div>
              Initiative primary
              <br /><code>.bg-initiative-primary</code>
              <br /><code>.initiative .bg-primary</code>
            </div>
            <div className="col-md-6">
              <div className="ratio ratio-21x9 mb-2">
                <div className="bg-secondary h-100 img-thumbnail round-3 w-100"></div>
              </div>
              Initiative secondary
              <br /><code>.bg-initiative-secondary</code>
              <br /><code>.initiative .bg-secondary</code>
            </div>
          </div>
        </div>
      </div>

      <h2>Gradient backgrounds</h2>
      <h3>Petitions</h3>
      <div className="row">
        <div className="col-md-4">
          <h6>Horizontal</h6>
          <div className="ratio ratio-16x9">
            <div className="h-100 w-100">
              <div className="bg-gradient-horizontal-petition h-100 img-thumbnail round-3 w-100"></div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <h6>Vertical</h6>
          <div className="ratio ratio-16x9" style={{ "--bs-aspect-ratio": "177.7%" }}>
            <div className="h-100 w-100">
              <div className="bg-gradient-vertical-petition h-100 img-thumbnail round-3 w-100"></div>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <h6>Full</h6>
          <div className="ratio ratio-16x9">
            <div className="h-100 w-100">
              <div className="bg-gradient-full-petition h-100 img-thumbnail round-3 w-100"></div>
            </div>
          </div>
        </div>
      </div>
      <h3>Initiatives</h3>
      <div className="row">
        <div className="col-md-4">
          <h6>Horizontal</h6>
          <div className="ratio ratio-16x9">
            <div className="h-100 w-100">
              <div className="bg-gradient-horizontal-initiative h-100 img-thumbnail round-3 w-100"></div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <h6>Vertical</h6>
          <div className="ratio ratio-16x9" style={{ "--bs-aspect-ratio": "177.7%" }}>
            <div className="h-100 w-100">
              <div className="bg-gradient-vertical-initiative h-100 img-thumbnail round-3 w-100"></div>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <h6>Full</h6>
          <div className="ratio ratio-16x9">
            <div className="h-100 w-100">
              <div className="bg-gradient-full-initiative h-100 img-thumbnail round-3 w-100"></div>
            </div>
          </div>
        </div>
      </div>
    </section >
  );
};

export default Backgrounds;
