import React from "react";

const Avatars = () => {
  return (
    <>
      <h3>Avatars</h3>
      <div className="row">
        <div className="col-lg-6 col-12 mb-5">
          <h6>Photo</h6>
          <div className="row align-items-center">
            <div className="col-5">
              <div className="avatar avatar-lg">
                <img
                  src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                  alt="avatar-lg"
                />
                <div className="avatar-dot"></div>
              </div>
            </div>
            <div className="col-4">
              <div className="avatar">
                <img
                  src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                  alt="avatar"
                />
                <div className="avatar-dot"></div>
              </div>
            </div>
            <div className="col-3">
              <div className="avatar avatar-sm">
                <img
                  src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                  alt="avatar-sm"
                />
                <div className="avatar-dot"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12 mb-5">
          <h6 className="mt-4 mt-md-0">Initials</h6>
          <div className="row align-items-center">
            <div className="col-5">
              <div className="avatar avatar-lg">
                <div className="avatar-text">AA</div>
                <div className="avatar-dot"></div>
              </div>
            </div>
            <div className="col-4">
              <div className="avatar">
                <div className="avatar-text">AA</div>
                <div className="avatar-dot"></div>
              </div>
            </div>
            <div className="col-3">
              <div className="avatar avatar-sm">
                <div className="avatar-text">AA</div>
                <div className="avatar-dot"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Avatars;
