import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useHistory  } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import Banner from "./components/Banner";
import { getMe } from "../../redux/users";
import Tab from "./components/Tab";
import { ThemeContext } from "../../ThemeContext";
import PetitionCards from "./components/PetitionCards";
import Profile from "./components/Profile";
import Signatures from "./components/Signatures";
import api from "../../helpers/api";
import { populateLocation } from "../../helpers/populateLocation";

const Index = () => {
  const [initiatives, setInitiatives] = useState([]);
  const [isShowPetitionButton, setIsShowPetitionButton] = useState(true);
  const [isShowUserProfile, setIsShowUserProfile] = useState(false);
  const { setTheme } = useContext(ThemeContext);
  const [user, setUser] = useState({});

  const location = useLocation();
  let history = useHistory();
  const pathName = location.pathname;
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    setTheme("petition");
  }, []);

  useEffect(() => {
    if (!users.me) {
      dispatch(getMe());
    };
  }, []);

  useEffect(async () => {
    try {
      if (!user?.me?.id) {
        history.replace(location.pathname, {
          loginRequired: true,
        });
        return;
      };
  
      await api.me(async (_status, data) => {
        const user = await populateLocation(data.me);
        delete data.role;
        setUser(user);
      });
    } catch (error) {
      console.log(error);
    }
  }, [location?.pathname, user?.me?.id]);

  const activeTab = pathName.includes("/profile/signatures") ? "signatures" : "petitions";

  return (
    <>
      <Banner user={user} initiatives={initiatives} />
      <main className="container">
        {isShowUserProfile ?
          <Profile setIsShowUserProfile={setIsShowUserProfile} user={user} setUser={setUser} />
          :
          <section>
            <section className="border-bottom border-secondary py-5 pb-4">
              <h2>Hallo {user?.firstName}!</h2>
              {user.location && user?.location?.name &&
                <div className="align-items-center d-flex mb-4">
                  <FontAwesomeIcon
                    className="me-2 text-primary"
                    fixedWidth
                    icon={faMapMarkerAlt}
                  />
                  <span>{user?.location?.name}</span>
                </div>
              }
              <Link
                className="pointer text-primary"
                to="/profile/edit"
              >
                Persönliche Daten bearbeiten
              </Link>
            </section>
            <Tab activeTab={activeTab} initiatives={initiatives} isShowPetitionButton={isShowPetitionButton} />
            <div className="tab-content" id="initiativeTabsNav">
              <PetitionCards
                isActive={activeTab === "petitions"}
                initiatives={initiatives}
                setInitiatives={setInitiatives}
                setIsShowPetitionButton={setIsShowPetitionButton}
              />
              <Signatures isActive={activeTab === "signatures"} pathName={pathName} />
            </div>
          </section>
        }
      </main>
    </>
  );
};

export default Index;
