import api from "../../../../helpers/api";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

const StepFour = ({ handleChange, handleNextStep, initiative, model }) => {
  const [initiators, setInitiators] = useState([]);
  const [targets, setTargets] = useState([]);
  const [isLoadingInitiators, setIsLoadingInitiators] = useState(false);
  const [isLoadingTargets, setIsLoadingTargets] = useState(false);

  const getInitiators = async (query) => {
    setIsLoadingInitiators(true);

    await api.getActors({ search: query }, async (_status, data) => {
      if (!data || !Array.isArray(data)) {
        setInitiators([]);
        return false;
      }

      setInitiators(data);
    });

    setIsLoadingInitiators(false);
  }

  const getTargets = async (query) => {
    setIsLoadingTargets(true);

    await api.getActors({ search: query }, async (_status, data) => {
      if (!data || !Array.isArray(data)) {
        setTargets([]);
        return false;
      }

      setTargets(data);
    });

    setIsLoadingTargets(false);
  }

  return (
    <>
      {"initiative" == model && <div>
        <h3 className="text-primary">Kontakt</h3>
        {/* <div className="mb-4">
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua.
      </div> */}
        <div className="mb-3">
          <label className="pb-2 text-primary">Vollsändige Adresse</label>
          <textarea
            type="text"
            className="form-control mb-3"
            defaultValue={initiative.contact?.address}
            name="contact.address"
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="pb-2 text-primary">Telefonnummer</label>
          <input
            type="text"
            className="form-control"
            defaultValue={initiative.contact?.phone}
            name="contact.phone"
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="pb-2 text-primary">E-Mail Adresse</label>
          <input
            type="text"
            className="form-control"
            defaultValue={initiative.contact?.email}
            name="contact.email"
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="text-primary">Webseite</label>
          <input
            type="text"
            className="form-control"
            defaultValue={initiative.contact?.url}
            name="contact.url"
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="pb-2 text-primary">Facebook</label>
          <div className="input-group mb-3">
            <span className="input-group-text w-25">facebook.com/</span>
            <input
              type="text"
              className="form-control"
              defaultValue={initiative.contact?.facebook}
              name="contact.facebook"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-3">
          <label className="pb-2 text-primary">Instagram</label>
          <div className="input-group mb-3">
            <span className="input-group-text w-25">instagram.com/</span>
            <input
              type="text"
              className="form-control"
              defaultValue={initiative.contact?.instagram}
              name="contact.instagram"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-3">
          <label className="pb-2 text-primary">Twitter</label>
          <div className="input-group mb-3">
            <span className="input-group-text w-25">twitter.com/</span>
            <input
              type="text"
              className="form-control"
              defaultValue={initiative.contact?.twitter}
              name="contact.twitter"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>}

      {"petition" == model && <div>
        <div className="mb-5">
          <label className="h3 text-primary" htmlFor="displayName">
            Initiator display name{" "}
            {initiative.initiators?.[0]?.email && (
              <span class="badge rounded-pill text-bg-secondary">{initiative.initiators?.[0]?.email}</span>
            )}
          </label>
          <input
            type="text"
            className="form-control mb-3"
            id="displayName"
            name="displayName"
            onChange={handleChange}
            value={initiative.initiators?.[0]?.displayName}
          />
        </div>
        {/* <div className="mb-5">
          <label className="h3 text-primary">Initiatoren/Initiatorinnen</label>
          <AsyncTypeahead
            filterBy={() => true}
            id="initiativeInitiators"
            labelKey="name"
            onChange={(value) => {
              handleChange({ target: { name: "initiators", value } })
            }}
            options={initiators}
            isLoading={isLoadingInitiators}
            maxHeight="330px"
            minLength={3}
            multiple
            onSearch={getInitiators}
            placeholder=""
            renderMenuItemChildren={(option, props, _index) => {
              return (
                <div className="clearfix">
                  <div className="float-start text-wrap">
                    <Highlighter search={props.text}>
                      {option.name}
                    </Highlighter>
                  </div>
                </div>
              )
            }}
            selected={initiative?.initiators}
          />
        </div> */}

      <div className="mb-5">
        <label className="h3 text-primary">Entscheider:innen</label>
        <AsyncTypeahead
          filterBy={() => true}
          id="initiativeTargets"
          labelKey="name"
          onChange={(value) => {
            handleChange({ target: { name: "targets", value } })
          }}
          options={targets}
          isLoading={isLoadingTargets}
          maxHeight="330px"
          minLength={3}
          multiple
          onSearch={getTargets}
          placeholder=""
          renderMenuItemChildren={(option, props, _index) => {
            return (
              <div className="clearfix">
                <div className="float-start text-wrap">
                  <Highlighter search={props.text}>
                    {option.name}
                  </Highlighter>
                </div>
              </div>
            )
          }}
          selected={initiative?.targets}
        />
      </div>
    </div >}
<div className="text-center text-md-end mt-5">
  <button
    className="btn btn-lg btn-primary"
    onClick={() => handleNextStep()}
    type="button"
  >
    Weiter
  </button>
</div>
    </>
  );
};

StepFour.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  initiative: PropTypes.object.isRequired,
};

export default StepFour;
