import React, { useRef, useState } from "react";
import api from "../helpers/api";

const Newsletter = (props) => {
  let fieldsetRef = useRef();

  const subscriberState = {
    email: props.value || "",
    source: props.source || "undefined",
  };

  const [subscriber, setSubscriber] = useState(subscriberState);
  const [error, setError] = useState({});
  const [isSuccessful, setIsSuccessful] = useState(false);

  const handleChange = (e) => {
    setSubscriber((subscriber) => ({
      ...subscriber,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({});

    if (fieldsetRef.current) {
      fieldsetRef.current.setAttribute("disabled", "disabled");
    }

    if (isFormValid()) {
      try {
        await api.subscribeNewsletter(subscriber, () => {
          setIsSuccessful(true);
        });
      } catch (error) {
        if (fieldsetRef.current) {
          fieldsetRef.current.removeAttribute("disabled");
        }

        setError((error) => {
          return {
            ...error,
            serverError: props.errorMessage
              ? props.errorMessage
              : "Es ist ein Fehler aufgetreten. Bitte, versuche es später noch einmal.",
          };
        });
      }
    } else {
      if (fieldsetRef.current) {
        fieldsetRef.current.removeAttribute("disabled");
      }
    }
  };

  const isFormValid = () => {
    let result = true;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!subscriber.email.match(emailRegex)) {
      result = false;
      setError((error) => {
        return {
          ...error,
          email: "Bitte gib eine gültige E-Mail ein",
        };
      });
    }
    if (!isPostalCodeValid()) {
      result = false;
      setError((error) => {
        return {
          ...error,
          location: "Zu dieser Postleitzahl konnte kein Ort gefunden werden.",
        };
      });
    }
    
    return result;
  };

  const isPostalCodeValid = () => {
    if (subscriber.rawPostalCode) {
      if (subscriber.rawPostalCode.length < 5) {
        return false;
      } else {
        if (!subscriber.location || !subscriber.postalCode) {
          return false
        }
      }
    }
    return true;
  }

  return (
    <form onSubmit={(e) => handleSubmit(e, subscriber)}>
      <fieldset
        className={`form-icon form-icon-right collapse fade ${
          isSuccessful === false ? "show" : ""
        } ${props.inputClass || ""}`}
        ref={fieldsetRef}
      >
        <props.NewsletterFields
          {...props.fieldsProps}
          subscriber={subscriber}
          handleChange={handleChange}
          error={error}
          setError={setError}
        />
      </fieldset>
      <div
        className={`collapse fade ${isSuccessful ? "show" : ""}`}
        type="submit"
      >
        {props.successMessage ? (
          <> {props.successMessage} </>
        ) : (
          <h5 className="text-success">
            Danke! Du bekommst eine Bestätigungsmail.
          </h5>
        )}
      </div>
    </form>
  );
};

export default Newsletter;
