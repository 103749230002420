import countries from "i18n-iso-countries";
import deLocale from "i18n-iso-countries/langs/de.json";
import fileDownload from "js-file-download";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Modal } from "bootstrap";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPen } from "@fortawesome/pro-solid-svg-icons";
import { getMe } from "../../redux/users";
import api from "../../helpers/api";
import formatDate from "../../helpers/format-date";
import NoteBox from "../../components/NoteBox";

const FIELD_LABELS = {
  firstName: "Vorname",
  lastName: "Nachname",
  email: "E-Mail",
};

const REQUIRED_FIELDS = {
  any: ["email", "postalCode", "location"],
  petition: {
    active: ["country", "firstName", "lastName", "subscribe"],
    canceled: ["subscribe"],
    ended: ["subscribe"],
    success: ["subscribe"],
  },
  support: {
    active: ["firstName", "lastName", "subscribe"],
    canceled: ["subscribe"],
    ended: ["subscribe"],
    success: ["subscribe"],
  },
  collection: {
    active: [],
    canceled: ["subscribe"],
    ended: ["subscribe"],
    success: ["subscribe"],
  },
};

countries.registerLocale(deLocale);
const countryObj = countries.getNames("de", { select: "official" });

const SignatureForm = ({ initiative, modal, shareUrl }) => {
  const CONTENT = {
    petition: {
      active: {
        title: "Jetzt unterstützen!",
        customMessage: "",
        message: "",
        buttonLabel: "Jetzt unterschreiben",
      },
      canceled: {
        title: "Sammlung beendet!",
        message:
          "Wenn Du trotzdem über dieses Thema und zukünftige Entscheide in Deiner Region auf dem Laufenden gehalten werden möchtest, trage unten Deine E-Mail-Adresse ein.",
        buttonLabel: "Ich will informiert bleiben",
      },
      notStarted: {
        title: "",
        message: "",
        buttonLabel: "",
      },
      ended: {
        title: "Sammlung beendet!",
        message:
          "Wenn du trotzdem über dieses Thema und zükunftige Kampagnen auf dem Laufenden gehalten werden möchtest trage unten Deine E-Mail-Adresse ein.",
        buttonLabel: "Ich will informiert bleiben",
      },
      success: {
        title: "",
        message: "",
        buttonLabel: "Ich will informiert bleiben",
      },
    },
    support: {
      active: {
        title: "Jetzt unterstützen!",
        customMessage: {
          buergerbegehren: `Für den Start dieses Bürgerbegehrens suchen wir mindestens ${initiative.signatureTargets?.[initiative.stage]
            } Personen, die uns unterstützen, sobald die Unterschriftensammlung startet.`,
          "einwohnerinnen-antrag": `Für den Start dieses Einwohner:innen-Antrages suchen wir mindestens ${initiative.signatureTargets?.[initiative.stage]
            } Personen, die uns unterstützen, sobald die Unterschriftensammlung startet.`,
            volksantrag: `Für den Start dieses Volksantrags suchen wir mindestens ${initiative.signatureTargets?.[initiative.stage]
            } Personen, die uns unterstützen, sobald die Unterschriftensammlung startet.`,
            volksbegehren: `Für den Start dieses Volksbegehrens suchen wir mindestens ${initiative.signatureTargets?.[initiative.stage]
            } Personen, die uns unterstützen, sobald die Unterschriftensammlung startet.`,
          volksinitiative: `Für den Start dieser Volksinitiative suchen wir mindestens ${initiative.signatureTargets?.[initiative.stage]
            } Personen, die uns unterstützen, sobald die Unterschriftensammlung startet.`,
        },
        message: "Wir senden Dir eine E-Mail sobald es los geht!",
        buttonLabel: "Ich bin dabei",
      },
      canceled: {
        title: "Die Sammlung wurde von den Initiator:innen beendet.",
        message:
          "Wenn Du trotzdem über dieses Thema und zukünftige Entscheide in Deiner Region auf dem Laufenden gehalten werden möchtest, trage unten Deine E-Mail-Adresse ein.",
        buttonLabel: "Ich will informiert bleiben",
      },
      notStarted: {
        title: "",
        message: "",
        buttonLabel: "",
      },
      ended: {
        title: "Diese Initiative hat die Sammlung beendet.",
        message:
          "Wenn Du trotzdem über dieses Thema und zukünftige Entscheide in Deiner Region auf dem Laufenden gehalten werden möchtest, trage unten Deine E-Mail-Adresse ein.",
        buttonLabel: "Ich will informiert bleiben",
      },
      success: {
        title:
          "Geschafft! Diese Initiative hat genügend Unterstützer:innen für den Start gesammelt.",
        message:
          "Wenn Du trotzdem über dieses Thema und zukünftige Entscheide in Deiner Region auf dem Laufenden gehalten werden möchtest, trage unten Deine E-Mail-Adresse ein.",
        buttonLabel: "Ich will informiert bleiben",
      },
    },
    collection: {
      active: {
        title: "Jetzt unterschreiben",
        message:
          "Erhalte jetzt Deine Unterschriftenliste mit einem Briefumschlag zum Selbstfalten. Einfach ausfüllen, unterschreiben und zurücksenden. Das Porto übernehmen wir!",
        buttonLabel: "Jetzt unterstützen!",
      },
      canceled: {
        title: "Unterschriftensammlung wurde von den Initiator:innen beendet.",
        message:
          "Wenn Du trotzdem über dieses Thema und zukünftige Entscheide in Deiner Region auf dem Laufenden gehalten werden möchtest, trage unten Deine E-Mail-Adresse ein.",
        buttonLabel: "Ich will informiert bleiben",
      },
      notStarted: {
        title: `Bald geht es los! Sammelstart ${formatDate(
          initiative.currentStage?.startDate
        )}`,
        message:
          "Wenn du eine Erinnerung kriegen möchtest sobald es los geht und über dieses Thema und zukunftige Entscheide in deiner Region auf dem Laufenden gehalten werden möchtest trage unten Deine Kontaktdaten ein.",
        buttonLabel: "Ich bin dabei!",
      },
      ended: {
        title: "Diese Initiative hat die Unterschriftensammlung beendet.",
        message:
          "Wenn Du trotzdem über dieses Thema und zukünftige Entscheide in Deiner Region auf dem Laufenden gehalten werden möchtest, trage unten Deine E-Mail-Adresse ein.",
        buttonLabel: "Ich will informiert bleiben",
      },
      success: {
        title:
          "Geschafft! Diese Initiative hat die Unterschriftensammlung erfolgreich beendet.",
        message:
          "Wenn Du trotzdem über dieses Thema und zukünftige Entscheide in Deiner Region auf dem Laufenden gehalten werden möchtest, trage unten Deine E-Mail-Adresse ein.",
        buttonLabel: "Ich will informiert bleiben",
      },
    },
  };

  let btnRef = useRef(); 
  const [errors, setErrors] = useState({});
  const fieldsetRef = useRef();
  const formModal = document.getElementById("signatureFormModal");
  const history = useHistory();
  const [isLocationValid, setIsLocationValid] = useState(true);
  const [location, setLocation] = useState({});
  const [locations, setLocations] = useState([]);
  const [signatures, setSignatures] = useState([]);
  const [signer, setSigner] = useState({country: "DE"});
  const [documents, setDocuments] = useState([]);
  const { pushInstruction, trackEvent } = useMatomo();

  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const closeModal = () => {
    var modal = Modal.getInstance(formModal);

    if (modal) {
      modal.hide();
    }
  };

  const getInitiativeStage = () => {
    if ("petition" == initiative.type) {
      return "petition";
    }

    if (
      initiative.stage === "first-collection" ||
      initiative.stage === "second-collection"
    ) {
      return "collection";
    }

    return initiative.stage;
  };

  const getInitiativeStatus = () => {
    const status = initiative.currentStage?.status || initiative.initiativeStatus;

    if ("ended" == status && initiative.success) {
      return "success";
    }

    return status;
  };

  const getLocations = async (postalCode) => {
    try {
      setErrors({ ...errors, location: null });
      setSignerLocation(null);

      if (signer.country != "DE") { return };

      if (postalCode.length < 5) {
        return;
      }

      const params = {
        country: "DE",
        postalCode,
        withPostalCodes: true,
        limit: 0,
      };

      await api.getLocations(params, (_status, data) => {
        if (!data || data.length === 0) {
          throw new Error();
          return;
        }

        setLocations(data);
        if (data.length === 1) {
          validateLocation(data[0]);
          setSignerLocation(data[0], postalCode);
        }
      });
    } catch (error) {
      setErrors((prev) => ({
        ...prev,
        location: "Zu dieser Postleitzahl konnte kein Ort gefunden werden.",
      }));
    }
  };

  const getRequiredFields = () => {
    const globalRequiredFields = REQUIRED_FIELDS?.any;
    const localRequiredFields = REQUIRED_FIELDS?.[initiativeStage]?.[
      initiativeStatus
    ]
      ? REQUIRED_FIELDS?.[initiativeStage]?.[initiativeStatus]
      : [];

    return [...globalRequiredFields, ...localRequiredFields];
  };

  const goToNextPage = async (signer) => {
    closeModal();
    let url;

    const initiativeStage = getInitiativeStage();
    const isStageActive = "active" === getInitiativeStatus();
    
    if ("collection" === initiativeStage && isStageActive && !hasDownloadedInitiative) {
      url = `/${initiative.name}/download`;
    } else {
      url = `/${initiative.name}/donation`;
    }

    const location = {
      pathname: url,
      state: {
        initiative,
        shareUrl,
        signer,
      },
    };
    history.push(location);
    formModal.addEventListener("hidden.bs.modal", () => {
      window.scrollTo(0, 0);
    })
  };

  const hasSignedPetition = useCallback(() => {
    return signatures.some((signature) => signature.signableId  === initiative.id);
  }, [signatures, initiative]); 

  const handleCreateDocument = async () => {
    const initiativeStage = getInitiativeStage();
    const documentProps = {
      initiativeId: initiative.id,
      initiativeStage: initiative.stage,
      firstName: signer.firstName,
      lastName: signer.lastName,
      country: signer.country,
      location: signer.location,
      postalCode: signer.postalCode,
      email: signer.email,
      exportType: "download",
      subscribe: "petition" == initiativeStage ? undefined : signer.subscribe,
    };

    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "true");
    };

    try {
      await api.createDocument(documentProps, async (_status, document) => {
        setDocuments(prev => [...prev, document]);

        await api.downloadDocument(
          document.id, document.verificationToken,
          (_status, data) => {
            fileDownload(data, "innn_it.pdf");
            if (btnRef.current) {
              btnRef.current.removeAttribute("disabled");
            };
          }
        );
      })
    } catch (error) {
      console.log({error});
    };
  };

  const handleDownloadDocument = async() => {
    try {
      if (!documents[0]) {
        return;
      };
  
      const document = documents[0];
  
      if (btnRef.current) {
        btnRef.current.setAttribute("disabled", "true");
      };

      await api.downloadDocument(
        document.id, document.verificationToken,
        (_status, data) => {
          fileDownload(data, "innn_it.pdf");
          if (btnRef.current) {
            btnRef.current.removeAttribute("disabled");
          };
        }
      );
    } catch (error) {
      console.log({error});
    };
  };
  
  const handleSubmit = (e, signer) => {
    e.preventDefault();
    
    setErrors({});
    
    if (!validateForm(signer)) {
      return;
    }

    if (fieldsetRef.current) {
      fieldsetRef.current.setAttribute("disabled", "disabled");
    }

    if (
      ("petition" == initiativeStage || "support" == initiativeStage) &&
      "active" == initiativeStatus
    ) {
      api.createSignature({
        signableId: initiative.id,
        signableModel: "petition" == initiativeStage ? "Petition" : "Initiative",
        initiativeStage: initiative.stage,
        firstName: signer.firstName,
        lastName: signer.lastName,
        country: signer.country,
        location: signer.location?.id,
        postalCode: signer.postalCode?.id,
        email: users?.me?.email || signer.email,
        subscribe: "petition" == initiativeStage ? undefined : signer.subscribe,
        test: signer.test,
      });

      trackEvent({
        category: initiative.type,
        action: "createSignature",
        customDimensions: [
          {
            id: 1,
            value: initiative.id,
          },
        ],
      });
    }

    goToNextPage(signer);
  };

  const isFieldRequired = (field) => {
    if (!requiredFields) {
      return false;
    }

    if (requiredFields.includes(field)) {
      return true;
    }

    return false;
  };

  const setSignerLocation = (location, postalCode) => {
    const signer = {
      location: null,
      postalCode: null,
    };

    if (
      location &&
      location.country &&
      location.id &&
      location.name &&
      location.postalCodes &&
      postalCode
    ) {
      const postalCodeObject = location.postalCodes.find((element) => {
        return element.postalCode == postalCode && element.id;
      });

      if (postalCodeObject) {
        signer.location = {
          id: location.id,
          name: location.name,
        };
        signer.postalCode = {
          id: postalCodeObject.id,
          postalCode: postalCodeObject.postalCode,
        };
      }
    }

    setLocation(signer);
  };

  const validateForm = (signer) => {
    let errors = {};

    requiredFields.forEach((field) => {
      if (!signer[field] || signer[field] === "") {
        if (!field === "subscribe" && initiative.type === "Petition") {
          errors[field] = "Bitte fülle dieses Feld aus";
        }
      }
    });

    if (
      signer.email &&
      !signer.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      errors = { ...errors, email: "Bitte gib eine gültige E-Mail ein" };
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateLocation = (location) => {
    if (initiativeStage === "petition") {
      return setIsLocationValid(true);
    }

    const result =
      location?.id === initiative.location.id ||
      location?.parents.includes(initiative.location.id);

    setIsLocationValid(result);
  };

  useEffect(() => {
    pushInstruction("FormAnalytics::scanForForms", document);
  }, []);

  useEffect(() => {
    if (!users.me) {
      dispatch(getMe());
    };
  }, []);

  useEffect(async () => {
    if (!users.me?.id) { return };

    const signer = users.me;
    try {
      if (users.me.location) {
        await api.getLocation(users.me.location, (_status, data) => {
          signer.location = data;
        })
      }

      if (users.me.postalCode) {
        await api.getPostalCode(users.me.postalCode, (_status, data) => {
          signer.postalCode = data;
        })
      }
    } catch (error) {
      console.log(error);
    }

    setSigner(signer);
  }, [users.me]);

  useEffect(async () => {
    try {
      if (!signer?.id || !initiative?.id) {
        return;
      }

      if ("initiative" === initiative.type) {
        await api.getDocuments({ signableId: initiative.id, signer: "me" }, (_status, data) => {
          setDocuments(data);
        });
      } else if ("petition" === initiative.type) {
        await api.getSignatures({ signableId: initiative.id, signer: "me" }, (_status, data) => {
          setSignatures(data);
        });
      }
    } catch (error) {
      console.log({error});
    };
  }, [initiative, signer]);

  const initiativeStage = getInitiativeStage();
  const initiativeStatus = getInitiativeStatus();
  let requiredFields = getRequiredFields();

  requiredFields = requiredFields.filter(field => {
    if ("initiative" ===  initiative.type || "DE" === signer?.country) { return true };
    
    return !["location", "postalCode"].includes(field);
  });


  const hasDownloadedInitiative = documents?.[0]?.exportType === "download" && documents?.[0]?.verifiedAt && documents?.[0]?.initiativeId === initiative.id;
  const hasRequestedPaper = documents?.[0]?.exportType === "post" && documents?.[0]?.initiativeId === initiative.id;

  const RequiredFieldSign = (field) => {
    if (isFieldRequired(field)) {
      return "*";
    }
  };

  return (
    <form className="bg-gradient-vertical pb-3 pt-4 px-3 rounded-2 shadow" name="signatureForm">
      <>
        {initiative.type === "initiative" && hasDownloadedInitiative || hasRequestedPaper ?
          <InitiativeSupportMessage
            btnRef={btnRef}
            goToNextPage={goToNextPage}
            handleCreateDocument={handleCreateDocument} 
            handleDownloadDocument={handleDownloadDocument} 
            hasDownloadedInitiative={hasDownloadedInitiative}
            signer={signer}
          />
        : 
          hasSignedPetition()  ?
            <div>
              <h3 className="mb-5 text-initiative-primary">Vielen Dank für Deine Unterstützung!</h3>
              <button
                type="button"
                className="btn btn-primary mb-4" 
                onClick={()=> goToNextPage(signer)}
              >
                Das kannst Du jetzt tun
              </button>
            </div>
        :
          <fieldset ref={fieldsetRef}>
            <Title text={CONTENT?.[initiativeStage]?.[initiativeStatus]?.title} />

            <Message
              customMessage={CONTENT?.[initiativeStage]?.[initiativeStatus]?.customMessage}
              initiative={initiative}
              initiativeStage={initiativeStage}
              initiativeStatus={initiativeStatus}
              message={CONTENT?.[initiativeStage]?.[initiativeStatus]?.message}
            />

            <SignerFields
              errors={errors}
              getLocations={getLocations} 
              handleSubmit={handleSubmit}
              initiative={initiative}
              initiativeStage={initiativeStage} 
              isLocationValid={isLocationValid}
              location={location}
              locations={locations}
              me={users?.me}
              modal={modal}
              RequiredFieldSign={RequiredFieldSign}
              setIsLocationValid={setIsLocationValid}
              setSignerLocation={setSignerLocation}
              signer={signer}
              setSigner={setSigner}
              submitLabel={CONTENT?.[initiativeStage]?.[initiativeStatus]?.buttonLabel}
              validateLocation={validateLocation}
            />
          </fieldset>
        }
      </>
    </form>
  );
};

const InvalidLocationAlert = ({ handleCloseInvalidLocationAlert, initiative, initiativeStage }) => {
  if (!initiative || initiativeStage === "petition") {
    return;
  }
  let message;
  if (initiative.location.level === "locality") {
    message = `Vielen Dank für Deine Mithilfe. Bitte beachte, dass dies ein lokales Projekt ist und ausschliesslich Personen mit Wohnsitz in ${initiative.location.name} unterschreiben dürfen.`;
  } else {
    message = `Vielen Dank für Deine Mithilfe. Bitte beachte, dass dies ein Projekt auf Landesebene ist und ausschliesslich Personen mit Wohnsitz in ${initiative.location.name} unterschreiben dürfen.`;
  }
  return (
    <div className="bg-danger mt-3 mx-n3 p-4 position-relative">
      <span
        className="position-absolute top-0 end-0 m-3"
        onClick={handleCloseInvalidLocationAlert}
        role="button"
      >
        <FontAwesomeIcon className="text-white" icon={faTimes} />
      </span>
      <label className="text-white pe-5">
        {message} <Link to="/initiatives">Alle Initiativen</Link>
      </label>
    </div>
  );
};

const Message = ({ customMessage, initiative, initiativeStage, initiativeStatus, message }) => {
  const paragraphs = [];

  if ("support" == initiativeStage && "active" == initiativeStatus) {
    paragraphs.push(
      customMessage?.[initiative.initiativeType]
    );
  }

  paragraphs.push(message);

  return (
    <div className="label text-primary">
      {paragraphs.map((text, index) => {
        return <p key={index}>{text}</p>;
      })}
    </div>
  );
};

const SubmitButton = ({ handleSubmit, label }) => {
  if (!label) {
    return false;
  }

  return (
    <div>
      <button
        className="btn btn-primary col-md-12 mb-4"
        type="submit"
        onClick={handleSubmit}
      >
        <span>{label}</span>
      </button>
      <div className="small text-black">
        <p>*Diese Felder müssen ausgefüllt werden.</p>
        <p>
          Wir verarbeiten Deine Daten gemäß unseren{" "}
          <Link to="/datenschutz">Datenschutzbestimmungen</Link>.
        </p>
      </div>
    </div>
  );
};

const TextField = ({
  defaultValue,
  errors,
  field,
  handleChange,
  modal,
  readOnly,
  RequiredFieldSign,
}) => {
  if (!field) {
    return false;
  }

  return (
    <div className="mb-4">
      <label
        className="text-primary"
        htmlFor={`${field}${modal ? "Modal" : ""}`}
      >
        {FIELD_LABELS?.[field]}
        {RequiredFieldSign(field)}
      </label>
      <input
        className={`
          ${readOnly ? "form-control-plaintext" : "form-control" }
          ${errors[field] ? " is-invalid" : ""}
        `}
        id={`${field}${modal ? "Modal" : ""}`}
        defaultValue={defaultValue}
        name={field}
        onChange={handleChange}
        placeholder={FIELD_LABELS?.[field]}
        readOnly={readOnly}
        type="text"
      />
      {errors[field] && (
        <span className="form-text invalid-feedback">{errors[field]}</span>
      )}
    </div>
  );
};

const SignerFields = ({
  errors,
  getLocations,
  handleSubmit,
  initiative,
  initiativeStage,
  isLocationValid,
  location,
  locations,
  me,
  modal,
  RequiredFieldSign,
  setIsLocationValid,
  setSignerLocation,
  signer,
  setSigner,
  submitLabel,
  validateLocation,
 }) => {
  const [isUserProfile, setIsUserProfile] = useState(false);
  const postalCodeInputRef = useRef();
  const [rawPostalCode, setRawPostalCode] = useState(false);
  const [showLocationDropdown, setShowLocationDropdown] = useState(false);

  const countryOptions = Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: key
    };
  });
  
  useEffect(() => {
    if (locations.length > 1) {
      setShowLocationDropdown(true);
    }
  }, [locations]);

  const handleChange = ({ target }) => {
    const name = target.name;
    let value = target.type === "checkbox" ? target.checked : target.value;

    if ("text" == target.type) {
      value = value.trim();
    }

    setSigner((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCloseInvalidLocationAlert = () => {
    setIsLocationValid(true);
    setSignerLocation(null);

    postalCodeInputRef.current.value = "";
  };

  const handlePostalCodeChange = (e) => {
    setRawPostalCode(e.target.value);
    getLocations(e.target.value);

    if ("DE" != signer.country) {
      setSigner(prev => ({...prev, postalCode: e.target.value}))
    };
  };

  const setCountry = (country) => {
    handleChange({
      target: { name: "country", value: country.value },
    });

    if ("DE" != country.value) {
      setSignerLocation(null, null);
    }
  };

  const setLocation = (location) => {
    setShowLocationDropdown((prev) => !prev);
    validateLocation(location);

    if ("DE" === signer.country) {
      setSignerLocation(location, rawPostalCode);
    };
  };

  return (
    <>
      {
        signer?.id &&
        signer?.email &&
        signer?.firstName && signer?.lastName &&
        signer?.country && signer?.location?.name && signer?.postalCode?.postalCode &&
        !isUserProfile
      ?
        <SignerDetails
          setIsUserProfile={setIsUserProfile}
          signer={signer} 
        />
      :
        <>
          <TextField
            defaultValue={signer.firstName}
            field="firstName"
            errors={errors}
            handleChange={handleChange}
            modal={modal}
            RequiredFieldSign={RequiredFieldSign}
          />
          <TextField
            defaultValue={signer.lastName}
            field="lastName"
            errors={errors}
            handleChange={handleChange}
            modal={modal}
            RequiredFieldSign={RequiredFieldSign}
          />
          <TextField
            defaultValue={me?.email || signer.email}
            field="email"
            errors={errors}
            handleChange={handleChange}
            modal={modal}
            readOnly={signer?.id && signer?.email}
            RequiredFieldSign={RequiredFieldSign}
            />
            {initiative.type === "petition" && (
              <NoteBox />
            )}
          {"petition" === initiative.type && (
            <div className="dropdown mb-4">
              <label className="mb-2 text-primary" htmlFor={`country${modal ? "Modal" : ""}`}>
                Land{RequiredFieldSign("country")}
              </label>
              <button
                className="form-control
                dropdown-toggle
                text-end
                d-flex
                justify-content-between
                align-items-center"
                type="button"
                id={`country${modal ? "Modal" : ""}`}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                >
                  {countryObj[signer?.country]}
                </button>
                <ul
                  className="dropdown-menu overflow-auto"
                  style={{ maxHeight: "250px" }}
                  aria-labelledby="locationDropdown"
                >
                {countryOptions?.map((country) => (
                  <li
                    className="dropdown-item"
                    key={country.value}
                    onClick={() => setCountry(country)}
                  >
                    {country.label}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {"DE" === signer.country && (
            <div className="row">
              <div className="col-4 mb-4">
                <label
                  className="text-primary"
                  htmlFor={`postalCode${modal ? "Modal" : ""}`}
                >
                  Postleitzahl{RequiredFieldSign("postalCode")}
                </label>
                <input
                  className={`form-control${errors.location ? " is-invalid" : ""}`}
                  id={`postalCode${modal ? "Modal" : ""}`}
                  defaultValue={signer.postalCode?.postalCode}
                  name="postalCode"
                  onChange={handlePostalCodeChange}
                  placeholder="12345"
                  ref={postalCodeInputRef}
                  type="text"
                />
                {errors.location && (
                  <span
                  className="form-text invalid-feedback"
                  style={{ minWidth: "18rem" }}
                >
                    {errors.location}
                  </span>
                )}
              </div>
              <div className="col-8 mb-4">
                <label
                  className="text-primary"
                  htmlFor={`location${modal ? "Modal" : ""}`}
                >
                  Ort{RequiredFieldSign("location")}
                </label>
                {locations.length > 1 ? (
                  <div className="dropdown">
                    <button
                      className={`
                        form-control
                        dropdown-toggle
                        text-start
                        d-flex
                        justify-content-between
                        align-items-center
                        ${showLocationDropdown ? " show" : ""}
                        `}
                        type="button"
                        id="locationDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {location.location?.name || "Ort"}
                      </button>
                      <ul
                        className={`dropdown-menu overflow-auto ${showLocationDropdown ? "show" : ""}`}
                        style={{ maxHeight: "250px" }}
                        aria-labelledby="locationDropdown"
                      >
                      {locations.map((location) => (
                        <li
                          className="dropdown-item"
                          key={location.id}
                          onClick={() => setLocation(location)}
                        >
                          {location.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <input
                  className={`form-control${errors.location ? " is-invalid" : ""
                      }`}
                    disabled={true}
                    value={location.location?.name || signer.location?.name || ""}
                    id={`location${modal ? "Modal" : ""}`}
                    name="location"
                    placeholder="Ort"
                    readOnly
                    tabIndex="-1"
                    type="text"
                  />
                )}
              </div>
            </div>
          )}
        </>
      }
      {isLocationValid ? (
        <>
          {(initiativeStage === "petition") ? (
            <></>
          ) : (
            <div className="form-check mb-4">
              <input
                className={`form-check-input${errors.subscribe ? " is-invalid" : ""
              }`}
            id={`subscribe${modal ? "Modal" : ""}`}
            name="subscribe"
            onChange={handleChange}
            type="checkbox"
          />
          <label
            className="text-black"
            htmlFor={`subscribe${modal ? "Modal" : ""}`}
          >
                Ich möchte über die Initiative “{initiative.title}” und innn.it per E-Mail weiter informiert werden. Die Einwilligung kann ich jederzeit mit Wirkung für die Zukunft widerrufen.
              </label>
              {errors.subscribe && (
                <span className="form-text invalid-feedback">
                {errors.subscribe}
              </span>
              )}
            </div>
          )}
          <SubmitButton
            handleSubmit={(e) => handleSubmit(e, {...signer, ...location})}
            label={submitLabel}
          />
        </>
      ) : (
        <InvalidLocationAlert handleCloseInvalidLocationAlert={handleCloseInvalidLocationAlert} initiative={initiative} initiativeStage={initiativeStage} />
      )}
    </>
  )
}

const Title = ({text}) => {
  if (!text) {
    return false;
  }

  return <h3 className="text-white" id="signatureFormTitle">{text}</h3>;
};

const SignerDetails = ({ setIsUserProfile, signer }) => {
  return (
    <div className="mb-3">
      <p className="fw-bold mb-5 small text-black">Du unterschreibst die Petition mit folgenden Daten:</p>
      <div className="d-flex">
        <h4 className="flex-grow-1 mb-2">{signer.firstName} {signer.lastName}</h4>
        <span onClick={() => setIsUserProfile((prev) => !prev)} style={{ cursor: "pointer" }}>
          <FontAwesomeIcon className="text-primary" icon={faPen} />
        </span>
      </div>

      <p className="mb-2">{signer.email}</p>
      <p className="mb-2">{countryObj[signer?.country]}</p>
      {"DE" == signer.country && (
        <p className="mb-2">
          <span className="me-4">{signer.postalCode?.postalCode}</span>
          <span>{signer?.location?.name}</span>
        </p>
      )}
    </div>
  )
};

const InitiativeSupportMessage = ({ btnRef, goToNextPage, handleCreateDocument, handleDownloadDocument, hasDownloadedInitiative, signer }) => {
  return (
    <div>
      <h3 className="mb-4 text-primary">Vielen Dank für Deine Unterstützung!</h3>
      <p className="label mb-4 text-primary">
        {hasDownloadedInitiative ? 
          "Du kannst die Unterschriftenliste hier noch einmal herunterladen."
        :
          "Deine Unterschriftenliste wird bald bei dir eintreffen."
        }
      </p>
      <button 
        type="button" 
        className="btn btn-primary mb-4 w-75" 
        onClick={hasDownloadedInitiative ? handleDownloadDocument : handleCreateDocument}
        ref={btnRef}
      >
        Jetzt herunterladen
      </button>
      <button 
        type="button" 
        className="btn btn-secondary mb-4 w-75" 
        onClick={()=> goToNextPage(signer)}
      >
        Das kannst Du jetzt tun 
      </button>
    </div>
  )
};

export default SignatureForm;
