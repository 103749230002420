const addCommasAndAnd = (list) => {
  if (list.length < 1) {
    return false;
  }

  if (list.length == 1) {
    return list[0];
  }

  return `${list.slice(0, -1).join(', ')} und ${list[list.length - 1]}`;
}

export default addCommasAndAnd;
