import React from "react";

const Dropdown = () => {
  return (
    <section className="mb-8 mt-4">
      <h3 className="mb-4">Dropdown</h3>
      <label className="text-primary">Label*</label>
      <div className="dropdown">
        <button
          className="btn btn-outline-primary dropdown-toggle"
          id="dropdownOptions"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Dropdown 
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownOptions">
          <li><a className="dropdown-item" href="#">Option 1</a></li>
          <li><a className="dropdown-item" href="#">Option 2</a></li>
        </ul>
      </div>
    </section>
  )
}

export default Dropdown;
