const FIELD_LABELS = {
  email: "E-Mail Adresse",
  firstName: "Vorname*",
  lastName: "Nachname*",
  location: "Ort*",
  password: "Passwort*",
  postalCode: "Postleitzahl*",
};

const FIELD_PLACEHOLDERS = {
  email: "beispiele@e-mail.de",
  firstName: "Vorname",
  lastName: "Nachname",
  location: "Ort",
  password: "Passwort",
  postalCode: "12345",
};

export { FIELD_LABELS, FIELD_PLACEHOLDERS };
