import React from "react";

const IconBox = (props) => {
  return (
    <div className="icon-box">
      <img className="icon-box-img" src={props.img} alt={props.imgAlt} />
      <h3 className="icon-box-title">{props.title}</h3>
      <p className="icon-box-text">{props.text}</p>
    </div>
  );
};

export default IconBox;
