import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import About from "./pages/about";
import ActorsList from "./pages/dashboard/ActorsList";
import Assets from "./assets";
import Authentication from "./pages/Authentication";
import AuthenticationModals from "./components/authentication";
import DashboardSidebar from "./components/DashboardSidebar";
import DocumentTracking from "./pages/dashboard/document-tracking";
import Contact from "./pages/Contact";
import CookieConsent from "./components/CookieConsent";
import Dashboard from "./pages/dashboard";
import DashboardRoute from "./DashboardRoute";
import Datenschutz from "./pages/datenschutz";
import DatenschutzSocialMedia from "./pages/datenschutzSocialMedia";
import EditActor from "./pages/dashboard/EditActor";
import EditInitiative from "./pages/dashboard/initiative/EditInitiative";
import EditUpdate from "./pages/dashboard/update/EditUpdate";
import EmailTemplate from "./pages/dashboard/update/EmailTemplate";
import Faq from "./pages/Faq";
import Footer from "./components/Footer";
import HeadContent from "./components/HeadContent";
import Header from "./components/Header";
import Home from "./pages/Home";
import Impressum from "./pages/impressum";
import Initiative from "./pages/initiative/index";
import InitiativeDonate from "./pages/initiative/donate/index";
import InitiativeDownload from "./pages/initiative/download/index";
import InitiativesList from "./pages/dashboard/initiatives";
import InitiativeShare from "./pages/initiative/share/index";
import Initiatives from "./pages/initiatives/index";
import InitiativeUpdates from "./pages/dashboard/update/InitiativeUpdates";
import Login from "./pages/user/Login";
import Maintenance from "./pages/Maintenance";
import NotFound from "./pages/NotFound";
import Nutzungsbedingungen from "./pages/nutzungsbedingungen";
import Profile from "./pages/user-dashboard/components/Profile";
import StartPetition from "./pages/petition/index";
import UserDashboard from "./pages/user-dashboard/index";
import Verification from "./pages/verification";
import AuthenticatedRoute from "./AuthenticatedRoute";

const redirectToVereinSite = () => {
  window.location.replace("//verein.innn.it");
  return null;
}

const Routes = () => {
  const isOnMaintenanceMode = "true" === process.env.REACT_APP_MAINTENANCE_MODE;

  if (isOnMaintenanceMode) {
    return (
      <Switch>
        <Route exact path="/">
          <Maintenance />
        </Route>
        <Redirect from="/*" to="/" />
      </Switch>
    );
  }

  return (
    <>
      <HeadContent />

      <Switch>
        <Route path="/dashboard">
          <DashboardSidebar />
        </Route>
        <Route exact path="/verein" />
        <Route path="/">
          <Header />
        </Route>
      </Switch>

      <Switch>
        {/* Home */}
        <Route exact path="/">
          <Home />
        </Route>

        {/* Redirections */}
        <Route exact path="/verein" component={redirectToVereinSite} />

        {/* Dashboard */}
        <DashboardRoute exact path="/dashboard" component={Dashboard} />
        <DashboardRoute
          exact
          key="dashboard-initiatives"
          path="/dashboard/initiatives"
          component={InitiativesList}
        />
        <DashboardRoute
          exact
          key="dashboard-petitions"
          path="/dashboard/petitions"
          component={InitiativesList}
        />
        <DashboardRoute
          exact
          path="/dashboard/actors"
          component={ActorsList}
        />
        <DashboardRoute
          exact
          path="/dashboard/tracking"
          component={DocumentTracking}
        />
        <DashboardRoute
          exact
          path="/dashboard/actors/new"
          component={EditActor}
        />
        <DashboardRoute
          exact
          path="/dashboard/actors/:id"
          component={EditActor}
        />
        <DashboardRoute
          exact
          key="dashboard-initiatives-new"
          path="/dashboard/initiatives/new"
          component={EditInitiative}
        />
        <DashboardRoute
          exact
          key="dashboard-initiatives-id"
          path="/dashboard/initiatives/:id"
          component={EditInitiative}
        />
        <DashboardRoute
          exact
          key="dashboard-petitions-new"
          path="/dashboard/petitions/new"
          component={EditInitiative}
        />
        <DashboardRoute
          exact
          key="dashboard-petitions-id"
          path="/dashboard/petitions/:id"
          component={EditInitiative}
        />
        <DashboardRoute
          exact
          path="/dashboard/initiatives/:initiativeId/updates/new"
          component={EditUpdate}
        />
        <DashboardRoute
          exact
          path="/dashboard/initiatives/:initiativeId/updates/:id"
          component={EditUpdate}
        />
        <DashboardRoute
          exact
          path="/dashboard/initiatives/:id/updates"
          component={InitiativeUpdates}
        />
        <DashboardRoute
          exact
          path="/dashboard/initiatives/:id/update/email"
          component={EmailTemplate}
        />
        <DashboardRoute
          exact
          path="/dashboard/petitions/:initiativeId/updates/new"
          component={EditUpdate}
        />
        <DashboardRoute
          exact
          path="/dashboard/petitions/:initiativeId/updates/:id"
          component={EditUpdate}
        />
        <DashboardRoute
          exact
          path="/dashboard/petitions/:id/updates"
          component={InitiativeUpdates}
        />
        <DashboardRoute
          exact
          path="/dashboard/petitions/:id/update/email"
          component={EmailTemplate}
        />

        <Route exact path="/authenticate">
          <Authentication />
        </Route>

        <Route exact path="/login">
          <Login />
        </Route>

        <Redirect exact from="/profile" to="/profile/petitions" />
        <AuthenticatedRoute exact path={["/profile/petitions", "/profile/signatures"]}>
          <UserDashboard />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/profile/edit">
          <Profile />
        </AuthenticatedRoute>

        {/* Static pages */}
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/assets">
          <Assets />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/datenschutz">
          <Datenschutz />
        </Route>
        <Route exact path="/datenschutz-socialmedia">
          <DatenschutzSocialMedia />
        </Route>
        <Route exact path="/faq">
          <Faq />
        </Route>
        <Route exact path="/impressum">
          <Impressum />
        </Route>
        <Route exact path="/nutzungsbedingungen">
          <Nutzungsbedingungen />
        </Route>

        {/* Verification */}
        <Route exact path="/verify/:id/:token">
          <Verification />
        </Route>

        <Route exact path="/verify/:model/:id/:token">
          <Verification />
        </Route>

        {/* Start petition */}
        <Route exact path="/petition-starten/:id?">
          <StartPetition />
        </Route>

        {/* Initiatives */}
        <Route exact path="/initiatives">
          <Initiatives />
        </Route>
        <Route exact path={["/:id", "/:id/updates", "/initiatives/:id", "/initiatives/:id/updates", "/:id/updates/:updateId"]}>
          <Initiative />
        </Route>
        <Route exact path="/:id/donation">
          <InitiativeDonate />
        </Route>
        <Route exact path="/:id/download">
          <InitiativeDownload />
        </Route>
        <Route exact path="/:id/share">
          <InitiativeShare />
        </Route>

        {/* Not found */}
        <Route>
          <NotFound />
        </Route>
      </Switch>

      <Switch>
        <Route path="/dashboard" />
        <Route exact path="/verein" />
        <Route path="/">
          <Footer />
          <CookieConsent />
          <AuthenticationModals />
        </Route>
      </Switch>
    </>
  );
};

export default Routes;
