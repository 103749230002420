import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "./Modal";
import TextField from "./TextField";
import validateForm from "../../custom-hooks/use-user-validation";
import api from "../../helpers/api";
import { FIELD_LABELS, FIELD_PLACEHOLDERS } from "./content";
import { hideModal, showModal } from "./modalFunctions";
import { getMe } from "../../redux/users";

const SignInModal = ({
  handleChange,
  user
}) => {
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});
  const fieldsetRef = useRef();

  const goBack = () => {
    hideModal("signInModal");
    showModal("getUserModal");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});

    const [isValid, errors] = validateForm("signIn", user);

    if (!isValid) {
      setFormErrors(errors);
      return;
    }

    if (fieldsetRef.current) {
      fieldsetRef.current.setAttribute("disabled", true);
    }

    try {
      await api.login(
        { email: user.email, password: user.password },
        (_status, _data) => {
          dispatch(getMe());
          setFormErrors({});
          hideModal("signInModal");
        }
      );
    } catch (error) {
      setFormErrors({ ...formErrors, password: "Bitte gib ein gültiges Passwort ein" });
    } finally {
      if (fieldsetRef.current) {
        fieldsetRef.current.removeAttribute("disabled");
      }
    }
  }

  return (
    <Modal id="signInModal">
      <form onSubmit={(e) => handleSubmit(e)} noValidate>
        <fieldset ref={fieldsetRef}>
          <h3 className="mb-5">Anmelden</h3>
          <p>
            Bitte gib das zu <strong> {user?.email} </strong> gehörende Passwort ein:
          </p>

          <TextField
            field="password"
            name="password"
            id="password"
            type="password"
            formErrors={formErrors}
            fieldLabels={FIELD_LABELS}
            handleChange={handleChange}
            placeholder={FIELD_PLACEHOLDERS}
            user={user}
          />
          <div className="mb-3">
            <a
              className="link text-primary"
              data-bs-toggle="modal"
              href="#resetPasswordRequestModal"
            >
              Passwort vergessen
            </a>
          </div>
          <div className="d-flex justify-content-between mb-6">
            <button
              className="btn btn-lg btn-outline-primary"
              onClick={goBack}
              type="button"
            >
              Zurück
            </button>
            <button
              className="btn btn-lg btn-primary"
              onClick={(e) => handleSubmit(e)}
              type="submit"
            >
              Weiter
            </button>
          </div>
        </fieldset>
      </form>
    </Modal>
  );
};

export default SignInModal;
