import { useState, useEffect } from "react";
import api from "../helpers/api";

export const useLocationFields = (originalProperties) => {
  const [errors, setErrors] = useState({});

  const [locationProperties, setLocationProperties] = useState({
    country: "DE",
    location: "",
    postalCode: "",
  });
  const [postalCode, setPostalCode] = useState("");

  const [locations, setLocations] = useState([]);
  const [showLocationDropdown, setShowLocationDropdown] = useState(false);

  useEffect(() => {
    if (
      originalProperties && (
        originalProperties.country ||
        originalProperties.postalCode ||
        originalProperties.location
      )
    ) {
      handleLocationProperties(originalProperties);
    }
  }, []);

  useEffect(() => {
    if (locations.length > 1) {
      setShowLocationDropdown(true);
    }
  }, [locations]);

  useEffect(() => {
    if (postalCode) {
      getLocations(postalCode);
    }
  }, [postalCode]);

  const getLocations = async (postalCode) => {
    try {
      const country = locationProperties.country;
      setErrors({});

      if (postalCode.length < 5) {
        return handleLocationProperties({country});
      }

      const params = {
        country,
        postalCode,
        withPostalCodes: true,
      };

      await api.getLocations(params, (_status, data) => {
        if (!data || data.length === 0) {
          throw new Error();
        }

        setLocations(data);

        if (data.length === 1) {
          handleLocationProperties({country, location: data[0]});
        }
      });
    } catch (error) {
      setErrors((prev) => ({
        ...prev,
        location: "Zu dieser Postleitzahl konnte kein Ort gefunden werden.",
      }));
    }
  };
  
  const handleLocationProperties = ({country = "DE", postalCode, location}) => {
    return new Promise((resolve, reject) => {
      setShowLocationDropdown(false);

      const properties = {
        country,
        location: null,
        postalCode: null,
      };
  
      // We don't process locations outside Germany
      if ("DE" !== country) {
        return resolve(properties);
      }

      // If there's location and no postal code, the postal code might be inside the location
      if (
        location?.id &&
        !postalCode?.id &&
        location?.postalCodes &&
        location.postalCodes.length > 0 &&
        location.postalCodes?.[0]?.id
      ) {
        postalCode = location.postalCodes[0];
      }

      // If there's location and postal code objects, process them directly
      if (
        location?.id &&
        postalCode?.id &&
        postalCode?.postalCode &&
        postalCode?.localities &&
        postalCode?.localities.includes(location.id)
      ) {
        properties.postalCode = postalCode;
        properties.location = location;
        return resolve(properties);
      }

      // If location and postal code are string, get them through the API
      if (
        location &&
        "string" === typeof location &&
  
        postalCode &&
        "string" === typeof postalCode
      ) {
        api.getPostalCode(postalCode, (_status, postalCodeData) => {
          properties.postalCode = postalCodeData;

          if (!postalCodeData?.localities.includes(location)) {
            return resolve(properties);
          }
  
          api.getLocation(location, (_status, locationData) => {
            properties.location = locationData;
            return resolve(properties);
          });
        });
      }

      return resolve(properties);
    }).then((properties) => {
      setLocationProperties(properties);
    });
  };
    
  return {
    errors,
    handleLocationProperties,
    locationProperties,
    locations,
    setPostalCode,
    showLocationDropdown,
  };
};
