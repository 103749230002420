import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faGlobe,
  faHandHoldingDollar,
  faPaperPlane,
} from "@fortawesome/pro-solid-svg-icons";
import { Trans, useTranslation } from "react-i18next";
import Newsletter from "./Newsletter";
import NewsletterInput from "./NewsletterInput";

function Footer() {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  const AboutUsItems = [
    { label: t("footer.column.aboutUs"), href: "/about" },
    { label: t("footer.column.faq"), href: "/faq" },
    {
      label: t("footer.column.press"),
      href: "//verein.innn.it/newsroom/#pressekontakt",
      blank: true,
    },
    {
      label: t("footer.column.organization"),
      href: "//verein.innn.it/",
      blank: true,
    },
  ];

  const SocialMediaItems = [
    {
      name: "instagram",
      label: t("footer.socialMedia.instagram"),
      icon: faInstagram,
      href: "https://instagram.com/innn.it",
    },
    {
      name: "facebook",
      label: t("footer.socialMedia.facebook"),
      icon: faFacebook,
      href: "https://facebook.com/HierKannstDuMitbestimmen",
    },
    {
      name: "twitter",
      label: t("footer.socialMedia.twitter"),
      icon: faTwitter,
      href: "https://twitter.com/innn_it",
    },
    {
      name: "tiktok",
      label: t("footer.socialMedia.tiktok"),
      icon: faTiktok,
      href: "https://tiktok.com/@innn.it",
    },
  ];

  const AboutUs = AboutUsItems.map((item) => {
    return (
      <li key={item.label}>
        <Link
          rel={item.blank ? "noopener noreferrer" : ""}
          target={item.blank ? "_blank" : ""}
          to={item.href}
        >
          {item.label}
        </Link>
      </li>
    );
  });

  const ColophonSocialMedia = SocialMediaItems.map((item) => {
    return (
      <li key={`colophonSocialMedia-${item.name}`}>
        <a href={item.href} target="_blank" rel="noreferrer">
          <FontAwesomeIcon
            aria-label={item.label}
            fixedWidth
            icon={item.icon}
            size="xl"
          />
        </a>
      </li>
    );
  });

  const SocialMedia = SocialMediaItems.map((item) => {
    return (
      <li key={`socialMedia-${item.name}`}>
        <a href={item.href} target="_blank" rel="noreferrer">
          <span className="footer-main-col--social-icon">
            <FontAwesomeIcon
              aria-label={item.label}
              fixedWidth
              icon={item.icon}
              size="xl"
            />
          </span>
          <span className="footer-main-col--social-label">{item.label}</span>
        </a>
      </li>
    );
  });

  return (
    <footer className="footer">
      <div className="footer-main">
        <div className="container">
          <div className="row gx-md-5">
            {/* Newsletter */}
            <div className="footer-main-col footer-main-col--newsletter">
              <div className="row">
                <div className="h6">{t("footer.newsletter.title")}</div>
                <Newsletter
                  fieldsProps={{
                    buttonClass: "btn btn-icon btn-icon-right",
                    buttonValue: (
                      <FontAwesomeIcon
                        className="text-initiative-primary"
                        icon={faPaperPlane}
                        size="xl"
                      />
                    ),
                  }}
                  source="footer"
                  NewsletterFields={NewsletterInput}
                />

                <p className="small text-white mb-0 mt-3">
                  <Trans i18nKey="footer.newsletter.privacyText">
                    <Link
                      className="text-petition-secondary"
                      to="/datenschutz"
                    ></Link>
                  </Trans>
                </p>
              </div>
            </div>

            {/* Info */}
            <div className="footer-main-col footer-main-col--info">
              <ul className="fa-ul footer-main-list">
                <li>
                  <span className="fa-li footer-main-list-icon">
                    <FontAwesomeIcon icon={faHandHoldingDollar} />
                  </span>
                  {t("footer.description")}
                </li>
                <li>
                  <span className="fa-li footer-main-list-icon">
                    <FontAwesomeIcon icon={faGlobe} />
                  </span>
                  {t("footer.server")}
                </li>
                <li className="label">
                  <span className="label">
                    <Link className="text-petition-secondary" to="/datenschutz">
                      {t("footer.column.dataProtection")}
                    </Link>
                  </span>
                  <span className="text-white">&emsp;|&emsp;</span>
                  <span className="label">
                    <Link className="text-petition-secondary" to="/nutzungsbedingungen">
                      {t("footer.column.termsOfUse")}
                    </Link>
                  </span>
                  <span className="text-white">&emsp;|&emsp;</span>

                  <span>
                    <Link className="text-petition-secondary" to="/impressum">
                      {t("footer.column.imprint")}
                    </Link>
                  </span>
                </li>
              </ul>
            </div>

            {/* About us */}
            <div className="footer-main-col footer-main-col-sm">
              <div className="footer-main-col-container">
                <div className="h6" id="aboutTitle">
                  {t("footer.column.title")}
                </div>
                <ul className="footer-main-nav" aria-labelledby="aboutTitle">
                  {AboutUs}
                </ul>
              </div>
            </div>

            {/* Social media */}
            <div className="footer-main-col footer-main-col-sm footer-main-col--social">
              <div className="h6" id="shareTitle">
                {t("footer.socialMedia.title")}
              </div>
              <ul className="footer-main-nav" aria-labelledby="shareTitle">
                {SocialMedia}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Colophon */}
      <div className="footer-colophon container">
        <div className="footer-colophon-text">
          {t("footer.colophon", { year })}
        </div>

        <ul className="footer-colophon-social">{ColophonSocialMedia}</ul>
      </div>
    </footer>
  );
}

export default Footer;
