import api from "../helpers/api";

// Action Types
export const actionTypes = {
  GET_ME: 'users/me'
};

// Reducer
const usersReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_ME:
      return { ...state, me: action.payload };

    default:
      return state;
  }
}

// synchronous function that returns an async thunk functions for side effects (like async api calls)
export const getMe = (cb) => {
  return async (dispatch, _getState) => {
    try {
      await api.me((_status, data)=> {
        dispatch({ type: actionTypes.GET_ME, payload: data.me });
        if(cb){
          cb();
        }
      })
    } catch(err) {
      dispatch({ type: actionTypes.GET_ME, payload: {} });
      if(cb){
        cb(err);
      }
    }
  };
};


export default usersReducer;
