import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const Tab = ({ activeTab, initiatives, isShowPetitionButton }) => {

  const tabsProps = [
    {
      id: "petitions",
      label: "Meine Petitionen",
    },
    {
      id: "signatures",
      label: "Meine Unterschriften",
    },
  ];

  let history = useHistory();

  const updateURL = (tabName) => {
    history.push(`/profile/${tabName}`);
  };

  const petitionButton = initiatives.length > 0 && isShowPetitionButton;

  const navItems = tabsProps.map((tab, index) => {
    const tabTitleCase = tab.id.charAt(0).toUpperCase() + tab.id.substr(1).toLowerCase();

    return (
      <li key={tab.id} className="nav-item" role="presentation">
        <button
          aria-controls={tab.id}
          aria-selected={0 == index ? "true" : "false"}
          className={`nav-link ${activeTab == tab.id ? "active" : ""}`}
          data-bs-toggle="tab"
          data-bs-target={`#initiativeTabsContent${tabTitleCase}`}
          id={`initiativeTabsNav${tabTitleCase}`}
          role="tab"
          type="button"
          onClick={()=> updateURL(tab.id)}
        >
          <span className="fs-5">{tab.label}</span>
        </button>
      </li>
    );
  });

  return (
    <div className="align-items-md-center d-flex justify-content-between flex-md-row flex-column">
      <ul
        className="nav nav-tabs nav-pills nav-fill nav-width mb-3 mt-5 my-md-5"
        id="initiativeTabsNav"
        role="tablist"
      >
        {navItems}
      </ul>
      <div className={`pb-md-0 pb-5 ${petitionButton ? "d-block" : "d-none"}`}>
        <Link
          className="btn btn-sm btn-outline-secondary text-primary btn-block"
          to="/petition-starten"
        >
          Neue Petition starten
        </Link>
      </div>
    </div>
  );
};


Tab.propTypes = {
  initiatives: PropTypes.array.isRequired,
  isShowPetitionButton: PropTypes.bool.isRequired,
};

  
export default Tab;
  