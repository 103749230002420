import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "./Modal"
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";

const SignUpMessageModal = () => {
  return (
    <Modal id="signUpMessageModal">
      <h3 className="mb-5 text-black">Dein Profil wurde erstellt</h3>
      <span className="d-flex mb-3">
        <FontAwesomeIcon
          className="icon icon-left text-petition-primary"
          icon={faEnvelope}
          size="3x"
        />
        <span className="ps-4">
          <h4 className="text-petition-primary mb-3">
            Bitte bestätige Deine E-Mail Adresse
          </h4>
          <span className="text-black">
            Wir haben Dir eine E-Mail mit einem Bestätigungslink zugesendet.
            Klicke einfach auf den Link in Deinem Postfach um zukünftig
            Neuigkeiten zu erhalten.
          </span>
        </span>
      </span>
    </Modal>
  )
}

export default SignUpMessageModal;
