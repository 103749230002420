const getLimit = (value) => {
  const targets = [
    100,
    250,
    500,

    1000,
    2000,
    5000,

    10000,
    25000,
    50000,

    100000,
    200000,
    300000,
    400000,
    500000,
    600000,
    700000,
    800000,
    900000,

    1000000,
    2000000,
    3000000,
    4000000,
    5000000,
    6000000,
    7000000,
    8000000,
    9000000,
    10000000,
  ];

  let i;
  value = parseInt(value);

  for (i = 0; i < targets.length - 1; i++) {
    if (value < targets[i]) {
      break;
    }
  }

  return targets[i];
};

export default getLimit;
