import React from "react";

function Typography() {
  return (
    <section className="mt-5">
      <h2>Typography</h2>
      <table className="table table-borderless">
        <tbody>
          <tr>
            <td width="25%">
              <h1>H1</h1>
            </td>
            <td>
              <h1>First Heading</h1>
            </td>
          </tr>
          <tr>
            <td>
              <h2>H2</h2>
            </td>
            <td>
              <h2>Second Heading</h2>
            </td>
          </tr>
          <tr>
            <td>
              <h3>H3</h3>
            </td>
            <td>
              <h3>Third Heading</h3>
            </td>
          </tr>
          <tr>
            <td>
              <h4>H4</h4>
            </td>
            <td>
              <h4>Fourth Heading</h4>
            </td>
          </tr>
          <tr>
            <td>
              <h5>H5</h5>
            </td>
            <td>
              <h5>Fifth Heading</h5>
            </td>
          </tr>
          <tr>
            <td>
              <h6>H6</h6>
            </td>
            <td>
              <h6>Section Header</h6>
            </td>
          </tr>
          <tr>
            <td>
              <p className="lead">Body Large</p>
            </td>
            <td>
              <p className="lead">"Yes Design systems will replace many design jobs as we know them today - they already have."</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Body</p>
            </td>
            <td>
              <p>Interface development patterns, processes and tools are like interchangeable parts and factory assembly lines</p>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#link">Link</a>
            </td>
            <td>
              <p>You already have an account? <a href="#link">Sign In</a></p>
            </td>
          </tr>
          <tr>
            <td>
              <label>Label</label>
            </td>
            <td>
              <label>Your email</label>
            </td>
          </tr>
          <tr>
            <td>
              <small>Small</small>
            </td>
            <td>
              <small>by Alex Grant</small>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}

export default Typography;
