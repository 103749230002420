import Banners from "./banners";
import Backgrounds from "./backgrounds";
import Buttons from "./buttons";
import Cards from "./cards";
import Colors from "./colors";
import Controls from "./controls";
import Dropdown from "./dropdown";
import Elements from "./elements";
import Fields from "./fields";
import Gauges from "./gauges"
import Heroes from "./heroes";
import Modals from "./modals";
import Shadows from "./shadows";
import Spacings from "./spacings";
import Statuses from "./statuses";
import Tabs from "./tabs";
import Typography from "./typography";

import React, { useContext, useEffect } from "react";
import HeadContentContext from "../HeadContentContext";

function Assets() {
  const { setHeadContent } = useContext(HeadContentContext);

  useEffect(() => {
    setHeadContent({pageTitle: "Assets"});
  }, []);

  return (
    <>
      <div className="container mb-5">
        <h1>Assets</h1>
        <Typography />
        <Colors />
        <Backgrounds />
        <Shadows />
        <Buttons />
        <Controls />
        <Elements />
        <Fields />
        <Dropdown />
        <Gauges />
        <Tabs />
        <Cards />
        <Modals />
        <Banners />
        <Spacings />
        <Statuses />
      </div>
      <div className="mb-5">
        <Heroes />
      </div>
    </>
  );
}

export default Assets;
