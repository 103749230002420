import api from "../../../helpers/api";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import InitiativeForm from "./InitiativeForm";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

const EditInitiative = () => {
  const DEFAULTS = {
    initiative: {
      documentExportTypes: ["post", "download"],
      initiativeType: "buergerbegehren",
      location: {
        id: "",
        name: ""
      },
      optionalFields: {
        counter: "none",
        endDate: true,
        signatureTarget: true,
      },
      stage: "support",
      type: "initiative"
    },
    petition: {
      initiativeStatus: "active",
      signatureTarget: 0,
      optionalFields: {
        counter: "signature",
        endDate: false,
        signatureTarget: true,
        startDate: false,
      },
      type: "petition",
      initiators: [],
    }
  }
  const PATHS = {
    initiative: "initiatives",
    petition: "petitions",
  };

  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  let savedLocation;

  const [initiative, setInitiative] = useState({});
  const [model, setModel] = useState();
  const [path, setPath] = useState();

  const users = useSelector(state => state.users);

  useEffect(() => {
    if (users.me?.id && initiative.initiators?.length == 0) {
      setInitiative({
        ...initiative,
        initiators: [{
          displayName: `${users.me.firstName || ""} ${users.me.lastName || ""}`,
          user: users.me.id
        }]
      });
    }
  }, [users]);

  useEffect(() => {
    (async () => {
      try {
        if (id) {
          await api.getInitiative(id, async (_status, data) => {
            const model = data.type;
            const path = PATHS?.[model]
            setInitiative(data);
            setModel(model);
            setPath(path);
            window.history.replaceState(null, '', `/dashboard/${path}/${data.id}`);
          });
        } else {
          const paths = location.pathname?.split("/");
          const modelPath = paths[paths.length - 2];
          let model = Object?.keys(PATHS).find(key => PATHS[key] === modelPath);
        
          if (!model) {
            model = "initiative";
          }

          setInitiative(DEFAULTS?.[model]);
          setModel(model);
          setPath(PATHS?.[model]);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            setStatus(404);
          }
          // Handle other error codes
        } else if (error.request) {
          // Client never received a response, or request never left
        } else {
          // Anything else
        }
      }
    })();
  }, [id]);

  const handleSubmit = async (e, action = null) => {
    e.preventDefault();

    try {
      if (!action) {
        if (!initiative.status || "draft" == initiative.status) {
          action = "draft";
        } else {
          action = "saved";
        }
      }

      let pathname;
      delete initiative.featuredImage;

      if (initiative?.location?.id) {
        initiative.location = initiative.location.id;
      }

      if (initiative.initiators) {
        initiative.initiators = initiative.initiators.map((initiator) => ({...initiator, _id: initiator.id}));
      }

      if (initiative.targets) {
        initiative.targets = initiative.targets.map((target) => ({...target, _id: target.id}));
      }

      await api.upsertInitiative(
        initiative?.id,
        {...initiative},
        async (status, data) => {
          switch (action) {
            case "preview":
              pathname = `/${data.id}`;
              break;

            default:
              pathname = `/dashboard/${path}`;
              break;
          }

          savedLocation = {
            pathname,
            state: {
              action,
              initiative: data,
              statusChange: "saved",
            },
          };

          const initiativeId = data.id;

          if (initiative.images && initiative.images[0]) {
            const formData = new FormData();
            formData.append("file", initiative.images[0]);
            formData.append("parent", initiativeId);

            await api.createAttachment(formData, async (_status, data) => {
              const attachmentId = data.id;
              await api.updateInitiative(
                initiativeId,
                { 
                  type: initiative.type,
                  featuredImage: attachmentId
                },
                () => { },
              );
            });
          }

          if (initiative.pdf && initiative.pdf[0]) {
            const formData = new FormData();
            formData.append("file", initiative.pdf[0]);
            formData.append("initiativeId", initiativeId);
            formData.append("doubleSided", initiative.doubleSided || false);

            await api.createTemplate(formData);
          }
        }
      );

      history.push(savedLocation);
    } catch (err) {
      console.log(err);
      alert("Could not update");
    }
  };

  return (
    <main className="dashboard py-6 py-md-7">
      {initiative && (
        <InitiativeForm
          handleSubmit={handleSubmit}
          initiative={initiative}
          model={model}
          setInitiative={setInitiative}
        />
      )}
    </main>
  );
};

export default EditInitiative;
