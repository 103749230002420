import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Attachment from "../../../../components/Attachment";

const StepSix = ({
  initiative,
  handleChange,
  handleSubmit,
  model,
}) => {
  const [exportTypeCategory, setExportTypeCategory] = useState();

  const handleExportTypes = (e) => {
    if ("initiative" != model) {
      return;
    }

    let value;
    if ("exportTypeCategory" == e.target.name) {
      setExportTypeCategory(e.target.value);

      if (
        "standard" == e.target.value
        && !(
          initiative.documentExportTypes.includes("download")
          || initiative.documentExportTypes.includes("post")
        )
      ) {
        value = ["download", "post"];
      } else {
        value = [e.target.value];
      }
    } else if ("exportType" == e.target.name) {
      if (e.target.checked) {
        value = [...initiative.documentExportTypes, e.target.value];
      } else {
        value = initiative.documentExportTypes.filter((type) => type != e.target.value);
        if (value.length == 0) {
          value = "manual";
        };
      }
    }

    handleChange({ target: { name: "documentExportTypes", value } });
  };

  useEffect(() => {
    if ("initiative" != model) {
      return;
    }

    if (initiative.documentExportTypes.includes("manual")) {
      setExportTypeCategory("manual");
    } else {
      setExportTypeCategory("standard");
    }
  }, [initiative.documentExportTypes]);

  return (

    <>
      {initiative.featuredImage && (
        <div>
          <Attachment
            className="w-100 mb-5"
            attachment={initiative.featuredImage}
            size="small"
          />
        </div>
      )}
      <div className="mb-5">
        <label className="h3 text-primary">Titelbild</label>
        {/* <div className="mb-4">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.
        </div> */}
        <input
          type="file"
          className="form-control"
          name="image"
          onChange={(e) =>
            handleChange({ target: { name: "images", value: e.target.files } })
          }
          placeholder="Hochladen"
        />
      </div>
      {"initiative" == model && "support" != initiative.stage && (
        <>
          <div className="mb-5">
            <label className="h3 text-primary">Unterschriftenliste</label>
            {/* <div className="mb-4">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
              sed diam voluptua.
            </div> */}
            <div className="form-check form-switch mb-4">
              <input type="checkbox"
                className="form-check-input"
                onChange={handleChange}
                name="doubleSided"
                id="doubleSided"
              />
              <label className="h4 text-primary" htmlFor="doubleSided">Doppelseitige Liste</label>
            </div>
            <input
              accept="application/pdf"
              type="file"
              className="form-control"
              name="pdf"
              onChange={(e) =>
                handleChange({ target: { name: "pdf", value: e.target.files } })
              }
              placeholder="Hochladen"
            />
          </div>
          <div className="mb-5">
            <label className="h3 text-primary">Datenexport</label>
            {/* <div className="mb-4">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
              sed diam voluptua.
            </div> */}
            <div className="form-check">
              <input
                className="form-check-input"
                checked={exportTypeCategory === "standard"}
                id="exportTypeStandard"
                name="exportTypeCategory"
                onChange={handleExportTypes}
                type="radio"
                value="standard"
              />
              <label className="form-check-label h4 text-primary" htmlFor="exportTypeStandard">
                Standard <small className="text-primary">(Download & Postversand)</small>
              </label>
              {exportTypeCategory === "standard" && (
                <div>
                  <div className="form-check">
                    <input
                      checked={initiative.documentExportTypes?.includes("download")}
                      className="form-check-input"
                      id="exportTypeDownload"
                      name="exportType"
                      onChange={handleExportTypes}
                      type="checkbox"
                      value="download"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exportTypeDownload"
                    >
                      Download
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      checked={initiative.documentExportTypes?.includes("post")}
                      className="form-check-input"
                      id="exportTypePost"
                      name="exportType"
                      onChange={handleExportTypes}
                      type="checkbox"
                      value="post"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exportTypePost"
                    >
                      Postversand
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div className="form-check">
              <input
                checked={exportTypeCategory === "manual"}
                className="form-check-input"
                id="exportTypeManual"
                name="exportTypeCategory"
                onChange={handleExportTypes}
                type="radio"
                value="manual"
              />
              <label className="form-check-label h4 text-primary" htmlFor="exportTypeManual">
                Datenliste & manueller Versand
              </label>
            </div>
          </div>
        </>
      )}
      <div className="text-center text-md-end mb-4">
        <button
          className="btn btn-lg btn-primary px-5"
          type="button"
          onClick={(e) => handleSubmit(e, "preview")}
        >
          Vorschau
        </button>
      </div>
      <div className="text-center text-md-end">
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={handleSubmit}
        >
          Entwurf sichern
        </button>
      </div>
    </>
  );
};

StepSix.propTypes = {
  attachments: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default StepSix;
