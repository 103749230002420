import { useMatomo } from "@datapunt/matomo-tracker-react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import HeadContentContext from "./HeadContentContext";
import Routes from "./Routes";
import { ThemeContext } from "./ThemeContext";
import { AuthenticationContext } from "./AuthenticationContext";
import { DecemberChallenge2023Context, isDecember2023 } from "./components/DecemberChallenge2023"; /** December Challenge banner 2023 */

const App = () => {
  const [authenticationProperties, setAuthenticationProperties] = useState({
    action: null,
    required: false,
  });
  const [headContent, setHeadContent] = useState({});
  const location = useLocation();
  const [theme, setTheme] = useState("initiative");
  const { trackPageView } = useMatomo();
  const [displayBanner, setDisplayBanner] = useState(isDecember2023()); /** December Challenge banner 2023 */

  useEffect(() => {
    if (headContent?.pageTitle) {
      document.title = `${headContent.pageTitle} | innn.it`;
    } else {
      document.title = "innn.it";
    }
  }, [headContent]);

  useEffect(() => {
    trackPageView();
    /** December Challenge banner 2023 start */
    const urlParams = new URLSearchParams(location.search);
    const displayBannerParam = urlParams.get("displayBanner");

    if ("true" == displayBannerParam) {
      setDisplayBanner(true);
    }
    /** December Challenge banner 2023 end */
  }, [location]);

  useEffect(() => {
    if (
      !location?.hash &&
      !location?.state?.scrollTo
    ) {
      window.scrollTo(0, 0);
    }
  }, [location?.pathname]);

  return (
    <AuthenticationContext.Provider value={{ authenticationProperties, setAuthenticationProperties }}>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <HeadContentContext.Provider value={{ headContent, setHeadContent }}>
          <DecemberChallenge2023Context.Provider value={[displayBanner, setDisplayBanner]}> {/* December Challenge banner 2023 */}
            <CookiesProvider>
              <div className={theme}>
                <Routes />
              </div>
            </CookiesProvider>
          </DecemberChallenge2023Context.Provider> {/* December Challenge banner 2023 */}
        </HeadContentContext.Provider>
      </ThemeContext.Provider>
    </AuthenticationContext.Provider>
  );
};

export default App;