import React, { useContext, useEffect } from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthenticationModals from "./components/authentication";
import { AuthenticationContext } from "./AuthenticationContext";
import { showModal } from "./components/authentication/modalFunctions";

const AuthenticatedRoute = ({ component: Component, ...props }) => {
  const {authenticationProperties, setAuthenticationProperties} = useContext(AuthenticationContext);
  const users = useSelector(state => state.users);
  const isAuth = !!users.me?.id;

  useEffect(() => {
    setAuthenticationProperties({
      ...authenticationProperties,
      required: true,
    });
  }, []);

  return (
    <Route
      {...props}
      render={(props) => {
        return isAuth
          ? <Component {...props} />
          : <AuthenticationModals />
      }}
    />
  );
};

export default AuthenticatedRoute;
