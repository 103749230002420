import api from "../../helpers/api";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router";

const EditActor = () => {
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  let savedLocation;

  const [actor, setActor] = useState({});

  useEffect(() => {
    (async () => {
      try {
        if (id) {
          await api.getActor(id, async (_status, data) => {
            setActor(data);
          });
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            setStatus(404);
          }
          // Handle other error codes
        } else if (error.request) {
          // Client never received a response, or request never left
        } else {
          // Anything else
        }
      }
    })();
  }, [id]);

  const handleChange = ({ target }) => {
    const name = target.name;
    let value = target.type === "checkbox" ? target.checked : target.value;

    setActor((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e, action = null) => {
    e.preventDefault();

    try {
      const pathname = "/dashboard/actors";
      await api.upsertActor(
        actor?.id,
        { ...actor },
        async (_status, data) => {
          savedLocation = {
            pathname,
            state: {
              actor: data,
              statusChange: "saved",
            },
          };
        }
      );

      history.push(savedLocation);
    } catch (err) {
      console.log(err);
      alert("Could not update");
    }
  };

  return (
    <main className="dashboard py-6 py-md-7">
      {actor && <form className="container-narrow">
        <div className="mb-5">
          <label className="h3 text-primary" htmlFor="actorName">Name</label>
          <input
            className="form-control"
            defaultValue={actor.name}
            id="actorName"
            name="name"
            onChange={handleChange}
            required
            type="text"
          />
        </div>
        <div className="text-md-end">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Entwurf sichern
          </button>
        </div>
      </form>}
    </main>
  );
};

export default EditActor;
