import React from "react";
import { Link } from "react-router-dom";
import iconBoxImageSignature from "../../../styles/images/icon-box/signature-alternate.svg";

const PetitionBanner = () => {
  return (
    <section className="banner-width">
      <section className="petition banner-background bg-gradient-horizontal-petition">
        <div className="banner-row align-items-center p-md-3 flex-row">
          <div className="banner-img text-center pt-5 pt-md-0 d-flex align-items-center">
            <img src={iconBoxImageSignature} />
          </div>
          <div className="banner-body">
            <div className="banner-text p-md-0">
              <h3 className="banner-title">Starte jetzt Deine eigene Petition</h3>
              <h5>
                Ergreif Initiative! Wir unterstützen Dich, damit Deine Kampagne Wirkung entfaltet.
              </h5>
              <div className="banner-footer">
                <Link
                  className="btn btn-sm btn-primary btn-block"
                  to="/petition-starten"
                >
                  Jetzt Petition starten
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default PetitionBanner