import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/pro-solid-svg-icons";
import TipBox from "./tipBox";
import useOutsideClick from "../../custom-hooks/useOutsideClick";
import useToolTip from "../../custom-hooks/use-tool-tip";

const Tip = ({ children, label }) => {
  const tipBoxRef = useRef();
  const { hideTip, showTip, showTipBox } = useToolTip();

  useOutsideClick(tipBoxRef, () => {
    if (showTipBox) {
      hideTip();
    };
  });

  return (
    <>
      <div className="tip" onClick={showTip}>
        <FontAwesomeIcon className="me-2 text-secondary" icon={faLightbulb} />
        <span className="text-secondary">{label}</span>
      </div>
      { showTipBox && <TipBox hideTip={hideTip} tipBoxRef={tipBoxRef}> {children} </TipBox> }
    </>
  )
};

export default Tip;
