import DatePicker from "react-date-picker/dist/entry.nostyle";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons";

const DatePickerField = (props) => {
  return (
    <DatePicker
      locale="de"
      minDate={props?.min}
      maxDate={props?.max}
      name={props?.name}
      value={props?.value}
      dayPlaceholder="__"
      monthPlaceholder="__"
      yearPlaceholder="____"
      clearIcon={null}
      prev2Label={null}
      next2Label={null}
      prevAriaLabel="Zurück"
      prevLabel={<FontAwesomeIcon className="text-secondary" icon={faChevronLeft} />}
      nextAriaLabel="Vor"
      nextLabel={<FontAwesomeIcon className="text-secondary" icon={faChevronRight} />}
      calendarIcon={<FontAwesomeIcon className="text-primary" icon={faChevronDown} />}
      onChange={props?.onChange}
    />
  );
};

export default DatePickerField;
