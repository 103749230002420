import React from "react";
import { Link } from "react-router-dom";
import getDonationUrl from "../helpers/get-donation-url";
import IconSignPetition from "../styles/images/icon-sign-petition.svg";

const PetitionBanner = (props) => {
  return (
    <div className="petition banner-background bg-gradient-horizontal-petition">
      <div className="banner-row align-items-center p-md-3 flex-row">
        <div className="banner-img text-center pt-5 pt-md-0 d-flex align-items-center">
          <img src={IconSignPetition} />
        </div>
        <div className="banner-body">
          <div className="banner-text p-md-0">
            <h3 className="banner-title">Starte jetzt Deine eigene Petition</h3>
            <h5>
              Was willst Du verändern? Sende uns Deine Idee für eine Petition.
            </h5>
            <div className="banner-footer">
              <Link
                className="btn btn-sm btn-primary btn-block"
                to="/petition-starten"
              >
                Jetzt Petition starten
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PetitionBanner;
