import React from "react";
import PropTypes from "prop-types";

const StepOne = ({
  counter,
  formErrors,
  handleChange,
  handleNextStep,
  initiative,
  maxLengths,
  model,
}) => {
  const CONTENT = {
    initiative: {
      title: "Name der Initiative"
    },
    petition: {
      title: "Titel"
    },
  };
  const content = CONTENT?.[model];

  return (
    <>
      <div className="mb-5">
        <label className="h3 text-primary">{content?.title}</label>
        {/* <div className="mb-4">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.
        </div> */}
        <input
          className={`form-control mb-3${formErrors.title ? " is-invalid" : ""
            }`}
          defaultValue={initiative.title}
          name="title"
          onChange={handleChange}
          required
          type="text"
        />
      </div>
      {"initiative" == model && <div className="mb-5">
        <label className="h3 text-primary">Slogan der Initiative</label>
        {/* <div className="mb-4">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.
        </div> */}
        <input
          className={`form-control mb-3${formErrors.subtitle ? " is-invalid" : ""
            }`}
          defaultValue={initiative.subtitle}
          maxLength={maxLengths.subtitle}
          name="subtitle"
          onChange={handleChange}
          type="text"
        />
        <span
          className={`d-block text-end ${counter.subtitle === 0 ? " text-danger" : " "
            }`}
        >
          {counter.subtitle}
        </span>
      </div>}
      <div className="mb-5">
        <label className="h3 text-primary">URL</label>
        {/* <div className="mb-4">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.
        </div> */}
        <div className="input-group mb-3">
          <span className="input-group-text">https://innn.it/</span>
          <input
            className={`form-control ${formErrors.name ? " is-invalid" : ""
              }`}
            defaultValue={initiative.name}
            name="name"
            onChange={handleChange}
            type="text"
          />
        </div>
      </div>
      <div className="text-center text-md-end">
        <button
          className="btn btn-lg btn-primary"
          type="button"
          onClick={() => handleNextStep()}
        >
          Weiter
        </button>
      </div>
    </>
  );
};

StepOne.propTypes = {
  counter: PropTypes.object,
  formErrors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  initiative: PropTypes.object,
  maxLengths: PropTypes.object,
};

export default StepOne;
