
import React, { useState } from "react";
import api from "../../../helpers/api";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import PropTypes from "prop-types";
import AddInitiators from "../components/AddInitiators"
import Tip from "../../../assets/elements/tip.js";

const StepTwo = ({ formErrors, handleChange, initiative, save }) => {
  const [initiatorFieldCount, setInitiatorFieldCount] = useState(0);
  const [isLoadingTargets, setIsLoadingTargets] = useState(false);
  const [targets, setTargets] = useState([]);

  const CONTENTS = [
    {
      bold: "Richte Deine Petition am besten an eine Person,",
      text: "sonst fühlt sich vielleicht niemand angesprochen",
    },
    {
      bold: "Recherchiere, wer über Deine Forderung entscheiden kann.",
      text: "Das muss nicht zwingend der Bundeskanzler sein. 🙃"
    }
  ];

  const counts = new Array(initiatorFieldCount).fill(true).map((_e, i) => (i + 1).toString());

  const getTargets = async (query) => {
    setIsLoadingTargets(true);

    await api.getActors({ search: query }, async (_status, data) => {
      if (!data || !Array.isArray(data)) {
        setTargets([]);
        return false;
      };

      setTargets(data);
    });

    setIsLoadingTargets(false);
  };

  const handleAddInitiatorsFields = () => {
    if (initiatorFieldCount < 5) {
      setInitiatorFieldCount(prev => prev + 1);
    };
  };

  const isEditInitiativeMode = initiative.id && initiative.status === "publish";

  return (
    <>
      <div className="mb-5">
        <div className="align-items-baseline d-flex flex-column flex-md-row mb-3 position-relative">
          <label className="h3 me-3 mb-md-0 mb-3 text-primary"> Wie heißt Du?</label>
          <Tip label="Tipps">
            <ul>
              <li className="mb-4">
                <strong>Starte als Person, nicht als Organisation,</strong>{" "} denn Menschen unterstützen am liebsten andere Menschen
              </li>
            </ul>
          </Tip>
        </div>
        <div className="mb-4">
          Wer stellt die Forderung? Gib Deinen Namen und ggf. den Deiner Mitstreiter:innen ein.
        </div>
        <input
          className={`form-control mb-2 ${formErrors.displayName ? " is-invalid" : ""}`}
          name="displayName"
          onChange={handleChange}
          placeholder="Name"
          required={true}
          type="text"
          value={initiative.displayName || ""}
        />
        {formErrors.displayName && (
          <span className="invalid-feedback mt-0">Bitte gib Deinen Namen ein.</span>
        )}
        {/* {isEditInitiativeMode &&
          <AddInitiators
            counts={counts}
            handleChange={handleChange}
            handleAddInitiatorsFields={handleAddInitiatorsFields}
            initiatorFieldCount={initiatorFieldCount}
            initiative={initiative}
            save={save}
          />
        } */}
      </div>

      <div className="mb-5">
        <div className="align-items-md-center d-flex flex-column flex-md-row mb-3 position-relative">
          <label className="h3 me-3 mb-md-0 mb-3 text-primary"> Wer entscheidet?</label>
          <Tip label="Tipps">
            {
              CONTENTS.map((content, index) => (
                <ul key={index}>
                  <li className="mb-4">
                    <strong>{content.bold}</strong>{" "}{content.text}
                  </li>
                </ul>
              ))
            }
          </Tip>
          
        </div>
        <div className="mb-3">
          An wen richtest Du Deine Petition? Wer kann über Deine Forderung entscheiden?
        </div>
        <AsyncTypeahead
          filterBy={() => true}
          id="initiativeTargets"
          allowNew={true}
          isLoading={isLoadingTargets}
          isInvalid={!!formErrors.targets}
          labelKey="name"
          maxHeight="330px"
          minLength={3}
          multiple
          newSelectionPrefix=""
          onChange={(value) => {
            handleChange({ target: { name: "targets", value } })
          }}
          options={targets}
          onSearch={getTargets}
          placeholder="Entscheider:in"
          renderMenuItemChildren={(option, props, _index) => {
            return (
              <div className="clearfix">
                <div className="float-start text-wrap">
                  <Highlighter search={props.text}>
                    {option.name}
                  </Highlighter>
                </div>
              </div>
            )
          }}
          selected={initiative.targets}
        />
        {formErrors.targets && (
          <span className="fs-6 text-danger">Bitte gib einen Namen ein.</span>
        )}
      </div>
    </>
  );
};

StepTwo.propTypes = {
  formErrors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  initiative: PropTypes.object,
};


export default StepTwo;