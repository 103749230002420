import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCaretDown, faPlus } from "@fortawesome/pro-solid-svg-icons";

const Buttons = () => {
  return (
    <section className="mt-5">
      <h2>Buttons</h2>
      <div className="mb-2">
        <button className="btn btn-petition-primary me-2">Sign petition</button>

        <button className="btn btn-initiative-secondary btn-sm me-2">
          <FontAwesomeIcon icon={faPlus} />
          Add signature lists
        </button>

        <button className="btn btn-petition-transparent btn-icon me-2">
          <FontAwesomeIcon icon={faArrowRight} />
        </button>

        <button className="btn btn-petition-primary" disabled={true}>Expired</button>
      </div>

      <pre>
        {
`<button className="btn btn-petition-primary me-2">Sign petition</button>

<button className="btn btn-initiative-secondary me-2">
  <FontAwesomeIcon icon={faPlus} />
  Add signature lists
</button>

<button className="btn btn-petition-transparent btn-sm btn-icon me-2">
  <FontAwesomeIcon icon={faArrowRight} />
</button>

<button className="btn btn-petition-primary" disabled={true}>Expired</button>`
        }
      </pre>

      <ul>
        <li>All FontAwesome icons are automatically sized and aligned to the left.</li>
        <li>Use <code>.btn-icon</code> in buttons that contain an icon only.</li>
      </ul>
      <h3>Petitions</h3>
      <div className="petition">
        <div className="mb-2">
          <button className="btn btn-primary me-2">
            Button
          </button>

          <button className="btn btn-primary me-2">
            <FontAwesomeIcon icon={faPlus} />
            Button with icon
          </button>

          <button className="btn btn-primary btn-icon me-2">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>

          <button className="btn btn-primary" disabled={true}>
            Disabled
          </button>
        </div>
        <div className="mb-2">
          <button className="btn btn-secondary me-2">
            Button
          </button>

          <button className="btn btn-secondary me-2">
            <FontAwesomeIcon icon={faPlus} />
            Button with icon
          </button>

          <button className="btn btn-secondary btn-icon me-2">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>

          <button className="btn btn-secondary" disabled={true}>
            Disabled
          </button>
        </div>
        <div className="mb-2">
          <button className="btn btn-outline me-2">
            Button
          </button>

          <button className="btn btn-outline me-2">
            <FontAwesomeIcon icon={faPlus} />
            Button with icon
          </button>

          <button className="btn btn-outline btn-icon me-2">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>

          <button className="btn btn-outline" disabled={true}>
            Disabled
          </button>
        </div>
        <div className="mb-2">
          <button className="btn btn-transparent me-2">
            Button
          </button>

          <button className="btn btn-transparent me-2">
            <FontAwesomeIcon icon={faPlus} />
            Button with icon
          </button>

          <button className="btn btn-transparent btn-icon me-2">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>

          <button className="btn btn-transparent" disabled={true}>
            Disabled
          </button>
        </div>
        <div className="mb-2">
          <button className="btn btn-sm btn-primary me-2">
            Button
          </button>

          <button className="btn btn-sm btn-primary me-2">
            <FontAwesomeIcon icon={faPlus} />
            Button with icon
          </button>

          <button className="btn btn-sm btn-primary btn-icon me-2">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>

          <button className="btn btn-sm btn-primary" disabled={true}>
            Disabled
          </button>
        </div>
        <div className="mb-2">
          <button className="btn btn-sm btn-secondary me-2">
            Button
          </button>

          <button className="btn btn-sm btn-secondary me-2">
            <FontAwesomeIcon icon={faPlus} />
            Button with icon
          </button>

          <button className="btn btn-sm btn-secondary btn-icon me-2">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>

          <button className="btn btn-sm btn-secondary" disabled={true}>
            Disabled
          </button>
        </div>
        <div className="mb-2">
          <button className="btn btn-sm btn-outline me-2">
            Button
          </button>

          <button className="btn btn-sm btn-outline me-2">
            <FontAwesomeIcon icon={faPlus} />
            Button with icon
          </button>

          <button className="btn btn-sm btn-outline btn-icon me-2">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>

          <button className="btn btn-sm btn-outline" disabled={true}>
            Disabled
          </button>
        </div>
        <div className="mb-2">
          <button className="btn btn-sm btn-transparent me-2">
            Button
          </button>

          <button className="btn btn-sm btn-transparent me-2">
            <FontAwesomeIcon icon={faPlus} />
            Button with icon
          </button>

          <button className="btn btn-sm btn-transparent btn-icon me-2">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>

          <button className="btn btn-sm btn-transparent" disabled={true}>
            Disabled
          </button>
        </div>
      </div>

      <h3>Initiatives</h3>
      <div className="initiative">
        <div className="mb-2">
          <button className="btn btn-primary me-2">
            Button
          </button>

          <button className="btn btn-primary me-2">
            <FontAwesomeIcon icon={faPlus} />
            Button with icon
          </button>

          <button className="btn btn-primary btn-icon me-2">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>

          <button className="btn btn-primary" disabled={true}>
            Disabled
          </button>
        </div>
        <div className="mb-2">
          <button className="btn btn-secondary me-2">
            Button
          </button>

          <button className="btn btn-secondary me-2">
            <FontAwesomeIcon icon={faPlus} />
            Button with icon
          </button>

          <button className="btn btn-secondary btn-icon me-2">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>

          <button className="btn btn-secondary" disabled={true}>
            Disabled
          </button>
        </div>
        <div className="mb-2">
          <button className="btn btn-outline me-2">
            Button
          </button>

          <button className="btn btn-outline me-2">
            <FontAwesomeIcon icon={faPlus} />
            Button with icon
          </button>

          <button className="btn btn-outline btn-icon me-2">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>

          <button className="btn btn-outline" disabled={true}>
            Disabled
          </button>
        </div>
        <div className="mb-2">
          <button className="btn btn-transparent me-2">
            Button
          </button>

          <button className="btn btn-transparent me-2">
            <FontAwesomeIcon icon={faPlus} />
            Button with icon
          </button>

          <button className="btn btn-transparent btn-icon me-2">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>

          <button className="btn btn-transparent" disabled={true}>
            Disabled
          </button>
        </div>
        <div className="mb-2">
          <button className="btn btn-sm btn-primary me-2">
            Button
          </button>

          <button className="btn btn-sm btn-primary me-2">
            <FontAwesomeIcon icon={faPlus} />
            Button with icon
          </button>

          <button className="btn btn-sm btn-primary btn-icon me-2">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>

          <button className="btn btn-sm btn-primary" disabled={true}>
            Disabled
          </button>
        </div>
        <div className="mb-2">
          <button className="btn btn-sm btn-secondary me-2">
            Button
          </button>

          <button className="btn btn-sm btn-secondary me-2">
            <FontAwesomeIcon icon={faPlus} />
            Button with icon
          </button>

          <button className="btn btn-sm btn-secondary btn-icon me-2">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>

          <button className="btn btn-sm btn-secondary" disabled={true}>
            Disabled
          </button>
        </div>
        <div className="mb-2">
          <button className="btn btn-sm btn-outline me-2">
            Button
          </button>

          <button className="btn btn-sm btn-outline me-2">
            <FontAwesomeIcon icon={faPlus} />
            Button with icon
          </button>

          <button className="btn btn-sm btn-outline btn-icon me-2">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>

          <button className="btn btn-sm btn-outline" disabled={true}>
            Disabled
          </button>
        </div>
        <div className="mb-2">
          <button className="btn btn-sm btn-transparent me-2">
            Button
          </button>

          <button className="btn btn-sm btn-transparent me-2">
            <FontAwesomeIcon icon={faPlus} />
            Button with icon
          </button>

          <button className="btn btn-sm btn-transparent btn-icon me-2">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>

          <button className="btn btn-sm btn-transparent" disabled={true}>
            Disabled
          </button>
        </div>
      </div>

      <h3>Styles</h3>
      <div className="mb-2">
        <button className="btn btn-danger me-2">
          Danger
        </button>

        <button className="btn btn-danger me-2" disabled={true}>
          Disabled
        </button>

        <button className="btn btn-danger-outline me-2">
          Outline
        </button>

        <button className="btn btn-danger-outline" disabled={true}>
          Disabled
        </button>
      </div>
      <div className="mb-2">
        <button className="btn btn-success me-2">
          Success
        </button>

        <button className="btn btn-success me-2" disabled={true}>
          Disabled
        </button>

        <button className="btn btn-success-outline me-2">
          Outline
        </button>

        <button className="btn btn-success-outline" disabled={true}>
          Disabled
        </button>
      </div>
    </section>
  );
};

export default Buttons;
