const formatDate = (rawDate, options = { year: "numeric", month: "long", day: "numeric" }) => {
  if (!rawDate) {
    return false;
  }

  const dateObject = new Date(rawDate);

  if (!dateObject instanceof Date || isNaN(dateObject)) {
    return false;
  }

  return dateObject.toLocaleDateString("de-DE", options);
};

export default formatDate;
