import React, { useState } from "react";
import PropTypes from "prop-types";
import Tip from "../../../assets/elements/tip";

const StepFour = ({ formErrors, handleChange, initiative }) => {
  const [error, setError] = useState({});

  const CONTENTS = [
    "wegmit219a",
    "VWohneZwangsarbeit",
    "Merkelsagwas"
  ];
  
  const getTipContents = (type) => {
    return (
      <>
        <p className="mb-3">Hashtags und Kurzlinks sollten <strong>kurz & verständlich sein,</strong> auch Abkürzungen sind ok. Am Besten vermitteln sie die Forderung Deiner Kampagne.</p>
        <ul className="list-unstyled">
        <li>Beispiele:</li>
        {CONTENTS.map((content, index) => (
          <li key={index}>
            {type === "url" ? "innn.it/" : "#"}<strong>{content}</strong>
          </li>
        ))}
      </ul>
      </>
    );
  };

  const onChange = (e) => {
    const {value, name} = e.target
    setError(prev => ({...prev, [name]: false}));

    if ('' != value && !value.match(/^[\w-]+$/)) {
      setError(prev => ({...prev, [name]: true}));
      return;
    };

    handleChange({ target: { name, value } });
  };

  return (
    <>
      <div className="mb-5">
        <div className="align-items-md-center d-flex flex-column flex-md-row mb-3 position-relative">
          <label className="h3 me-3 mb-md-0 mb-3 text-primary">Hashtag für Deine Petition (optional)</label>
          <Tip label="Tipps">
            {getTipContents()}
          </Tip>
        </div>
        <div className="mb-4">
          Optional kannst Du Dir einen einzigartigen Hashtag für die Verbreitung 
          Deiner Petition online überlegen.
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text">#</span>
          <input
            className={`form-control ${(formErrors.hashtag || error.hashtag) ? " is-invalid" : ""}`}
            name="hashtag"
            onChange={onChange}
            type="text"
            value={initiative.hashtag}
          />
          {(formErrors.hashtag || error.hashtag) && (
            <span className="form-text invalid-feedback mt-2">Dein Hashtag darf keine Sonder- oder Leerzeichen enthalten.</span>
          )}
        </div>
      </div>  
  
      <div className="mb-5">
        <div className="align-items-md-center d-flex flex-column flex-md-row mb-3 position-relative">
          <label className="h3 me-3 mb-md-0 mb-3 text-primary">Link für Deine Petition (optional)</label>
          <Tip label="Tipps">
            {getTipContents("url")}
          </Tip>
        </div>
        <div className="mb-3">
          Der Link wird automatisch aus dem Titel Deiner Petition generiert. 
          Optional kannst Du Dir hier statt dessen einen kurzen Link für Deine Petition anlegen.
          Es bietet sich an den Hashtag auch als Kurzlink zu verwenden.
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text">https://innn.it/</span>
          <input
            className={`form-control ${(formErrors.name || error.name) ? " is-invalid" : ""}`}
            name="name"
            onChange={onChange}
            type="text"
            value={initiative.name}
          />
          {(formErrors.name || error.name) && (
            <span className="form-text invalid-feedback mt-2">Deine URL darf keine Sonder- oder Leerzeichen enthalten.</span>
          )}
        </div>
      </div>
      
    </>
  )
}

StepFour.propTypes = {
  handleChange: PropTypes.func.isRequired,
  initiative: PropTypes.object,
};


export default StepFour;