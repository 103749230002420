import React from "react";

const useValidateStep = (step, initiative, source='dashboard') => {
  const model = initiative.type;
  const stepRequiredFields = {
    initiative: {
      dashboard: {
        1: ["title", "subtitle", "name"],
        2: ["location"],
        3: ["summary", "content"],
      }
    },
    petition: {
      dashboard: {
        1: ["title", "name"],
        // 2: ["initiators", "targets"],
        3: ["content"],
      },

      "petition-starten": {
        1: ["title", "tag"],
        2: ["displayName", "targets"],
        3: ["content"],
        5: ["featuredImage"]
      }
    }
  };

  const requiredFields = stepRequiredFields[model][source][step];
  const errors = {};

  if (!requiredFields) {
    return [true, errors];
  }

  requiredFields.forEach((field) => {
    if (
      initiative[field] && (
        ("string" == typeof initiative[field] && initiative[field] !== "") ||
        ((Array.isArray(initiative[field])) && initiative[field].length > 0) ||
        ("object" == typeof initiative[field] && Object.keys(initiative[field]).length > 0)
      )
    ) {
      errors[field] = false;
    } else {
      errors[field] = true;
    }
  });

  return [isValid(errors), errors];
};

const isValid = (errors) => {
  return !Object.values(errors).includes(true);
};

export default useValidateStep;
