import React from "react";
import { Link } from "react-router-dom";

const Statuses = () => {
  return (
    <section className="mt-5">
      <h2>Statuses</h2>
      <div className="row">
        <div className="col-12 col-md-4">
          <span className="status status-bg">Volksinitiative</span>
          <span className="status status-underline">
            2. Sammlung
            <Link to="/" className="status-link">
              Was bedeutet das?
            </Link>
          </span>
        </div>
      </div>
    </section>
  );
};

export default Statuses;
