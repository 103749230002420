import api from "./api";

export const populateLocation = (props) => {
  return new Promise((resolve, reject) => {
    if (
      !props.location ||
      "string" !== typeof props.location ||

      !props.postalCode ||
      "string" !== typeof props.postalCode
    ) {
      resolve(props);
    }

    try {
      const populatedProps = props;

      api.getPostalCode(props.postalCode, (_status, postalCode) => {
        if (!postalCode?.localities.includes(props.location)) {
          reject(false);
        }

        populatedProps.postalCode = postalCode;

        api.getLocation(props.location, (_status, location) => {
          populatedProps.location = location;
          resolve(populatedProps);
        });
      });
    } catch (error) {
      reject(error);
    }
  });
};
