import React, { useContext, useEffect } from "react";
import { ThemeContext } from "../ThemeContext";

const DatenschutzSocialMedia = () => {
  const { setTheme } = useContext(ThemeContext);

  useEffect(() => {
    setTheme("initiative");
  }, []);

  return (
    <main>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            <h2 className="text-primary py-4">Datenschutzhinweise für unsere Social Media-Seiten</h2>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <p>Wenn Du unsere Social-Media-Seiten besuchst, kann es gegebenenfalls erforderlich sein, dass hierbei Daten zu Deiner Person verarbeitet werden. Nachfolgend möchten wir Dich über den Umgang mit Deinen Daten gem. Art. 13 Datenschutzgrundverordnung (DSGVO) informieren.</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Verantwortlicher</h3>
            <p>Wir, innn.it e.V., Pressehaus Podium, Karl-Liebknecht-Straße 29A, 10178 Berlin, betreiben folgende Social Media-Seiten für innn.it:</p>
            <ul>
              <li>Twitter: <a href="https://twitter.com/innn_it" target="_blank" rel="noreferrer">https://twitter.com/innn_it</a></li>
              <li>Meta (Facebook): <a href="https://www.facebook.com/HierKannstDuMitbestimmen/" target="_blank" rel="noreferrer">https://www.facebook.com/HierKannstDuMitbestimmen</a></li>
              <li>Instagram: <a href="https://www.instagram.com/innn.it/" target="_blank" rel="noreferrer">https://www.instagram.com/innn.it</a></li>
              <li>TikTok: <a href="https://www.tiktok.com/@innn.it" target="_blank" rel="noreferrer">https://www.tiktok.com/@innn.it</a></li>
            </ul>
            <p>Neben uns ist auch der jeweilige Betreiber der Social-Media-Plattform für die Verarbeitung Deiner personenbezogenen Daten verantwortlich. Soweit wir hierauf Einfluss nehmen und die Datenverarbeitung parametrisieren können, wirken wir im Rahmen der uns zur Verfügung stehenden Möglichkeiten auf den datenschutzgerechten Umgang durch den Betreiber der Social-Media-Plattform hin. Bitte beachte in diesem Zusammenhang auch die Datenschutzhinweise der jeweiligen Social-Media-Plattform.</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Datenverarbeitung durch uns</h3>
            <p>Die von Dir auf unseren Social Media-Seiten eingegebenen Daten wie z. B. Nutzernamen, Kommentare, Videos, Bilder, Likes, öffentliche Nachrichten etc. werden durch die Social Media-Plattform veröffentlicht und von uns nicht für andere Zwecke verarbeitet. Wir behalten uns lediglich vor, Inhalte zu löschen, sofern dies erforderlich sein sollte. Ggf. teilen wir Deine Inhalte auf unserer Seite, wenn dies eine Funktion der Social Media-Plattform ist und kommunizieren über die Social Media-Plattform mit Dir.</p>
            <p>Sofern Du uns eine Anfrage auf der Social-Media-Plattform stellst, verweisen wir je nach Inhalt gegebenenfalls auch auf andere, sichere Kommunikationswege, die die Vertraulichkeit garantieren. Du hast zum Beispiel jederzeit die Möglichkeit, uns Deine Anfragen an die im <a href="https://innn.it/impressum">Impressum</a> genannte Anschrift oder an hallo[ät]innn.it zu senden. Die Wahl des geeigneten Kommunikationsweges liegt hierbei in Deiner eigenen Verantwortung.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO. Die Datenverarbeitung erfolgt im Interesse unserer Öffentlichkeitsarbeit und Kommunikation mit Dir.</p>
            <p>Einige Social-Media-Plattformen erstellen Statistiken, die auf Basis von Nutzungsdaten erstellt werden und Informationen über Deine Interaktion mit unserer Social-Media-Seite enthalten. Die Durchführung und Zurverfügungstellung dieser Statistiken können wir nicht beeinflussen und auch nicht unterbinden. Diese Informationen verarbeiten wir nach Art. 6 Abs. 1 lit. f DSGVO in dem berechtigten Interesse, den Umgang mit unseren Social-Media-Seiten zu validieren und unsere Inhalte zielgruppenorientiert zu verbessern.</p>
            <p>Wie bereits ausgeführt, achten wir an den Stellen, an denen uns der Anbieter der Social Media-Plattform die Möglichkeit gibt, darauf, unsere Social Media-Seiten möglichst datenschutzkonform zu gestalten. Wir nutzen die demografischen, interessenbasierten, verhaltensbasierten oder standortbasierten Zielgruppendefinitionen für Werbung, die uns der Betreiber der Social Media Plattform ggf. zur Verfügung stellt. Im Hinblick auf Statistiken, die uns der Anbieter der Social Media Plattform zur Verfügung stellt, können wir diese nur bedingt beeinflussen und auch nicht abschalten. Wir achten aber darauf, dass uns keine zusätzlichen optionalen Statistiken zur Verfügung gestellt werden.</p>
            <p><strong>Wenn Du einer bestimmten Datenverarbeitung, auf die wir einen Einfluss haben, widersprechen möchtest, wende Dich bitte an die im <a href="https://innn.it/impressum">Impressum</a> genannten Kontaktdaten. Wir prüfen Deinen Widerspruch dann oder leiten ihn bei Bedarf an die Social Media-Plattform weiter.</strong></p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Einsatz von Insights</h3>
            <p>Im Zusammenhang mit dem Betrieb unserer Facebook-Seite nutzen wir die Funktion Insights von Meta (Facebook), um statistische Daten zu den Nutzer:innen unserer Facebook-Seite zu erhalten. Dies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO, basierend auf unserem Interesse, die Nutzung unseres Auftritts bei Facebook messen zu können.</p>
            <p>Welche Informationen dabei verwendet werden, findest Du in den „<a href="https://www.facebook.com/legal/terms/information_about_page_insights_data" target="_blank" rel="noreferrer">Informationen zu Seiten-Insights-Daten</a>“. Hier findest Du auch Informationen zur gemeinsamen Verantwortlichkeit von uns und Meta (Facebook) bei Nutzung der Funktion Insights. Ferner findest Du dort Informationen zu Deinen Betroffenenrechten. Wir haben mit Meta (Facebook) eine Vereinbarung getroffen. In dieser haben wir vereinbart, dass Meta (Facebook) vorrangig dafür verantwortlich ist, Dir Informationen über die gemeinsame Verarbeitung bereitzustellen und es Dir zu ermöglichen, Deine Dir gemäß der DSGVO zustehenden Rechte auszuüben.</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Datenverarbeitung durch den Betreiber der Social Media-Plattform</h3>
            <p>Der Betreiber der Social Media-Plattform setzt Webtrackingmethoden ein. Das Webtracking kann dabei auch unabhängig davon erfolgen, ob Du bei der Social Media-Plattform angemeldet oder registriert bist.</p>
            <p>Wir möchten Dich daher darauf hinweisen, dass nicht ausgeschlossen werden kann, dass der	Anbieter der Social-Media-Plattform Deine Profil- und Verhaltensdatendaten nutzt, um etwa Deine Gewohnheiten, persönlichen Beziehungen, Vorlieben usw. auszuwerten. Wir haben insofern keinen Einfluss auf die Verarbeitung Deiner Daten durch den Anbieter der Social-Media-Plattform, sodass die Nutzung der Social-Media-Plattform auf Deine eigene Verantwortung hin erfolgt.</p>
            <p>Nähere Informationen zur Datenverarbeitung durch den Anbieter der Social Media-Plattform, Konfigurationsmöglichkeiten zum Schutz Deiner Privatsphäre soweit weitere Widerspruchsmöglichkeiten und, sofern vorhanden und abgeschlossen, die Vereinbarung nach Art. 26 DSGVO findest Du in den Datenschutzhinweisen des Anbieters:</p>
            <ul>
              <li>Twitter: <a href="https://twitter.com/privacy" target="_blank" rel="noreferrer">https://twitter.com/privacy</a></li>
              <li>Meta (Facebook): <a href="https://www.facebook.com/about/privacy" target="_blank" rel="noreferrer">https://www.facebook.com/about/privacy</a> und <a href="https://www.facebook.com/legal/terms/page_controller_addendum" target="_blank" rel="noreferrer">https://www.facebook.com/legal/terms/page_controller_addendum</a></li>
              <li>Instagram: <a href="https://www.instagram.com/privacy" target="_blank" rel="noreferrer">https://www.instagram.com/privacy</a></li>
              <li>TikTok: <a href="https://www.tiktok.com/legal/privacy-policy-eea" target="_blank" rel="noreferrer">https://www.tiktok.com/legal/privacy-policy-eea</a></li>
            </ul>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Speicherdauer</h3>
            <p>Wir löschen Deine personenbezogenen Daten, wenn sie für die vorgenannten Verarbeitungszwecke nicht mehr erforderlich sind und keine gesetzlichen Aufbewahrungspflichten einer Löschung entgegenstehen.</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Deine Rechte als Nutzer:in</h3>
            <p>Du hast als Webseitennutzer:in sowohl uns gegenüber als auch gegenüber dem Anbieter der Social-Media-Plattform, die Möglichkeit bei Vorliegen der Voraussetzungen folgende Rechte geltend zu machen:</p>
            <h4>Auskunftsrecht (Art. 15 DSGVO):</h4>
            <p>Du hast das Recht eine Bestätigung darüber zu verlangen, ob Dich betreffende personenbezogene Daten verarbeitet werden; ist dies der Fall, so hast Du ein Recht auf Auskunft über diese personenbezogenen Daten und auf die in Art. 15 DSGVO im Einzelnen aufgeführten Informationen.</p>
            <h4>Recht auf Berichtigung und Löschung (Art. 16 und 17 DSGVO):</h4>
            <p>Du hast das Recht, unverzüglich die Berichtigung Dich betreffender unrichtiger personenbezogener Daten und ggf. die Vervollständigung unvollständiger personenbezogener Daten zu verlangen. Du hast zudem das Recht, zu verlangen, dass Dich betreffende personenbezogene Daten unverzüglich gelöscht werden, sofern einer der in Art. 17 DSGVO im Einzelnen aufgeführten Gründe zutrifft, z. B. wenn die Daten für die verfolgten Zwecke nicht mehr benötigt werden.</p>
            <p>Du hast zudem das Recht, zu verlangen, dass Dich betreffende personenbezogene Daten unverzüglich gelöscht werden, sofern einer der in Art. 17 DSGVO im einzelnen aufgeführten Gründe zutrifft, z.B. wenn die Daten für die verfolgten Zwecke nicht mehr benötigt werden.</p>
            <h4>Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO):</h4>
            <p>Du hast das Recht, die Einschränkung der Verarbeitung zu verlangen, wenn eine der in Art. 18 DSGVO aufgeführten Voraussetzungen gegeben ist, z. B. wenn Du Widerspruch gegen die Verarbeitung eingelegt hast, für die Dauer einer etwaigen Prüfung.</p>
            <h4>Recht auf Datenübertragbarkeit (Art. 20 DSGVO):</h4>
            <p>In bestimmten Fällen, die in Art. 20 DSGVO im Einzelnen aufgeführt werden, hast Du das Recht, die Dich betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten bzw. die Übermittlung dieser Daten an einen Dritten zu verlangen.</p>
            <h4>Widerspruchsrecht (Art. 21 DSGVO):</h4>
            <p><strong>Werden Daten auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO verarbeitet (Datenverarbeitung zur Wahrung berechtigter Interessen), steht Dir das Recht zu, aus Gründen, die sich aus Deiner besonderen Situation ergeben, jederzeit gegen die Verarbeitung Widerspruch einzulegen. Wir verarbeiten die personenbezogenen Daten dann nicht mehr, es sei denn, es liegen nachweisbar zwingende schutzwürdige Gründe für die Verarbeitung vor, die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</strong></p>
            <p><strong>Werden die Daten auf Grundlage des berechtigten Interesses zum Zwecke der Direktwerbung verarbeitet, steht Dir ein eigenes Widerspruchsrecht zu, das Du jederzeit ohne Angabe von Gründen geltend machen kannst und dessen Ausübung zur Beendigung der Verarbeitung zum Zwecke der Direktwerbung führt.</strong></p>
            <h4>Beschwerderecht bei einer Aufsichtsbehörde</h4>
            <p>Du hast gemäß Art. 77 DSGVO das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Du der Ansicht bist, dass die Verarbeitung der Dich betreffenden Daten gegen datenschutzrechtliche Bestimmungen verstößt. Das Beschwerderecht kann insbesondere bei einer Aufsichtsbehörde in dem Mitgliedstaat Deines Aufenthaltsorts, Deines Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes geltend gemacht werden.</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Kontaktdaten des Datenschutzbeauftragten</h3>
            <p>Unser Datenschutzbeauftragter steht Dir gern für Auskünfte oder Anregungen zum Thema Datenschutz zur Verfügung:</p>
            <p>datenschutz nord GmbH
              <br />Niederlassung Berlin
              <br />Kurfürstendamm 212
              <br />10719 Berlin
              <br />office[ät]datenschutz-nord.de
            </p>
          </div>
        </div>
      </div>
    </main>
  )
}

export default DatenschutzSocialMedia;
