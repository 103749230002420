const validateFields = (action = "getUser", user) => {
  let errors = {};

  const actionFields = {
    getUser: ["email"],
    resetPasswordRequest: ["email"],
    resetPassword: ["password"],
    setPassword: ["password"],
    signIn: ["password"],
    signUp: ["firstName", "lastName", "country", "password", "subscribe"],
  };

  if (!actionFields[action]) {
    action = "getUser"
  }
  const currentActionFields = actionFields[action];

  if (
    currentActionFields.includes("country") &&
    user["country"] &&
    "DE" == user["country"]
  ) {
    currentActionFields.push("location");
    currentActionFields.push("postalCode");
  }

  currentActionFields.forEach((field) => {
    if (!user[field] || user[field] === "") {
      errors[field] = "Bitte fülle dieses Feld aus";
    }

    if (
      user.email &&
      !user.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      errors = { ...errors, email: "Bitte gib eine gültige E-Mail ein" };
    }
  });

  return [isValid(errors), errors];
};

const isValid = (errors) => {
  return Object.keys(errors).length === 0;
};

export default validateFields;
