import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react"
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux"
import App from "./App";
import "./i18n";
import matomoOptions from "./matomoOptions";
import store from "./redux/store"
import "./styles/main.scss";

const matomoInstance = createInstance(matomoOptions);
ReactDOM.render(
  <React.StrictMode>
    <MatomoProvider value={matomoInstance}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </MatomoProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
