import React, { useState } from "react";
import { Link } from "react-router-dom";
import Attachment from "./Attachment.js";
import hero from "../styles/images/hero-image.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faMapMarkerAlt, faPen, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import InitiativeStage from "./InitiativeStage";
import InitiativeType from "./InitiativeType";
import formatNumber from "../helpers/format-number.js";
import addCommasAndAnd from "../helpers/add-commas-and-and.js";
import getTarget from "../helpers/get-target";

const InitiativeCard = (props) => {
  const [isDeleteSignatureModal, setIsDeleteSignatureModal] = useState(false);
  const initiative = props.initiative;
  const url = initiative.name ? `/${initiative.name}` : "/contact";

  const InitiativeImage = () => {
    if (initiative.featuredImage) {
      return (
        <Attachment
          alt={initiative.title}
          attachment={initiative.featuredImage}
          className="card-img-top"
        />
      );
    } else {
      return <img className="card-img-placeholder" src={hero}></img>;
    }
  };

  const fetchInitiators = () => {
    let initiators = initiative.initiators;
    if (!initiators || initiators.length < 1) {
      initiators = initiative._initiators;
    }

    if (!initiators) {
      return false;
    }

    const parsedInitiators = initiators.map((e) => {
      if (e.name) {
        return e.name;
      } else if (e.displayName && "verified" == e.status) {
        return e.displayName
      }
      
      return false;
    });

    const validInitiators = parsedInitiators.filter((e) => e);
    const initiatorLabels = addCommasAndAnd(validInitiators);

    if (!initiatorLabels) {
      return false;
    }

    const LABELS = {
      singularInitiator: `${initiatorLabels} fordert:`,
      pluralInitiator: `${initiatorLabels} fordern:`,
    }

    if (validInitiators.length == 1) {
      return LABELS.singularInitiator;
    } else {
      return LABELS.pluralInitiator;
    }
  }

  const getCountdown = () => {
    if (!initiative.endDates?.[initiative.stage]) {
      return false;
    }

    const dayInMiliseconds = 24 * 60 * 60 * 1000;
    const now = new Date();
    const endDate = new Date(initiative.endDates?.[initiative.stage]);
    const countdown = Math.round((endDate - now) / dayInMiliseconds);

    return countdown;
  };

  const getLinkLabel = () => {
    if (props.label) {
      return props.label;
    }

    if (initiative.type === "petition") {
      return "Mehr erfahren";
    } else if (initiative.type === "initiative") {
      if ("ended" === initiative?.initiativeStatuses?.[initiative?.stage]) {
        return "Details ansehen"
      } else {
        return "Jetzt unterstützen"

      }
    }
    return false;

  }
  const getProgress = (count, target) => {
    return (count / target) * 100;
  };

  const onMouseEnter = () => {
    if (props.pathName === "/profile/signatures") {
      setIsDeleteSignatureModal(true);
    };
  };

  const onMouseLeave = () => {
    setIsDeleteSignatureModal(false);
  };

  const countdown = getCountdown();
  const initiators = fetchInitiators();
  const linkLabel = getLinkLabel();

  return (
    <div 
      className="card h-100 position-relative" 
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      { isDeleteSignatureModal && <DeleteSignatureIcon handleClick={props.handleClick} /> }
      <Link className="card-img-link" to={url}>
        {!!initiative.success && (
          <div className="card-banner card-banner-success">Erfolg!</div>
        )}
        {!!countdown &&
          countdown <= 10 &&
          countdown > 0 && (
            <div className="card-banner card-banner-danger">
              Noch {countdown} {countdown == 1 && "Tag"}
              {countdown > 1 && "Tage"}!
            </div>
          )}
        <InitiativeImage />
      </Link>
      <div className="card-body">
        <h2
          className={`h3 card-title order-3 ${initiative.type === "petition" ? "text-black" : " text-primary"
            }`}
        >
          {initiative.title}
        </h2>
        <div className="card-meta order-1">
          <div className="card-location d-flex align-items-center">
            {initiative.type === "initiative" &&
              <>
                <FontAwesomeIcon
                  className="icon-left text-primary"
                  fixedWidth
                  icon={faMapMarkerAlt}
                />
                <span>
                  {typeof initiative.location === "object"
                    ? initiative.location?.name
                    : initiative.location
                  }
                </span>
              </>
            }
            {initiative.type === "petition" && initiators &&
              <>
                <FontAwesomeIcon
                  className="icon-left text-primary"
                  fixedWidth
                  icon={faBullhorn}
                />

                <span className="fs-5">{initiators}</span>
              </>
            }
          </div>
          {initiative.type != "petition" && (
            <div className="card-status">
              <InitiativeType initiative={initiative} />
              <InitiativeStage initiative={initiative} />
            </div>
          )}
        </div>
        {initiative.type != "petition" && (
          <p className="h5 card-subtitle order-2" role="doc-subtitle">
            {initiative.subtitle}
          </p>
        )}
      </div>
      <div className="card-footer">
        {"ended" === initiative?.initiativeStatuses?.[initiative?.stage] && (
          <label className="text-danger ps-4 d-block mb-3">
            Sammlung beendet
          </label>
        )}

        {linkLabel && <Link className="btn btn-outline" to={url}>{linkLabel}</Link>}

        {initiative.type === "petition" && !!initiative.signatureCount && (
          <div className="mb-1 mt-5">
            <div className="d-flex align-items-center mb-1">
              <FontAwesomeIcon
                className="icon-left text-primary"
                fixedWidth
                icon={faPen}
                size="xl"
              />
              <p className="h5 mb-0">
                {formatNumber(initiative.signatureCount)}{" "}
                Unterschriften auf innn.it
              </p>
            </div>
            <div className="progress progress-sm">
              <div
                className="progress-bar"
                style={{
                  width: getProgress(
                    initiative.signatureCount,
                    getTarget(initiative.signatureCount)
                  ) + "%",
                }}
                aria-valuenow="50"
              ></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const DeleteSignatureIcon = ({ handleClick }) => {
  return (
    <div className="icon-trash-can"
      onClick={handleClick}
      data-bs-toggle="modal" 
      data-bs-target="#deleteSignature"
    >
      <FontAwesomeIcon
        className="text-primary"
        fixedWidth
        icon={faTrashCan}
        size="sm"
      />
    </div>
  )
};

export default InitiativeCard;
