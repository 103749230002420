import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "./Modal"
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";

const SetPasswordMessageModal = () => {
  return (
    <Modal id="setPasswordMessageModal">
      <span className="d-flex mb-3">
        <FontAwesomeIcon
          className="icon icon-left text-petition-primary"
          icon={faEnvelope}
          size="3x"
        />
        <span className="ps-4">
          <h4 className="text-petition-primary mb-3">
            Bitte bestätige Deine E-Mail Adresse, um ein Passwort zu erstellen
          </h4>
          <span className="text-black">
            Klicke einfach auf den Link, den wir Dir per E-Mail gesendet haben.
            Nachdem Du ein Passwort erstellt hast, hast Du Zugriff auf Dein
            Konto.
          </span>
        </span>
      </span>
    </Modal>
  )
}

export default SetPasswordMessageModal;
