import { useMatomo } from "@datapunt/matomo-tracker-react";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

const DocumentPostForm = ({
  createDocument,
  exportType,
  firstFieldRef,
  signer,
}) => {
  const [error, setError] = useState({});
  let fieldsetRef = useRef();
  const [recipient, setRecipient] = useState({})
  const { trackEvent } = useMatomo();

  const handleChange = (e) => {
    setRecipient({
      ...recipient,
      [e.target.name]: e.target.value,
    });

    trackEvent({
      category: "document",
      action: "fillDocumentFormField",
    });
  }

  useEffect(() => {
    setRecipient({
      ...signer,
      location: signer?.location?.name,
      postalCode: signer?.postalCode?.postalCode,
    });
  }, [signer]);

  const handleSubmit = async (e) => {
    e.preventDefault()

    setError({});
    if (!isFormValid()) {
      return;
    }

    if (fieldsetRef && fieldsetRef.current) {
      fieldsetRef.current.setAttribute("disabled", "true");
    }

    createDocument({ exportType, recipient }).finally(() => {
      if (fieldsetRef && fieldsetRef.current) {
        fieldsetRef.current.removeAttribute("disabled");
      }

      trackEvent({
        category: "document",
        action: "createDocument",
      });
    })
  }

  function isFormValid() {
    let error = {};

    if (!recipient.firstName || recipient.firstName === "") {
      error = { ...error, firstName: "Bitte gib einen gültigen Vornamen ein" };
    }
    if (!recipient.lastName || recipient.lastName === "") {
      error = { ...error, lastName: "Bitte gib einen gültigen Namen ein" };
    }
    if (!recipient.address || recipient.address === "") {
      error = { ...error, address: "Bitte gib eine gültige Straße und Hausnummer ein" };
    }
    if ((!recipient.postalCode || recipient.postalCode === "") || !recipient.postalCode.match(/\b[0-9]{5}\b/)) {
      error = { ...error, postalCode: "Bitte gib eine gültige Postleitzahl ein" };
    }
    if (!recipient.location || recipient.location === "") {
      error = { ...error, location: "Bitte gib einen gültigen Ort ein" };
    }

    setError(error);

    return Object.keys(error).length === 0;
  }

  return (
    <form onSubmit={handleSubmit}>
      <fieldset ref={fieldsetRef}>
        <p className="mb-5">Wir möchten die demokratische Mitbestimmung so zugänglich wie möglich machen. Deshalb senden wir Dir die Unterschriftenliste inklusive Umschlag zum Selbstfalten zu.</p>
        <h4 className="text-primary mb-4">Bitte sendet meine Unterschriftenliste an diese Post-Adresse: </h4>
        <div className="mb-3">
          <label className="text-primary">Vorname</label>
          <input
            className={`form-control${error.firstName ? " is-invalid" : ""}`}
            defaultValue={recipient.firstName}
            name="firstName"
            onChange={handleChange} placeholder="Vorname"
            type="text"
          />
          {error.firstName && (
            <span className="form-text invalid-feedback">{error.firstName}</span>
          )}
        </div>
        <div className="mb-3">
          <label className="text-primary">Name</label>
          <input
            className={`form-control${error.lastName ? " is-invalid" : ""}`}
            defaultValue={recipient.lastName}
            name="lastName"
            onChange={handleChange}
            placeholder="Name"
            type="text"
          />
          {error.lastName && (
            <span className="form-text invalid-feedback">{error.lastName}</span>
          )}
        </div>
        <div className="mb-3">
          <label className="text-primary">Straße und Hausnummer</label>
          <input
            className={`form-control${error.address ? " is-invalid" : ""}`}
            name="address"
            onChange={handleChange}
            placeholder="Straße und Hausnummer"
            ref={firstFieldRef}
            type="text"
          />
          {error.address && (
            <span className="form-text invalid-feedback">{error.address}</span>
          )}
        </div>
        <div className="mb-3 row">
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <label className="text-primary">Postleitzahl</label>
            <input
              className={`form-control${error.postalCode ? " is-invalid" : ""}`}
              defaultValue={recipient.postalCode}
              name="postalCode"
              onChange={handleChange}
              placeholder="12345"
              type="text"
            />
            {error.postalCode && (
              <span className="form-text invalid-feedback">{error.postalCode}</span>
            )}
          </div>
          <div className="col-12 col-md-8">
            <label className="text-primary">Ort</label>
            <input
              className={`form-control${error.location ? " is-invalid" : ""}`}
              defaultValue={recipient.location}
              name="location"
              onChange={handleChange}
              placeholder="Ort"
              type="text"
            />
            {error.location && (
              <span className="form-text invalid-feedback">{error.location}</span>
            )}
          </div>
        </div>
        <p className="label mb-3 text-black">Informationen zur Verarbeitung Deiner Daten findest Du in den <a href="https://innn.it/datenschutz#:~:text=Unterschreiben%20/%20Versand%20der%20Unterschriftenlisten" target="_blank" rel="noreferrer">Datenschutzhinweisen.</a></p>
        <button className="btn btn-primary mt-4" type="submit">
          Bestätigen
        </button>
      </fieldset>
    </form>
  )
}

DocumentPostForm.propTypes = {
  createDocument: PropTypes.func.isRequired,
};

export default DocumentPostForm;
