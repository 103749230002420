import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";

const TextField = ({
  field,
  fieldLabels,
  formErrors,
  handleChange,
  placeholder,
  user,
  type,
}) => {
  if (!field) {
    return false;
  }

  const [passwordDisplayed, setPasswordDisplayed] = useState(false);

  const togglePasswordDisplay = () => {
    setPasswordDisplayed(!passwordDisplayed);
  };
  
  return (
    <div>
      <label className="text-black" htmlFor={field}>{fieldLabels?.[field]}</label>
      <div className="form-icon mb-3">
        <input
          className={`form-control ${formErrors?.[field] ? " is-invalid" : ""}`}
          name={field}
          onChange={handleChange}
          defaultValue={user?.[field]}
          placeholder={placeholder?.[field]}
          required
          type={"password" == type ? (passwordDisplayed ? "text" : "password") : type}
          autoComplete="on"
        />

        {formErrors[field] && (
          <span
            className="form-text invalid-feedback"
            style={{ minWidth: "18rem" }}
          >
            {formErrors[field]}
          </span>
        )}

        {"password" === field && (
          <div className="btn btn-icon btn-icon-right" role="button">
            <FontAwesomeIcon
              icon={passwordDisplayed ? faEye : faEyeSlash}
              onClick={togglePasswordDisplay}
              fixedWidth
            />
          </div>
        )}
      </div>
    </div>
  );
};

TextField.propTypes = {
  field: PropTypes.string,
  fieldLabels: PropTypes.object,
  formErrors: PropTypes.object,
  name: PropTypes.string,
  user: PropTypes.object,
  handleChange: PropTypes.func,
  passwordShown: PropTypes.bool,
  type: PropTypes.string
};

export default TextField;
