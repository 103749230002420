import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight, faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";
import api from "../../../helpers/api";
import SuccessMessage from "./SuccessMessage";


const Updates = ({ selectedPetition, setSelectedPetition }) => {
  const PAGE_SIZE = 3;

  const [limit, setLimit] = useState(PAGE_SIZE);
  const [search, setSearch] = useState("");
  const [sortParams, setSortParams] = useState({});
  const [updates, setUpdates] = useState([]); 
  const offset = 0; 

  const BaseUpdateShareUrl =`${window.location.origin}/${selectedPetition?.name}/updates`;
  
  const formatDate = (rawDate) => {
    if (!rawDate) {
      return false;
    };

    const dateObject = new Date(rawDate);

    if (!dateObject instanceof Date || isNaN(dateObject)) {
      return false;
    };

    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    
    return dateObject.toLocaleDateString("de-DE", options);
  };

  const loadMore = async () => {
    setLimit(prev => prev + PAGE_SIZE);
  };

  useEffect(() => {
    (async () => {
      const result = await getUpdates(search, limit, offset);
      if (Array.isArray(result)) {
        setUpdates(result);
      };
    })()
  }, [limit]);

  const getUpdates = async (search, limit, offset) => {
    return await api.getUpdates(selectedPetition.name,
      { search: search, status: ["publish", "draft"], limit: limit, offset: offset },
      (_status, data) => {
        return data;
      }
    );
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const sortUpdates = (field) => {
    setSortParams(prev => {
      let ascending = true;

      if (prev.field === field) {
        ascending = prev.ascending ? false : true
      };
      return { field, ascending };
    });
  };

  useEffect(() => {
   if (!sortParams.field) { return };

   const sortedUpdates = [...updates];

   const order = sortParams.ascending ? 1 : -1
   sortedUpdates.sort((a, b) => b[sortParams.field] > a[sortParams.field] ? -order : order);

   setUpdates(sortedUpdates);
  }, [sortParams]);
  

  useEffect(() => {
    (async () => {
      try {
        if (selectedPetition) {
          const result = await getUpdates(search, limit, offset);
          setUpdates(result);
        };
      } catch (error) {
        console.log({error});
      }
    })();
  }, [selectedPetition, search]);


  
  return (
    <section>
      <div className="mb-md-0 mb-5">
        <div 
          className="pointer align-items-center d-flex mb-3" 
          onClick={()=> setSelectedPetition(!selectedPetition)}
        >
          <FontAwesomeIcon
            className="icon-left me-1 text-primary"
            fixedWidth
            icon={faChevronRight} 
          />
          <span className="text-primary">Zurück zur Petition {" "}
            <span className="text-black">{selectedPetition?.name}</span>
          </span>
        </div>
        <div className="d-md-none d-block mt-md-0 mt-4">
          <Link
            className="btn btn-sm btn-outline-secondary text-primary btn-block"
            to="/petition-starten"
          >
            Neues Update posten
          </Link>
        </div>
      </div>
      <div className="border rounded py-3"style={{backgroundColor: "var(--bs-gray-1)"}}>  
        <div className="align-items-md-center d-flex justify-content-between px-md-4 px-3">
          <div className="col-md-6 col-12 align-items-md-center d-md-flex">
            <h3 className="text-primary me-5">Updates</h3>
            <div className="form-icon form-icon-left form-icon-position mb-3 w-md-75 w-100">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
              <input
                className="border border-primary form-control"
                name="search"
                onChange={(e) => handleChange(e)}
                placeholder="Updates filtern"
                type="text"
              />
            </div>
          </div>
          <div className="d-md-block d-none">
            <Link
              className="btn btn-sm btn-secondary btn-block"
              to="/petition-starten"
            >
              Neues Update posten
            </Link>
          </div>
        </div>
        <div className="mt-4 mb-3">
          <div className="row px-4">
            <div 
              className="pointer col-md-3 col-6 align-items-center d-flex mb-3 order-md-1 order-1" 
              onClick={() => sortUpdates("title")}
            >
              <FontAwesomeIcon
                className="icon-left text-black me-1"
                fixedWidth
                icon={faChevronDown} 
              />
              <span className="text-black">Name</span>
            </div>
            <div className="col-md-3 d-md-block d-none order-md-3">
              <span>Link</span>
            </div>
            <div 
              className="pointer col-md-3 col-6 align-items-center d-flex mb-3 order-md-3 order-2" 
              onClick={() => sortUpdates("createdAt")}
            >
              <FontAwesomeIcon
                className="icon-left text-primary me-1"
                fixedWidth
                icon={faChevronDown} 
              />
              <span className="text-primary">Datum</span>
            </div>
            <div className="col-md-3 d-md-block d-none order-md-4">
              <span>Aktion</span>
            </div>
          </div>
          <div>
            <div className="bg-white container">
              {updates &&
                Array.isArray(updates) &&
                updates.map((update, index) => (
                  <div className="align-items-center border-bottom border-secondary row px-4 py-2" key={index}>
                    <span className="col-6 col-md-3 my-md-0 my-3 order-1">{update.title}</span>
                    <CopyToClipboard
                      text={`${BaseUpdateShareUrl}/${update.name}`}
                    >
                      <span
                        className="pointer col-6 col-md-3 order-md-2 order-4 text-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#linkConfirmationText"
                      >
                        Link kopieren
                      </span>
                    </CopyToClipboard>
                    
                    <span className="col-6 col-md-3 my-md-0 my-3 order-1 order-md-3 order-2">{formatDate(update.createdAt)}</span>
                    <Link
                      className="btn btn-sm btn-outline-secondary btn-block col-6 col-md-3 order-md-4 order-3 text-primary"
                      to="/petition-starten"
                    >
                      Bearbeiten
                    </Link>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <footer className="pointer ps-4" onClick={loadMore}>
          <FontAwesomeIcon
            className="icon text-primary"
            icon={faChevronDown}
          />
          <span className="text-primary ms-3">Mehr anzeigen</span>
        </footer>
      </div> 
      <SuccessMessage type="update" />
    </section>
  );
};


Updates.propTypes = {
  selectedPetition: PropTypes.object,
};



export default Updates;