import api from "../../helpers/api";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getMe } from "../../redux/users";
import { Link, useLocation, useParams } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import DonationBanner from "../../components/DonationBanner";
import DonationLink from "../../components/DonationLink";
import background1 from "../../styles/images/background/background1.svg";
import donationImage from "../../styles/images/icon-box/donation-alternate.svg";
import donationPetitionImage from "../../styles/images/donate.svg";
import downloadImage from "../../styles/images/banners/confirm-download.svg";
import emailImage from '../../styles/images/email.svg';
import fileDownload from "js-file-download";
import iconNewsletter from "../../styles/images/icon-newsletter.svg";
import NotFound from "../NotFound";
import signatureImage from '../../styles/images/banners/signature.svg';
import stamps from '../../styles/images/banners/download-stamp.svg';
import { ThemeContext } from "../../ThemeContext";

const CONTENT = {
  document: {
    title: "Deine E-Mail Adresse wurde bestätigt. Deine Unterschriftenliste wird bald bei Dir eintreffen!",
  },
  signature: {
    title: "Wir melden uns sobald es mit der Sammlung los geht!",
  },
  petition: {
    expiredToken: "Hoppala. Dieser Link ist abgelaufen.",
    signature: "Deine Unterschrift wurde bestätigt.",
    signatureAlreadyVerified: "Hoppala. Deine Unterschrift ist bereits bestätigt.",
    signatureAndSubscribe: "Deine Unterschrift & E-Mail-Adresse wurden bestätigt.",
    signatureNotSubscribe: "Deine E-Mail-Adresse wurde bestätigt.",
  },
  image: {
    expiredToken: donationPetitionImage,
    signatureAlreadyVerified: donationPetitionImage,
    signatureAndSubscribe: signatureImage,
    signatureNotSubscribe: emailImage,
  }
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const DocumentVerification = () => {
  const params = useParams();
  const query = useQuery();
  let btnRef = useRef();
  const [confirmationContext, setConfirmationContext] = useState("");
  const dispatch = useDispatch();
  const [document, setDocument] = useState({});
  const [initiative, setInitiative] = useState({});
  const [isSubscriptionSuccessful, setIsSubscriptionSuccessful] = useState(false);
  const { setTheme } = useContext(ThemeContext);
  const [signableId, setSignableId] = useState({});
  const [signableModel, setSignableModel] = useState({});
  const [status, setStatus] = useState("");
  const { trackEvent } = useMatomo();

  const getInitiative = async (id) => {
    try {
      await api.getInitiative(id, (_status, data) => {
        setInitiative(data);
      });  
    } catch (error) {
      setStatus(404);
    }
  }

  const handleDownload = async () => {
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "true");
    }
    try {
      await api.downloadFile(
        document.url,
        (_status, data) => {
          if (btnRef.current) {
            btnRef.current.removeAttribute("disabled");
          }
          fileDownload(data, "innn_it.pdf");
        }
      );

      trackEvent({
        category: "initiative",
        action: "downloadDocument",
        customDimensions: [
          {
            id: 1,
            value: document.initiativeId,
          },
        ],
      });
    } catch (error) {
      if (btnRef.current) {
        btnRef.current.removeAttribute("disabled");
      }
      if (error.response.status === 404) {
        return <NotFound />;
      }
    }
  };

  const handleSubscribeNewsletter = async () => {
    try {
      const payload = {
        ...document.signer,
        source: "postVerification",
        signableId
      };
      await api.subscribeNewsletter(payload, () => {
        setIsSubscriptionSuccessful(true);
      });  
    } catch(error) {
      console.log(error)
    }
  }

  useEffect(async () => {
    try {
      let { model, id, token } = params;
      let verifySubscription = JSON.parse(query.get("subscribe"));

      if ("boolean" !== typeof verifySubscription) {
        verifySubscription = false;
      }

      if (!model) {
        model = "document";
      }

      let verify = "verifyDocument";
      if ("document" == model) {
        verify = "verifyDocument";
      } else if ("signature" == model) {
        verify = "verifySignature";
      }

      await api[verify](
        id,
        { verifySubscription, token },
        async (_status, document) => {
          const signableId = document.signableId ? document.signableId : document.initiativeId;
          const signableModel = ("Initiative" == document.signableModel || document.initiativeId) ? 
            "Initiative" : "Petition";
          const theme = signableModel.toLowerCase();

          setDocument(document);
          setSignableId(signableId);
          setSignableModel(signableModel);
          setTheme(theme);

          if ("Petition" == signableModel) {
            if (!document.verifiedNow && document.verificationError === "expiredToken") {
              getInitiative(signableId);
              setConfirmationContext("expiredToken");
            } else if (!document.verifiedNow &&  document.verificationError === "alreadyVerified") {
              setConfirmationContext("signatureAlreadyVerified");
            } else if (document.subscribe && verifySubscription) {
              setConfirmationContext("signatureAndSubscribe");
            } else if (document.subscribe && !verifySubscription) {
              setConfirmationContext("signatureNotSubscribe");
            } else if (!document.subscribe) {
              setConfirmationContext("signature");
            }
          }

          const email = document?.signer?.email;
          if (email) {
            await api.login(
              { email, token },
              (_status, _data) => {
                dispatch(getMe());
              }
            );
          }
        }
      );
    } catch (error) {
      setStatus(404);
    }
    
  }, []);

  useEffect(() => {
    if (!confirmationContext || !document.id) {
      return;
    }

    let { model } = params;

    if (!model) {
      model = "document";
    }

    const subscribe = "signatureAndSubscribe" == confirmationContext;

    trackEvent({
      category: signableModel.toLowerCase(),
      action: `verify${model.charAt(0).toUpperCase() + model.slice(1)}`,
      customDimensions: [
        {
          id: 1,
          value: signableId,
        },
      ],
    });

    if (subscribe) {
      trackEvent({
        category: signableModel.toLowerCase(),
        action: "verifySubscription",
        customDimensions: [
          {
            id: 1,
            value: signableId,
          },
        ],
      });
    }
  }, [confirmationContext, document])

  if (404 == status) {
    return <NotFound />;
  }

  return (
    <main>
      {/* Download document */}
      {
        "Initiative" == signableModel &&
        "document" == params.model &&
        document.id &&
        "download" == document.exportType &&
        (
          <section className="hero hero-headline bg-secondary" style={{
            backgroundImage: `url("${background1}")`,
          }}>
            <div className="hero-container">
              <div className="hero-row py-0">
                <div className="hero-body mx-0 w-100">
                  <img
                    className="mb-5 img-fluid"
                    src={downloadImage}
                  />
                  <h2 className="hero-title text-center">Deine E-Mail Adresse wurde bestätigt!</h2>
                  <div className="d-grid gap-2">
                    <button
                      className="btn btn-primary"
                      onClick={handleDownload}
                      ref={btnRef}
                    >
                      <FontAwesomeIcon className="icon icon-left text-white" icon={faDownload} />
                      <span>Unterschriftenliste herunterladen</span>
                    </button>
                    <Link
                      role="button"
                      className="bg-white btn btn-outline"
                      to="/"
                    >
                      Zur Startseite
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      }

      {/* Donation banner */}
      {
        "Initiative" == signableModel &&
        "document" == params.model &&
        document.id &&
        "download" == document.exportType &&
        (
          <section className="container mt-5">
            <DonationBanner
              linkClass="btn btn-gradient btn-block"
              linkLabel="Jetzt spenden"
              source="initiative_verification"
              signableId={signableId}
            />
          </section>
        )
      }

      {/* Confirm document sending or verify signature */}
      {
        "Initiative" == signableModel &&
        document.id &&
        ("signature" == params.model || ("document" == params.model && "download" != document.exportType)) &&
        <section className="hero hero-headline bg-secondary" style={{
          backgroundImage: `url("${background1}")`,
        }}>
          <div className="hero-container">
            <div className="hero-row py-0">
              <div className="hero-body mx-0 w-100">
                <h2 className="hero-title text-center">{CONTENT?.[params.model]?.title}</h2>
                <img
                  className="mb-5 img-fluid"
                  src={stamps}
                />
                <h2 className="text-center text-primary mb-3">Briefmarken für direkte Demokratie</h2>
                <p className="lead mb-4 text-center">
                  Die Teilnahme bei innn.it ist sowohl für die Initiativen als auch
                  für die Nutzer:innen kostenlos. Auch die Portokosten werden komplett
                  von Change.org e.V. übernommen, dem Trägerverein der Plattform. Hier
                  kannst du den Change.org e.V. mit einer Spende unterstützen:
                </p>
                <div className="d-grid gap-2">
                  <DonationLink
                    className="btn btn-primary"
                    signableId={signableId}
                    source="initiative_verification"
                  >
                    Zur Spendenseite
                  </DonationLink>
                </div>
              </div>
            </div>
          </div>
        </section>
      }

      {/* Petition signatures */}
      {
        "Petition" == signableModel &&
        document.id &&
        "signature" == params.model &&
        <>
          <section className="bg-swirl pb-6">
            <div className="bg-transparent bg-gradient-horizontal pt-5">
              <div className="container text-center">
                {
                  confirmationContext == "expiredToken" && initiative.title && initiative.name ?
                    <div>
                      <h1 className="h2 mb-4 text-primary">
                        {CONTENT.petition?.[confirmationContext]}
                      </h1>
                      <h4 className="mb-4">
                        Wenn du die Petition {" "}
                        <Link 
                          className="text-primary" 
                          to={`/${initiative.name}`}>
                          {initiative.title}
                        </Link> {" "}
                        noch einmal unterschreiben möchtest, kannst Du das hier tun:
                      </h4>
                      <Link 
                        role="button"
                        className="btn btn-sm btn-primary btn-block mb-6" 
                        to={`/${initiative.name}`}
                      >
                        Zur Petition
                      </Link>
                    </div>
                :
                  confirmationContext == "signatureAlreadyVerified" ?
                    <h1 className="h2 mb-6 text-primary">
                      {CONTENT.petition?.[confirmationContext]}
                      <br />Vielen Dank!
                    </h1>
                    :
                    <h1 className="h2 mb-6 text-primary">
                      Vielen Dank!
                      <br />{CONTENT.petition?.[confirmationContext]}
                    </h1>
                }
                <img src={CONTENT.image[confirmationContext]} className="img-fluid" />
              </div>
            </div> 
          </section>
          <section className="container">
            <DonationPetitionBanner 
              linkClass="btn btn-sm btn-primary btn-block"
              linkLabel="Jetzt spenden"
              source="postVerification"
              signableId={signableId} 
            />
          </section>
        </>
      }
    </main>
  );
};

const DonationPetitionBanner = (props) => {
  const bannerTitle = "Wir sind kostenlos und das soll auch so bleiben";
  const bannerBody =
    "innn.it ist zu 100% spendenfinanziert und kostenfrei für alle. Damit das so bleibt, brauchen wir Deine Unterstützung.";

  return (
    <div className="banner-background bg-gradient-horizontal-petition">
      <div className="align-items-center banner-row flex-row-reverse mx-auto p-md-3 w-75">
        <div className="banner-img text-center pt-5 pt-md-0 d-flex align-items-center">
          <img src={donationImage} alt="donation-image" />
        </div>
        <div className="banner-body">
          <div className="banner-text p-md-0">
            <h2 className="text-initiative-primary mb-3">{bannerTitle}</h2>
            <h5>{bannerBody}</h5>
            <div className="banner-footer">
              <DonationLink
                className={props.linkClass}
                rel="noreferrer"
                signableId={props.signableId}
                source={props.source}
                target="_blank"
              >
                {props.linkLabel}
              </DonationLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SignatureBanner = ({ document, handleSubscribeNewsletter, isSubscriptionSuccessful }) => {
  return (
      <div className="container align-items-center banner-row flex-row-reverse mx-auto p-3 px-md-5 ">
        <div className="banner-img text-center pt-5 pt-md-0 d-flex align-items-center order-2 order-md-1">
          <img src={iconNewsletter} alt="icon-newsletter" className="img-fluid" />
        </div>
        <div className="banner-body order-1 order-md-2">
          <div className="banner-text p-md-0">
            <h2 className="banner-title">Bleib informiert!</h2>
            <h5>
              Melde dich mit Deiner E-Mail-Adresse {document.signer.email} für den Newsletter an 
              und wir halten dich auf dem Laufenden. 
            </h5>
            <p className="small mt-3 text-black">
              Ich möchte über den Erfolg dieser Petition sowie über andere wichtige 
              Kampagnen per E-Mail von innn.it informiert werden. Diese Einwilligung 
              kann ich jederzeit mit Wirkung für die Zukunft widerrufen.
            </p>
            <div className="banner-footer mt-0">
              {
                isSubscriptionSuccessful 
                ? 
                <SuccessMessage />
                :
                <button
                  className="btn btn-primary btn-block px-6"
                  onClick={handleSubscribeNewsletter}
                >
                  Jetzt anmelden
                </button>
              }
              <p className="small mt-4 text-black">
                Du kannst Dich jederzeit wieder vom Newsletter abmelden. Weitere 
                Informationen findest Du in unserer {" "}
                <Link to="/datenschutz">Datenschutzerklärung</Link>.
              </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const SuccessMessage = (() => {
  return (
    <span className="mb-3 d-flex">
      <FontAwesomeIcon className="icon icon-left text-primary" icon={faEnvelope} size="4x" />
      <span className="ps-4">
        <h4>Vielen Dank! Bitte bestätige Deine E-Mail-Adresse.</h4>
        <p className="small text-black">Wir haben Dir eine E-Mail mit einem Bestätigungslink zugesendet. 
          Klicke einfach auf den Link in Deinem Postfach um zukünftig Neuigkeiten zu erhalten. 
        </p>
      </span>
    </span>
  );
});

export default DocumentVerification;
