import React, { useEffect, useState } from "react";

export const useRequiredFields = (requiredFields) => {
  const [errors, setErrors] = useState({})

  const isFieldRequired = (field) => {
    if (!requiredFields || requiredFields.length === 0) {
      return false;
    };
  
    if (requiredFields.includes(field)) {
      return true;
    };
    return false;
  };
  
  const RequiredFieldSign = (field) => {
    if (isFieldRequired(field)) {
      return "*";
    }
  };

  const useValidationForm = (obj) => {
    requiredFields.forEach((field) => {
      if (!obj[field] || obj[field] === "") {
        setErrors(prev => ({...prev, [field]: "Bitte fülle dieses Feld aus"}))
      }
    });
  }

  return {
    requiredFields,
    RequiredFieldSign
  };
};
