import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMapMarkerAlt, faTimes } from "@fortawesome/pro-solid-svg-icons";
import api from "../../helpers/api"

const ActorsList = () => {
  const [actors, setActors] = useState([]);
  const [open, setOpen] = useState(true);

  const location = useLocation();
  const actor = location.state?.initiative;

  useEffect(() => {
    (async () => {
      try {
        await api.getActors(
          {limit: 0},
          (_status, data) => {
            setActors(data);
          }
        );
      } catch (error) {
        if (error.response) {
          // Handle error
        }
      }
    })();
  }, []);

  return (
    <main className="dashboard">
      {actor?.id && open && (
        <section className="bg-secondary mb-5 mt-n5">
          <div className="container d-flex justify-content-md-between align-items-md-center py-4">
            <FontAwesomeIcon className="icon icon-left text-success" icon={faCheck} size="lg" />
            <div className="d-flex justify-content-md-between flex-wrap flex-md-nowrap flex-grow-1">
              <span className="h3 mb-md-0 mb-5 text-primary">
                Dein Entwurf wurde gesichert
              </span>
              <Link
                to={`/dashboard/actors/${actor.id}`}
                className="bg-white btn btn-sm btn-outline-primary me-md-5"
              >
                Zurück
              </Link>
            </div>
            <span onClick={(e) => setOpen(false)}>
              <FontAwesomeIcon className="text-primary" icon={faTimes} size="lg" />
            </span>
          </div>
        </section>
      )}

      <section className="container-narrow">
        <div className="d-flex justify-content-end">
          <Link to={`/dashboard/actors/new`} className="btn btn-primary">
            Neue Agierende
          </Link>
        </div>
      </section>

      <section className="container-narrow mt-5">
        {actors && Array.isArray(actors) && (
          <table className="table table-hover align-middle">
            <tbody>
              {actors.map((actor, index) => (
                <tr key={index}>
                  <td>
                    <Link to={`/dashboard/actors/${actor.id}`}>
                      {actor.name}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/dashboard/actors/${actor.id}`} className="btn btn-outline-primary w-75">
                      Bearbeiten
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </section>
    </main>
  );
};

export default ActorsList;
