import React, { useContext, useEffect } from "react";
import { ThemeContext } from "../ThemeContext";

const Datenschutz = () => {
  const { setTheme } = useContext(ThemeContext);

  useEffect(() => {
    setTheme("initiative");
  }, []);

  return (
    <main>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            <h2 className="text-primary py-4">Informationen zum Datenschutz bei innn.it</h2>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <p>Nachfolgend möchten wir Dich über den Umgang mit Deinen Daten gemäß Artikel 13 der Datenschutzgrundverordnung (DSGVO) informieren.</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Verantwortlicher</h3>
            <p>Verantwortlicher für die Verarbeitung von personenbezogenen Daten, die bei dem Besuch dieser Webseite erfasst werden, ist innn.it e.V., Pressehaus Podium, Karl-Liebknecht-Straße 29A, 10178 Berlin, E-Mail: datenschutz[ät]innn.it</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Datenschutzbeauftragter</h3>
            <p>Unser Datenschutzbeauftragter steht Dir gerne für Auskünfte oder Anregungen zum Thema Datenschutz zur Verfügung:</p>
            <p>datenschutz nord GmbH
              <br />Zweigstelle Berlin-Charlottenburg
              <br />Kurfürstendamm 212
              <br />10719 Berlin
              <br />E-Mail: office[ät]datenschutz-nord.de
            </p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Datenverarbeitungsprozesse</h3>
            <h4>Nutzungsdaten</h4>
            <p>Wenn Du unsere Webseite besuchst, speichern wir auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO temporär sogenannte Nutzungsdaten. Die Nutzungsdaten verwenden wir zu statistischen Zwecken, um die Qualität unserer Webseite zu verbessern. Wir verwenden diese Informationen außerdem, um den Aufruf unserer Webseite zu ermöglichen, zur Kontrolle und Administration unserer Systeme sowie zur Verbesserung der Gestaltung der Webseite. Diese von uns verfolgten Zwecke stellen zugleich das berechtigte Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Der Datensatz besteht aus:</p>
            <ul>
              <li>dem Namen und der Adresse der angeforderten Inhalte,</li>
              <li>dem Datum und der Uhrzeit der Abfrage,</li>
              <li>der übertragenen Datenmenge,</li>
              <li>dem Zugriffsstatus (Inhalt übertragen, Inhalt nicht gefunden),</li>
              <li>der Beschreibung des verwendeten Webbrowsers und des Betriebssystems,</li>
              <li>dem Referral-Link, der angibt, von welcher Seite Du auf unsere gelangt bist,</li>
              <li>der IP-Adresse des anfragenden Rechners, die so verkürzt wird, dass ein Personenbezug nicht mehr herstellbar ist.</li>
            </ul>

            <h4>Einsatz von Cookies</h4>
            <p>Auf unserer Webseite setzen wir Cookies ein. Cookies sind kleine Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem des Nutzers gespeichert und ausgelesen werden können. Sie erlauben es, Informationen über einen bestimmten Zeitraum vorzuhalten und den Rechner der Besucher:in zu identifizieren. Dies erleichtert die Navigation und macht unsere Webseite benutzerfreundlicher. Cookies helfen uns auch, besonders beliebte Bereiche unserer Webseite zu identifizieren. Man unterscheidet zwischen Session-Cookies, die wieder gelöscht werden, sobald Du Deinen Browser schließt und permanenten Cookies, die über die einzelne Sitzung hinaus gespeichert werden.</p>
            <p>Einige Cookies sind zur Nutzung unserer Webseite erforderlich. Diese Cookies nutzen wir nicht für Analyse-, Tracking- oder Werbezwecke. Teilweise enthalten diese Cookies lediglich Informationen zu bestimmten Einstellungen und sind nicht personenbezogen. Sie können auch notwendig sein, um die Benutzerführung, Sicherheit und Umsetzung der Seite zu ermöglichen. Wir nutzen diese Cookies auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO, basierend auf unserem berechtigten Interesse, grundlegende Funktionen der Webseite anbieten zu können, um die Aufrechterhaltung der Funktionalität zu gewährleisten.</p>
            <p>Du kannst Deinen Browser so einstellen, dass er Dich über die Platzierung von Cookies informiert. So wird der Gebrauch von Cookies für Dich transparent. Du kannst Cookies zudem jederzeit über die entsprechende Browsereinstellung löschen und das Setzen neuer Cookies verhindern. Bitte beachte, dass unsere Webseiten dann ggf. nicht angezeigt werden können und einige Funktionen technisch nicht mehr zur Verfügung stehen. Darüber hinaus setzen wir auf unserer Webseite auch technisch nicht erforderliche Cookies ein. Zum Einsatz dieser Cookies weisen wir im Folgenden gesondert hin.</p>
            <p><button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#cookieConsentModal">Cookie-Einstellungen</button></p>

            <h4>Bewerbung</h4>
            <p>Du kannst Dich per E-Mail auf offene Stellen bei innn.it e.V. bewerben. Deine Bewerbungsdaten werden an die für die ausgeschriebene Stelle zuständigen Personen weitergeleitet. Alle Beteiligten behandeln Deine Bewerbungsunterlagen mit der gebotenen Sorgfalt und vertraulich. Wir verarbeiten die Daten, die Du uns im Rahmen deiner Online-Bewerbung preisgibst, zur Bewerberauswahl. Hierbei ist es auch möglich, dass wir Dich zu Video-Interviews einladen. Weitere Informationen (u.a. zum eingesetzten Videokonferenzsystem) lassen wir Dir im weiteren Bewerbungsverlauf zukommen. Rechtsgrundlage für diese Datenverarbeitung ist § 26 Abs. 1 S. 1 BDSG (Entscheidung über die Begründung eines Beschäftigungsverhältnisses).</p>
            <p>Nach Abschluss des Auswahlverfahrens werden die durch Dich für das konkrete Auswahlverfahren gemachten Angaben und die übersandten Unterlagen nach sechs Monaten gelöscht, sofern wir keinen Arbeitsvertrag mit Dir geschlossen haben. Für den Fall, dass wir Deine Bewerbung auch bei anderen oder zukünftigen Stellenausschreibungen berücksichtigen dürfen, bitten wir um einen entsprechenden Hinweis in Deiner Bewerbung. Mit Deiner ausdrücklichen Zustimmung verarbeiten wir Deine Daten dann auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO (Einwilligung). Deine Einwilligung kannst Du jederzeit mit Wirkung für die Zukunft widerrufen. Die bis zum Widerruf erfolgte Datenverarbeitung bleibt rechtmäßig.</p>
            <p>Für Bewerbungen im Bewerberverfahren gelten ergänzend unsere <a href="https://verein.innn.it/datenschutz-bewerbungsverfahren" target="_blank" rel="noreferrer">Datenschutzhinweise für Bewerber:innen.</a></p>

            <h4>Spendenformular</h4>
            <p>Wenn Du innn.it mit einer Spende unterstützen möchtest, kannst Du auf unserer Webseite ein Spendenformular ausfüllen. Hierfür benötigen wir von Dir die folgenden Angaben:</p>
            <ul>
              <li>Anrede</li>
              <li>Vorname, Nachname</li>
              <li>E-Mail-Adresse</li>
              <li>Zahlungsweise</li>
              <li>Spendenbetrag und Rhythmus der Spende</li>
            </ul>
            <p>Darüber hinaus kannst Du uns auf freiwilliger Basis folgende Angaben mitteilen:</p>
            <ul>
              <li>Titel</li>
              <li>Adresse (erforderlich, wenn Spendenbescheinigung gewünscht)</li>
              <li>Telefonnummer</li>
            </ul>
            <p>Diese Daten verarbeiten wir zur Verwaltung der Spende. Dazu gehört u.a. der Versand der Spendenbescheinigung. Gegebenenfalls rufen wir Dich bei Fragen zu Deiner Spende an. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO. Wenn Du uns Deine Telefonnummer mitteilst und uns mit einer Spende unterstützt hast, nutzen wir Deine Telefonnummer gelegentlich auch, um uns persönlich bei Dir zu bedanken. Gegebenenfalls fragen wir Dich, ob Du Dir auch zukünftig vorstellen kannst, uns als Förder:in zu unterstützen. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, basierend auf unserem berechtigten Interesse, interessierte Personen als langfristige Unterstützer:innen zu gewinnen. Du kannst der Verwendung Deiner Telefonnummer zu diesem Zweck jederzeit widersprechen, z.B. per E-Mail.</p>

            <h4>Unterschreiben von Petitionen (Kampagnen)</h4>
            <p>Auf unserer Seite kannst Du verschiedene Petitionen (Kampagnen) unterstützen, Hierfür benötigst Du gemäß unserer Nutzungsbedingungen ein Nutzer:innen-Konto, das wir für Dich erstellen. Nach Erstellung des Kontos, kannst Du Dich jederzeit mit Deinem Passwort anmelden und Deine Angaben im Nutzer:innen-Konto eigenständig verwalten. Wir benötigen die E-Mail-Adresse, damit wir sichergehen können, dass sich hinter der Unterschrift ein Mensch verbirgt. Zur Verifizierung erhältst Du von uns eine E-Mail mit einem Bestätigungslink, der von Dir angeklickt werden muss, damit Deine Unterschrift gezählt werden kann. Die erneute Verifizierung ist nicht notwendig, wenn Du bereits ein Nutzer:innen-Konto auf innn.it hast und über Deinen Browser oder einen Token in einer E-Mail in Deinem Nutzer:innen-Konto eingeloggt bist. Außerdem benötigen wir für die Teilnahme an Petitionen Deinen Vor- und Nachnamen sowie Deine Postleitzahl und Stadt, wenn Du aus Deutschland kommst. Durch Angabe der Postleitzahl und Stadt erhalten die Unterschriften mehr Gewicht. Außerdem nutzen wir diese Informationen, um den Nutzer:innen passende Petitionen aus der Region vorschlagen zu können. Wir sehen die Unterzeichnung von Petitionen als Akt öffentlicher Meinungsäußerung. Wer besonders sensibel ist, kann anstatt des richtigen Namens ein Pseudonym als Anzeigenamen nutzen. Dein Vor- und Nachname (oder Pseudonym) sowie Postleitzahl, Stadt und Datum der Unterschrift werden mit der Initiator:in der Petition geteilt und können von dieser Person an den:die Entscheider:in – z.B. im Rahmen einer Petitionsübergabe – weitergegeben werden. Nur wenn die Liste einsehbar ist, kann die Legitimität der Unterschriften nachgewiesen und ein:e Entscheider:in beeinflusst werden.</p>
            <p>Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b (Bearbeitung und Durchführung von Maßnahmen zur Petitionsunterstützung, Nachweis der Unterstützung/Teilnahme) bzw. Art. 6 Abs. 1 lit. f DSGVO, basierend auf dem berechtigten Interesse, Dir die Unterstützung von Petitionen zu ermöglichen und die entsprechenden, oben dargestellten Verfahrensschritte durchzuführen.</p>
            
            <h4>Starten von Petitionen (Kampagnen) </h4>
            <p>Auf unserer Webseite kannst Du eine Petition starten, die nach Veröffentlichung auf der Seite auffindbar ist und von anderen Nutzer:innen unterschrieben werden kann. Hierfür benötigst Du gemäß unserer Nutzungsbedingungen ein Nutzer:innen-Konto, das wir für Dich erstellen. Nach Erstellung des Kontos, kannst Du Dich jederzeit mit Deinem Passwort anmelden und Deine Angaben im Nutzer:innen-Konto eigenständig verwalten.</p>
            <p>Wir benötigen die E-Mail-Adresse, damit wir sichergehen können, dass sich hinter der Unterschrift ein Mensch verbirgt. Falls Du noch kein Nutzer:innen-Konto auf innn.it hast, erhältst Du von uns zur Verifizierung eine E-Mail mit einem Bestätigungslink, der von Dir angeklickt werden muss, damit Dein Konto erstellt werden kann. Ohne das Erstellen des Kontos kann die ordnungsgemäße Durchführung der Petitionserstellung nicht gewährleistet werden. Außerdem benötigen wir für die ordnungsgemäße Erstellung von Petitionen Deinen Vor- und Nachnamen, damit die Petition Dir als Petitionsstarter:in zugeordnet werden kann, sowie deine Postleitzahl und Stadt, wenn Du aus Deutschland kommst. Wir sehen die Veröffentlichung einer Petition als Akt öffentlicher Meinungsäußerung. Wer besonders sensibel ist, kann anstatt des richtigen Namens ein Pseudonym als Anzeigenamen nutzen.</p>
            <p>Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b (Bearbeitung und Durchführung von Maßnahmen zur Petitionserstellung/Nutzung unseres Services) bzw. Art. 6 Abs. 1 lit. f DSGVO, basierend auf dem berechtigten Interesse, Dir die Erstellung von Petitionen zu ermöglichen und die entsprechenden, oben dargestellten Verfahrensschritte durchzuführen.</p>
            
            <h4>Kontaktaufnahme “Initiative starten“</h4>
            <p>Über das auf unserer Webseite bereitgestellte Formular kannst Du uns Deine Idee für eine Initiative zuschicken. Dabei verarbeiten wir folgende Daten als Pflichtangaben: Name, E-Mail-Adresse und Nachricht. Diese Angaben nutzen wir, um Dich persönlich anzusprechen, Deine Anfrage zu bearbeiten, Dich hierzu bei Rückfragen zu kontaktieren und Dir zu antworten. Freiwillig kannst Du uns Deine Telefonnummer und Deine Webseite mitteilen, damit wir Dich ggf. auch telefonisch kontaktieren können und uns ein Bild von der geplanten Initiative machen können. Rechtsgrundlage für die Verarbeitung der Daten ist Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse daran, im Kontakt mit den Nutzern der Webseite zu sein und an uns gerichtete Anliegen/Vorschläge zu beantworten.</p>

            <h4>Unterschreiben / Versand der Unterschriftenlisten</h4>
            <p>Auf unserer Seite kannst Du verschiedene Projekte unterstützen, indem Du für diese mit Deiner E-Mail-Adresse „unterschreibst“. Wir benötigen die E-Mail-Adresse, damit wir sichergehen können, dass sich hinter der Anfrage ein Mensch verbirgt. Zur Verifizierung erhältst Du von uns eine E-Mail mit einem Bestätigungslink. Ohne diese Angabe kann die ordnungsgemäße Durchführung nicht gewährleistet werden.</p>
            <p>Um auch andere Personen zur Unterschrift zu ermutigen, kannst Du Deinen persönlichen Unterschriftenbogen ausdrucken und Personen in Deinem persönlichen Umfeld ebenfalls unterschreiben lassen. Anschließend kannst Du uns die ausgefüllte Liste zur Weiterleitung zukommen lassen. Wir verwenden Deine Daten und ggf. die Daten der Mitunterzeichner:innen nicht zu anderen Zwecken. Sofern Du keinen eigenen Drucker hast, kannst Du Dir die Listen von uns auch zusenden lassen, z.B. indem Du auf unserer Webseite den von uns beauftragten Versand-Dienstleister nutzt, der die Liste für Dich ausdruckt und Dir zusendet. Hierzu setzen wir onlinebrief24.de, einen Dienst der letterei.de Postdienste GmbH, ein. Hinweise zur Datenverarbeitung findest Du auch unter <a href="https://www.onlinebrief24.de/datenschutz/datenschutzvereinbarung/" target="_blank" rel="noreferrer">https://www.onlinebrief24.de/datenschutz/datenschutzvereinbarung</a>. Wenn Du Dich für das postalische Zusenden der Liste entscheidest, benötigen wir an dieser Stelle auch Deine postalische Adresse, damit Dir die Unterschriftenliste zugesandt werden kann. Bei einigen Initiativen ist die Angabe der postalischen Adresse nach Angabe Deiner E-Mail-Adresse erforderlich, damit wir Dir die Unterschriftenliste zusenden können. Hintergrund dessen ist, dass bei einigen Initiativen ein „Selbstausdrucken“ leider nicht möglich ist.</p>
            <p>Rechtsgrundlage für die Datenverarbeitungen ist Art. 6 Abs. 1 lit. b DSGVO bzw. Art. 6 Abs. 1 lit. f DSGVO basierend auf unserem berechtigten Interesse, Dir die Unterschriftenlisten postalisch zuzusenden.</p>

            <h4>Newsletter</h4>
            <p>Auf innn.it hast Du u.a. bei der Teilnahme an einer Petition die Möglichkeit, Dich für unseren kostenlosen Newsletter anzumelden. Der Newsletter informiert Dich über die Petition oder Initiative sowie über weitere Kampagnen und Aktionen, die Deine Unterstützung benötigen könnten. Für das Abonnement des Newsletters benötigen wir Deine E-Mail-Adresse. Diese verwenden wir, um Dir den Newsletter zuzuschicken. Mit der Anmeldung für einen Newsletter erteilst Du eine Einwilligung in die Verarbeitung der personenbezogenen Daten zum Versand des jeweiligen Newsletters sowie zur Erfolgsmessung und der statistischen Auswertung des Klick- und Öffnungsverhaltens. Wir werten die Nutzung des Newsletters aus, um Dir Inhalte zusenden zu können, die Dich interessieren.</p>
            <p>Rechtsgrundlage der Datenverarbeitung ist Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), die Du uns mit einem Klick auf den entsprechenden Link in der Bestätigungs-E-Mail erteilst. Die Einwilligung kannst Du jederzeit mit Wirkung für die Zukunft widerrufen. Nutze dazu den Link zur Abbestellung am Ende jeder E-Mail. Alternativ kannst Du eine E-Mail mit dem Betreff „Newsletter-Abmeldung“ an hallo[ät]innn.it schicken. Die bis zum Widerruf erfolgte Datenverarbeitung bleibt rechtmäßig.</p>

            <h4>Webseitenanalyse (Matomo)</h4>
            <p>Zur bedarfsgerechten Gestaltung unserer Webseiten verwenden wir das Webanalysetool Matomo, eine Open-Source-Software zur statistischen Auswertung der Besucherzugriffe.</p>
            <p>Matomo verwendet mit Deiner Einwilligung Cookies, die auf Deinem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite durch Dich ermöglichen. Die durch den Cookie erzeugten Informationen über Deine Benutzung dieses Internetangebotes werden auf unserem eigenen Server in Deutschland gespeichert. Die IP-Adresse wird sofort nach der Verarbeitung und vor deren Speicherung anonymisiert. </p>
            <p>Die Datenverarbeitung erfolgt auf Grundlage Deiner Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO, sofern Du Deine Einwilligung über unser Banner abgegeben hast. Du kannst Deine Einwilligung jederzeit widerrufen. Bitte folge hierzu diesem Link und treffe die entsprechenden Einstellungen über unser Banner.</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Eingebettete Videos</h3>
            <p>Auf unseren Webseiten betten wir Videos ein, die nicht auf unseren Servern gespeichert sind. Damit der Aufruf unserer Webseiten mit eingebetteten Videos nicht automatisch dazu führt, dass Inhalte des Drittanbieters nachgeladen werden, zeigen wir in einem ersten Schritt nur lokal abgespeicherte Vorschaubilder der Videos an. Hierdurch erhält der Drittanbieter keine Informationen. Erst nach einem Klick auf das Vorschaubild, werden Inhalte des Drittanbieters nachgeladen. Hierdurch erhält der Drittanbieter die Information, dass Du unsere Seite aufgerufen hast sowie die in diesem Rahmen technisch erforderlichen Nutzungsdaten. Außerdem ist der Drittanbieter dann in der Lage Trackingtechnologien zu implementieren. Wir haben auf die weitere Datenverarbeitung durch den Drittanbieter keinen Einfluss. Mit dem Klick auf das Vorschaubild gibst Du uns die Einwilligung, Inhalte des Drittanbieters nachzuladen. </p>
            <p>Die Einbettung erfolgt auf Grundlage Deiner Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO, sofern Du Deine Einwilligung über einen Klick auf das Vorschaubild abgegeben hast. Bitte beachte, dass die Einbettung vieler Videos dazu führt, dass Deine Daten außerhalb der EU bzw. des EWR verarbeitet werden. In einigen Ländern besteht dabei das Risiko, dass Behörden auf die Daten zu Sicherheits- und Überwachungszwecken zugreifen, ohne dass Du hierüber informiert wirst oder Rechtsmittel einlegen kannst. Sofern wir Anbieter in unsicheren Drittländern einsetzen, erfolgt die Übermittlung in ein Drittland auf Grundlage von Art. 46 Abs. 2 lit. c DSGVO (Standarddatenschutzklauseln). Wir setzen Videos der folgenden Anbieter ein:</p>

            <h4>YouTube/Google (USA)</h4>
            <p>Wir verwenden eingebettete YouTube-Videos im erweiterten Datenschutzmodus. Das heißt: YouTube speichert keine Cookies für einen Nutzer, der eine Webseite mit einem eingebetteten YouTube-Videoplayer aufruft, jedoch nicht auf das Video klickt, um die Wiedergabe zu starten. Wird auf den YouTube-Videoplayer geklickt, kann YouTube unter Umständen Cookies (u.a. von DoubleClick) auf dem Computer des Nutzers speichern, es werden jedoch keine personenbezogenen Cookie-Informationen für Wiedergaben von eingebetteten Videos gespeichert.</p>
            <h5>Angemessenes Datenschutzniveau:</h5>
            <p>Kein angemessenes Datenschutzniveau. Die Übermittlung erfolgt auf Grundlage von Standarddatenschutzklauseln gem. Art. 46 Abs. 2 lit. c DSGVO.</p>
            <h5>Widerruf der Einwilligung:</h5>
            <p>Wenn Du ein Vorschaubild angeklickt hast, werden die Inhalte des Drittanbieters sofort nachgeladen. Wenn Du ein solches Nachladen auf anderen Seiten nicht wünschst, klicke bitte die Vorschaubilder nicht mehr an.</p>
            <p>Weitere Informationen zur Datenverarbeitung findest Du <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noreferrer">hier</a></p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Social Media-Plugins</h3>
            <p>Aus Gründen des Datenschutzes binden wir keine Social Media-Plugins direkt in unseren Webauftritt ein. Wenn Du unsere Seiten aufrufst, werden daher grundsätzlich keine Daten an Social Media-Dienste, wie z.B. Facebook übermittelt. Eine Profilbildung durch Dritte ist somit ausgeschlossen.</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Einsatz von Dienstleistern / Empfänger der Daten</h3>
            <p>Wir geben Deine Daten im Rahmen einer Auftragsverarbeitung gem. Art. 28 DSGVO an Dienstleister weiter, die uns beim Betrieb unserer Webseiten und der damit zusammenhängenden Prozesse unterstützen. Unsere Dienstleister sind uns gegenüber streng weisungsgebunden und entsprechend vertraglich verpflichtet. Dies sind Dienstleister der folgenden Kategorien:</p>
            <ul>
              <li>Hosting-Dienstleister für den Betrieb unserer Webseite (AWS)</li>
              <li>Dienstleister für den Versand von Transaktionsmails (AWS)</li>
              <li>Dienstleister für Datenbankmanagement (MongoDB)</li>
              <li>Dienstleister für den Versand von direkten E-Mails (Google)</li>
              <li>E-Mail-Marketing-Dienstleister für den Versand von Newslettern (Braze)</li>
              <li>Dienstleister für Versand von Unterschriftenlisten (onlinebrief24.de)</li>
              <li>Dienstleister für die Spendenverwaltung (FundraisingBox by Wikando)</li>
              <li>Dienstleister für die Auslieferung und Erfolgsmessung unserer Webseite (Matomo)</li>
              <li>Dienstleister für Office-Anwendungen (Google Workspace)</li>
            </ul>
            <p>Die Dienstleister verarbeiten die Daten ausschließlich auf unsere Weisung und sind zur Einhaltung der geltenden Datenschutzbestimmungen verpflichtet worden. Sämtliche Auftragsverarbeiter wurden sorgfältig ausgewählt und erhalten nur in dem Umfang und für den benötigten Zeitraum Zugang zu Deinen Daten, der für die Erbringung der Leistungen erforderlich ist.</p>
            <p>Die Server einiger von uns eingesetzten Dienstleister befinden sich in den USA und anderen Ländern außerhalb der Europäischen Union. Unternehmen in diesen Ländern unterliegen einem Datenschutzgesetz, das allgemein personenbezogene Daten nicht in demselben Maße schützt, wie es in den Mitgliedstaaten der Europäischen Union der Fall ist. Soweit Deine Daten in einem Land verarbeitet werden, welches nicht über ein anerkannt hohes Datenschutzniveau wie die Europäische Union verfügt, stellen wir über vertragliche Regelungen oder andere anerkannte Instrumente sicher, dass das Datenschutzniveau weitestgehend gesichert wird. Mit datenempfangenden Dienstleistern in Drittländern schließen wir die von der EU-Kommission für die Verarbeitung von personenbezogenen Daten in Drittländern bereitgestellten Standarddatenschutzklauseln gemäß Art. 46 Abs. 2 lit. c DSGVO ab.</p>

          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Pflicht zur Bereitstellung der Daten</h3>
            <p>Die Bereitstellung Deiner Daten ist gesetzlich nicht vorgeschrieben und erfolgt freiwillig. Für die Nutzung von bestimmten Services ist die Bereitstellung Deiner Daten jedoch erforderlich. Wir informieren Dich bei der Eingabe von Daten, wenn die Bereitstellung für den jeweiligen Service oder die jeweilige Funktion erforderlich ist. In diesem Fall sind diese Daten als Pflichtfelder gekennzeichnet. Bei erforderlichen Daten hat die Nichtbereitstellung zur Folge, dass der betreffende Service oder die betreffende Funktion nicht erbracht werden kann. Bei optionalen Daten kann die Nichtbereitstellung zur Folge haben, dass wir unsere Dienste nicht in gleicher Form und gleichem Umfang wie üblich erbringen können.</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Dauer der Datenspeicherung</h3>
            <p>Deine Daten speichern wir nur solange, wie diese für die Erbringung unserer Dienstleistungen erforderlich sind und der Löschung keine Aufbewahrungsfristen entgegenstehen. Die gesetzliche Aufbewahrungsfrist für steuerrelevante Daten, z.B. Spenden, beträgt 10 Jahre.</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h3 className="text-primary">Deine Rechte als betroffene Person</h3>
            <p>Als betroffene Person hast Du das Recht auf Auskunft über die Dich betreffenden personenbezogenen Daten (Art. 15 DSGVO) sowie auf Berichtigung unrichtiger Daten (Art. 16 DSGVO) oder auf Löschung, sofern einer der in Art. 17 DSGVO genannten Gründe vorliegt, z.B. wenn die Daten für die verfolgten Zwecke nicht mehr benötigt werden. Es besteht zudem das Recht auf Einschränkung der Verarbeitung, wenn eine der in Art. 18 DSGVO genannten Voraussetzungen vorliegt und in den Fällen des Art. 20 DSGVO das Recht auf Datenübertragbarkeit. Sofern die Verarbeitung von Daten auf Grundlage Deiner Einwilligung erfolgt, bist Du nach Art. 7 DSGVO berechtigt, die Einwilligung in die Verwendung Deiner personenbezogenen Daten jederzeit zu widerrufen. Ein Widerruf berührt die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht. Jede betroffene Person hat zudem das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn sie der Ansicht ist, dass die Verarbeitung der sie betreffenden Daten gegen datenschutzrechtliche Bestimmungen verstößt (Art. 77 DSGVO).</p>
          </div>
          <div className="col-12">
            <div className="border border-danger py-5 mt-5">
              <div className="row">
                <div className="col-12 col-md-8 offset-md-2">
                  <h3 className="text-primary">Widerspruchsrecht</h3>
                  <p>In Fällen, in denen wir Deine personenbezogenen Daten auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. e oder f DSGVO verarbeiten, hast Du das Recht, aus Gründen, die sich aus Deiner besonderen Situation ergeben, jederzeit Widerspruch einzulegen. Wir verarbeiten die personenbezogenen Daten dann nicht mehr, es sei denn, es liegen nachweisbar zwingende schutzwürdige Gründe für die Verarbeitung vor, die gegenüber Deinen Interessen, Rechten und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
                  <p>Du hast zudem das Recht, jederzeit Widerspruch gegen die Verarbeitung Deiner Daten für Zwecke der Direktwerbung einzulegen. Das gilt auch für das mit einer Direktwerbung in Verbindung stehende Profiling.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Datenschutz;
