import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "bootstrap";
import api from "../helpers/api";
import background1 from "../styles/images/background/background1.svg";
import hero from "../styles/images/hero-image.svg";
import HeadContentContext from "../HeadContentContext";
import { ThemeContext } from "../ThemeContext";

const Contact = () => {
  const { setHeadContent } = useContext(HeadContentContext);
  const { setTheme } = useContext(ThemeContext);

  useEffect(() => {
    setHeadContent({ pageTitle: "Initiative starten" });
    setTheme("initiative");
  }, [setHeadContent]);

  return (
    <main>
      <Heading />
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 order-2 order-md-1">
            <Form />
          </div>
          <div className="col-12 col-md-4 order-1 order-md-2">
            <Aside />
          </div>
        </div>
      </div>
    </main>
  );
};

const Aside = () => {
  return (
    <section>
      <h3 className="contact-title text-primary">Wir beraten Dich gern</h3>
      <p>
        Du möchtest Deine Initiative bei innn.it einstellen oder hast eine Idee
        für eine neue Initiative? Dann schreib uns!
      </p>
      <p>
        Unser Team aus erfahrenen Campaigner:innen wird Deinen Vorschlag sichten
        und sich zeitnah bei Dir zurückmelden.
      </p>
      <p>
        Die wichtigsten Fragen beantworten wir <Link to="/faq">hier</Link>.
      </p>
    </section>
  );
};
const Form = () => {
  const fieldsetRef = useRef();
  const successMsgRef = useRef();

  const valuesInitial = {
    name: "",
    email: "",
    phone: "",
    site: "",
    body: "",
  };
  const [values, setValues] = useState(valuesInitial);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (fieldsetRef.current) {
      fieldsetRef.current.setAttribute("disabled", "disabled");
    }

    if (isFormValid()) {
      try {
        await api.contact(values, () => {
          setIsSuccessful(true);
          if (fieldsetRef.current) {
            fieldsetRef.current.removeAttribute("disabled");
          }
        });
      } catch (error) {
        if (fieldsetRef.current) {
          fieldsetRef.current.removeAttribute("disabled");
        }
      }
    } else {
      if (fieldsetRef.current) {
        fieldsetRef.current.removeAttribute("disabled");
      }
    }
  };

  const isFormValid = () => {
    let isFormValid = true;

    setErrors({});

    if (!values.name) {
      isFormValid = false;
      setErrors((errors) => {
        return {
          ...errors,
          name: "Bitte Namen eingeben.",
        };
      });
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidEmail = values.email.match(emailRegex);

    if (!isValidEmail) {
      isFormValid = false;
      setErrors((errors) => {
        return {
          ...errors,
          email: "Bitte gib eine gültige E-Mail ein.",
        };
      });
    }

    if (!values.body) {
      isFormValid = false;
      setErrors((errors) => {
        return {
          ...errors,
          body: "Bitte Nachricht eingeben.",
        };
      });
    }

    return isFormValid;
  };

  useEffect(() => {
    // Avoid validation of form when values are set on load and when user is typing it for the first time
    if (Object.keys(errors).length && fieldsetRef.current.first) {
      isFormValid();
    }
    fieldsetRef.current.first = true;
  }, [values]);

  useEffect(() => {
    let fieldsetCollapse = Collapse.getInstance(fieldsetRef.current);
    let successMsgCollapse = Collapse.getInstance(successMsgRef.current);

    if (!fieldsetCollapse) {
      fieldsetCollapse = new Collapse(fieldsetRef.current, { toggle: false });
      fieldsetRef.current.addEventListener("hidden.bs.collapse", () => {
        successMsgCollapse.show();
      });
    }

    if (!successMsgCollapse) {
      successMsgCollapse = new Collapse(successMsgRef.current, {
        toggle: false,
      });
    }

    if (isSuccessful) {
      fieldsetCollapse.hide();
    }
  }, [isSuccessful]);

  return (
    <section className="p-md-5" onSubmit={(e) => handleSubmit(e, values)}>
      <form className="form">
        <fieldset className="collapse show" ref={fieldsetRef}>
          <h3 className="text-secondary mb-5">
            Wir freuen uns über Deine Nachricht!
          </h3>
          <div className="mb-3">
            <label className="text-primary">Name*</label>
            <input
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
              error={errors.name}
              name="name"
              onChange={(e) => handleChange(e, values)}
              type="text"
              value={values.name}
            />
            {errors.name && (
              <span className="form-text invalid-feedback">{errors.name}</span>
            )}
          </div>

          <div className="mb-3">
            <label className="text-primary">E-Mail-Adresse*</label>
            <input
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              error={errors.email}
              name="email"
              onChange={(e) => handleChange(e)}
              type="text"
              value={values.email}
            />
            {errors.email && (
              <span className="form-text invalid-feedback">{errors.email}</span>
            )}
          </div>

          <div className="mb-3">
            <label className="text-primary">Telefonnummer</label>
            <input
              className="form-control"
              name="phone"
              onChange={(e) => handleChange(e)}
              type="phone"
              value={values.phone}
            />
          </div>

          <div className="mb-3">
            <label className="text-primary">Webseite</label>
            <input
              className="form-control"
              name="site"
              onChange={(e) => handleChange(e)}
              type="url"
              value={values.site}
            />
          </div>

          <div className="mb-3">
            <label className="text-primary">Deine Nachricht*</label>
            <textarea
              className={`form-control ${errors.body ? "is-invalid" : ""}`}
              error={errors.body}
              name="body"
              onChange={(e) => handleChange(e)}
              type="text"
              value={values.body}
            />
            {errors.body && (
              <span className="form-text invalid-feedback">{errors.body}</span>
            )}
          </div>

          <div className="mb-4 mt-5">
            <button className="btn btn-primary" type="submit">
              Senden
            </button>
          </div>
          <p className="small">Wir verarbeiten Deine Daten gemäß unserer <Link to="/datenschutz">Datenschutzhinweise</Link>.</p>
        </fieldset>

        <div className="collapse fade" ref={successMsgRef}>
          <h3 className="text-primary">Danke für Deine Nachricht!</h3>
        </div>
      </form>
    </section>
  );
};

const Heading = () => {
  return (
    <section
      className="hero hero-image"
      style={{
        backgroundImage: `url("${background1}")`,
      }}
    >
      <div className="hero-container">
        <div className="hero-row">
          <div className="hero-body">
            <h1 className="hero-title">Was willst Du verändern?</h1>
            <h3 className="hero-subtitle">
              Sende uns Deine Idee für eine Initiative
            </h3>
          </div>
          <div className="hero-img">
            <div className="hero-img-container">
              <img src={hero} alt="Was willst Du verändern?"></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
