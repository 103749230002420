import React from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import StepSix from "./StepSix";

const StepComponent = ({ step, ...rest }) => {
  const STEPS = {
    1: StepOne,
    2: StepTwo,
    3: StepThree,
    4: StepFour,
    5: StepFive,
    6: StepSix,
  };

  const CurrentComponent = STEPS[step];

  return <CurrentComponent {...rest} />;
};

export default StepComponent;
