import React from "react";
import FeaturesImage from "../styles/images/icon-box.svg";
import IconPetition from "../styles/images/icon-box-petition.svg";
import IconSignPetition from "../styles/images/icon-sign-petition.svg";
import IconBox from "../components/IconBox";

const Banners = () => {
  return (
    <section className="mt-5">
      <h2>Banners</h2>
      <BannerFeatures />

      <BannerShadow />
      <BannerRounded />
      <BannerImageRight />
      <BannerImageLeft />

      <h3>Banner with icon</h3>
      <BannerSignPetition />
      <BannerPetition />
      <BannerInitiative />
    </section>
  );
};

const BannerFeatures = () => {
  const iconBoxBody =
    "Here is a message that's going to be two lines, so we see the text as it wraps.";
  const iconsBoxTitles = [
    "Diverse Initiativen",
    "Unser Netzwerk",
    "Finanziert durchSpenden",
    "Das sindwir",
  ];
  return (
    <>
      <h6>Features</h6>
      <div className="banner banner-features">
        <h2 className="banner-title">Das ist innn.it</h2>
        <div className="banner-container">
          <div className="banner-row">
            {iconsBoxTitles.map((title) => {
              return (
                <div className="banner-feature" key={title}>
                  <IconBox
                    img={FeaturesImage}
                    imgAlt={title}
                    title={title}
                    text={iconBoxBody}
                  ></IconBox>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

const BannerShadow = () => {
  return (
    <>
      <h6>Banner with shadow</h6>

      <div className="banner banner-image banner-shadow banner-image-right">
        <div className="banner-row">
          <div
            className="banner-img"
            style={{
              backgroundImage: `url("https://via.placeholder.com/640x380")`,
            }}
          ></div>
          <div className="banner-body">
            <div className="banner-text">
              <h2 className="banner-title">
                Briefmarken für direkte Demokratie
              </h2>
              <p>
                Here is a short description that's going to be two lines, so we
                see the text as it wraps.
              </p>
              <div className="banner-footer d-grid gap-2">
                <button
                  className="btn btn-gradient btn-block"
                  type="button"
                  id="button-addon2"
                >
                  Jetzt spenden
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const BannerRounded = () => {
  return (
    <>
      <h6>Banner rounded</h6>
      <div className="banner banner-rounded banner-image banner-image-left">
        <div className="banner-row">
          <div
            className="banner-img"
            style={{
              backgroundImage: `url("https://via.placeholder.com/640x380")`,
            }}
          ></div>
          <div className="banner-body bg-secondary text-white">
            <div className="banner-text">
              <h2 className="banner-title">Sign up for updates.</h2>
              <p>
                Here is a short description that's going to be two lines, so we
                see the text as it wraps.
              </p>
              <div className="banner-footer">
                <div className="input-group-rounded">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="E-mail Addresse"
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="button-addon2"
                  >
                    Sign up
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const BannerImageLeft = () => {
  return (
    <>
      <h6>Banner with image on left</h6>

      <div className="banner banner-image banner-image-left">
        <div className="banner-row">
          <div
            className="banner-img"
            style={{
              backgroundImage: `url("https://via.placeholder.com/640x380")`,
            }}
          ></div>
          <div className="banner-body">
            <div className="banner-text">
              <h2 className="banner-title">
                Here is a message that will be in several lines, so we see the
                text as it wraps.
              </h2>
              <p>
                Interface development patterns, processes and tools are like
                interchangeable parts and factory assembly lines — tools of
                industrial productivity that enable fewer people to create the
                same product as before.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const BannerImageRight = () => {
  return (
    <>
      <h6>Banner with image on left</h6>
      <div className="banner banner-image banner-image-right">
        <div className="row">
          <div
            className="banner-img"
            style={{
              backgroundImage: `url("https://via.placeholder.com/640x380")`,
            }}
          ></div>
          <div className="banner-body">
            <div className="banner-text">
              <h2 className="banner-title">
                Here is a message that will be in several lines, so we see the
                text as it wraps.
              </h2>
              <p>
                Interface development patterns, processes and tools are like
                interchangeable parts and factory assembly lines — tools of
                industrial productivity that enable fewer people to create the
                same product as before.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const BannerSignPetition = () => {
  return (
    <div className="col-12 col-md-8 order-md-0 mb-7">
      <div className="petition banner-background bg-gradient-horizontal-petition">
        <div className="banner-row align-items-center p-md-3 flex-row">
          <div className="banner-img text-center pt-5 pt-md-0 d-flex align-items-center">
            <img src={IconSignPetition} />
          </div>
          <div className="banner-body">
            <div className="banner-text p-md-0">
              <h2 className="banner-title">
                Starte jetzt Deine eigene Petition
              </h2>
              <h5>
                Was willst du verändern? Sende uns Deine Idee für eine Petition
                zu.
              </h5>
              <div className="banner-footer">
                <button
                  className="btn btn-sm btn-primary btn-block"
                  type="button"
                  id="button-addon2"
                >
                  Jetzt Petition starten
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BannerPetition = () => {
  return (
    <div className="petition banner-background bg-gradient-horizontal-petition">
      <div className="align-items-center banner-row flex-row-reverse mx-auto p-md-3 w-75">
        <div className="banner-img text-center pt-5 pt-md-0 d-flex align-items-center">
          <img src={IconPetition} alt="icon for initiative" />
        </div>
        <div className="banner-body">
          <div className="banner-text p-md-0">
            <h2 className="banner-title">Starte jetzt Deine eigene Petition</h2>
            <h5>
              This is a long Subheadline that can stretch over two or three
              lines
            </h5>
            <div className="banner-footer">
              <button
                className="btn btn-sm btn-primary btn-block"
                type="button"
                id="button-addon2"
              >
                Jetzt Petition starten
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BannerInitiative = () => {
  return (
    <div className="initiative banner-background bg-gradient-horizontal-initiative">
      <div className="align-items-center banner-row flex-row-reverse mx-auto p-md-3 w-75">
        <div className="banner-img text-center pt-5 pt-md-0 d-flex align-items-center">
          <img src={FeaturesImage} alt="icon for initiative" />
        </div>
        <div className="banner-body">
          <div className="banner-text p-md-0">
            <h2 className="banner-title">Starte jetzt Deine eigene Petition</h2>
            <h5>
              Was willst du verändern? Sende uns Deine Idee für eine Initiative
              zu.
            </h5>
            <div className="banner-footer">
              <button
                className="btn btn-sm btn-primary btn-block"
                type="button"
                id="button-addon2"
              >
                Kontaktiere uns jetzt
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banners;
