import Gauge from "../../components/Gauge";
import getTarget from "../../helpers/get-target";

const InitiativeGauge = ({ initiative }) => {
  const CONTENT = {
    signature: {
      collection: "Gesammelte Unterschriften",
      support: "Unterstützer",
      petition: <>Unterschriften<br />auf <span className="text-primary">innn.it</span></>,
    },
    document: "Listen angefordert",
  }

  const COUNTS = {
    signature: {
      initiative: initiative.signatureCounts?.[initiative.stage],
      petition: initiative.signatureCount,
    },
    document: {
      initiative: initiative.downloadCounts?.[initiative.stage],
    }
  }

  const TARGETS = {
    signature: {
      initiative: initiative.signatureTargets?.[initiative.stage],
      petition: getTarget(initiative.signatureCount),
    },
    document: {
      initiative: initiative.signatureTargets?.[initiative.stage],
    }
  }

  if (!initiative.optionalFields) {
    return false;
  }

  const gaugeModel = initiative.optionalFields.counter;
  if (!gaugeModel) {
    return false;
  }
  
  if (
    "signature" === gaugeModel &&
    (!!COUNTS?.[gaugeModel]?.[initiative.type])
  ) {
    return (
      <div className="mb-3">
        <Gauge
          arc={
            initiative.optionalFields.signatureTarget &&
            !!TARGETS?.[gaugeModel]?.[initiative.type]
          }
          label={"initiative" == initiative.type ? ("support" == initiative.stage ? CONTENT.signature.support : CONTENT.signature.collection) : CONTENT.signature.petition}
          level={COUNTS?.[gaugeModel]?.[initiative.type]}
          limit={TARGETS?.[gaugeModel]?.[initiative.type]}
        />
      </div>
    );
  } else if (
    "download" === gaugeModel &&
    !!COUNTS?.document?.[initiative.type]
  ) {
    return (
      <div className="mb-3">
        <Gauge
          label="Listen angefordert"
          level={CONTENT.document}
          limit={COUNTS?.document?.[initiative.type]}
        />
      </div>
    )
  } else {
    return false;
  }
}

export default InitiativeGauge;
