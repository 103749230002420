import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faLock, faTimes } from "@fortawesome/pro-solid-svg-icons";
import api from "../../../helpers/api";
import TextField from "./TextField";
import { useRequiredFields } from "../../../custom-hooks/use-required-field";

const ResetPassword = ({ user, setUser }) => {
  const [errors, setErrors] = useState({});
  const [isSuccessful, setIsSuccessful] = useState(false);
  const modalRef = useRef();
  const REQUIRED_FIELDS = ["password", "newPassword"];
 
  const { requiredFields, RequiredFieldSign } = useRequiredFields(REQUIRED_FIELDS);

  const handleChange = ({ target }) => {
    setUser((prev) => ({
      ...prev,
      [target.name]: target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors({});

    if (!validatePasswords()) {
      return;
    };

    try {
      if (user.status === "verified") {
        await api.changePassword(user.id, user, (status, _data) => {
          if (status === 200) {
            setIsSuccessful(true);
          };
        });
      };  
    } catch (error) {
      console.log(error);
    };
  };

  const validatePasswords = () => {
    let errors = {};

    requiredFields.forEach((field) => {
      if (!user[field] || user[field] === "") {
        errors[field] = "Bitte fülle dieses Feld aus";
      }
    });

    setErrors(errors);  
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    const handleClose = () => {
      setIsSuccessful(false);
    };
  
    const modal = modalRef.current;

    modal.addEventListener("hidden.bs.modal", handleClose);

    return () => {
      modal.removeEventListener("hidden.bs.modal", handleClose);
    };
  }, []);

  return (
    <>
      <div className="pointer align-items-center col-md-5 col-12 d-flex mt-4 p-3" 
        style={{backgroundColor: "var(--bs-gray-1)"}}
        ref={modalRef}
      >
        <FontAwesomeIcon
          className="icon-left text-primary"
          fixedWidth
          icon={faLock} 
        />
        <button 
          className="border-0 bg-transparent text-primary" 
          type="button"
          data-bs-toggle="modal" 
          data-bs-target="#resetPassword"
        >
          Passwort zurück setzen
        </button>
        <div
          className="modal fade"
          id="resetPassword"
          tabIndex="-1"
          aria-labelledby="resetPasswordLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header container">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <FontAwesomeIcon
                    className="text-petition-primary"
                    fixedWidth
                    icon={faTimes}
                    size="2x"
                  />
                </button>
              </div>
              <section className="modal-body">
                {isSuccessful ? 
                  <div className="px-3">
                    <div className="align-item-center d-flex">
                      <FontAwesomeIcon className="icon icon-left text-primary" icon={faCheck} size="2x" />
                      <h3 className="text-primary">Vielen Dank! Dein Passwort wurde aktualisiert.</h3>
                    </div>
                    <div className="d-flex float-end mb-4 mt-4">
                      <button 
                        className="btn btn-lg btn-primary"
                        aria-label="Close"
                        data-bs-dismiss="modal"  
                      >
                        Schließen
                      </button>
                    </div>
                  </div> 
                  :
                  <div className="pb-7 px-3">
                    <h3 className="mb-4 text-black">Passwort ändern</h3>
                    <p className="mb-3 text-black">Bitte gib zur Bestätigung Dein aktuelles und darunter ein neues Passwort ein:</p>
                    <TextField
                      errors={errors}
                      field="password"
                      fieldLabel="Aktuelles Passwort"
                      handleChange={handleChange}
                      RequiredFieldSign={RequiredFieldSign}
                      required={RequiredFieldSign("password")}
                      type="password"  
                    />
                    <TextField
                      errors={errors}
                      field="newPassword"
                      fieldLabel="Neues Passwort"
                      handleChange={handleChange}
                      RequiredFieldSign={RequiredFieldSign}
                      required={RequiredFieldSign("newPassword")}
                      type="password"
                    /> 
                    <div className="my-5">
                      <button
                        className="btn btn-petition-primary float-end"
                        onClick={handleSubmit}
                        type="submit"
                      >
                        Speichern
                      </button>
                    </div>
                  </div>
                }
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;