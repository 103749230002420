import api from "../../../helpers/api";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tip from "../../../assets/elements/tip.js";
import TipBox from "../../../assets/elements/tipBox";
import useToolTip from "../../../custom-hooks/use-tool-tip";

const StepOne = ({ counter, formErrors, initiative, maxLengths, handleChange, tags }) => {
  const { showTipBox, hideTip, showTip } = useToolTip();

  const CONTENTS = [
    {
      item: "Haustiere sind keine Sachgegenstände! Tiere müssen vom Gesetz als Lebewesen anerkannt werden.",
    },
    {
      item: "Krankenhäuser sollen Kranke heilen statt Gewinn zu machen. Schaffen Sie das Fallpauschalensystem ab!",
    },
    {
      item: "Das Geld aus den Maskendeals steht Ihnen nicht zu: Spenden Sie es!",
    }
  ];

  return (
    <>
      <div className="mb-5">
        <div className="align-items-md-center d-flex flex-column flex-md-row mb-3 position-relative">
          <label className="h3 me-3 mb-md-0 mb-3 text-primary">
            Der Titel Deiner Petition
          </label>
          <Tip
            label="Beispiele"
          >
            <h3 className="mb-4 text-primary">Titel Beispiele: </h3>
            {
              CONTENTS.map((content, index) => (
                <p
                  className="mb-2"
                  key={index}
                >
                  {content.item}
                </p>
              ))
            }
          </Tip>
        </div>
        <div className="mb-4">
          Der Titel Deiner Petition sollte alle wichtigen Punkte Deiner Forderung beinhalten und
          möglichst eindringlich formuliert sein. Der Titel ist das erste was potentielle
          Unterstützer:innen motiviert, Deine Petition zu unterschreiben.
        </div>
        <input
          className={`form-control mb-2${formErrors.title ? " is-invalid" : ""}`}
          maxLength={maxLengths.title}
          name="title"
          onChange={handleChange}
          placeholder="Titel"
          required={true}
          type="text"
          value={initiative.title}
        />
        <div className={`d-flex ${formErrors.title ? "justify-content-between invalid-feedback" : "justify-content-end"}`}>
          {formErrors.title && (
            <span>Bitte gib einen Titel ein.</span>
          )}
          <span className={`fs-4 ${counter.title === 0 ? " text-danger" : "text-black"}`}>
            {counter.title}
          </span>
        </div>
      </div>

      <div className="mb-5">
        <label className="h3 mb-3 text-primary">
          Kategorie
        </label>
        <div className="mb-3">
          Wähle eine dieser Kategorien für Deine Petition,
          um sie für innn.it-Nutzer:innen auffindbar zu machen.
        </div>
        {
          tags && (
            <select
              className={`form-select form-control mb-2${formErrors.tag ? " is-invalid" : ""}`}
              name="tag"
              onChange={handleChange}
              value={initiative.tag}
            >
              <option value="0" disabled={true}>Bitte auswählen</option>
              {
                tags.map(
                  tag => <option value={tag.id} key={tag.id}>{tag.title}</option>
                )
              }
            </select>
          )
        }
        {formErrors.tag && (
          <span className="form-text invalid-feedback">Bitte wähle eine Kategorie aus.</span>
        )}
      </div>
    </>
  );
};

StepOne.propTypes = {
  counter: PropTypes.object,
  formErrors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  initiative: PropTypes.object,
  maxLengths: PropTypes.object,
  tags: PropTypes.array
};

export default StepOne;
