import formatDate from "../../helpers/format-date";

const InitiativeEndDate = ({ initiative }) => {
  if (!initiative.optionalFields) {
    return false;
  }

  const stageSignatureCount = initiative.signatureTargets?.[initiative.stage];
  const stageEndDate = initiative.endDates?.[initiative.stage]

  const hasCount = initiative.optionalFields.signatureTarget && !!stageSignatureCount;
  const hasEndDate = initiative.optionalFields.endDate && !!stageEndDate;

  let signatureTarget;
  let endDate;

  if (hasCount) {
    signatureTarget = new Intl.NumberFormat("de-DE").format(stageSignatureCount);
  }

  if (hasEndDate) {
    endDate = formatDate(stageEndDate);
  }

  let content;

  if (hasCount && hasEndDate) {
    switch (initiative.stage) {
      case "support":
        content = <>Die Initiative braucht <span className="text-primary">{signatureTarget} Unterschriften</span> bis zum <span className="text-primary">{endDate}</span> um zu starten.</>
        break;

      case "ended":
        content = <>Das Ziel der Initiative war <span className="text-primary">{signatureTarget} Unterschriften</span> bis zum <span className="text-primary">{endDate}</span> zu sammeln.</>
        break;

      case "first-collection":
      case "second-collection":
      default:
        content = <>Die Initiative braucht <span className="text-primary">{signatureTarget} Unterschriften</span> bis zum <span className="text-primary">{endDate}</span>.</>
        break;
    }
  } else if (!hasCount && hasEndDate) {
    switch (initiative.stage) {
      case "support":
        content = <>Die Initiative braucht Unterschriften bis zum <span className="text-primary">{endDate}</span> um zu starten.</>
        break;

      case "ended":
        content = <>Das Ziel der Initiative war bis zum <span className="text-primary">{endDate}</span> zu sammeln.</>
        break;

      case "first-collection":
      case "second-collection":
      default:
        content = <>Die Initiative braucht Unterschriften bis zum <span className="text-primary">{endDate}</span>.</>
        break;
    }
  } else if (hasCount && !hasEndDate) {
    switch (initiative.stage) {
      case "support":
        content = <>Die Initiative braucht <span className="text-primary">{signatureTarget} Unterschriften</span> um zu starten.</>
        break;

      case "ended":
        content = <>Das Ziel der Initiative war <span className="text-primary">{signatureTarget} Unterschriften</span> zu sammeln.</>
        break;

      case "first-collection":
      case "second-collection":
      default:
        content = <>Die Initiative braucht <span className="text-primary">{signatureTarget} Unterschriften</span>.</>
        break;
    }
  } else {
    return false;
  }

  return (
    <p className="mb-4 text-center">{content}</p>
  );
}

export default InitiativeEndDate;
