import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import api from "../../../helpers/api";

const Banner = ({ user }) => {
  const [initiative, setInitiative] = useState({});
  const [isBannerOpen, setIsBannerOpen] = useState(true);

  const location = useLocation();
  let history = useHistory();

  const isUserVerified = "verified" === user?.status;
  const isPetition = "petition" === initiative?.type;
  const displayBannerMessage = isUserVerified && isPetition;

  useEffect(() => {
    (async () => {
      try {
        const { addInitiatorTo, token } = queryString.parse(location.search);

        if (addInitiatorTo && token) {
          await api.getInitiative(addInitiatorTo, async (_status, data) => {
            setInitiative(data);
            history.push(location.pathname);
          });
        }
      } catch (error) {
        // Handle error
      }
    })();
  }, [location]);

  return (
    <>
      {displayBannerMessage && isBannerOpen && (
        <section className="bg-secondary py-4">
          <div className="container d-flex flex-md-nowrap flex-wrap">
            <div
              className="d-flex flex-grow-1 justify-content-end mb-2 pe-md-2 order-md-2"
              onClick={() => setIsBannerOpen(false)}
              role="button"
            >
              <FontAwesomeIcon
                className="text-primary"
                icon={faTimes}
                size="lg"
              />
            </div>
            <div className="d-flex ps-md-4">
              <FontAwesomeIcon
                className="text-primary"
                icon={faBullhorn}
                size="4x"
              />
              <div className="mx-md-5 px-md-0 px-4">
                <h3 className="mb-3 text-black">
                  Vielen Dank für Deinen Einsatz!
                </h3>
                {user && (
                  <p>
                    Stark! Du bist nun Mitinitiator:in der Petition{" "}
                    {initiative?.title}. Du findest sie unter “Meine Petitionen”
                    und kannst sie dort bearbeiten.
                  </p>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Banner;
