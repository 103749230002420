import React from "react";
import hero from "../styles/images/hero-image.svg";

const Heroes = () => {
  return (
    <section className="mt-5 mb-5 text-center">
      <h2 className="mb-3">Heroes</h2>
      <h6>Hero with image</h6>
      <div className="hero hero-image">
        <div className="hero-container">
          <div className="hero-row">
            <div className="hero-body">
              <h1 className="hero-title">Neue Sammlung starten</h1>
              <h3 className="hero-subtitle">Subheadline goes here</h3>
            </div>
            <div className="hero-img">
              <div className="hero-img-container">
                <img src={hero} alt="innn.it-img"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h6>Hero with headline</h6>
      <div className="hero hero-headline">
        <div className="hero-container">
          <div className="hero-row">
            <div className="hero-body">
              <h1 className="hero-title">
                Large headline that will be in several lines.
              </h1>
              <button className="btn btn-gradient">Jetzt Spenden</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Heroes;
