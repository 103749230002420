import { useEffect, useRef, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import countries from "i18n-iso-countries";
import deLocale from "i18n-iso-countries/langs/de.json";
import Modal from "./Modal";
import TextField from "./TextField";
import validateForm from "../../custom-hooks/use-user-validation";
import api from "../../helpers/api";
import { FIELD_LABELS, FIELD_PLACEHOLDERS } from "./content";
import { hideModal, showModal } from "./modalFunctions";
import { useLocationFields } from "../../custom-hooks/use-location-field";

countries.registerLocale(deLocale);
const countryObj = countries.getNames("de", { select: "official" });

const countryOptions = Object.entries(countryObj).map(([key, value]) => {
  return {
    label: value,
    value: key
  };
});

const SignUpModal = ({
  handleChange,
  user, setUser
}) => {
  const [formErrors, setFormErrors] = useState({});
  const fieldsetRef = useRef();
  const history = useHistory();

  const {
    errors: locationErrors,
    locationProperties,
    locations,
    handleLocationProperties,
    setPostalCode,
    showLocationDropdown,
  } = useLocationFields();

  useEffect(() => {
    setUser({
      ...user,
      country: locationProperties?.country || user?.country || "DE",
      location: locationProperties?.location || null,
      postalCode: locationProperties?.postalCode || null,
    });
  }, [locationProperties]);

  const goBack = () => {
    hideModal("signUpModal");
    showModal("getUserModal");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});

    const [isValid, errors] = validateForm("signUp", user);

    if (!isValid) {
      setFormErrors(errors);
      return;
    }

    if (fieldsetRef.current) {
      fieldsetRef.current.setAttribute("disabled", true);
    }

    try {
      await api.createUser(
        { ...user, url: history.location.pathname },
        (_status, _data) => {
          setFormErrors({});
          hideModal("signUpModal");
          showModal("signUpMessageModal");
        }
      );
    } catch (error) {
    } finally {
      if (fieldsetRef.current) {
        fieldsetRef.current.removeAttribute("disabled");
      }
    }
  };

  return (
    <Modal id="signUpModal">
      <form onSubmit={(e) => handleSubmit(e)} noValidate>
        <fieldset ref={fieldsetRef}>
          <h3 className="mb-5">Registrieren</h3>
          <TextField
            field="firstName"
            name="firstName"
            id="signUpModalFirstName"
            type="text"
            formErrors={formErrors}
            fieldLabels={FIELD_LABELS}
            handleChange={handleChange}
            placeholder={FIELD_PLACEHOLDERS}
            user={user}
          />
          <TextField
            field="lastName"
            name="lastName"
            id="signUpModalLastName"
            type="text"
            formErrors={formErrors}
            fieldLabels={FIELD_LABELS}
            handleChange={handleChange}
            placeholder={FIELD_PLACEHOLDERS}
            user={user}
          />

          <div className="dropdown mb-4">
            <label
              className="mb-2 text-black"
              htmlFor="country"
            >
              Land
            </label>
            <button
              className="
                form-control
                dropdown-toggle
                text-start
                d-flex
                justify-content-between
                align-items-center
              "
              type="button"
              id="signUpModalCountryDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {countryObj[user?.country]}
            </button>
            <ul
              className="dropdown-menu overflow-auto"
              style={{ maxHeight: "250px" }}
              aria-labelledby="locationDropdown"
            >
              {countryOptions?.map((country) => (
                <li
                  className="dropdown-item"
                  key={country.value}
                  onClick={() => {
                    handleLocationProperties({ country: country.value });
                  }}
                >
                  {country.label}
                </li>
              ))}
            </ul>
          </div>
          {"DE" == user?.country && (
            <div className="row">
              <div className="col-4 mb-4">
                <label className="text-black" htmlFor="postalCode">Postleitzahl</label>
                <input
                  className={`form-control${locationErrors.location || formErrors.location ? " is-invalid" : ""
                    }`}
                  id="signUpModalPostalCode"
                  name="postalCode"
                  onChange={(e) => {
                    setPostalCode(e.target.value);
                  }}
                  placeholder="12345"
                  type="text"
                />
                {(locationErrors.location || formErrors.location) && (
                  <span
                    className="form-text invalid-feedback"
                    style={{ minWidth: "18rem" }}
                  >
                    {locationErrors.location || formErrors.location}
                  </span>
                )}
              </div>
              <div className="col-8 mb-3">
                <label className="text-black" htmlFor="formLocation">Ort</label>
                {locations.length > 1 ? (
                  <div className="dropdown">
                    <button
                      className={`form-control text-start d-flex justify-content-bottom align-items-center dropdown-toggle ${showLocationDropdown ? " show" : ""
                        }`}
                      type="button"
                      id="signUpModalLocationDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {locationProperties?.location?.name || "Ort"}
                    </button>
                    <ul
                      className={`dropdown-menu ${showLocationDropdown ? "show" : ""
                        }`}
                      aria-labelledby="locationDropdown"
                    >
                      {locations.map((location) => (
                        <li
                          className="dropdown-item"
                          key={location.id}
                          onClick={() => {
                            handleLocationProperties({ country: user.country, location })
                          }}
                        >
                          {location.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <input
                    className={`form-control${locationErrors.location || formErrors.location
                      ? " is-invalid"
                      : ""
                      }`}
                    value={user?.location?.name || ""}
                    id="signUpModalFormLocation"
                    name="location"
                    placeholder="Ort"
                    readOnly
                    type="text"
                  />
                )}
              </div>
            </div>
          )}

          <TextField
            field="password"
            name="password"
            id="signUpModalPassword"
            type="password"
            formErrors={formErrors}
            fieldLabels={FIELD_LABELS}
            handleChange={handleChange}
            placeholder={FIELD_PLACEHOLDERS}
            user={user}
          />
          <div className="form-check mb-4">
            <input
              className={`form-check-input${formErrors.subscribe ? " is-invalid" : ""
                }`}
              id="signUpModalSubscribeTrue"
              name="subscribe"
              onChange={handleChange}
              type="radio"
              value={true}
            />
            <label className="small text-black" htmlFor="signUpModalSubscribeTrue">
              Ja, ich möchte über Neuigkeiten von Kampagnen und Aktionen von
              innn.it e.V. per E-Mail informiert werden. Eine Abmeldung ist
              jederzeit möglich.
            </label>
          </div>
          <div className="form-check mb-5">
            <input
              className={`form-check-input${formErrors.subscribe ? " is-invalid" : ""
                }`}
              id="signUpModalSubscribeFalse"
              name="subscribe"
              onChange={handleChange}
              type="radio"
              value={false}
            />
            <label className="small text-black" htmlFor="signUpModalSubscribeFalse">
              Nein, ich möchte keine Informationen über Petitionen, Kampagnen
              und Aktionen per E-Mail erhalten.
            </label>
            {formErrors?.subscribe && (
              <span className="form-text invalid-feedback">
                {formErrors?.subscribe}
              </span>
            )}
          </div>
          <div className="d-flex justify-content-between mb-4">
            <button
              className="btn btn-lg btn-outline-primary"
              onClick={goBack}
              type="button"
            >
              Zurück
            </button>
            <button
              className="btn btn-lg btn-primary"
              type="submit"
            >
              Weiter
            </button>
          </div>

          <div className="small text-black">
            <p>*Diese Felder müssen ausgefüllt werden.</p>
            <p>
              Durch die Registrierung oder Anmeldung, akzeptierst Du die{" "}
              <Link className="text-primary" to="/datenschutz">Datenschutzhinweise</Link> von innn.it.
            </p>
          </div>
        </fieldset>
      </form>
    </Modal>
  );
};

export default SignUpModal;
