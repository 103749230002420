import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

const TipBox = (props) => {
  return (
    <div className="tipBox" ref={props.tipBoxRef}>
      <div className="d-flex justify-content-end mb-3">
        <FontAwesomeIcon 
          className="text-primary" 
          icon={faTimes} 
          onClick={props.hideTip}
        />
      </div>
      {props.children}
    </div>
  )
};

export default TipBox;