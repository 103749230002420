import React, { useEffect, useState } from "react";
import { format, sub } from "date-fns";
import { Link } from "react-router-dom";
import api from "../helpers/api";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import graphIconActive from "../../src/styles/images/graph-icon-active.svg";
import graphIconInActive from "../../src/styles/images/graph-icon-inactive.svg";

const DashboardTable = () => {
  const [graphData, setGraphData] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [stats, setStats] = useState({});
  const [graph, setGraph] = useState({
    cycle: "monthly",
    view: "download",
  });

  const aggregatedStats = {
    download: "Downloads",
    post: "Bestätigte Downloads",
    signatureCount: "Empfangene Unterschriften",
  };

  const cycles = [
    {
      name: "Woche",
      value: "daily",
    },
    {
      name: "Monat",
      value: "weekly",
    },
    {
      name: "Jahr",
      value: "monthly",
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const downloadData = await api.getDocumentStats(
          { params: { exportType: "download" } },
          async (_status, data) => data
        );

        const postData = await api.getDocumentStats(
          { params: { exportType: "post" } },
          async (_status, data) => data
        );

        const signatureCountData = await api.getDocumentStats(
          { params: { cycle: "total" } },
          async (_status, data) => data
        );

        setStats((prev) => ({
          ...prev,
          download: getTotal(downloadData, "export"),
          post: getTotal(postData, "export"),
          signatureCount: getTotal(signatureCountData, "signature"),
        }));
      } catch (error) {}
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { cycle, view } = graph;
      const { startDate, endDate } = getDates();
      let params = { cycle: cycle, startDate, endDate };

      if (["download", "post"].includes(view)) {
        params.exportType = view;
      }

      const graphStats = await api.getDocumentStats(
        { params },
        async (_status, data) => data
      );

      let data = graphStats.data.map((graphStat) => {
        return {
          x: formatXAxis(graphStat.date, cycle),
          value: ["download", "post"].includes(view)
            ? graphStat.status.verified
            : graphStat.signatureCount,
        };
      });

      setGraphData(data);
    })();
  }, [graph]);

  const formatToGermanNumber = (number) => {
    if (typeof number === "undefined") {
      return "";
    }
    return new Intl.NumberFormat("de-DE").format(number);
  };

  const formatXAxis = (value, cycle) => {
    if (cycle === "monthly") {
      return format(new Date(value), "MMM");
    } else if (cycle === "weekly") {
      return value;
    } else if (cycle === "daily") {
      return format(new Date(value), "eeee");
    }
  };

  const getDates = () => {
    const currentDate = new Date();
    let startDate;
    const endDate = format(currentDate, "yyyy-MM-dd");
    const { cycle } = graph;

    if (cycle === "daily") {
      startDate = sub(currentDate, { days: 7 });
    } else if (cycle === "weekly") {
      startDate = sub(currentDate, { weeks: 3 });
    } else if (cycle === "monthly") {
      startDate = sub(currentDate, { months: 11 });
    } else {
      return;
    }

    return { startDate: format(startDate, "yyyy-MM-dd"), endDate };
  };

  const getTotal = ({ data }, type) => {
    if (!data[0]) {
      return;
    }
    if (type === "export") {
      return data[0]["status"]["verified"];
    } else if (type === "signature") {
      return data[0]["signatureCount"];
    }
    return data.reduce((acc, current) => {
      const value =
        type == "export" ? current.status.verified : current.signatureCount;
      return acc + value;
    }, 0);
  };

  const graphAggregate = () => {
    if (selectedPeriod) {
      const data = graphData.find(({ x }) => x === selectedPeriod);
      if (data) {
        return data.value;
      }
    }
    return graphData.reduce((acc, { value }) => acc + value, 0);
  };

  const handleChangeView = (key) => {
    setGraph((prev) => ({ ...prev, view: key }));
  };

  const chartHeight = () => {
    return (Object.keys(aggregatedStats).length - 1) * 85
  }

  return (
    <section className="dashboard-table">
      <header className="dashboard-table-header">
        <h3 className="text-primary">Daten und Zahlen</h3>
        <Link className="dashboard-table-header-link" to="/dashboard/tracking">
          Zum Tracking
        </Link>
      </header>
      {isOpen && (
        <div className="dashboard-table-body">
          <div className="dashboard-table-body-left-side">
            <div className="document-statistics-container">
              {Object.keys(aggregatedStats).map((key) => {
                return (
                  <div
                    className="document-statistics"
                    key={aggregatedStats[key]}
                    onClick={() => handleChangeView(key)}
                  >
                    <div className="stats">
                      <h4 className="text-primary">{aggregatedStats[key]}</h4>
                      <div className="stats__count">
                        <h3 className="text-end text-primary">
                          {formatToGermanNumber(stats[key])}
                        </h3>
                        <img
                          src={`${
                            graph.view === key
                              ? graphIconActive
                              : graphIconInActive
                          }`}
                          alt="document-statistics graph icon"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="dashboard-table-body-right-side">
            <div className="bg-white">
              <div className="chart-header">
                <div className="chart-details">
                  <h4 className="text-primary">{aggregatedStats[graph.view]}</h4>
                  <div className="cycle-selector">
                    {cycles.map(({ name, value }) => (
                      <CycleSelector
                        key={name}
                        isSelected={graph.cycle == value}
                        setGraph={setGraph}
                        name={name}
                        value={value}
                      />
                    ))}
                  </div>
                </div>
                <h3 className="ps-3 text-primary">
                  {formatToGermanNumber(graphAggregate())}
                </h3>
              </div>
              <Chart
                data={graphData}
                formatToGermanNumber={formatToGermanNumber}
                height={chartHeight()}
                setSelectedPeriod={setSelectedPeriod}
                selectedPeriod={selectedPeriod} 
              />
            </div>
          </div>
        </div>
      )}
      <footer
        className="dashboard-table-footer"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <FontAwesomeIcon
          className="icon text-primary"
          icon={isOpen ? faChevronUp : faChevronDown}
        />
        <span className="fw-bold text-primary ms-3">{`${
          isOpen ? "Ausblenden" : "Einblenden"
        }`}</span>
      </footer>
    </section>
  );
};

export default DashboardTable;

const CycleSelector = ({ setGraph, isSelected, name, value }) => {
  return (
    <span
      className={`fw-bold px-2 ${
        isSelected ? "text-primary" : "text-secondary"
      }`}
      onClick={() => setGraph((prev) => ({ ...prev, cycle: value }))}
    >
      {name}
    </span>
  );
};

const Chart = ({
  data,
  formatToGermanNumber,
  height,
  setSelectedPeriod,
  selectedPeriod,
}) => {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    setClicked(false);
  }, [data]);

  const ActiveDot = ({ cx, cy }) => {
    if (!clicked) {
      return null;
    }
    return (
      <circle
        cx={cx}
        cy={cy}
        r={5}
        className="customized-active-dot"
        fill={"#6EE2DF"}
      />
    );
  };

  const handleXAxisClick = (e) => {
    const value = e.value;
    if (value === selectedPeriod) {
      setSelectedPeriod(null);
    } else {
      setSelectedPeriod(value);
    }
  };

  return (
    <ResponsiveContainer width="100%" height={height}>
      <AreaChart
        data={data}
        width={800}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        onClick={() => setClicked(true)}
      >
        <Area
          type="monotone"
          activeDot={<ActiveDot />}
          dataKey="value"
          fill="url(#Graphcolor)"
          fillOpacity={1}
          stroke="#300FCB"
        />
        <defs>
          <linearGradient id="Graphcolor">
            <stop offset="7.080%" stopColor="#300FCB" />
            <stop offset="93.16%" stopColor="#6EE2DF" />
          </linearGradient>
        </defs>

        <Tooltip
          cursor={clicked ? { stroke: "#6EE2DF" } : false}
          trigger="click"
          content={
            <CustomTooltip
              clicked={clicked}
              formatToGermanNumber={formatToGermanNumber}
            />
          }
        />

        <XAxis
          axisLine={false}
          dataKey="x"
          interval="preserveStartEnd"
          tickLine={false}
          onClick={(e) => handleXAxisClick(e)}
          stroke="#300FCB"
          padding={{ left: 5, right: 5 }}
        />
        <YAxis hide={true} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const CustomTooltip = ({ active, clicked, formatToGermanNumber, payload }) => {
  if (!clicked) {
    return null;
  }
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <div>{formatToGermanNumber(payload[0].value)}</div>
      </div>
    );
  }

  return null;
};
