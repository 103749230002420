import api from "../../../helpers/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";

const DocumentTracking = () => {
  const defaultFormDocument = {
    code: "",
    signatureCount: 1,
  };

  const [alert, setAlert] = useState({});
  const [document, setDocument] = useState();
  const fieldsetRef = useRef();
  const [formDocument, setFormDocument] = useState(defaultFormDocument);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const handleChange = async (e) => {
    const { value, name, min, max } = e.target;
    setIsSuccessful(false);

    if (name === "code") {
      if (value.length === 6) {
        try {
          await api.getDocument(
            value,
            (_status, data) => {
              setDocument(data);
            }
          );
        } catch (error) {
          setDocument({});
        }
      } else if (name === "signatureCount") {
        value = parseInt(Math.max(Number(min), Math.min(Number(max), Number(value))));
      }
    }

    setFormDocument({
      ...formDocument,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (await isFormValid(true)) {
      try {
        if (document) {
          if (fieldsetRef.current) {
            fieldsetRef.current.setAttribute("disabled", "disabled");
          }

          await api.receiveDocument(
            formDocument.code,
            { signatureCount: formDocument.signatureCount },
            () => {
              setIsSuccessful(true);
              setFormDocument(defaultFormDocument);

              if (fieldsetRef.current) {
                fieldsetRef.current.removeAttribute("disabled");
              }
            }
          );
        }
      } catch (error) {
        setIsSuccessful(false);
        setAlert({
          message: "Es ist ein Fehler aufgetreten. Bitte, versuche es später noch einmal.",
          type: "danger",
        });

        if (fieldsetRef.current) {
          fieldsetRef.current.removeAttribute("disabled");
        }
      }
    };
  }

  const isFormValid = async (isSubmit = false) => {
    let alert = {};
    let isValid = false;

    if (
      !formDocument.code
      || formDocument.code === ""
      || formDocument.code.length < 6
    ) {
      if (isSubmit) {
        alert = {
          message: "Bitte gib einen Tracking-code ein.",
          type: "danger",
        };
      }
    } else if (
      !document
      || formDocument.code !== document.code
    ) {
      if (isSubmit || formDocument.code.length == 6) {
        alert = {
          message: "Code ungultig.",
          type: "danger",
        };
      }
    } else {
      try {
        await api.getInitiative(
          document.initiativeId,
          (_status, initiative) => {
            isValid = true;
            if (document.signatureCount === 0) {
              alert = {
                message: `Initiative: “${initiative.title}”.`,
                type: "success",
              };
            } else {
              alert = {
                message:
                  `Unter diesem Code wurden bereits Unterschriften zu “${initiative.title}” hinzugefügt.`,
                type: "primary",
              };
            }
          }
        );
      } catch (error) {
        alert = {
          message: "Es ist ein Fehler aufgetreten. Bitte, versuche es später noch einmal.",
          type: "danger",
        }
      }
    }

    setAlert(alert);
    return isValid;
  }

  useEffect(() => {
    isFormValid();
  }, [formDocument]);

  return (
    <main className="dashboard">
      <div className="container-narrow">
        <div className="modal-dialog pe-auto">
          <form onSubmit={handleSubmit} className="overflow-hidden p-5 pt-7 rounded shadow">
            <div className={`bg-secondary mb-5 mt-n7 mx-n5 collapse fade ${isSuccessful ? "show" : ""}`}>
              <p className="h5 text-primary px-5 py-2 text-center">
                Die Unterschriften wurden erfolgreich hinzugefügt.
              </p>
            </div>
            <h3 className="mb-4">Unterschriftenliste einpflegen</h3>
            <fieldset ref={fieldsetRef}>
              <div className="mb-5">
                <label
                  className="text-primary"
                  htmlFor="code"
                >
                  Tracking-Nummer
                </label>
                <div className="form-icon form-icon-right">
                  <input
                    className={`
                      form-control
                      ${"danger" == alert.type ? "is-invalid" : ""}
                      ${"success" == alert.type ? "is-valid" : ""}`
                    }
                    id="code"
                    maxLength="6"
                    name="code"
                    onChange={handleChange}
                    type="text"
                    value={formDocument.code}
                  />
                  {alert && (
                    <span className={`form-text text-${alert.type}`}>{alert.message}</span>
                  )}

                  {alert && "primary" == alert.type && (
                    <FontAwesomeIcon className="icon text-primary" icon={faExclamationCircle} />
                  )}
                </div> 
              </div>
              <div className="mb-7">
                <label
                  className="text-primary"
                  htmlFor="signatureCount"
                >
                  Anzahl Unterschriften
                </label>
                <div>
                  <input
                    className="form-control"
                    id="signatureCount"
                    min="0"
                    name="signatureCount"
                    onChange={handleChange}
                    step="1"
                    type="number"
                    value={formDocument.signatureCount}
                  />
                </div>
              </div>
              <div className="d-flex align-items-end flex-md-column-reverse">
                <button className="btn btn-primary">Hinzufügen</button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </main>
  );
};

export default DocumentTracking;
