import React from "react";

const Badges = () => {
  return (
    <div>
      <h3 className="mt-4 mt-md-0">Badges</h3>
      <div className="row">
        <div className="col-md-6 col-6">
          <h6>Counter</h6>
          <div className="row align-items-center">
            <div className="col-4">
              <span className="badge bg-primary">2</span>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-6">
          <h6>Dot Badges</h6>
          <div className="row align-items-center">
            <div className="col-1">
              <div className="dot"></div>
            </div>
            <div className="col-1">
              <div className="dot dot-sm"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Badges;
