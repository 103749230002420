import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../ThemeContext";

const Impressum = () => {
  const { setTheme } = useContext(ThemeContext);

  useEffect(() => {
    setTheme("initiative");
  }, []);

  return (
    <main>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            <h2 className="text-primary py-4">Impressum</h2>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <p>Die Plattform innn.it wird betrieben von:</p>
            <p>
              innn.it e.V.
              <br />Pressehaus Podium
              <br />Karl-Liebknecht-Straße 29A
              <br />10178 Berlin
            </p>
            <p>Wir sind beim Amtsgericht Charlottenburg als Verein unter der Nummer VR&nbsp;35314&nbsp;B eingetragen.</p>
            <p>Vertretungsberechtigter Vorstand: Gregor Hackmack und Nora Circosta. Inhaltlich verantwortlich: Gregor Hackmack.</p>
          </div>
          <div className="col-12 col-md-8 offset-md-2">
            <h4>Kontakt:</h4>
            <p>Der beste und schnellste Weg mit uns in Kontakt zu treten, ist per E-Mail. Schreib uns gerne an hallo[ät]innn.it</p>
            <p>
              Die Datenschutzhinweise findest Du auf <Link to="/datenschutz">https://innn.it/datenschutz</Link>.
              <br />Die Moderationsregeln für Inhalte auf der Plattform findest Du auf <Link to="//verein.innn.it/moderationsregeln" target="_blank">https://verein.innn.it/moderationsregeln</Link>.
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Impressum;
