import { CKEditor } from "@ckeditor/ckeditor5-react";
import * as ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import api from "../../../helpers/api";
import Attachment from "../../../components/Attachment";

const EditUpdate = () => {
  const MAX_LENGHTS = {
    content: 0,
    ctaLabel: 30,
    title: 100,
  };

  const [counter, setCounter] = useState({ ...MAX_LENGHTS });
  let history = useHistory();
  let location = useLocation();
  const { initiativeId, id } = useParams();
  const [update, setUpdate] = useState({
    id: null,
    status: "draft",
    title: "",
    content: "",
    cta: {
      enabled: false,
      label: "",
      url: "",
    },
    customAuthor: {
      enabled: false,
      name: "",
    },
  });

  const paths = location.pathname?.split("/");
  const modelPath = paths[2];

  useEffect(() => {
    (async () => {
      try {
        if (initiativeId && id) {
          await api.getUpdate(initiativeId, id, async (_status, data) => {
            setUpdate({
              id: data.id || null,
              status: data.status || "draft",
              title: data.title || "",
              content: data.content || "",
              cta: {
                enabled: false,
                label: "",
                url: "",
              },
              customAuthor: {
                enabled: data.customAuthor.enabled ? true : false,
                name: data.customAuthor.name || "",
              },
              status: data.status,
            });

            Object.keys(data).forEach(key => {
              if (MAX_LENGHTS[key]) {
                updateCounter(key, data[key]);
              }
            })
          });
        }
      } catch (error) {}
    })();
  }, [initiativeId, id]);

  const handleChange = (e) => {
    const { name, type } = e.target;
    const value = type === "checkbox" ? e.target.checked : e.target.value;

    if (MAX_LENGHTS[name]) {
      if (value.length > MAX_LENGHTS[name]) {
        return;
      }

      updateCounter(name, value)
    }

    if (name.includes(".")) {
      const [nestedField, field] = name.split(".");
      setUpdate({
        ...update,
        [nestedField]: {
          ...update[nestedField],
          [field]: value,
        },
      });
    } else {
      setUpdate({
        ...update,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e, action) => {
    e.preventDefault();

    const actions = {
      draft: {
        pathname: `/dashboard/${modelPath}/${initiativeId}/updates`,
        status: "draft",
      },
      email: {
        pathname: `/dashboard/${modelPath}/${initiativeId}/update/email`,
        status: "publish",
      },
      publish: {
        pathname: `/dashboard/${modelPath}/${initiativeId}/updates`,
        status: "publish",
      },
      save: {
        pathname: `/dashboard/${modelPath}/${initiativeId}/updates`,
        status: update.status,
      },
    };

    try {
      const params = {
        ...update,
        ...(actions[action].status && { status: actions[action].status }),
      };
      await api.upsertUpdate(
        initiativeId,
        id,
        params,
        async (_status, data) => {
          const location = {
            pathname: actions[action].pathname,
            state: {
              action: action,
              update: data,
            },
          };

          const updateId = data.id;

          if (update.images && update.images[0]) {
            const formData = new FormData();
            formData.append("file", update.images[0]);
            formData.append("parent", updateId);

            await api.createAttachment(formData, async (_status, data) => {
              const attachmentId = data.id;
              await api.updateUpdate(
                initiativeId,
                updateId,
                { featuredImage: attachmentId },
                () => {}
              );
            });
          }
          history.push(location);
        }
      );
    } catch (err) {}
  };

  const updateCounter = (name, value) => {
    let count;
    if (name === "content") {
      count = value
          .replace(/(<([^>]+)>)/gi, "")
          .replace(/&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-fA-F]{1,6});/ig, "-")
          .length;
    } else {
      count = MAX_LENGHTS[name] - value.length;
    }
    setCounter(prev => ({
      ...prev,
      [name]: count,
    }));
  }

  const editorConfiguration = {
    toolbar: [
      "heading",
      "undo",
      "redo",
      "bold",
      "italic",
      "blockQuote",
      "imageTextAlternative",
      "link",
      "imageUpload",
      "numberedList",
      "bulletedList",
    ],
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
  };

  return (
    <main className="dashboard">
      {update && (
        <section className="container-narrow-sm">
          <h3 className="my-5 text-primary">Neues Update erstellen</h3>
          <form>
            <div className="mb-5">
              <label className="h3 text-primary">Titel</label>
              <input
                className="form-control mb-3"
                maxLength={MAX_LENGHTS.title}
                name="title"
                onChange={handleChange}
                placeholder="Name"
                type="text"
                defaultValue={update.title}
              />

              <span
                className={`d-block text-end ${
                  counter.title === 0 ? " text-danger" : " "
                }`}
              >
                {counter.title}
              </span>
            </div>
            <div className="mb-5">
              <label className="h3 text-primary">Deine Neuigkeiten</label>
              {/*
              <div className="mb-4">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua.
              </div>
              */}
              <div className="border border-primary rounded-3 overflow-hidden">
                <CKEditor
                  config={editorConfiguration}
                  data={update.content}
                  editor={ClassicEditor}
                  onBlur={(_event, editor) => {
                    handleChange({
                      target: { name: "content", value: editor.getData() },
                    });
                  }}
                  onChange={(_event, editor) => {
                    updateCounter("content", editor.getData())
                  }}
                />
              </div>
              <span
                className="d-block text-end"
              >
                Unsere Empfehlung ist 1.500 Zeichen. Dein Update hat {`${(counter.content.toLocaleString("de-DE"))}`} Zeichen.
              </span>
            </div>

            <div className="mb-5">
              <label className="h3 text-primary">Medien hinzufügen</label>
              {/*
              <div className="mb-3">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua.
              </div>
              */}
              {update.featuredImage && (
                <div>
                  <Attachment
                    className="w-100 mb-5"
                    attachment={update.featuredImage}
                    size="small"
                  />
                </div>
              )}
              <input
                className="form-control"
                name="image"
                onChange={(e) =>
                  handleChange({
                    target: { name: "images", value: e.target.files },
                  })
                }
                type="file"
              />
            </div>
            {/*
            <div className="mb-5">
              <div className="d-flex align-items-center">
                <label className="h3 me-4 text-primary">
                  {" "}
                  Button hinzufügen{" "}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    checked={update.cta.enabled}
                    name="cta.enabled"
                    onChange={handleChange}
                    type="checkbox"
                  />
                </div>
              </div>
              <div className="mb-3">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua.{" "}
              </div>
              <div>
                <label
                  className={`${update.cta.enabled ? "text-primary" : "text-gray-1"}`}
                >
                  Button Text
                </label>
                <input
                  type="text"
                  className="form-control mb-3"
                  disabled={update.cta.enabled ? "" : "disabled"}
                  maxLength={MAX_LENGHTS.ctaLabel}
                  name="cta.label"
                  onChange={handleChange}
                  placeholder="Button Text"
                  value={update.cta.label}
                />
                <span
                  className={`d-block text-end ${counter.ctaLabel === 0 ? "text-danger" : " "}`}
                >
                  {counter.ctaLabel}
                </span>
              </div>
              <div className="mb-5">
                <label
                  className={`${update.cta.enabled ? "text-primary" : "text-gray-1"}`}
                >
                  Button Link
                </label>
                <input
                  className="form-control mb-3"
                  disabled={update.cta.enabled ? "" : "disabled"}
                  name="cta.url"
                  onChange={handleChange}
                  placeholder="https://www.beispiel.de"
                  type="url"
                  value={update.cta.url}
                />
              </div>
              <div className="mb-5">
                <label
                  className={`${update.cta.enabled ? "text-primary" : "text-gray-1"}`}
                >
                  Button Vorschau
                </label>
                <div className="bg-gray-4 py-3 ps-3">
                  <input
                    className={`btn btn-primary ${update.cta.enabled ? "" : "bg-gray-1 border-gray-1"}`}
                    disabled={update.cta.enabled ? "" : "disabled"}
                    name="previewButton"
                    onChange={handleChange}
                    value={`${update.cta.label ? update.cta.label : "Button Text"}`}
                  />
                </div>
              </div>
            </div>
            */}
            <div className="mb-5">
              <div className="d-flex align-items-center">
                <label className="h3 me-4 text-primary">Absender ändern</label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    checked={update.customAuthor.enabled}
                    name="customAuthor.enabled"
                    onChange={handleChange}
                    type="checkbox"
                  />
                </div>
              </div>
              <div className="mb-3">
                Wenn das Update unter einem anderen Namen oder als News-Artikel
                gepostet werden soll kann hier der Name / Name der Zeitung
                eingetragen werden.
              </div>
              <div className="mb-5">
                <label
                  className={`${
                    update.customAuthor.enabled ? "text-primary" : "text-gray-1"
                  }`}
                >
                  Absender
                </label>
                <input
                  className="form-control"
                  disabled={update.customAuthor.enabled ? "" : "disabled"}
                  name="customAuthor.name"
                  onChange={handleChange}
                  placeholder="Beispielname"
                  type="text"
                  value={update.customAuthor.name}
                />
              </div>
            </div>
            <div>
              {((update.id && "publish" != update.status) || !update.id) && (
                <button
                  className="btn btn-lg btn-primary me-4"
                  onClick={(e) => handleSubmit(e, "publish")}
                  type="button"
                >
                  Update posten
                </button>
              )}

              {!update.id && (
                <button
                  type="button"
                  className="btn btn-lg btn-outline-primary"
                  onClick={(e) => handleSubmit(e, "draft")}
                >
                  Entwurf speichern
                </button>
              )}

              {update.id && (
                <button
                  className="btn btn-lg btn-outline-primary"
                  onClick={(e) => handleSubmit(e, "save")}
                  type="button"
                >
                  Änderungen speichern
                </button>
              )}
            </div>
          </form>
        </section>
      )}
    </main>
  );
};

export default EditUpdate;
