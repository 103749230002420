import api from "../../../helpers/api";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import DocumentPostForm from "./DocumentPostForm";
import DownloadImage from "../../../styles/images/icon-box/download.svg";
import fileDownload from "js-file-download";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { faDownload, faPlus, faMinus } from "@fortawesome/pro-solid-svg-icons";
import IconBox from "../../../components/IconBox";
import MailImage from "../../../styles/images/icon-box/mail.svg";
import ShareImage from "../../../styles/images/icon-box/megaphone.svg";
import SignImage from "../../../styles/images/icon-box/signature.svg";
import { ThemeContext } from "../../../ThemeContext";

const Index = () => {
  let btnRef = useRef();
  let firstFieldRef = useRef();
  const location = useLocation();
  let history = useHistory();
  let { id } = useParams();

  const [initiative, setInitiative] = useState({});
  const [isFormCollapsed, setIsFormCollapsed] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [document, setDocument] = useState();
  const [documentExportOptions, setDocumentExportOptions] = useState({ download: false, form: false });
  const { setTheme } = useContext(ThemeContext);
  const [signer, setSigner] = useState();
  const { trackEvent } = useMatomo();

  const createDocument = async (props) => {
    const documentProps = {
      initiativeId: initiative.id,
      initiativeStage: initiative.stage,
      country: signer.country,
      location: signer.location?.id,
      postalCode: signer.postalCode?.id,
      email: signer.email,
      firstName: signer.firstName,
      lastName: signer.lastName,
      exportType: props.exportType,
      recipient: props.recipient,
      subscribe: signer.subscribe,
    };

    return new Promise(async (resolve, reject) => {
      try {
        await api.createDocument(documentProps, async(_status, document) => {
          setDocument(document);

          if (document.verified) {
            await api.downloadDocument(
              document.id, document.verificationToken,
              (_status, data) => {
                fileDownload(data, "innn_it.pdf");
                resolve();
              }
            );
          }
        });
      } catch (error) {
        reject();
      }
    })
  }

  const handleSubmit = async () => {
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "true");
    }

    createDocument({ exportType: "download" }).finally(() => {
      if (btnRef.current) {
        btnRef.current.removeAttribute("disabled");
      }

      trackEvent({
        category: "document",
        action: "createDocument",
      });
    });
  }

  useEffect(() => {
    if (!location.state || !location.state.signer || !location.state.initiative) {
      return history.push(`/initiatives/${id}`)
    }

    setInitiative(location.state.initiative);
    setTheme(location.state.initiative.type || "petition");
    setSigner(location.state.signer);

  }, [id, history, location.state]);

  useEffect(() => {
    if (
      !initiative
      || !initiative.documentExportTypes
      || !Array.isArray(initiative.documentExportTypes)
    ) {
      return;
    }

    const documentExportOptions = {
      download: false,
      form: false,
    };

    if (initiative.documentExportTypes.includes("download")) {
      documentExportOptions.download = true;
    }

    if (initiative.documentExportTypes.includes("post")) {
      documentExportOptions.form = "post";
    } else if (initiative.documentExportTypes.includes("manual")) {
      documentExportOptions.form = "manual";
    }

    if (!documentExportOptions.download && documentExportOptions.form) {
      setIsFormCollapsed(false)
    }

    setDocumentExportOptions(documentExportOptions);
  }, [initiative]);

  useEffect(() => {
    if (document && document.id) {
      setIsSubmitted(true);
    }
  }, [document]);

  useEffect(() => {
    if (isFormCollapsed) {
      return;
    }
    firstFieldRef.current.focus();
  }, [isFormCollapsed])

  return (
    <main className="download-signature-form">

      {/* Header banner */}
      <section className="banner banner-features container mt-5">
        <h2 className="text-primary">
          Vielen Dank! So einfach geht’s weiter:
        </h2>
        <div className="banner-container">
          <div className="banner-row">
            <div className="banner-feature">
              <IconBox
                img={DownloadImage}
                imgAlt="Ausdrucken"
                title="Ausdrucken"
                text="Drucke die Unterschriftenliste und den Umschlag zum Selbstfalten aus."
              />
            </div>
            <div className="banner-feature">
              <IconBox
                img={SignImage}
                imgAlt="Unterschreiben"
                title="Unterschreiben"
                text="Jetzt können Du und bis zu fünf weitere Personen die Liste ausfüllen und unterschreiben."
              />
            </div>
            <div className="banner-feature">
              <IconBox
                img={MailImage}
                imgAlt="Versenden"
                title="Versenden"
                text="Falte den Umschlag, packe die Unterschriftenliste hinein. Das Porto übernehmen wir!"
              />
            </div>
            <div className="banner-feature">
              <IconBox
                img={ShareImage}
                imgAlt="Weitererzählen"
                title="Weitererzählen"
                text="Gar nicht kompliziert, oder? Wir freuen uns, wenn Du innn.it in Deinem Netzwerk teilst."
              />
            </div>
          </div>
        </div>
      </section>

      {/* Download document */}
      <section>
        {!isSubmitted && documentExportOptions.download && (
          <div
            className="container mb-3">
            <button
              className="btn btn-primary"
              onClick={handleSubmit}
              ref={btnRef}
            >
              <FontAwesomeIcon className="icon icon-left text-white" icon={faDownload} />
              <span>Unterschriftenliste selbst ausdrucken</span>
            </button>
          </div>
        )}
        {isSubmitted && "download" == document.exportType && <ConfirmationNotice document={document} initiative={initiative} />}
      </section>

      {/* Send document by post */}
      <section>
        {!isSubmitted && documentExportOptions.download && documentExportOptions.form && (
          <div className="container mb-3">
            <button
              className="btn btn-outline"
              onClick={() => setIsFormCollapsed(prev => !prev)}
            >
              <FontAwesomeIcon className="icon icon-left text-primary" icon={isFormCollapsed ? faPlus : faMinus} />
              <span>Ich habe keinen Drucker. Was nun?</span>
            </button>
          </div>
        )}

        {
          (
            !isSubmitted ||
            (document && "download" == document.exportType)
          ) &&
          <div className={`bg-secondary collapse py-5 ${isFormCollapsed ? "" : "show"}`} id="AddressForm">
            <div className="container">
              <h2 className={`text-primary ${!documentExportOptions.form ? "" : "d-none"}`}>
                Fordere hier Deine Liste an:
              </h2>
              <div className="row">
                <div className="col col-md-6">
                  <DocumentPostForm
                    createDocument={createDocument}
                    exportType={documentExportOptions.form}
                    firstFieldRef={firstFieldRef}
                    signer={signer}
                  />
                </div>
              </div>
            </div>
          </div>
        }
        {isSubmitted && "download" != document.exportType && (
          <ConfirmationNotice
            document={document}
            initiative={initiative}
          />
        )}
      </section>
    </main>
  );
};

const ConfirmationNotice = ({ document, initiative }) => {
  const history = useHistory();
  const { trackEvent } = useMatomo();

  const messages = {
    download: {
      created: {
        title: "Bitte bestätige Deine E-Mail-Adresse.",
        content: "Wir haben Dir eine E-Mail mit einem Bestätigungslink zugesendet. Klicke einfach auf den Link in Deinem Postfach, anschließend kannst Du Deine Unterschriftenliste herunterladen und ausdrucken.",
      },
      verified: {
        title: "Deine Unterschriftenliste wird nun heruntergeladen.",
        content: `Falls Du die Unterschriftenliste noch einmal herunterladen möchtest kannst Du das jederzeit auf der Seite der Initiative “${initiative.title}” tun.`
      }
    },
    post: {
      created: {
        title: "Bitte bestätige Deine E-Mail-Adresse.",
        content: "Wir haben Dir eine E-Mail mit einem Bestätigungslink zugesendet. Klicke einfach auf den Link in Deinem Postfach und anschließend senden wir die Unterschriftenliste an Deine Postadresse."
      },
      verified: {
        title: "Vielen Dank! Deine Unterschriftenliste wird bald bei Dir eintreffen!",
      }
    },
    verifiedDownload: {
    }
  }

  const goToDonationPage = () => {
    history.push({
      pathname: `/${initiative.name}/donation`,
      state: location.state,
    });

    window.scrollTo(0, 0);

    trackEvent({
      category: "document",
      action: "startDonation",
    });
  }

  return (
    <section className="bg-secondary mb-3 py-4">
      <div className="container d-flex">
        <FontAwesomeIcon className="me-4 text-primary" icon={faEnvelope} size="4x" />
        <div>
          <div className="row">
            <div className="col col-md-9">
              <h4 className="text-primary">
                {messages?.[document?.exportType]?.[document?.status]?.title}
              </h4>
              <p>
                {messages?.[document?.exportType]?.[document?.status]?.content}
              </p>
              <button className="btn btn-outline" type="submit" onClick={goToDonationPage}>
                Weiter
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
