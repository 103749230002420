import { faBullseyeArrow, faEuroSign, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { faBoxHeart } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { isWithinInterval } from "date-fns";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import getDonationUrl from "../helpers/get-donation-url";
import { useMatomo } from "@datapunt/matomo-tracker-react";

export const isDecember2023 = () => {
  const now = new Date();
  const start = new Date("2023-12-01T00:00:00.000+01:00");
  const end = new Date("2024-01-01T00:00:00.000+01:00");

  const isDecember2023 = isWithinInterval(now, { start, end });
  return isDecember2023;
};

export const DecemberChallenge2023Context = createContext();

export const DecemberChallengeBanner2023 = ({ signable }) => {
  // Tracking
  const { trackEvent } = useMatomo();

  useEffect( () => {
    if (!signable.id)
      return; 

    trackEvent({
      category: "DecemberChallengeBanner2023",
      action: "bannerLoaded",
    });
  }, [signable, trackEvent]);


  // Used by close animation
  const [bannerHeight, setBannerHeight] = useState(0);
  const bannerRef = useRef();
  const [displayBanner, setDisplayBanner] = useContext(DecemberChallenge2023Context);

  useEffect(() => {
    const handleResize = () => {
      let bannerHeight = "auto";
      if (!displayBanner) {
        bannerHeight = 0;
      } else if (bannerRef.current) {
        bannerHeight = bannerRef.current.scrollHeight;
      }
      setBannerHeight(bannerHeight);
    };

    setTimeout(() => handleResize(), 1000);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [bannerRef, displayBanner, setBannerHeight]);

  // Progress bar
  const [progress, setProgress] = useState(0);
  const goal = 100000;

  useEffect(() => {
    axios("https://docs.google.com/spreadsheets/d/e/2PACX-1vQxT9uJwY6F1fabUEizo1GGrfPgl3w-nEzlADjOgxvW8JV3Zv3BBWvuk-nK9q1GgiN7R21ogXgPLSqe/pub?gid=0&single=true&output=csv").then((response) => {
      setProgress(response.data);
    }).catch(() => null);
  }, []);

  // Form
  const [amount, setAmount] = useState(50);
  const [customAmount, setCustomAmount] = useState("");
  const customAmountInputRef = useRef();

  const handleAmountChange = ({ target }) => {
    setAmount(target.value);

    if ("custom" == target.value) {
      customAmountInputRef.current?.focus();
    }
  };

  const handleCustomAmountChange = ({ target }) => {
    if ("custom" == amount) {
      setCustomAmount(target.value);
    }
  };

  const handleCustomAmountInputFocus = () => {
    setAmount("custom");
  };

  const FormButton = () => {
    const finalAmount = "custom" === amount ? customAmount : amount;

    return (
      <div className="d-grid gap-2">
        <a
          className="decemberChallengeBanner2023-form-button"
          href={getDonationUrl("dezember-challenge", "BannerDecember2023", signable?.id) + `&amount=${finalAmount}`}
          onClick={() => {
            trackEvent({
              category: "DecemberChallengeBanner2023",
              action: "donationStarted",
            });
          }}
          rel="noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon icon={faBoxHeart} fixedWidth />
          Jetzt unterstützen
        </a>
      </div>
    );
  };

  return displayBanner ? (
    <section className="decemberChallengeBanner2023" id="decemberChallengeBanner2023" ref={bannerRef} style={{ height: bannerHeight }}>
      <div className="container">
        <div className="row py-3 align-items-center">
          <div className="col-12 d-flex justify-content-end">
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                setDisplayBanner(false);
                trackEvent({
                  category: "DecemberChallengeBanner2023",
                  action: "bannerClosed",
                });
              }}
            >
              <FontAwesomeIcon
                className="text-petition-primary"
                fixedWidth
                icon={faTimes}
                size="2x"
              />
            </button>
          </div>
          <div className="col-12 col-md-6 col-xl-5 offset-xl-1">
            <p className="decemberChallengeBanner2023-title">Veränderung ist möglich.</p>
            <p className="decemberChallengeBanner2023-subtitle">Aber nur, wenn Du mitmachst!</p>
            <p>Auf innn.it engagieren sich Tausende Menschen wie Du für eine bessere Welt. Beteiligung ist heute dringender denn je! 2023 hat uns hart getroffen und die Anzahl der Förder:innen schrumpft von Monat zu Monat. Hilfst Du uns, die Verluste im Dezember auszugleichen?</p>
          </div>
          <div className="col-12 col-md-6 col-xl-4 offset-xl-1">
            <div className="d-none d-md-block">
              <form className="decemberChallengeBanner2023-form">
                <fieldset className="decemberChallengeBanner2023-form-options row align-items-center">
                  <div className="form-check col-3">
                    <input
                      checked={10 == amount}
                      className="form-check-input"
                      id="amount1"
                      name="amount"
                      onChange={handleAmountChange}
                      type="radio"
                      value={10}
                    />
                    <label className="form-check-label" htmlFor="amount1">10€</label>
                  </div>
                  <div className="form-check col-3">
                    <input
                      checked={25 == amount}
                      className="form-check-input"
                      id="amount2"
                      name="amount"
                      onChange={handleAmountChange}
                      type="radio"
                      value={25}
                    />
                    <label className="form-check-label" htmlFor="amount2">25€</label>
                  </div>
                  <div className="form-check col-3">
                    <input
                      checked={50 == amount}
                      className="form-check-input"
                      id="amount3"
                      name="amount"
                      onChange={handleAmountChange}
                      type="radio"
                      value={50}
                    />
                    <label className="form-check-label" htmlFor="amount3">50€</label>
                  </div>
                  <div className="form-check col-3">
                    <input
                      checked={100 == amount}
                      className="form-check-input"
                      id="amount4"
                      name="amount"
                      onChange={handleAmountChange}
                      type="radio"
                      value={100}
                    />
                    <label className="form-check-label" htmlFor="amount4">100€</label>
                  </div>
                  <div className="form-check col-3">
                    <input
                      checked={200 == amount}
                      className="form-check-input"
                      id="amount5"
                      name="amount"
                      onChange={handleAmountChange}
                      type="radio"
                      value={200}
                    />
                    <label className="form-check-label" htmlFor="amount5">200€</label>
                  </div>
                  <div className="col-9">
                    <div className="input-group">
                      <div className="input-group-text">
                        <input
                          checked={"custom" == amount}
                          className="form-check-input mt-0"
                          id="amount_custom"
                          name="amount"
                          onChange={handleAmountChange}
                          type="radio"
                          value="custom"
                          aria-label="Wähl diese Option für einen anderen Betrag"
                        />
                      </div>
                      <input
                        aria-label="Anderer Betrag"
                        className="form-control decemberChallengeBanner2023-form-input"
                        onChange={handleCustomAmountChange}
                        onFocus={handleCustomAmountInputFocus}
                        placeholder="Anderer Betrag"
                        ref={customAmountInputRef}
                        type="text"
                        value={customAmount}
                      />
                      <div className="decemberChallengeBanner2023-form-input-icon">
                        <FontAwesomeIcon icon={faEuroSign} fixedWidth size="lg" />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <FormButton />
              </form>
            </div>
            <div className="d-md-none">
              <FormButton />
            </div>
          </div>
          {progress < goal && <div className="col-12 col-xl-10 col-xl-5 offset-xl-1">

            <div className="decemberChallengeBanner2023-progress-container">
              <div className="decemberChallengeBanner2023-progress">

                <div className="decemberChallengeBanner2023-progress-text">

                  <div className="decemberChallengeBanner2023-progress-left">
                    <div className="decemberChallengeBanner2023-progress-left-icon">
                      <span className="decemberChallengeBanner2023-progress-number">
                        <FontAwesomeIcon fixedWidth icon={faBoxHeart} />
                        {new Intl.NumberFormat("de").format(progress)}€
                      </span>
                    </div>
                  </div>

                  <div className="decemberChallengeBanner2023-progress-right">
                    <small>Spendenziel:</small>
                    <span className="decemberChallengeBanner2023-progress-number">
                      <FontAwesomeIcon fixedWidth icon={faBullseyeArrow} />
                      {new Intl.NumberFormat("de").format(goal)}€
                    </span>
                  </div>

                </div>

                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={100 * progress / goal}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{width: (100 * progress / goal) + "%"}}
                  />
                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </section>
  ) : null;
};
