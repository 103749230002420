import React, { useState, useEffect, useRef } from "react";
import api from "../../../helpers/api";
import SuccessMessage from "./SuccessMessage";
import { Modal } from "bootstrap";

const ChangeEmailAddress = ({ user }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState({});

  const modalRef = useRef();

  const handleSubmit = async (e) => {
    setError({...error, email: false});

    e.preventDefault();

    if (!validateEmail()) {
      return;
    };

    try {
      const modal = Modal.getOrCreateInstance(modalRef.current);
      await api.updateUser(user.email, {email: email});
      if (modal) modal.show();
    } catch (error) {
      console.log("could not update", {error});
    }
  };

  const validateEmail = () => {
    let isValid = true;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    if (email === "" || !email.match(emailRegex)) {
      setError({...error, email: "Bitte gib eine gültige E-Mail ein"});
      isValid = false;
    };

    return isValid;
  };


  return (
    <>
      <div className="col-md-5 col-12 pt-2 mt-5" style={{backgroundColor: "var(--bs-gray-1)"}}>
        <h4 className="mt-3">E-Mail Adresse ändern</h4>
        <p className="mt-3 pe-md-5 pe-0">Wir senden Dir eine E-Mail mit einem Bestätigungslink zu</p>
        <div className="mb-4">
          <label className="mb-2 text-black" htmlFor="email">
            Neue E-Mail Adresse
          </label>
          <input
            className={`form-control${error.email ? " is-invalid" : ""}`}
            id="email"
            name="email"
            onChange={(e)=> setEmail(e.target.value)}
            placeholder="lily@gmail.com"
            type="text"
          />
          {error.email && (
          <span className="form-text invalid-feedback">{error.email}</span>
        )}
        </div>
        <div className="mb-4 mt-md-4">
          <button
            className="btn btn-sm btn-primary px-4"
            onClick={handleSubmit}
            type="submit"
          >
            E-Mail Adresse ändern
          </button>
        </div>
      </div>
      <SuccessMessage type={"email"} modalRef={modalRef} />
    </>
  );
};


export default ChangeEmailAddress;