import { Modal } from "bootstrap";

const hideModal = (modalId) => {
  const modalElement = document.getElementById(modalId);
  const modal = Modal.getOrCreateInstance(modalElement);
  if (modal) modal.hide();
}

const showModal = (modalId) => {
  const modalElement = document.getElementById(modalId);
  const modal = Modal.getOrCreateInstance(modalElement);
  if (modal) modal.show();
}

export { hideModal, showModal };
