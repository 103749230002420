import React from "react";
import PropTypes from "prop-types";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import * as ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import Tip from "../../../assets/elements/tip.js";
import useToolTip from "../../../custom-hooks/use-tool-tip";

const StepThree = ({ formErrors, handleChange, initiative }) => {
  const { hideTip, showTip, showTipBox } = useToolTip();

  const CONTENTS = [
    {
      bold: "Das Problem:",
      text: `Starte mit dem Missstand, dem Skandal - je emotionaler, desto eher werden Menschen Deinen Petitionstext zuende lesen.`,
    },
    {
      bold: "Deine Forderung:",
      text: `Platziere Deine Forderung möglichst weit oben im Text, 
      damit Leser:innen schnell erfahren, was Dein Lösungsvorschlag ist.`,
    },
    {
      bold: "Deine persönliche Geschichte:",
      text: `Auch wenn Du nicht direkt von dem Problem betroffen bist,
      interessieren sich Leser:innen & Medien für deine persönlichen Beweggründe die Petition zu starten.`,
    },
    {
      bold: "Warum wendest du dich an die ausgewählten Entscheider:innen",
      text: "und wie können diese das Problem beheben?",
    },
    {
      bold: "Gibt es Beispiele",
      text: `aus anderen Ländern / Regionen, in denen mit dem Problem besser umgegangen wird? 
      Führe diese Beispiele gern auf, um den Leser:innen Hoffnung zu geben, dass der Missstand tatsächlich behoben werden kann.`
    }
  ];

  const editorConfiguration = {
    toolbar: [
      "heading",
      "undo",
      "redo",
      "bold",
      "italic",
      "blockQuote",
    ],
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
  };

  return (
    <>
      <div className="mb-5">
        <div className="align-items-md-center d-flex flex-column flex-md-row mb-3 position-relative">
          <label className="h3 me-3 mb-md-0 mb-3 text-primary"> Der Petitionstext:</label>
          <Tip
            label="Tipps"
          >
            <h4 className="mb-2">Dein Petitionstext sollte die folgenden Bausteine beinhalten:</h4>
              {
                CONTENTS.map((content, index) => (
                  <ul key={index}>
                    <li className="mb-2">
                      <strong>{content.bold}</strong>{" "}{content.text}
                    </li>
                  </ul>
                ))
              }
          </Tip>
        </div>
        <div className="mb-4">
          Erkläre in Deinem Petitionstext, was das Problem ist und was Du forderst.
          Vergiss nicht, relevante Quellen anzugeben, wenn du Tatsachenbehauptungen aufstellst.
        </div>
        <div
          className={`rounded-3 overflow-hidden mb-3 ${formErrors.content
              ? "border border-danger"
              : "border border-gray-1"
            }`}
        >
          <CKEditor
            editor={ClassicEditor}
            data={initiative.content}
            config={editorConfiguration}
            onChange={(_event, editor) => {
              const data = editor.getData();
              handleChange({
                target: { name: "content", value: data },
              });
            }}
          />
        </div>
        <span className={`d-block text-end ${formErrors.content ? "text-danger" : " "}`}>
          Bitte schreibe mindestens 1000 Zeichen.
        </span>
      </div>
    </>
  );
};

StepThree.propTypes = {
  formErrors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  initiative: PropTypes.object,
};

export default StepThree;
