import React from "react";

const InitiativeStage = (props) => {
  const initiative = props.initiative;
  
  const fetchInitiativeStage = () => {
    switch (initiative.stage) {
      case "support":
        return "Unterstützung";
        break;

      case "first-collection":
        return "Sammlung";
        break;

      case "second-collection":
        return "2. Sammlung";
        break;

      default:
        return false;
        break;
    }
  };

  const initiativeStage = fetchInitiativeStage();

  return (
    initiativeStage && (<span className="status status-underline">{initiativeStage}</span>)
  );
};

export default InitiativeStage;
