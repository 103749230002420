import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import api from "../../../helpers/api";
import DeleteSignatureModal from "./DeleteSignatureModal";
import InitiativeCard from "../../../components/InitiativeCard";


const Signatures = ({ isActive, pathName }) => {
  const [petitions, setPetitions] = useState([]);
  const [signatures, setSignatures] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
  const [selectedPetition, setSelectedPetition] = useState();

  const limit = 9;
  const type = "petition";

  const loadMore = async () => {
    getSignatures(limit, offset, type);
  };

  const getSignatures = async (limit, offset, type) => {
    if (isLoading) {
      return false;
    };

    setIsLoading(true);

    await api.getSignatures(
      { signer: "me", limit: limit, offset: offset, type: type },
      (_status, data) => {
        if (data === undefined || data.length < 3) {
          setShowLoadMoreButton(false);
        };

        if (Array.isArray(data)) {
          setSignatures([...signatures, ...data]);
        };

        setOffset(offset + data.length);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    (async () => {
      try {
        await getSignatures(limit, offset, type);
      } catch (error) {
        if (error.response) {
          // Handle error
        };
      }
    })();
  }, []);


  useEffect(() => {
    const getSignedInitiatives = async () => {
      const petitionIds = signatures.reduce((result, signature) => {
        if (signature.signableModel = "Petition") {
          return [...result, signature.signableId]
        } else {
          return result;
        }
      }, []);

      if (petitionIds.length > 0) {
        await api.getInitiatives({ type: "petition", ids: petitionIds },
          (_status, data) => {
            if (Array.isArray(data)) {
              setPetitions(data);
            };
          }
        );
      };
    };

    getSignedInitiatives();

  }, [signatures]);

  const activeClass= isActive ? "active show" : "";
  const selectedSignature = signatures.find((signature) => signature.signableId === selectedPetition?.id)

  return (
    <div
      className={`card-holder tab-pane fade ${activeClass}`}
      id="initiativeTabsContentSignatures"
      aria-labelledby="initiativeTabsNavSignatures"
    >
      {petitions.length > 0 ?
        <div className="card-container">
          <div className="row">
            {petitions &&
              Array.isArray(petitions) &&
              petitions.map((petition, index) => (
                <div className="col-12 col-md-4 mb-4" key={index}>
                  <InitiativeCard
                    initiative={petition}
                    pathName={pathName}
                    handleClick={() => setSelectedPetition(petition)}
                  />
                </div>
              ))}
          </div>
          <DeleteSignatureModal
            petitionName={selectedPetition?.name}
            signatureId={selectedSignature?.id}
            setSignatures={setSignatures}
          />
        </div>
        :
        <div className="col-md-6 col-12">
          <h4>Unterschreibe jetzt Deine erste Petition, um Veränderung zu bewirken:
            <Link
              className="text-primary"
              to={{ pathname: "/", state: { scrollTo: "petitions" } }}
            >
              {" "}Petitionen durchsuchen
            </Link>
          </h4>
        </div>
      }
      {signatures.length > 0 && showLoadMoreButton && (
        <div className="d-flex mb-4 justify-content-center">
          {isLoading ? (
            <FontAwesomeIcon
              className="text-secondary"
              icon={faSpinner}
              size="2x"
              spin
            />
          ) : (
            <button className="btn btn-primary" onClick={loadMore}>
              Mehr laden
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Signatures;