import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMapMarkerAlt, faTimes } from "@fortawesome/pro-solid-svg-icons";
import api from "../../../helpers/api"

const InitiativesList = () => {
  const PATHS = {
    initiative: "initiatives",
    petition: "petitions",
  };

  const MESSAGES = {
    initiative: {
      draft: "Dein Entwurf wurde gesichert.",
      publish: "Deine Initiative ist online!",
      saved: "Deine Änderungen wurden gesichert",
      back: "Zurück zur Initiative",
      new: "Neue Initiative erstellen",
    },
    petition: {
      draft: "Dein Entwurf wurde gesichert.",
      publish: "Deine Petition ist online!",
      saved: "Deine Änderungen wurden gesichert",
      back: "Zurück zum Bearbeiten",
      new: "Neue Petition erstellen",
    },
  };

  const [initiatives, setInitiatives] = useState([]);
  const [open, setOpen] = useState(true);

  let location = useLocation();
  const initiative = location.state?.initiative;
  const isPetition = "petition" == initiative?.type;

  const paths = location.pathname?.split("/");
  const modelPath = paths[paths.length - 1];
  let model = Object?.keys(PATHS).find(key => PATHS[key] === modelPath);

  if (!model) {
    model = "initiative";
  }

  const path = PATHS?.[model];
  const messages = MESSAGES?.[model];

  useEffect(() => {
    (async () => {
      try {
        await api.getInitiatives(
          {
            limit: 0,
            source: "dashboard",
            status: ["publish", "draft"],
            type: model,
          },
          (_status, data) => {
            setInitiatives(data);
          }
        );
      } catch (error) {
        if (error.response) {
          // Handle error
        }
      }
    })();
  }, []);

  return (
    <main className="dashboard">
      {initiative?.id && open && (
        <section className={`mb-5 mt-n5 ${isPetition ? "bg-petition-secondary" : "bg-secondary"}`}>
          <div className="container d-flex justify-content-md-between align-items-md-center py-4">
            <FontAwesomeIcon className={`icon icon-left ${isPetition ? "text-white" : "text-success"}`} icon={faCheck} size="lg" />
            <div className="d-flex justify-content-md-between flex-wrap flex-md-nowrap flex-grow-1">
              <span className="h3 mb-md-0 mb-5 text-primary">
                {messages[location.state.action]}
              </span>
              <Link
                to={`/dashboard/${model}/${initiative.id}`}
                className="bg-white btn btn-sm btn-outline-primary me-md-5"
              >
                {messages.back}
              </Link>
            </div>
            <span onClick={(e) => setOpen(false)}>
              <FontAwesomeIcon className={`${isPetition ? "text-petition-primary" : "text-primary"}`} icon={faTimes} size="lg" />
            </span>
          </div>
        </section>
      )}

      <section className="container-narrow">
        <div className="d-flex justify-content-end">
          <Link to={`/dashboard/${path}/new`} className="btn btn-primary">
            {messages.new}
          </Link>
        </div>
      </section>

      <section className="container-narrow mt-5">
        {initiatives && Array.isArray(initiatives) && (
          <div>
            {initiatives.map((initiative, index) => (
              <div className="d-flex mb-3" key={index}>
                <div className="me-auto">
                  <Link to={`/dashboard/${path}/${initiative.id}`}>
                    {initiative.title}
                  </Link>
                  <br />
                  <small className="text-muted">{initiative.name} &bull; {initiative.id}</small>
                </div>
                <div>
                  <Link to={`/dashboard/${path}/${initiative.id}/updates`}>
                    Updates
                  </Link>
                </div>
                {"initiative" == model && <div>
                  <FontAwesomeIcon className="icon icon-left text-primary" icon={faMapMarkerAlt} />
                  <span>
                    {typeof initiative.location === "object" ?
                      initiative.location?.name
                      :
                      initiative.location}
                  </span>
                </div>}
                <div className="col-md-3">
                  {initiative.status == "publish" ? <Link to={`/dashboard/${path}/${initiative.id}`} className="btn btn-outline-primary w-75">
                    Bearbeiten
                  </Link>
                    :
                    <button type="button" className="btn btn-outline-primary w-75">
                      Veröffentlichen
                    </button>
                  }
                </div>
              </div>
            ))}
          </div>
        )}
      </section>
    </main>
  );
};

export default InitiativesList;
