import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import CardImageLg from "../styles/images/card-bg-lg.svg";
import InitiativeCard from "../components/InitiativeCard";

const activeInitiative = {
  title: "Active initiative",
  location: "Berlin",
  type: "volksinitiative",
  stage: "first-collection",
  subtitle: "This is just a regular initiative",
}

const inactiveSuccessInitiative = {
  title: "Inactive initiative that was a success",
  location: "Hamburg",
  initiativeType: "buergerbegehren",
  stage: "first-collection",
  subtitle: "This is just a regular initiative",
  status: "success",
}

const activeInitiativePitch = {
  title: "Active Initiative pitch",
  location: "Munich",
  initiativeType: "volksinitiative",
  stage: "support",
  subtitle: "This is just a regular initiative",
}

const endDate = new Date();
endDate.setDate(endDate.getDate() + 3);

const activeCountdownInitiative = {
  title: "Active initiative with countdown",
  location: "Cologne",
  initiativeType: "volksinitiative",
  stage: "second-collection",
  subtitle: "This is just a regular initiative",
  endDate: endDate.toISOString(),

}

const activeHoverInitiative = {
  title: "Active initiative with hover",
  location: "Frankfurt am Main",
  type: "volksinitiative",
  stage: "first-collection",
  subtitle: "This is just a regular initiative",
}

const Card = () => {
  return (
    <section className="mt-5">
      <h2>Cards</h2>
      <div className="row">
        <div className="col-12 col-md-4 mb-6">
          <h4>Active initiative</h4>
          <InitiativeCard initiative={activeInitiative} />
        </div>

        <div className="col-12 col-md-4 mb-6">
          <h4>Inactive initiative that was a success</h4>
          <InitiativeCard initiative={inactiveSuccessInitiative} />
        </div>

        <div className="col-12 col-md-4 mb-6">
          <h4>Active Initiative-pitch</h4>
          <InitiativeCard initiative={activeInitiativePitch} />
        </div>

        <div className="col-12 col-md-4 mb-6">
          <h4>Active initiative with countdown</h4>
          <InitiativeCard initiative={activeCountdownInitiative} />
        </div>

        <div className="col-12 col-md-4 mb-6">
          <h4>Active initiative with hover</h4>
          <InitiativeCard initiative={activeHoverInitiative} />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-8">
          <div className="mt-5">
            <h3>Update</h3>
            <h6>Text and Image</h6>
            <div className="card card-large">
              <img
                src={CardImageLg}
                className="card-img-top"
                alt="placeholder"
              />
              <div className="card-body">
                <h4 className="card-title">Title goes here</h4>
                <p className="card-text">
                  Here is a message that's going to be two lines, so we will see
                  the text as it wraps... <a href="#href">Learn more</a>
                </p>
                <div className="card-footer">
                  <FontAwesomeIcon className="text-secondary" icon={faFacebook} />
                  <div className="d-flex flex-column">
                    <a href="#href">This is a source</a>
                    <a href="#href">26.02.2021</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-8">
          <div className="mt-5">
            <h6>Text</h6>
            <div className="card card-large">
              <div className="card-body">
                <h4 className="card-title">Title goes here</h4>
                <p className="card-text">
                  Here is a message that's going to be two lines, so we will see
                  the text as it wraps... <a href="#href">Learn more</a>
                </p>
                <div className="card-footer">
                  <FontAwesomeIcon className="text-secondary" icon={faFacebook} />
                  <div>
                    <a href="#href">This is a source</a>
                    <a href="#href">26.02.2021</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Card;
