import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";

function Chips() {
  return (
    <>
      <h3>Chips</h3>
      <div className="row">
        <div className="col-md-4">
          <h6>Text</h6>
          <div className="chip">
            <div className="chip-label">Text chip</div>
          </div>
        </div>
        <div className="col-md-4">
          <h6>Icon</h6>
          <div className="chip">
              <FontAwesomeIcon className="icon text-gray-2" icon={faMapMarkerAlt} />
              <div className="chip-label">Icon chip</div>
          </div>
        </div>
        <div className="col-md-4">
          <h6>Person</h6>
          <div className="chip">
            <img className="chip-img" src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="Person" />
            <div className="chip-label">Name Surname</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Chips;
