import React, { useContext, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import countries from "i18n-iso-countries";
import deLocale from "i18n-iso-countries/langs/de.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import illustrationNewsletter from "../styles/images/illustration-newsletter.svg";
import Newsletter from "./Newsletter";
import { useLocationFields } from "../custom-hooks/use-location-field";

const FIELD_LABELS = {
  email: "E-Mail (erforderlich)",
  lastName: "Nachname",
  firstName: "Vorname",
};

const REQUIRED_FIELDS = {
  any: ["email"],
};

countries.registerLocale(deLocale);
const countryObj = countries.getNames("de", { select: "official" });

const countryOptions = Object.entries(countryObj).map(([key, value]) => {
  return {
    label: value,
    value: key
  };
});

const NewsletterWidget = () => {
  const modalRef = useRef();
  let history = useHistory();

  useEffect(() => {
    const handleNewsletterWidgetClose = () => {
      history.push(location.pathname);
    }
    
    const handleNewsletterWidgetOpen = () => {
      history.push({hash: "newsletter"});
    }
  
    const modal = modalRef.current;

    modal.addEventListener("hidden.bs.modal", handleNewsletterWidgetClose);
    modal.addEventListener("shown.bs.modal", handleNewsletterWidgetOpen);

    return () => {
      modal.removeEventListener("hidden.bs.modal", handleNewsletterWidgetClose);
      modal.removeEventListener("shown.bs.modal", handleNewsletterWidgetOpen);
    };
  }, []);

  return (
    <div
      className="modal fade newsletter-widget"
      id="newsletterWidget"
      tabIndex="-1"
      aria-labelledby="newsletterWidgetLabel"
      aria-hidden="true"
      ref={modalRef}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header container">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <FontAwesomeIcon
                className="text-petition-primary"
                fixedWidth
                icon={faTimes}
                size="2x"
              />
            </button>
          </div>
          <section className="modal-body container p-0">
            <div className="row m-auto">
              <Aside />
              <Form />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

const Aside = () => {
  return (
    <aside className="col-md-6 col-12">
      <h2 className="text-petition-primary" id="newsletterWidgetLabel">Bleib informiert!</h2>
      <p className="h4 text-black">
        Abonniere unseren Newsletter und wir halten Dich auf dem Laufenden.
      </p>
      <div className="d-md-block d-none">
        <img
          src={illustrationNewsletter}
          className="img-fluid"
          alt="illustration-newsletter"
        />
      </div>

      <p className="small text-black mt-4 d-none d-md-block">
        Du kannst Dich jederzeit wieder vom Newsletter abmelden. Weitere
        Informationen findest Du in unseren{" "}
        <Link className="text-petition-primary" to="/datenschutz">Datenschutzhinweisen</Link>.
      </p>
    </aside>
  );
};

const Form = () => {
  const getRequiredFields = () => {
    const globalRequiredFields = REQUIRED_FIELDS?.any;
    return [...globalRequiredFields];
  };

  const requiredFields = getRequiredFields();

  return (
    <div className="col-md-6 col-12">
      <Newsletter
        NewsletterFields={FormInput}
        requiredFields={requiredFields}
        source="header"
        successMessage={<SuccessMessage />}
      />
    </div>
  );
};

const FormInput = ({
  label,
  error,
  subscriber,
  buttonClass,
  buttonValue,
  handleChange,
  ...rest
}) => {
  const firstFieldRef = useRef();

  const {
    errors: locationErrors,
    locationProperties,
    locations,
    handleLocationProperties,
    setPostalCode,
    showLocationDropdown,
  } = useLocationFields();

  useEffect(() => {
    firstFieldRef.current.focus();
  }, []);

  useEffect(() => {
    handleChange({
      target: { name: "country", value: locationProperties.country },
    });  

    handleChange({
      target: { name: "location", value: locationProperties.location?.id || null },
    });  

    handleChange({
      target: { name: "postalCode", value: locationProperties.postalCode?.id || null },
    });  
  }, [locationProperties]);

  return (
    <>
      {label && <label>{label}</label>}
      <TextField
        field="email"
        errors={error}
        handleChange={handleChange}
        firstFieldRef={firstFieldRef}
      />
      <TextField field="firstName" errors={error} handleChange={handleChange} />
      <TextField field="lastName" errors={error} handleChange={handleChange} />

      <div className="dropdown mb-4">
        <label
          className="text-petition-primary"
          htmlFor="country"
        >
          Land
        </label>
        <button
          className="
            form-control
            dropdown-toggle
            text-start
            d-flex
            justify-content-between
            align-items-center"
          type="button"
          id="countryDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {countryObj[locationProperties?.country]}
        </button>
        <ul
          className="dropdown-menu overflow-auto"
          style={{ maxHeight: "250px" }}
          aria-labelledby="locationDropdown"
        >
          {countryOptions?.map((country) => (
            <li
              className="dropdown-item"
              key={country.value}
              onClick={() => {
                handleLocationProperties({country: country.value});
              }}
            >
              {country.label}
            </li>
          ))}
        </ul>
      </div>
      {"DE" == locationProperties?.country && (
        <div className="row">
          <div className="col-4 mb-4">
            <label className="text-petition-primary" htmlFor="postalCode">
              Postleitzahl
            </label>
            <input
              className={`form-control form-control-petition${locationErrors.location ? " is-invalid" : ""}`}
              id="postalCode"
              name="postalCode"
              onChange={(e) => {
                setPostalCode(e.target.value);
              }}
              placeholder="12345"
              type="text"
            />
            {(locationErrors.location || error.location) && (
              <span
                className="form-text invalid-feedback"
                style={{ minWidth: "18rem" }}
              >
                {locationErrors.location || error.location}
              </span>
            )}
          </div>
          <div className="col-8 mb-4">
            <label className="text-petition-primary" htmlFor="location">
              Ort
            </label>
            {locations.length > 1 ? (
              <div className="dropdown">
                <button
                  className={`btn btn-outline-primary dropdown-toggle ${
                    showLocationDropdown ? " show" : ""
                  }`}
                  type="button"
                  id="locationDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {locationProperties?.location?.name || " Ort"}
                </button>
                <ul
                  className={`dropdown-menu ${
                    showLocationDropdown ? "show" : ""
                  }`}
                  aria-labelledby="locationDropdown"
                >
                  {locations.map((location) => (
                    <li
                      className="dropdown-item"
                      key={location.id}
                      onClick={() => {
                        handleLocationProperties({country: locationProperties?.country, location})
                      }}
                    >
                      {location.name}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <input
                className={`form-control${
                  locationErrors.location || error.location ? " is-invalid" : ""
                }`}
                value={locationProperties?.location?.name || ""}
                id="location"
                name="location"
                placeholder="Ort"
                readOnly
                type="text"
              />
            )}
          </div>
        </div>
      )}

      <div>
        <button className="btn btn-petition-primary col-md-6 mb-4" type="submit">
          <span>Jetzt abonnieren</span>
        </button>
      </div>

      <p className="d-md-none small text-black mt-3">
        Du kannst Dich jederzeit wieder vom Newsletter abmelden. Weitere
        Informationen findest Du in unseren{" "}
        <Link to="/datenschutz">Datenschutzhinweisen</Link>.
      </p>
    </>
  );
};

const SuccessMessage = () => {
  return (
    <span className="d-flex mb-3">
      <FontAwesomeIcon
        className="icon icon-left text-petition-primary"
        icon={faEnvelope}
        size="4x"
      />
      <span className="ps-4">
        <h4 className="text-petition-primary mb-3">
          Vielen Dank! Bitte bestätige Deine E-Mail-Adresse.
        </h4>
        <span className="text-black">
          Wir haben Dir eine E-Mail mit einem Bestätigungslink zugesendet.
          Klicke einfach auf den Link in Deinem Postfach um zukünftig
          Neuigkeiten zu erhalten.
        </span>
      </span>
    </span>
  );
};

const TextField = ({ errors, field, handleChange, firstFieldRef }) => {
  if (!field) {
    return false;
  }

  return (
    <div className="mb-4">
      <label className="text-petition-primary" htmlFor={field}>
        {FIELD_LABELS?.[field]}
      </label>
      <input
        ref={firstFieldRef}
        className={`form-control form-control-petition${errors[field] ? " is-invalid" : ""}`}
        id={field}
        name={field}
        onChange={handleChange}
        placeholder={FIELD_LABELS?.[field]}
        type="text"
      />
      {errors[field] && (
        <span className="form-text invalid-feedback">{errors[field]}</span>
      )}
    </div>
  );
};

export default NewsletterWidget;
