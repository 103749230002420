import React, { useContext, useEffect, useState } from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../helpers/api";
import donationInitiativeImage from "../../../styles/images/banners/donation.svg";
import donationPetitionImage from "../../../styles/images/donate.svg";
import getDonationUrl from "../../../helpers/get-donation-url";
//import Pagination from "../assets/elements/pagination";
import { ThemeContext } from "../../../ThemeContext";
import { getMe } from "../../../redux/users";

const Donation = () => {
  const CONTENT = {
    initiative: {
      donationImage: donationInitiativeImage,
      title: "Briefmarken für direkte Demokratie",
      content: (
        <>
          <p class="fw-bold">
            Hilf uns unsere Portokasse aufzufüllen damit noch mehr Menschen die
            Chance haben mitzuwirken. Können Sie mit einer Spende mithelfen, die
            Briefmarken für die nächsten Wochen zu finanzieren?
          </p>
          <p>
            Die Teilnahme bei innn.it ist sowohl für die Initiativen als auch
            für die Nutzer:innen kostenlos. Auch die Portokosten werden komplett
            von Change.org e.V. übernommen, dem Trägerverein der Plattform. Hier
            kannst du den Change.org e.V. mit einer Spende unterstützen:
          </p>
        </>
      ),
    },
    petition: {
      donationImage: donationPetitionImage,
      title: "Engagier Dich gemeinsam mit uns!",
      content: (
        <>
          Schon ein kleiner regelmäßiger Beitrag hat{" "}
          <strong>große Wirkung</strong>. Mit Deiner Förderung trägst Du dazu
          bei, dass wir innn.it kontinuierlich ausbauen, Initiator*innen von
          Petitionen begleiten und Kampagnen Gehör verschaffen können!{" "}
          <strong>Schließt Du Dich uns an?</strong>
        </>
      ),
    },
  };

  const location = useLocation();
  let history = useHistory();
  let { id } = useParams();
  const { trackEvent } = useMatomo();
  const [initiative, setInitiative] = useState({});
  const { setTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (location.state?.initiative) {
      setInitiative(location.state.initiative);
      setTheme(location.state.initiative.type || "petition");
      return;
    }

    api.getInitiative(id, (_status, data) => {
      setInitiative(data);
      setTheme(data.type || "petition");
    });
  }, [location.state, id]);

  const handleClick = (event, url) => {
    event.preventDefault();
    trackEvent({
      category: initiative.type,
      action: "goToDonation",
    });
    window.open(url, "_blank");
  };

  const goToSharePage = () => {
    history.push({
      pathname: `/${id}/share`,
      state: location.state,
    });

    window.scrollTo(0, 0);

    trackEvent({
      category: initiative.type,
      action: "startShare",
    });
  };

  return (
    <div className="donation">
      <DonationBanner initiative={initiative} />
      {/* <div className="donation-header">
        <div className="donation-header-container d-flex justify-content-between">
          <h3 className="donation-header-text text-secondary">
            Grundeinkommen für alle
          </h3>
          <Icon name={"icon-close"} />
        </div>
      </div> */}
      <div className="container pt-5">
        {/* <div className="pagination-container d-flex  justify-content-center">
          <Pagination />
        </div> */}
        <div className="row">
          <div className="col-12 col-md-6">
            <h2 className="text-primary pb-5">
              {CONTENT?.[initiative.type]?.title}
            </h2>
            <div>{CONTENT?.[initiative.type]?.content}</div>
            <p className="pt-5 d-grid gap-2 col-md-8">
              <a
                className="btn btn-primary"
                id="button-addon2"
                onClick={(event) =>
                  handleClick(
                    event,
                    getDonationUrl(
                      initiative.type,
                      "post_signature",
                      initiative.id
                    )
                  )
                }
              >
                Zur Spendenseite
              </a>
              <button
                className="btn btn-outline"
                type="submit"
                onClick={goToSharePage}
              >
                Weiter ohne Spende
              </button>
            </p>
          </div>
          <div className="align-items-center col-12 col-md-6 d-flex justify-content-center">
            <img
              className="img-fluid"
              src={CONTENT?.[initiative.type]?.donationImage}
              alt="innn.it-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const DonationBanner = ({ initiative }) => {
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!users.me) {
      dispatch(getMe());
    }
  }, [users.me]);
  
  const DONATION_BANNER_MESSAGES = {
    ended: `Wir haben Dir eine E-Mail mit einem Bestätigungslink zugesendet. 
      Klicke einfach auf den Link in Deinem Postfach um zukünftig Neuigkeiten 
      zu erhalten.`,
    support: `Wir haben Dir eine E-Mail mit einem Bestätigungslink zugesendet. 
      Klicke einfach auf den Link in Deinem Postfach und wir informieren dich 
      sobald es mit der Unterschriftensammlung los geht.`,
    petition: `Wir haben Dir eine E-Mail mit einem Bestätigungslink zugesendet. 
      Klicke einfach auf den Link in Deinem Postfach um Deine Unterschrift 
      zu bestätigen. Andernfalls wird sie nicht gezählt.`,
  };

  const [isBannerOpen, setIsBannerOpen] = useState(true);
  const isEnded =
    "ended" === initiative?.initiativeStatuses?.[initiative?.stage];
  const isSupport = "support" === initiative?.stage;
  const isPetition = "petition" === initiative.type;

  const displayBannerMessage = (isEnded || isSupport || isPetition) && !users?.me?.id;

  return (
    <>
      {displayBannerMessage && isBannerOpen && (
        <section className="bg-secondary py-4">
          <div className="container d-flex flex-md-nowrap flex-wrap">
            <div
              className="d-flex flex-grow-1 justify-content-end mb-2 pe-md-2 order-md-2"
              onClick={() => setIsBannerOpen(false)}
              role="button"
            >
              <FontAwesomeIcon
                className="text-primary"
                icon={faTimes}
                size="lg"
              />
            </div>
            <div className="d-flex ps-md-4">
              <FontAwesomeIcon
                className="text-primary"
                icon={faEnvelope}
                size="4x"
              />
              <div className="mx-md-5 px-md-0 px-4">
                <h3 className="mb-3 text-primary ">
                  Vielen Dank! Bitte bestätige Deine E-Mail-Adresse.
                </h3>
                {isEnded && <p>{DONATION_BANNER_MESSAGES.ended}</p>}
                {isSupport && !isEnded && (
                  <p>{DONATION_BANNER_MESSAGES.support}</p>
                )}
                {isPetition && (
                  <p>{DONATION_BANNER_MESSAGES.petition}</p>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Donation;
