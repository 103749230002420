import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import api from "../../helpers/api";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch} from "react-redux";
import { getMe } from "../../redux/users";
import queryString from "query-string";

const Login = () => {
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [isTokenSent, setIsTokenSent] = useState();

  const dispatch = useDispatch(); //this hook gives us dispatch method
  const history = useHistory();
  let location = useLocation();

  const handleChange = (e) => {
    setEmail(e.target.value);
  }

  useEffect(() => {
    dispatch(getMe( async (err)=> {
      if(!err){
        history.push("/dashboard");
      }
      else {
        const {email: loginEmail, token, url} = queryString.parse(location.search);
        if(loginEmail && token){
          await login(loginEmail, token, url)
        }
      }
    }));
  }, [])
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    
    try{
      await api.sendToken({email: email, url: location.state?.referrer}, (_status, _data) => {
        // display message
        setIsTokenSent(true);
        e.target.reset();
      })
    } catch(error) {
      setError("Bitte gib eine gültige E-Mail-Adresse ein");
    }
  };

  const login = async (loginEmail, token, url) => {
    setError(null);
    
    try{
      await api.login({email: loginEmail, token}, (_status, _data) => {
        // dispatch get me action to fetch logged in user and save in state
        dispatch(getMe(()=> {
          if(url) {
            history.push(url);
            return;
          }
           history.push("/dashboard");
        }));
       
      })
    } catch(error) {
      setError("Bitte gib eine gültige E-Mail-Adresse ein");
    }
  };

  return (
    <main className="mt-7">
      <section className="login-page">
        <h3>Login</h3>
        <form className="py-4"onSubmit={handleSubmit}>
          <div className="custom-control mb-5">
            <label  className="text-primary">Email</label>
            <input
              type="email"
              className={`form-control ${error ? " is-invalid" : ""}`}
              name="email"
              onChange={handleChange}
              placeholder="me@example.com"
            />
            {error && <span className="form-text invalid-feedback">{error}</span>}
          </div>
          
          <div className="text-end">
            <button type="submit" className="btn btn-primary btn-lg">
            Login
          </button>
          </div>
        </form>
      </section>
      {isTokenSent &&
        <section className="token-notice-container">
          <div className="token-notice">
            <div className="text-center me-4">
              <FontAwesomeIcon aria-hidden="true" className="pe-0 text-primary" icon={faEnvelope} size="4x" />
            </div>
            <div>
              <h4 className="text-primary">Bitte bestätige Deine E-Mail-Adresse.</h4>
              <p className="mb-5">Wir haben Dir eine E-Mail mit einem Bestätigungslink zugesendet. Klicke einfach auf den Link in Deinem Postfach um dich einzuloggen.</p>
            </div>
          </div>
        </section>
      }
    </main>
  );
};

export default Login;
