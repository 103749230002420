import axios from "axios";

class Api {
  constructor() {
    let api = axios.create({
      baseURL: "/api/v1",
      headers: {},
    });
    api.interceptors.response.use(this.handleSuccess, this.handleError);
    this.api = api;
  }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {
    return Promise.reject(error);
  };

  async changePassword(id, params, callback) {
    const response = await this.api.patch(`/users/${id}/change-password`, params);
    return callback ? callback(response.status, response.data) : true;
  }

  async contact(params, callback) {
    const response = await this.api.post("/email/contact", params);
    return callback ? callback(response.status, response.data) : true;
  }

  async createAttachment(body, callback) {
    const response = await this.api.post("/posts/attachments", body);
    return callback ? callback(response.status, response.data) : true;
  }

  async createDocument(params, callback) {
    const response = await this.api.post("/documents", params);
    return callback ? callback(response.status, response.data) : true;
  }

  async getDocument(code, callback) {
    const response = await this.api.get(`/documents/${code}`);
    return callback ? callback(response.status, response.data) : true;
  }

  async getDocuments(params = {}, callback) {
    const response = await this.api.get("/documents",  { params });
    return callback ? callback(response.status, response.data) : true;
  }

  async getDocumentStats(params, callback) {
    const response = await this.api.get("/documents/stats", params);
    return callback ? callback(response.status, response.data) : true;
  }

  async receiveDocument(code, params, callback) {
    const response = await this.api.put(`/documents/${code}/receive`, params);
    return callback ? callback(response.status, response.data) : true;
  }

  async createActor(params, callback) {
    const response = await this.api.post("/posts/actors", params);
    return callback ? callback(response.status, response.data) : true;
  }

  async createInitiative(params, callback) {
    const response = await this.api.post("/posts/initiatives", params);
    return callback ? callback(response.status, response.data) : true;
  }

  async createSubscription(payload, callback) {
    const response = await this.api.post("/email/subscription", payload);
    return callback ? callback(response.status, response.data) : true;
  }

  async createSignature(params, callback) {
    const response = await this.api.post("/documents/signatures", params);
    return callback ? callback(response.status, response.data) : true;
  }

  async deleteSignature(id, callback) {
    const response = await this.api.delete(`/documents/signatures/${id}`);
    return callback ? callback(response.status, response.data) : true;
  }

  async getSignature(id, callback) {
    const response = await this.api.get(`/documents/signatures/${id}`);
    return callback ? callback(response.status, response.data) : true;
  }

  async createTemplate(params, callback) {
    const response = await this.api.post("/documents/templates", params);
    return callback ? callback(response.status, response.data) : true;
  }

  async createUpdate(initiativeId, params, callback) {
    const response = await this.api.post(
      `/posts/initiatives/${initiativeId}/updates`,
      params
    );
    return callback ? callback(response.status, response.data) : true;
  }

  async createUser(params, callback) {
    const response = await this.api.post("/users", params);
    return callback ? callback(response.status, response.data) : true;
  }

  async downloadDocument(id, token, callback) {
    const response = await this.api.get(`/documents/${id}/download?token=${token}`, { responseType: "blob" });
    return callback ? callback(response.status, response.data) : true;
  }

  async downloadFile(url, callback) {
    const response = await this.api.get(url, { responseType: "blob" });
    return callback ? callback(response.status, response.data) : true;
  }

  async getActor(id, callback) {
    const response = await this.api.get(`/posts/actors/${id}`);
    return callback ? callback(response.status, response.data) : true;
  }

  async getActors(params = {}, callback) {
    const response = await this.api.get("/posts/actors", { params });
    return callback ? callback(response.status, response.data) : true;
  }

  async getAttachments(params = {}, callback) {
    const response = await this.api.get("/posts/attachments", { params });
    return callback ? callback(response.status, response.data) : true;
  }

  async getCapabilities(callback) {
    const response = await this.api.get("/users/capabilities");
    return callback ? callback(response.status, response.data) : true;
  }

  async getInitiative(id, callback) {
    const response = await this.api.get(`/posts/initiatives/${id}`);
    return callback ? callback(response.status, response.data) : true;
  }

  async getInitiatives(params = {}, callback) {
    const response = await this.api.get("/posts/initiatives", { params });
    return callback ? callback(response.status, response.data) : true;
  }

  async getLocation(id, callback) {
    const response = await this.api.get(`/posts/locations/${id}`);
    return callback ? callback(response.status, response.data) : true;
  }

  async getLocations(params, callback) {
    const response = await this.api.get("/posts/locations", { params });
    return callback ? callback(response.status, response.data) : true;
  }

  async getPostalCode(id, callback) {
    const response = await this.api.get(`/posts/postal-codes/${id}`);
    return callback ? callback(response.status, response.data) : true;
  }

  async getSignatures(params = {}, callback) {
    const response = await this.api.get("/documents/signatures", { params });
    return callback ? callback(response.status, response.data) : true;
  }

  async getTags(params = {}, callback) {
    const response = await this.api.get("/posts/tags", { params });
    return callback ? callback(response.status, response.data) : true;
  }

  async getUpdate(initiativeId, id, callback) {
    const response = await this.api.get(
      `/posts/initiatives/${initiativeId}/updates/${id}`
    );
    return callback ? callback(response.status, response.data) : true;
  }

  async getUpdates(initiativeId, params = {}, callback) {
    const response = await this.api.get(
      `/posts/initiatives/${initiativeId}/updates`,
      { params }
    );
    return callback ? callback(response.status, response.data) : true;
  }

  async getUser(id, callback) {
    const response = await this.api.get(`/users/${id}`);
    return callback ? callback(response.status, response.data) : true;
  }

  async login(params, callback) {
    const response = await this.api.post("/users/login", params);
    return callback ? callback(response.status, response.data) : true;
  }

  async logout(callback) {
    const response = await this.api.post("/users/logout");
    return callback ? callback(response.status, response.data) : true;
  }

  async me(callback) {
    const response = await this.api.get("/users/me");
    return callback ? callback(response.status, response.data) : true;
  }

  async resetPassword(id, params = {}, callback) {
    const response = await this.api.post("/users/me/reset-password", params);
    return callback ? callback(response.status, response.data) : true;
  }

  async sendToken(params = {}, callback) {
    const response = await this.api.post("/users/token", params);
    return callback ? callback(response.status, response.data) : true;
  }

  async setPassword(id, params = {}, callback) {
    const response = await this.api.post(`/users/${id}/set-password`, params);
    return callback ? callback(response.status, response.data) : true;
  }

  async subscribeNewsletter(payload, callback) {
    const response = await this.api.post("/email/newsletter", payload);
    return callback ? callback(response.status, response.data) : true;
  }

  async updateActor(id, params = {}, callback) {
    const response = await this.api.patch(`/posts/actors/${id}`, params);
    return callback ? callback(response.status, response.data) : true;
  }

  async updateInitiative(id, params = {}, callback) {
    const response = await this.api.patch(`/posts/initiatives/${id}`, params);
    return callback ? callback(response.status, response.data) : true;
  }

  async updateUser(id, params = {}, callback) {
    const response = await this.api.patch(`/users/${id}`, params);
    return callback ? callback(response.status, response.data) : true;
  }

  async updateUpdate(initiativeId, id, params = {}, callback) {
    const response = await this.api.put(
      `/posts/initiatives/${initiativeId}/updates/${id}`,
      params
    );
    return callback ? callback(response.status, response.data) : true;
  }

  async upsertActor(id, params, callback) {
    if (id) {
      return this.updateActor(id, params, callback);
    } else {
      return this.createActor(params, callback);
    }
  }

  async upsertInitiative(id, params, callback) {
    if (id) {
      return this.updateInitiative(id, params, callback);
    } else {
      return this.createInitiative(params, callback);
    }
  }

  async upsertUpdate(initiativeId, id, params, callback) {
    if (initiativeId && id) {
      return this.updateUpdate(initiativeId, id, params, callback);
    } else {
      return this.createUpdate(initiativeId, params, callback);
    }
  }

  async verifyDocument(id, params, callback) {
    const response = await this.api.post(`/documents/${id}/verify`, params);
    return callback ? callback(response.status, response.data) : true;
  }

  async verifySignature(id, params, callback) {
    const response = await this.api.post(`/documents/signatures/${id}/verify`, params);
    return callback ? callback(response.status, response.data) : true;
  }
}

export default new Api;
