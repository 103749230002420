import React, { useState } from "react";
import PropTypes from "prop-types";
import Attachment from "../../../components/Attachment";
import Tip from "../../../assets/elements/tip.js"
import TipBox from "../../../assets/elements/tipBox";
import useToolTip from "../../../custom-hooks/use-tool-tip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

const StepFive = ({ formErrors, handleImageUpload, initiative, isSaving }) => {
  const {showTipBox, hideTip, showTip} = useToolTip();

  const CONTENTS = [
    { url: "https://pixabay.com/",
    },
    { url: "https://www.pexels.com/de-de/",
    },
    { url: "https://unsplash.com/",
    }
  ];

  const CONTENTS_2 = [
    { item: `Dein Petitionsbild ist das erste, was Menschen sehen. Es sollte daher auffällig 
      sein und Deine Forderung visuell unterstreichen`,
    },
    { item: "Achte darauf, dass Dein Bild auch auf kleinen Bildschirmen gut zu erkennen ist",
    },
    { item: "Vermeinde (zu viel) Text im Bild.",
    },
    { item: "Stell sicher, dass Du das Bild veröffentlichen darfst",
    }
  ];


  return (
    <>
      <div className="mb-5">
        <div className="align-items-md-center d-flex flex-column flex-md-row mb-3 position-relative">
          <label className="h3 me-3 mb-md-0 mb-3 text-primary">Petitionsbild</label>
          <Tip
            label="Tipps"
          >
            {
              CONTENTS_2.map((content, index) => (
                <ul key={index}> 
                  <li className="mb-3">{content.item}</li>
                </ul>    
              ))
            }
            <h4>Kostenlose Fotos findest du z.B. auf: </h4>
            {
              CONTENTS.map((content, index) => (
                <ul key={index}> 
                  <li className="mb-2">{content.url}</li>
                </ul>    
              ))
            } 
          </Tip>
        </div>
        <div className="mb-4">
          Wähle ein Bild, dass Dein Petitionsthema widerspiegelt. Je emotionaler das Bild, 
          desto mehr Aufmerksamkeit wirst du für Deine Petition bekommen.
        </div>
        <div className="mb-4"> Mögliche Dateiformate sind .jpg und .png.</div>
        <label
          className={`btn btn-lg btn-outline-secondary text-primary position-relative ${isSaving ? "disabled" : ""}`}
          htmlFor="actual-btn"
        >
          <span className={isSaving ? "invisible" : ""}>
            {(initiative.featuredImage) ? "Neues Bild hochladen" : "Hochladen"}
          </span>
          <span
            className={`position-absolute top-50 start-50 translate-middle ${!isSaving ? "invisible" : ""}`}
          >
            <FontAwesomeIcon icon={faSpinner} spin aria-label="Petitionsbild wird hochgeladen..." />
          </span>
        </label>
        <input
          type="file"
          className={`form-control mb-3${formErrors.featuredImage ? " is-invalid" : ""}`}
          disabled={isSaving ? true : false}
          id="actual-btn"
          name="image"
          onChange={handleImageUpload}
          placeholder="Hochladen"
          hidden
        />
        {formErrors.featuredImage && (
          <span className="form-text invalid-feedback">Bitte füge ein Bild hinzu.</span>
        )}

        {initiative?.featuredImage && "object" === typeof initiative?.featuredImage &&
          <Attachment
            alt={initiative.title}
            attachment={initiative.featuredImage}
            className="w-100 mt-5"
            size="lg"
          />
        }
      </div>
    </>
  );
};

StepFive.propTypes = {
  formErrors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  initiative: PropTypes.object,
};

export default StepFive;