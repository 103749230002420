import React, { useEffect, useRef, useState } from "react";

const Gauge = (props) => {
  let {arc, label, level, limit} = props;
  const ref = useRef();

  const [count, setCount] = useState(0);
  let levelCount = 0;
  const [levelRotate, setLevelRotate] = useState("auto");

  const duration = 5000;
  const incrementDuration = 99;

  let observer;

  useEffect(() => {
    if (!level || observer) {
      return false;
    }
    observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          if (0 == limit) {
            limit = level;
          }

          const levelPercentage = (level / limit) * 100;
          const levelRotate = (levelPercentage / 100) / 2;
          const levelRotateCss = (-(0.5 - (levelRotate < 0.5 ? levelRotate : 0.5))) + "turn";
          setLevelRotate(levelRotateCss);
          const steps = duration / incrementDuration;
          const increment = level / steps;

          const updateCount = () => {
            levelCount = levelCount + increment;
            setCount(Math.floor(levelCount));

            if (levelCount <= level) {
              setTimeout(updateCount, incrementDuration);
            } else {
              setCount(level);
            }
          }
          
          updateCount();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [props]);

  return (
    <div className="gauge mb-3" ref={ref}>
      {arc && (
        <div className="gauge-arc">
          <div className="gauge-arc-fill" style={{transform: `rotate(${levelRotate})`}}></div>
        </div>
      )}

      <div className="gauge-value">{new Intl.NumberFormat("de-DE").format(
              count
            )}</div>
      {label && (
        <div className="gauge-label">{label}</div>
      )}
    </div>
  );
};

export default Gauge;
