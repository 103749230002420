import React from "react";

const Indicators = () => {
  return (
    <section className="mt-5">
      <h3>Indicators</h3>
      <div className="d-flex mt-5">
        <div className="me-5">
          <h6>Dark</h6>
          <ol className="indicators indicators-dark mt-3">
            <li className="indicator active"></li>
            <li className="indicator"></li>
            <li className="indicator"></li>
            <li className="indicator"></li>
          </ol>
        </div>
        <div>
          <h6>Light</h6>
          <ol className="indicators indicators-light mt-3">
            <li className="indicator active"></li>
            <li className="indicator"></li>
            <li className="indicator"></li>
            <li className="indicator"></li>
          </ol>
        </div>
      </div>
    </section>
  );
};

export default Indicators;
