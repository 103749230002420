import React from "react";

const Colors = () => {
  return (
    <section className="mt-5">
      <h2>Colors</h2>
      <h3>Petitions</h3>
      <div className="row">
        <div className="col col-3">
          <div className="ratio ratio-1x1 mb-2">
            <div className="bg-petition-primary h-100 img-thumbnail round-3 w-100"></div>
          </div>
          Primary
          <br />
          <code>petition-primary</code>
        </div>
        <div className="col col-3">
          <div className="ratio ratio-1x1 mb-2">
            <div className="bg-petition-secondary h-100 img-thumbnail round-3 w-100"></div>
          </div>
          Secondary
          <br />
          <code>petition-secondary</code>
        </div>
      </div>

      <h3>Initiatives</h3>
      <div className="row">
        <div className="col col-3">
          <div className="ratio ratio-1x1 mb-2">
            <div className="bg-initiative-primary h-100 img-thumbnail round-3 w-100"></div>
          </div>
          Primary
          <br />
          <code>initiative-primary</code>
        </div>
        <div className="col col-3">
          <div className="ratio ratio-1x1 mb-2">
            <div className="bg-initiative-secondary h-100 img-thumbnail round-3 w-100"></div>
          </div>
          Secondary
          <br />
          <code>initiative-secondary</code>
        </div>
      </div>

      <h3>Styles</h3>
      <div className="row">
        <div className="col col-3">
          <div className="ratio ratio-1x1">
            <div className="bg-danger h-100 img-thumbnail round-3 w-100"></div>
          </div>
          Danger
          <br />
          <code>danger</code>
        </div>
        <div className="col col-3">
          <div className="ratio ratio-1x1 mb-2">
            <div className="bg-success h-100 img-thumbnail round-3 w-100"></div>
          </div>
          Success
          <br />
          <code>sucess</code>
        </div>
      </div>

      <h3>Grays</h3>
      <div className="row">
        <div className="col col-3">
          <div className="ratio ratio-1x1 mb-2">
            <div className="bg-white h-100 img-thumbnail round-3 w-100"></div>
          </div>
          White
          <br />
          <code>white</code>
        </div>
        <div className="col col-3">
          <div className="ratio ratio-1x1 mb-2">
            <div className="h-100 img-thumbnail round-3 w-100" style={{backgroundColor: "var(--bs-gray-1)"}}></div>
          </div>
          Gray 1
          <br />
          <code>gray-1</code>
        </div>
        <div className="col col-3">
          <div className="ratio ratio-1x1 mb-2">
            <div className="h-100 img-thumbnail round-3 w-100" style={{backgroundColor: "var(--bs-gray-2)"}}></div>
          </div>
          Gray 2
          <br />
          <code>gray-2</code>
        </div>
        <div className="col col-3">
          <div className="ratio ratio-1x1 mb-2">
            <div className="h-100 img-thumbnail round-3 w-100" style={{backgroundColor: "var(--bs-gray-3)"}}></div>
          </div>
          Gray 3
          <br />
          <code>gray-3</code>
        </div>
        <div className="col col-3">
          <div className="ratio ratio-1x1 mb-2">
            <div className="h-100 img-thumbnail round-3 w-100" style={{backgroundColor: "var(--bs-gray-4)"}}></div>
          </div>
          Gray 4
          <br />
          <code>gray-4</code>
        </div>
        <div className="col col-3">
          <div className="ratio ratio-1x1 mb-2">
            <div className="h-100 img-thumbnail round-3 w-100" style={{backgroundColor: "var(--bs-gray-5)"}}></div>
          </div>
          Gray 5
          <br />
          <code>gray-5</code>
        </div>
        <div className="col col-3">
          <div className="ratio ratio-1x1 mb-2">
            <div className="bg-black h-100 img-thumbnail round-3 w-100"></div>
          </div>
          Black
          <br />
          <code>black</code>
        </div>
      </div>
    </section>
  );
};

export default Colors;
