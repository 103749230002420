import React from "react";
import SocialShareIcon from "../../components/SocialShareIcon";

const InitiativeShare = ({ shareUrl, initiative }) => {

  return (
    <div>
      <div className="h3 text-secondary">
        Teilen führt zu mehr Unterschriften
      </div>
      <p className="mb-4">
        Informiere Deine Familie und Freund:innen. So hilfst Du, mehr
        Unterschriften zu sammeln!
      </p>
      <SocialShareIcon 
        background="primary"
        color="white"
        shareUrl={shareUrl} 
        initiative={initiative} 
      />
    </div>
  );
};

export default InitiativeShare;
