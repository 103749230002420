import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { faComment, faPenClip, faTableColumns, faUsers } from "@fortawesome/pro-solid-svg-icons";
import logoWhite from "../styles/images/logo-white.svg";
import { ThemeContext } from "../ThemeContext";

const DashboardSidebar = () => {
  const { setTheme } = useContext(ThemeContext);
  const navItems = [
    { icon: faTableColumns, label: "Dashboard", url: "/" },
    { icon: faComment, label: "Initiativen", url: "/initiatives" },
    { icon: faPenClip, label: "Petitionen", url: "/petitions" },
    { icon: faUsers, label: "Agierende", url: "/actors" },
    { icon: faCalendar, label: "Tracking", url: "/tracking" },
    // { icon: "icon-calendar-outline", label: "Medien", url: "/" },
  ];

  const footerItems = [
    // { icon: "icon-settings-outline", label: "Einstellungen", url: "/" },
    // { icon: "icon-settings-outline", label: "Logout", url: "/" },
  ];

  useEffect(() => {
    setTheme("initiative");
  }, []);

  const Logo = () => {
    return (
      <Link
        className="mb-5"
        to="/"
      >
        <img
          alt="innn.it"
          src={logoWhite}
          style={{height: "4rem"}}
        />
      </Link>
    );
  };

  const User = () => {
    return (
      <div className="fw-bold mb-4 text-white">
        {/* Lily Musterfrau */}
      </div>
    );
  };

  const getItems = (items) => {
    return items.map((item) => {
      return (
        <li key={item.label} className="align-items-center d-flex mx-n4">
          <Link className="bg-hover-secondary d-block h-100 px-4 py-3 text-hover-primary text-white w-100" to={`/dashboard${item.url}`}>
            <FontAwesomeIcon className="icon icon-left text-secondary text-hover-primary" icon={item.icon} />
            <span>
              {item.label}
            </span>
          </Link>
        </li>
      );
    });
  };

  const NavLinks = () => {
    return (
      <ul className="mb-auto p-0 w-100">
        {getItems(navItems)}
      </ul>
    );
  };
  
  const FooterLinks = () => {
    return (
      <ul className="p-0 w-100">
        {getItems(footerItems)}
      </ul>
    );
  };

  const BackButton = () => {
    return (
      <Link
        className="btn btn-white text-primary mt-4"
        to="/"
      >
        Back to Home
      </Link>
    );
  };

  return (
    <nav className="align-items-start bg-primary d-flex fixed-top flex-column p-4 vh-100" style={{width: "256px"}}>
      <Logo />
      <User />
      <NavLinks />
      <FooterLinks />
      <BackButton />
    </nav>
  );
};

export default DashboardSidebar;
