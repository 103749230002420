import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/pro-regular-svg-icons";
import DatePickerField from "../components/DatePickerField";

const Fields = () => {
  const [date, setDate] = useState("");

  return (
    <section className="mt-5">
      <h2>Fields</h2>
      <div className="row">
        <div className="col-md-6">
          <form className="form petition">
            <h3>Petition</h3>
            <div className="mb-5">
              <h6>No label</h6>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  disabled={true}
                  placeholder="Disabled"
                />
              </div>
            </div>

            <div className="mb-5">
              <h6>Label</h6>
              <div className="mb-3">
                <label className="form-label">Lorem ipsum</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Dolor sit amet"
                />
              </div>
              <div className="mb-3">
                <label className="disabled form-label">Lorem ipsum</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={true}
                  placeholder="Dolor sit amet"
                />
              </div>
            </div>

            <div className="mb-5">
              <h6>Helper</h6>
              <label className="form-label">Lorem ipsum</label>
              <input
                type="text"
                className="form-control"
                placeholder="Dolor sit amet"
              />
              <div className="form-text">Lorem ipsum dolor site amet</div>
            </div>

            <div className="mb-5">
              <h6>Icons</h6>
              <div className="form-icon form-icon-left mb-3">
                <FontAwesomeIcon icon={faBookmark} />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Placeholder"
                />
              </div>
              <div className="form-icon form-icon-right mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Placeholder"
                />
                <FontAwesomeIcon icon={faBookmark} />
              </div>
              <div className="form-icon form-icon-left form-icon-right mb-3">
                <FontAwesomeIcon icon={faBookmark} />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Placeholder"
                />
                <FontAwesomeIcon icon={faBookmark} />
              </div>
            </div>

            <div className="mb-5">
              <h6>Textarea</h6>
              <label className="form-label">Labeled field with caption</label>
              <textarea
                type="text"
                className="form-control"
                placeholder="Placeholder"
              />
              <div className="form-text">Helper text</div>
            </div>
          </form>
        </div>
        <div className="col-md-6">
          <h3>Initiative</h3>
          <form className="form initiative">
            <div className="mb-5">
              <h6>No label</h6>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  disabled={true}
                  placeholder="Disabled"
                />
              </div>
            </div>

            <div className="mb-5">
              <h6>Label</h6>
              <div className="mb-3">
                <label className="form-label">Lorem ipsum</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Dolor sit amet"
                />
              </div>
              <div className="mb-3">
                <label className="disabled form-label">Lorem ipsum</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={true}
                  placeholder="Dolor sit amet"
                />
              </div>
            </div>

            <div className="mb-5">
              <h6>Helper</h6>
              <label className="form-label">Lorem ipsum</label>
              <input
                type="text"
                className="form-control"
                placeholder="Dolor sit amet"
              />
              <div className="form-text">Lorem ipsum dolor site amet</div>
            </div>

            <div className="mb-5">
              <h6>Icons</h6>
              <div className="form-icon form-icon-left mb-3">
                <FontAwesomeIcon icon={faBookmark} />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Placeholder"
                />
              </div>
              <div className="form-icon form-icon-right mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Placeholder"
                />
                <FontAwesomeIcon icon={faBookmark} />
              </div>
              <div className="form-icon form-icon-left form-icon-right mb-3">
                <FontAwesomeIcon icon={faBookmark} />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Placeholder"
                />
                <FontAwesomeIcon icon={faBookmark} />
              </div>
            </div>

            <div className="mb-5">
              <h6>Textarea</h6>
              <label className="form-label">Labeled field with caption</label>
              <textarea
                type="text"
                className="form-control"
                placeholder="Placeholder"
              />
              <div className="form-text">Helper text</div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Fields;
