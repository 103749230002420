import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "./Modal"
import TextField from "./TextField";
import { FIELD_LABELS, FIELD_PLACEHOLDERS } from "./content";
import { hideModal, showModal } from "./modalFunctions";
import api from "../../helpers/api";
import validateForm from "../../custom-hooks/use-user-validation";

const ResetPasswordRequestModal = ({
  handleChange,
  user
}) => {
  const fieldsetRef = useRef();
  const [formErrors, setFormErrors] = useState({});
  const history = useHistory();

  const goBack = () => {
    hideModal("resetPasswordRequestModal");
    showModal("signInModal");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});

    const [isValid, errors] = validateForm("resetPasswordRequestModal", user);

    if (!isValid) {
      setFormErrors(errors);
      return;
    }

    if (fieldsetRef.current) {
      fieldsetRef.current.setAttribute("disabled", true);
    }

    try {
      await api.sendToken(
        { email: user.email, type: "password", url: history.location.pathname },
        (_status, _data) => {
          hideModal("resetPasswordRequestModal");
          showModal("resetPasswordRequestMessageModal");
        }
      );
    } catch (error) {
    } finally {
      if (fieldsetRef.current) {
        fieldsetRef.current.removeAttribute("disabled");
      }
    }
  };

  return (
    <Modal id="resetPasswordRequestModal">
      <form onSubmit={(e) => handleSubmit(e)} noValidate>
        <fieldset ref={fieldsetRef}>
          <div className="mb-5">
            <h3 className="mb-5">Passwort zurück setzen</h3>
            <p>
              Wir schicken Dir einen Link an Deine E-Mail Adresse mit dem Du Dein
              Passwort zurück setzen kannst.
            </p>
            <TextField
              field="email"
              name="email"
              type="text"
              id="email"
              formErrors={formErrors}
              fieldLabels={FIELD_LABELS}
              handleChange={handleChange}
              placeholder={FIELD_PLACEHOLDERS}
              user={user}
            />
          </div>

          <div className="d-flex justify-content-between mb-4">
            <button
              className="btn btn-lg btn-outline-primary"
              onClick={goBack}
              type="button"
            >
              Zurück
            </button>
            <button
              className="btn btn-lg btn-primary"
              onClick={handleSubmit}
              type="submit"
            >
              Weiter
            </button>
          </div>
        </fieldset>
      </form>
    </Modal>
  )
}

export default ResetPasswordRequestModal;
