import React from "react";
import PropTypes from "prop-types";

const Attachment = (props) => {
  const imageMimeTypes = [
    'image/gif',
    'image/jpeg',
    'image/png',
  ];
  const attachment = props.attachment;

  if (!imageMimeTypes.includes(attachment.mimeType)) {
    return false;
  }

  const imageProperties = {};

  if (!(attachment.urls || attachment.url)) {
    return false;
  }

  if (attachment.urls) {
    const size = (attachment.urls[props.size] ? props.size : "original");
    const webpSize = (attachment.urls[`${props.size}Webp`] ? `${props.size}Webp` : "webp");

    if (attachment.urls) {
      imageProperties.size = {
        src: attachment.urls[size],
        type: attachment.mimeType,
      }

      imageProperties.webp = {
        src: attachment.urls[webpSize],
        type: "image/webp",
      }
    }
  }

  if (attachment.url) {
    imageProperties.size = {
      src: attachment.url,
      type: attachment.mimeType,
    }
  }

  return (
    <picture>
      {imageProperties.webp && (
        <>
          <source srcSet={imageProperties.webp.src} type={imageProperties.webp.type} />
        </>
      )}
      {imageProperties.size && (
        <>
          <source srcSet={imageProperties.size.src} type={imageProperties.size.type} />
          <img alt={props.alt} className={props.className} src={imageProperties.size.src} />
        </>
      )}
    </picture>
  );
};

Attachment.defaultProps = {
  className: "",
  size: "original",
};

Attachment.propTypes = {
  attachment: PropTypes.object.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
  alt: PropTypes.string,
};

export default Attachment;
