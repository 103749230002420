import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import InitiativeSignatureForm from "./InitiativeSignatureForm";

const InitiativeSignatureModal = (props) => {
  return (
    <div
      className="modal fade"
      id="signatureFormModal"
      tabIndex="-1"
      aria-labelledby="signatureFormTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-gradient-vertical">
          <div className="modal-body p-0">
            <button className="btn-close" data-bs-dismiss="modal">
              <FontAwesomeIcon className="text-secondary" icon={faTimes} />
            </button>
            <InitiativeSignatureForm {...props} modal={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitiativeSignatureModal;
