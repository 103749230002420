import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import api from "../../helpers/api";
import DonationBanner from "../../components/DonationBanner";
import FaqImage from "../../styles/images/faq-image.svg";
import FeaturesImage from "../../styles/images/icon-box.svg";
import HeadContentContext from "../../HeadContentContext";
import hero from "../../styles/images/banners/home.svg";
import InitiativeCard from "../../components/InitiativeCard";
import IconBox from "../../components/IconBox";
import iconBoxImageMegaphone from "../../styles/images/icon-box/megaphone.svg";
import iconBoxImageSignature from "../../styles/images/icon-box/signature.svg";
import iconBoxImageDonation from "../../styles/images/icon-box/donation.svg";
import iconBoxImageSecurity from "../../styles/images/icon-box/security.svg";
import { ThemeContext } from "../../ThemeContext";

const Initiatives = () => {
  const faqRef = useRef();
  const initiativesRef = useRef();
  const location = useLocation();
  const { setHeadContent } = useContext(HeadContentContext);
  const { setTheme } = useContext(ThemeContext);

  useEffect(() => {
    setTheme("initiative");
    setHeadContent({ pageTitle: "Initiativen" });
  }, []);

  useEffect(() => {
    if(location?.state?.scrollTo === "faq"){
      goToFaq();
    }    
  }, [location]);

  const goToFaq = () => {
    faqRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <main className="page-home">
      <Hero initiativesRef={initiativesRef} />
      <IconBoxes />
      <InitiativeList initiativesRef={initiativesRef} />
      <InitiativeBanner />
      <Faq faqRef={faqRef} />
      <section className="container mt-5">
        <DonationBanner
          linkClass="btn btn-gradient btn-block"
          linkLabel="Jetzt spenden"
          source="home_button_bottom"
        />
      </section>
    </main>
  );
};

const Hero = ({ initiativesRef }) => {
  const heroTitle = "Hier kannst Du mitbestimmen!";
  const heroBody =
    "Unterstütze direktdemokratische Initiativen in Deutschland oder starte Deine eigene Initiative";

  const goToInitiatives = () => {
    initiativesRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div className="hero-holder">
      <div className="hero hero-image bg-gradient-horizontal-initiative background">
        <div className="hero-container">
          <div className="hero-row">
            <div className="hero-body content">
              <h1 className="hero-title text-primary">{heroTitle}</h1>
              <p className="h4">{heroBody}</p>
              <div className="hero-button-container">
                <button className="btn btn-primary" onClick={goToInitiatives}>
                  Jetzt mitmachen
                </button>
                <a
                  className="btn btn-outline bg-white"
                  href={`https://changeverein.org/spenden-innnit/?donation_custom_field_2304=home_button`}
                  id="button-addon2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>
                    Spenden <span className="text-danger">&hearts;</span>
                  </span>
                </a>
              </div>
            </div>
            <div className="hero-img">
              <div className="hero-img-holder mt-0">
                <img src={hero} alt="innn.it-img"></img>
              </div>
            </div>
          </div>
        </div>
        <div className="page-home-image page-home-image__initiative"></div>
      </div>
    </div>
  );
};

const IconBoxes = () => {
  const iconBoxImages = [
    iconBoxImageMegaphone,
    iconBoxImageSignature,
    iconBoxImageDonation,
    iconBoxImageSecurity,
  ];

  const iconBoxTitles = [
    "Demokratisch mitbestimmen",
    "Rechtsverbindliche Unterschriften",
    "Zu 100% durch Spenden finanziert",
    "Datenschutz hat höchste Priorität",
  ];

  const iconBoxBodyTexts = [
    "Wir erleichtern das erfolgreiche Durchführen von Bürger*innen- und Volksbegehren.",
    "Erstmalig kannst Du einfacher als bisher rechtsgültig auf Papier unterschreiben.",
    "innn.it wird vom innn.it e.V. mit Sitz in Berlin betrieben.",
    "innn.it ist auf deutschen Servern zu Hause und speichert nur die nötigsten Informationen",
  ];
  return (
    <div className="box-icon">
      <div className="banner banner-features">
        <h2 className="banner-title">Das ist innn.it</h2>
        <div className="banner-container">
          <div className="banner-row">
            {iconBoxTitles.map((title, index) => {
              return (
                <div className="banner-feature" key={title}>
                  <IconBox
                    img={iconBoxImages[index]}
                    imgAlt={title}
                    title={title}
                    text={iconBoxBodyTexts[index]}
                    key={title}
                  ></IconBox>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const InitiativeBanner = () => {
  return (
    <section className="container mt-5">
      <div className="initiative banner-background bg-gradient-horizontal-initiative">
        <div className="align-items-center banner-row flex-row-reverse mx-auto p-md-3 w-75">
          <div className="banner-img text-center pt-5 pt-md-0 d-flex align-items-center">
            <img src={FeaturesImage} alt="icon-for-initiative" />
          </div>

          <div className="banner-body">
            <div className="banner-text p-md-0">
              <h2 className="banner-title">
                Starte jetzt Deine eigene Initiative
              </h2>

              <h5>
                Was willst Du verändern? Sende uns Deine Idee für eine
                Initiative.
              </h5>

              <div className="banner-footer">
                <Link
                  className="btn btn-sm btn-primary btn-block"
                  to="/contact"
                >
                  Kontaktiere uns jetzt
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const InitiativeList = ({ initiativesRef }) => {
  const initiativeStarten = {
    subtitle: "Was willst Du verändern?",
    title: "Hier könnte deine Initiative stehen",
    location: "In Deiner Stadt",
    signatureTarget: 100000,
    type: "contact",
  };

  const [initiatives, setInitiatives] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
  const limit = 18;

  const loadMore = async () => {
    getInitiatives(limit, offset);
  };

  const getInitiatives = async (limit, offset) => {
    if (isLoading) {
      return false;
    }

    setLoading(true);

    await api.getInitiatives(
      { limit: limit, offset: offset },
      (status, data) => {
        if (data === undefined || data.length === 0) {
          setShowLoadMoreButton(false);
        }

        if (Array.isArray(data)) {
          setInitiatives(initiatives.concat(data));
        }

        setOffset(offset + data.length);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    (async () => {
      try {
        await getInitiatives(limit - 1, offset);
      } catch (error) {
        if (error.response) {
          // Handle error
        }
      }
    })();
  }, []);

  return (
    <section className="card-holder" ref={initiativesRef}>
      <div className="container">
        <h1 className="my-5 text-center text-primary">Aktuelle Initiativen</h1>
        <div className="row">
          {initiatives &&
            Array.isArray(initiatives) &&
            initiatives.map((initiative, index) => (
              <div className="col-12 col-md-4 mb-4" key={initiative.id}>
                <InitiativeCard initiative={initiative} />
              </div>
            ))}
          <div className="col-12 col-md-4 mb-4">
            <InitiativeCard
              initiative={initiativeStarten}
              label="Jetzt informieren"
            />
          </div>
        </div>
        {showLoadMoreButton && (
          <div className="d-flex mb-4 justify-content-center">
            {isLoading ? (
              <FontAwesomeIcon
                className="text-secondary"
                icon={faSpinner}
                size="2x"
                spin
              />
            ) : (
              <button className="btn btn-primary" onClick={loadMore}>
                Mehr laden
              </button>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

const Faq = ({faqRef}) => {
  return (
    <section ref={faqRef}>
      <FaqHero />
      <FaqData />
    </section>
  );
}

const FaqData = () => {
  const faqData = [
    {
      question: "Wie unterschreibe ich korrekt auf innn.it?",
      answer: (
        <>
          <p>
            Wer im eigenen Bundesland oder der eigenen Kommune eine
            Volksinitiative oder eine Bürgerbegehren auf innn.it unterzeichnen
            möchte, …
          </p>
          <ul>
            <li>
              druckt die entsprechende Unterschriftenliste aus, füllt die
              benötigten Felder gut leserlich aus und unterschreibt.
            </li>
            <li>druckt zusätzlich noch ein vorfrankiertes Umschlagblatt aus.</li>
            <li>
              faltet zum Versenden die Liste in das Umschlagblatt wie angegeben
              ein, klebt es an der Seite mit Tesa zu und wirft es in den nächsten
              Briefkasten.
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "Kann ich innn.it auch ohne eigenen Drucker nutzen?",
      answer:
        "Derzeit leider noch nicht. Eine entsprechende Funktion wird aber schon in Kürze freigeschaltet",
    },
    {
      question:
        "Können auf innn.it auch Petitionen eingestellt oder unterschrieben werden?",
      answer:
        "Nein, auf innn.it können Petitionen weder eingestellt noch unterschrieben werden. Auf innn.it können ausschließlich Volks- und Bürger:innenbegehren eingestellt und von Nutzer:innen unterschrieben werden.",
    },
    {
      question: "Muss ich mit Kosten rechnen?",
      answer:
        "Die Nutzung von innn.it ist sowohl für Unterzeichnende als auch für Initiativen kostenlos. Auch die Portokosten werden komplett von Change.org e.V., dem Trägerverein von innn.it, übernommen.",
    },
    {
      question: "Wie wird innn.it finanziert?",
      answer:
        "innn.it wird ausschließlich über Spenden finanziert. Das können sowohl Einmalspenden, als auch regelmäßige Spenden von Förder:innen des Change.org e.V. sein.",
    },
    {
      question: "Wie unterstützt innn.it Initiativen und Begehren?",
      answer:
        "Initiativen, die ihr Begehren auf innn.it einstellen, werden von Change.org sowohl während der Unterschriftensammlung als auch in der Phase des Entscheides bei der Öffentlichkeitsarbeit über soziale Medien und Presse unterstützt. Zusätzlich profitieren Initiativen bei innn.it von der großen Kampagnenerfahrung des Change.org-Deutschland-Teams.",
    },
    {
      question: "Welche Initiativen können ihr Begehren auf innn.it einstellen?",
      answer:
        "Alle Initiativen, Vereine und Verbände, die ein Bürger:innen- oder Volksbegehren durchführen, können bei innn.it mitmachen. Initiativen, die mitmachen wollen, melden sich über das entsprechende Kontaktformular und werden bei der Erstellung ihrer Initiative auf innn.it vom erfahrenen Change.org Kampagnenteam begleitet. Ausgeschlossen sind Forderungen, die dem Grundgesetz widersprechen, oder rechtsextreme, sexistische und diskriminierende Forderungen.",
    },
    {
      question: "Wie stelle ich selbst ein Begehren auf innn.it ein?",
      answer: (
        <>
          Sie wollen Ihr Volks- oder Ihr Bürgerbegehren bei innn.it einstellen?
          Dann schreiben sie uns an <a href="mailto:hallo@innn.it">hallo@innn.it</a> und stellen uns kurz
          Ihre Initiative vor. Wir melden uns bei Ihnen und besprechen mit Ihnen
          das weitere Vorgehen. Initiativen, die mitmachen wollen, erstellen dann
          einen Steckbrief mit einem passenden Bild, einer kurzen Beschreibung der
          Initiative, den Kontaktdaten der Initiative und der Unterschriftenliste,
          die wir auf innn.it veröffentlichen.
        </>
      ),
    },
    {
      question: "Wer betreibt innn.it?",
      answer:
        "innn.it wird von dem Verein Change.org e.V. betrieben. Change.org fördert damit den Weg von der Petition zur Entscheidung. Bürger:innen- und Volksbegehren sind ein wichtiges Instrument der demokratischen Mitbestimmung in unserem Land. Dieses Instrument wollen wir weiter verbessern und unterstützen. Wir glauben, dass mehr Referenden auf allen politischen Ebenen essentiell sind, um unsere Demokratie zu stärken und auszubauen. Mit innn.it will der Change.org e.V. Bürgerinnen und Bürger und deren Initiativen bei der Durchführung von Begehren unterstützen und die Teilnahme an ihnen erleichtern.",
    },
    {
      question:
        "Speichert innn.it personenbezogene Daten und wofür werden diese Daten verwendet?",
      answer:
        "Grundsätzlich erfolgt die Benutzung von innn.it ohne Speicherung persönlicher Daten. Nutzer:innen können allerdings mit ihrer E-mail-Adresse den innn.it-Newsletter abonnieren, um über neue Entwicklungen bei innn.it oder bei dem von ihnen unterzeichneten Begehren informiert zu werden. Diese Daten werden ausschließlich auf Servern in Deutschland gespeichert und nur für innn.it verwendet. Darüber hinaus gilt natürlich auch für innn.it die aktuelle Datenschutzgrundverordnung.",
    },
  ];

  return (
    <div className="container pt-3">
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1">
          <div className="accordion accordion-flush mt-5 mb-5" id="faq">
            {faqData.map((faq, index) => {
              return (
                <div key={index} className="accordion-item">
                  <h3 className="accordion-header" id={`#heading${index}`}>
                    <button
                      key={index}
                      className="accordion-button collapsed text-start"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="false"
                      aria-controls={`collapse${index}`}
                    >
                      {faq.question}
                    </button>
                  </h3>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#faq"
                  >
                    <div className="accordion-body">{faq.answer}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

const FaqHero = () => {
  return (
    <div className="hero hero-image pt-5 mb-5">
      <div className="hero-container">
        <div className="hero-row row">
          <div className="col-md-10 offset-md-1">
            <div className="row">
              <div className="hero-body">
                <h1 className="hero-title">Häufig gestellte Fragen</h1>
                <h3 className="hero-subtitle">
                  Hier findest du Antworten auf Fragen rund um Initiativen auf innn.it
                </h3>
              </div>
              <div className="hero-img">
                <div className="hero-img-container">
                  <img src={FaqImage} alt="innn.it-img"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Initiatives;
