import React from "react";
import Gauge from "../components/Gauge";

const Gauges = () => {
  const label= <>Unterschriften<br />auf <span className="text-petition-primary">innn.it</span></>;
  return (
    <section className="col-md-6 col-12 mt-5">
      <h2>Gauge</h2>
      <div className="d-flex flex-wrap justify-content-between">
        <div className="initiative mb-3 mb-md-0">
          <Gauge arc={true} label="Listen angefordert" level="125879" limit="160879" />
        </div>
        <div className="petition">
          <Gauge arc={true} label={label} level="125879" limit="160879" />
        </div>
      </div>
    </section>
  )
}

export default Gauges;
