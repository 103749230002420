import React from "react";

const Step = ({ currentStep, completedSteps, handleNextStep, handlePreviousStep, isSaving, step }) => {
  let className = "page-item";
  const active = step == currentStep;
  const checked = completedSteps.includes(step) && !active;
              
  if (active) {
    className += " active";
  } else if (checked) {
    className += " checked";
  }
  const onClick = step > currentStep ? handleNextStep : handlePreviousStep;

  return (
    <li className={`${className} ${isSaving ? "disabled" : ""}`}>
      <span 
        aria-disabled="true" 
        className="page-link page-link-petition" 
        onClick={(e) => onClick(e, step)}
      >
        {checked ? "" : step}
      </span>
    </li>
  );
};

export default Step;