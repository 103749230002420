import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Modal = (props) => {
  return (
    <div
      className="modal fade"
      id={props.id}
      tabIndex="-1"
      aria-labelledby="userModalLabel"
      aria-hidden="true"
      ref={props.modalRef}
    >
      <div className="modal-dialog d-flex align-content-center">
        <div className="modal-content">
          <div className="modal-header container">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <FontAwesomeIcon
                className="text-petition-primary"
                fixedWidth
                icon={faTimes}
                size="2x"
              />
            </button>
          </div>
          <section className="modal-body container p-0 text-black">
            <div className="row m-auto">
              {props.children}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Modal;