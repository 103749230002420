import { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getMe } from "../../redux/users";
import GetUserModal from "./GetUserModal";
import ResetPasswordModal from "./ResetPasswordModal";
import ResetPasswordRequestMessageModal from "./ResetPasswordRequestMessageModal";
import ResetPasswordRequestModal from "./ResetPasswordRequestModal";
import ResetPasswordSuccessModal from "./ResetPasswordSuccessModal";
import SetPasswordMessageModal from "./SetPasswordMessageModal";
import SetPasswordModal from "./SetPasswordModal";
import SetPasswordSuccessModal from "./SetPasswordSuccessModal";
import SignInModal from "./SignInModal";
import SignUpMessageModal from "./SignUpMessageModal";
import SignUpModal from "./SignUpModal";
import SignUpSuccessModal from "./SignUpSuccessModal";
import { showModal } from "./modalFunctions";
import { AuthenticationContext } from "../../AuthenticationContext";

const AuthenticationModals = () => {
  const { authenticationProperties, setAuthenticationProperties } = useContext(AuthenticationContext);
  const location = useLocation();
  const [token, setToken] = useState();
  const [user, setUser] = useState({});

  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!users.me) {
      dispatch(getMe());
    }
  }, []);

  useEffect(() => {
    if (!users?.me?.id) return;
    setUser(users?.me);

    if (authenticationProperties?.action && users?.me?.id) {
      setToken(authenticationProperties?.token);
      switch (authenticationProperties?.action) {
        case "setPassword":
          showModal("setPasswordModal");
          break;

        case "resetPassword":
          showModal("resetPasswordModal");
          break;

        case "verify":
        default:
          showModal("signUpSuccessModal");
          break;
      }
      setAuthenticationProperties({
        ...authenticationProperties,
        action: null,
      });
    }
  }, [users?.me, location]);

  useEffect(() => {
    if (!users?.me) return;
    if (authenticationProperties?.required && !users?.me?.id) {
      showModal("getUserModal");
    }
  }, [authenticationProperties, users?.me]);

  const handleChange = ({ target }) => {
    const name = target.name;
    let value = target.type === "checkbox" ? target.checked : target.value;

    if ("text" == target.type) {
      value = value.trim();
    }

    if ("email" == target.name) {
      return setUser({
        country: user?.country,
        email: value
      });
    }

    setUser({
      ...user,
      [name]: value,
    });
  };

  return (
    <>
      <ResetPasswordModal handleChange={handleChange} token={token} user={user} />
      <SetPasswordModal handleChange={handleChange} token={token} user={user} />

      <ResetPasswordSuccessModal />
      <SetPasswordSuccessModal />
      <SignUpSuccessModal />

      <GetUserModal handleChange={handleChange} user={user} setUser={setUser} />
      <ResetPasswordRequestModal handleChange={handleChange} user={user} setUser={setUser} />
      <SignInModal handleChange={handleChange} user={user} setUser={setUser} />
      <SignUpModal handleChange={handleChange} user={user} setUser={setUser} />

      <ResetPasswordRequestMessageModal />
      <SetPasswordMessageModal />
      <SignUpMessageModal />
    </>
  );
};

export default AuthenticationModals;
