import React, { useState } from "react";
import { Range } from "react-range";

const useSlider = (min, max, defaultState, label, id) => {
  const [slide, setSlide] = useState(defaultState);
  const handleChange = (e) => {
    setSlide(e.target.value);
  };

  const Slider = () => (
    <input
      type="range"
      id={id}
      min={min}
      max={max}
      className="slider-range"
      step={0.5}
      defaultValue={slide}
      onChange={(e) => handleChange(e)}
    />
  );

  return [slide, Slider, setSlide];
};

const SlideRange = () => {
  const STEP = 1;
  const MIN = 10;
  const MAX = 100;
  const [values, setValues] = useState([25, 75]);

  return (
    <Range
      values={values}
      step={STEP}
      min={MIN}
      max={MAX}
      onChange={(values) => {
        setValues(values);
      }}
      renderTrack={({ props, children }) => (
        <div
          className="slider-range"
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
        >
          <div ref={props.ref} className="slider">
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props }) => <div {...props} className="thumb"></div>}
    />
  );
};

const Slides = () => {
  const [slideValue, Slider] = useSlider(1, 100, 50);
  return (
    <>
      <h3>Sliders</h3>
      <div className="row">
        <div className="col-6">
          <h6>Simple</h6>
          <Slider />
        </div>
        <div className="col-6">
          <h6>Range</h6>
          <SlideRange />
        </div>
      </div>
    </>
  );
};

export default Slides;
