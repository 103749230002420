import React from "react";

function Progress() {
  return (
    <>
      <h3>Progress</h3>
      <div className="row">
        <div className="col-6">
          <h6>Regular</h6>
          <div className="progress">
            <div className="progress-bar" style={{width: '50%'}} aria-valuenow="50"></div>
          </div>
        </div>
        <div className="col-6">
          <h6>Small</h6>
          <div className="progress progress-sm">
            <div className="progress-bar" style={{width: '50%'}} aria-valuenow="50"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Progress;
