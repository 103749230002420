import React from "react";
import DashboardTable from "../../components/DashBoardTable";

const Index = () => {
  return (
    <main className="dashboard">
      <DashboardTable />
    </main>
  );
};

export default Index;
