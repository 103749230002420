import React, { useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import api from "../helpers/api";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { getMe } from "../redux/users";
import { showModal } from "./authentication/modalFunctions";

const UserNavbar = () => {
  const users = useSelector((state) => state.users);
  const currentUser = users?.me;
  const dispatch = useDispatch();
  const authenticationTriggerRef = useRef();

  useEffect(() => {
    if (!users.me) {
      dispatch(getMe());
    }
  }, []);

  return (
    <nav className="user-navbar py-2 bg-gray-1">
      <div className="container d-flex justify-content-end">
        {currentUser?.id ? (
          <div className="dropdown">
            {currentUser.firstName ? (
              <span>
                Hallo, <strong>{currentUser.firstName}</strong>
              </span>
            ) : (
              <span>Hallo!</span>
            )}
            <button className="user-navbar-menu-button dropdown-toggle ms-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span className="user-navbar-menu-button-open">
                <FontAwesomeIcon
                  className="icon text-primary"
                  icon={faChevronDown}
                />
              </span>
              <span className="user-navbar-menu-button-close">
                <FontAwesomeIcon
                  className="icon text-primary"
                  icon={faChevronUp}
                />
              </span>
            </button>
            <Menu user={currentUser} />
          </div>
        ) : (
          <ul className="user-navbar-links">
            <li className="user-navbar-links-item">
              <a
                className="text-primary"
                data-bs-toggle="modal"
                ref={authenticationTriggerRef}
                href="#getUserModal"
              >
                Login
              </a>
            </li>
            <li className="user-navbar-links-item">
              <a
                className="text-primary"
                data-bs-toggle="modal"
                href="#getUserModal"
              >
                Registrieren
              </a>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
};

const Menu = ({ user }) => {
  const history = useHistory();
  const menuContent = [
    { label: "Meine Petitionen", url: "/profile/petitions" },
    { label: "Meine Unterschriften", url: "/profile/signatures" },
    { label: "Mein Konto", url: "/profile/edit" },
  ];
  
  const fetchMenuItem = (item, index) => {
    return (
      <li className="user-navbar-menu-list-item" key={index}>
        <Link to={{ pathname: item.url }}>{item.label}</Link>
      </li>
    );
  };

  const logout = async () => {
    try {
      await api.logout((_status, _data) => {
        window.location.reload();
      });
    } catch (error) {
      //handle error
    }
  };
  
  const setPasswordRequest = async () => {
    await api.sendToken(
      { email: user.email, type: "password", url: history.location.pathname },
      (_status, _data) => {
        showModal("setPasswordMessageModal");
      }
    );
  }

  return (
    <ul className="user-navbar-menu-list dropdown-menu">
      {user?.hasPassword ? (
        menuContent.map(fetchMenuItem)
      ) : (
        <>
          <li className="user-navbar-menu-list px-3">
            Bitte erstelle ein Passwort, um auf Dein Profil zugreifen zu können
          </li>
          <li
            className="btn btn-primary btn-sm d-block text-white mx-3"
            onClick={setPasswordRequest}
          >
            Passwort erstellen
          </li>
        </>
      )}
      {user?.email ? (
        <li className="user-navbar-menu-list-label">{user.email}</li>
      ) : (
        ""
      )}
      <li className="user-navbar-menu-list-button">
        <a type="button" onClick={() => logout()} href="#">Abmelden</a>
      </li>
    </ul>
  );
};

export default UserNavbar;
