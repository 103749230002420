import React from "react";
import bannerStamps from "../styles/images/banners/stamps.svg";
import DonationLink from "./DonationLink";

const bannerTitle = "Briefmarken für direkte Demokratie";
const bannerBody =
  "innn.it ist komplett kostenfrei und übernimmt zum Beispiel auch das Porto. Damit das so bleibt, brauchen wir Deine Unterstützung.";

const BannerLink = (props) => {
  return (
    <div className="banner banner-image banner-shadow banner-image-right">
      <div className="banner-row">
        <div className="banner-img bg-secondary d-flex align-items-center">
          <img className="ms-md-n5" src={bannerStamps} />
        </div>
        <div className="banner-body">
          <div className="banner-text">
            <h2 className="banner-title">{bannerTitle}</h2>
            <p>{bannerBody}</p>
            <div className="banner-footer d-grid gap-2">
              <DonationLink
                className={props.linkClass}
                rel="noreferrer"
                signableId={props.signableId}
                source={props.source}
                target="_blank"
              >
                {props.linkLabel}
              </DonationLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerLink;
