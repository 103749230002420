import React from "react";
import PropTypes from "prop-types";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import * as ClassicEditor from "ckeditor5-custom-build/build/ckeditor";

const StepThree = ({
  counter,
  formErrors,
  handleChange,
  handleNextStep,
  initiative,
  maxLengths,
  model,
}) => {
  const editorConfiguration = {
    htmlSupport: {
      allow: [
        {
          name: "a",
          attributes: {
            href: true,
            name: true,
            target: true,
          },
        },
        {
          name: /.*/,
          attributes: {
            id: true,
          },
        },
      ],
    },
    toolbar: [
      "heading",
      "undo",
      "redo",
      "bold",
      "italic",
      "blockQuote",
      "imageTextAlternative",
      "link",
      "imageUpload",
      "numberedList",
      "bulletedList",
      "sourceEditing",
    ],
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
  };

  return (
    <>
      {"initiative" == model && <div className="mb-5">
        <label className="h3 text-primary">Kurzbeschreibung</label>
        {/* <div className="mb-4">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.
        </div> */}
        <textarea
          type="text"
          className={`form-control ${formErrors.summary ? " is-invalid" : ""}`}
          defaultValue={initiative.summary}
          maxLength={maxLengths.summary}
          name="summary"
          onChange={handleChange}
        />
        <span
          className={`d-block text-end ${
            counter.summary === 0 ? " text-danger" : " "
          }`}
        >
          {counter.summary}
        </span>
      </div>}

      <div className="mb-5">
        <label className="h3 text-primary">Beschreibung</label>
        {/* <div className="mb-4">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.
        </div> */}
        <div
          className={`rounded-3 overflow-hidden ${
            formErrors.content
              ? "border border-danger"
              : "border border-primary"
          }`}
        >
          <CKEditor
            editor={ClassicEditor}
            data={initiative.content}
            config={editorConfiguration}
            onChange={(_event, editor) => {
              const data = editor.getData();
              handleChange({
                target: { name: "content", value: data },
              });
            }}
          />
        </div>
        <span className="d-block text-end">
          Bitte schreibe mindestens 1000 Zeichen.
        </span>
      </div>
      <div className="text-center text-md-end">
        <button
          className="btn btn-lg btn-primary"
          onClick={handleNextStep}
          type="button"
        >
          Weiter
        </button>
      </div>
    </>
  );
};

StepThree.propTypes = {
  counter: PropTypes.object,
  formErrors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  initiative: PropTypes.object.isRequired,
  maxLenghts: PropTypes.object,
};

export default StepThree;
