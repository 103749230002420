import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/pro-solid-svg-icons";

const NoteBox = () => {
  return (
   <div className="alert alert-light d-flex align-items-start" role="alert">
      <div className="alert-icon-container d-flex align-items-center text-dark" style={{marginTop: "0.2rem"}}>
        <FontAwesomeIcon icon={faLightbulb} className="me-2"/>
      </div>
      <div style={{fontSize: "12px"}}>
        <p className="mb-0">Prüfe nach der Unterzeichnung dein E-Mail-Postfach, um die Unterschrift zu bestätigen. <strong className="text-dark">Andernfalls wird sie nicht gezählt.</strong></p>
      </div>
    </div>
  );
};

export default NoteBox;
