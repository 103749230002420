import React, { useState, useEffect, useRef } from "react";
import countries from "i18n-iso-countries";
import deLocale from "i18n-iso-countries/langs/de.json";
import api from "../../../helpers/api";
import { useRequiredFields } from "../../../custom-hooks/use-required-field";
import TextField from "./TextField";
import { useLocationFields } from "../../../custom-hooks/use-location-field";
import SuccessMessage from "./SuccessMessage";

countries.registerLocale(deLocale);
const countryObj = countries.getNames("de", { select: "official" });

const countryOptions = Object.entries(countryObj).map(([key, value]) => {
  return {
    label: value,
    value: key
  };
});

const Form = ({ user, setUser }) => {
  if (!user?.id) return null;

  const [errors, setErrors] = useState({});
  const [isSuccessful, setIsSuccessful] = useState(false);
  const btnRef = useRef();

  const REQUIRED_FIELDS = ["country", "firstName", "lastName"];
  let allRequiredFields = [...REQUIRED_FIELDS, ...(user.country === "DE" ? ["location", "postalCode"] : [])
]
  const { requiredFields, RequiredFieldSign } = useRequiredFields(allRequiredFields);

  const {
    errors: locationErrors,
    locationProperties,
    locations,
    handleLocationProperties,
    setPostalCode,
    showLocationDropdown,
  } = useLocationFields({
    country: user.country,
    postalCode: user.postalCode,
    location: user.location,
  });

  useEffect(() => {
    setUser({
      ...user,
      country: locationProperties?.country || user?.country || "DE",
      location: locationProperties?.location || null,
      postalCode: locationProperties?.postalCode || null,
    });
  }, [locationProperties]);

  useEffect(() => {
    if (isSuccessful) {
      btnRef.current.click();
      return;
    };
  }, [isSuccessful]);

  const handleChange = ({ target }) => {
    setUser((prev)=> ({...prev, [target.name]: target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors({});

    if (!validateForm()) {
      return;
    };

    try {
      await api.updateUser(user.id, user, (_status, _data) => {
        setIsSuccessful(true);
      });
    } catch (error) {
      alert("could not update data");
    }
  };

  const validateForm = () => {
    let errors = {};

    requiredFields.forEach((field) => {
      if (!user[field] || user[field] === "") {
        errors[field] = "Bitte fülle dieses Feld aus";
      }
    });

    if (
      user.email &&
      !user.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      errors = { ...errors, email: "Bitte gib eine gültige E-Mail ein" };
    };

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <>
      <form className="col-md-5 col-12 form px-2 mb-5" onSubmit={(e) => handleSubmit(e)}>
        <fieldset>
          <h4 className="mb-4 pt-3">
            Persönliche Daten
          </h4>
          <TextField
            defaultValue={user.firstName}
            errors={errors}
            field="firstName"
            handleChange={handleChange}
            RequiredFieldSign={RequiredFieldSign}
            fieldLabel="Vorname"
            required={RequiredFieldSign("firstName")}
          />
          <TextField
            defaultValue={user.lastName}
            errors={errors}
            fieldLabel="Name"
            field="lastName"
            handleChange={handleChange}
            RequiredFieldSign={RequiredFieldSign}
            required={RequiredFieldSign("lastName")}
          />
          <div className="dropdown mb-4">
            <label
              className="mb-2 text-black"
              htmlFor="country"
            >
              Land{RequiredFieldSign("country")}
            </label>
            <button
              className="
            form-control
            dropdown-toggle
            text-start
            d-flex
            justify-content-between
            align-items-center"
              type="button"
              id="countryDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {countryObj[user?.country]}
            </button>
            <ul
              className="dropdown-menu overflow-auto"
              style={{ maxHeight: "250px" }}
              aria-labelledby="locationDropdown"
            >
              {countryOptions?.map((country) => (
                <li
                  className="dropdown-item"
                  key={country.value}
                  onClick={() => {
                    handleLocationProperties({ country: country.value });
                  }}
                >
                  {country.label}
                </li>
              ))}
            </ul>
          </div>
          {"DE" == user?.country && (
            <div className="row">
              <div className="col-4 mb-4">
                <label
                  className="mb-2 text-black"
                  htmlFor="postalCode"
                >
                  Postleitzahl{RequiredFieldSign("postalCode")}
                </label>
                <input
                  defaultValue={user.postalCode?.postalCode || ""}
                  className={`form-control${locationErrors.location || errors.location ? " is-invalid" : ""
                    }`}
                  id="postalCode"
                  name="postalCode"
                  onChange={(e) => {
                    setPostalCode(e.target.value);
                  }}
                  placeholder="12345"
                  type="text"
                />
                {locationErrors.location || errors.location && (
                  <span className="form-text invalid-feedback"
                    style={{ minWidth: "18rem" }}
                  >
                    {locationErrors.location || errors.location}
                  </span>
                )}
              </div>
              <div className="col-8 mb-4">
                <label
                  className="mb-2 text-black"
                  htmlFor="location"
                >
                  Ort{RequiredFieldSign("location")}
                </label>
                {locations?.length > 1 ? (
                  <div className="dropdown">
                    <button
                      className={`
                    form-control
                    dropdown-toggle
                    text-start
                    d-flex
                    justify-content-between
                    align-items-center
                    ${showLocationDropdown ? " show" : ""}
                    `}
                      type="button"
                      id="locationDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {locationProperties?.location?.name || "Ort"}
                    </button>
                    <ul
                      className={`dropdown-menu overflow-auto ${showLocationDropdown ? "show" : ""}`}
                      style={{ maxHeight: "250px" }}
                      aria-labelledby="locationDropdown"
                    >
                      {locations.map((location) => (
                        <li
                          className="dropdown-item"
                          key={location.id}
                          onClick={() => {
                            handleLocationProperties({ country: user.country, location })
                          }}
                        >
                          {location.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <input
                    className="form-control"
                    disabled={true}
                    value={user?.location?.name || location?.name || ""}
                    id="location$"
                    name="location"
                    placeholder="Ort"
                    readOnly
                    tabIndex="-1"
                    type="text"
                  />
                )}
              </div>
            </div>
          )}
          <div className="mb-md-5 mt-md-4">
            <button
              className="btn btn-sm btn-primary px-4"
              onClick={handleSubmit}
              type="submit"
            >
              Änderungen speichern
            </button>
          </div>
        </fieldset>
      </form>
      <div>
        <button type="button" className="visually-hidden" data-bs-toggle="modal" data-bs-target="#userConfirmation" ref={btnRef}></button>
      </div>
      <SuccessMessage type="userProfile" />
    </>
  );
};


export default Form;