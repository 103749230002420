import React, { useState } from "react";
import PropTypes from "prop-types";

const StepFive = ({ handleChange, handleNextStep, initiative, model }) => {
  const formatDate = (date) => {
    if (!date) {
      return;
    }
    return date.toISOString().split("T")[0];
  };

  const VALUES = {
    initiative: {
      signatureTarget: {
        name: `signatureTargets.${initiative.stage}`,
        value: initiative.signatureTargets?.[initiative.stage],
      },
    },
    petition: {
      signatureTarget: {
        name: "signatureTarget",
        value: initiative.signatureTarget,
      },
    }
  }
  const counterOptions = [
    {
      id: "counterDownload",
      label: "Anzahl gesammelter Unterschriften zeigen",
      value: "signature",
    },
    {
      id: "counterSignature",
      label: "Anzahl angeforderter Listen zeigen",
      value: "download",
    },
    {
      id: "counterNone",
      label: "Fortschritt verbergen",
      value: "none",
    },
  ];

  return (
    <>
      {"initiative" == model && <div className="mb-5">
        <div className="d-flex flex-column flex-md-row justify-content-md-between">
          <label
            className="h3 text-primary align-self-md-end"
            htmlFor="labelForStartDate"
          >
            Start der Sammlung
          </label>
          <div className="form-check form-switch d-flex flex-md-column-reverse align-items-md-end mb-3">
            <input
              checked={initiative?.optionalFields?.startDate}
              className="form-check-input"
              id="viewStartDate"
              name="optionalFields.startDate"
              onChange={handleChange}
              type="checkbox"
            />
            <label
              className="ms-2 ms-md-0 h4 form-check-label text-primary"
              htmlFor="viewStartDate"
            >
              Anzeigen
            </label>
          </div>
        </div>
        <input
          className="form-control mb-3"
          id="labelForStartDate"
          name={`startDates.${initiative.stage}`}
          onChange={handleChange}
          type="date"
          value={formatDate(
            initiative.startDates?.[initiative.stage] ? new Date(initiative.startDates?.[initiative.stage]) : ""
          )}
        />
      </div>}

      {"initiative" == model && <div className="mb-5">
        <div className="d-flex flex-column flex-md-row justify-content-md-between">
          <label
            className="h3 text-primary align-self-md-end"
            htmlFor="labelForEndDate"
          >
            Frist für Unterschriften
          </label>
          <div className="form-check form-switch d-flex flex-md-column-reverse align-items-md-end mb-3">
            <input
              checked={initiative?.optionalFields?.endDate}
              className="form-check-input"
              id="viewEndDate"
              name="optionalFields.endDate"
              onChange={handleChange}
              type="checkbox"
            />
            <label
              className="ms-2 ms-md-0 h4 form-check-label text-primary"
              htmlFor="viewEndDate"
            >
              Anzeigen
            </label>
          </div>
        </div>
        {/* <div className="mb-4">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.
        </div> */}
        <input
          className="form-control mb-3"
          id="labelForEndDate"
          name={`endDates.${initiative.stage}`}
          onChange={handleChange}
          type="date"
          value={formatDate(
            initiative.endDates?.[initiative.stage] ? new Date(initiative.endDates?.[initiative.stage]) : ""
          )}
        />
      </div>}

      {"initiative" == model && <div className="mb-5">
        <div className="d-flex flex-column flex-md-row justify-content-md-between">
          <label className="h3 text-primary" htmlFor="signatureTarget">
            Unterschriften Ziel
          </label>
          <div className="form-check form-switch align-items-md-end d-flex flex-md-column-reverse mb-3">
            <input
              checked={initiative.optionalFields.signatureTarget}
              className="form-check-input"
              id="viewSignatureTarget"
              name="optionalFields.signatureTarget"
              onChange={handleChange}
              type="checkbox"
            />
            <label
              className="form-check-label d-md-none ms-2 ms-md-0 h4 form-check-label text-primary"
              htmlFor="viewSignatureTarget"
            >
              Anzeigen
            </label>
          </div>
        </div>
        {/* <div className="mb-4">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.
        </div> */}
        <input
          type="number"
          className="form-control mb-3"
          id="signatureTarget"
          name={VALUES?.[model]?.signatureTarget.name}
          onChange={handleChange}
          value={VALUES?.[model]?.signatureTarget.value}
        />
      </div>}

      {"initiative" == model && <div className="d-flex flex-column justify-content-start mb-4">
        {counterOptions.map((counterOption) => {
          return (
            <div key={counterOption.id}>
              <div className="form-check mb-4">
                <input
                  checked={!initiative?.optionalFields?.counter ? "none" == counterOption.value : counterOption.value == initiative?.optionalFields?.counter}
                  className="form-check-input"
                  id={counterOption.id}
                  name="optionalFields.counter"
                  onChange={handleChange}
                  type="radio"
                  value={counterOption.value}
                />
                <label
                  className="form-check-label h3 mb-0 ms-0 ms-md-4 text-primary"
                  htmlFor={counterOption.id}
                >
                  {counterOption.label}
                </label>
              </div>
              {
                "signature" == initiative?.optionalFields?.counter &&
                "signature" == counterOption.value && (
                  <div className="mb-4">
                    <label className="fw-normal text-primary" htmlFor="signatureCount">
                      Gesammelte Unterschriften manuell eingeben
                    </label>
                    <input
                      className="form-control"
                      id="signatureCount"
                      name={`signatureCounts.${initiative.stage}`}
                      onChange={handleChange}
                      type="number"
                      value={initiative.signatureCounts?.[initiative.stage]}
                    />
                  </div>
                )
              }
            </div>
          );
        })}
      </div>}

      <div className="text-center text-md-end">
        <button
          className="btn btn-lg btn-primary"
          onClick={() => handleNextStep()}
          type="button"
        >
          Weiter
        </button>
      </div>
    </>
  );
};

StepFive.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  initiative: PropTypes.object.isRequired,
};

export default StepFive;
