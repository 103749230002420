import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "./Modal"
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

const SetPasswordSuccessModal = () => {
  return (
    <Modal id="setPasswordSuccessModal">
      <span className="d-flex mb-3">
        <FontAwesomeIcon
          className="icon icon-left text-petition-primary"
          icon={faCheck}
          size="3x"
        />
        <span className="ps-4">
          <h4 className="text-petition-primary mb-3">
            Vielen Dank! Dein Passwort wurde erstellt.
          </h4>
        </span>
      </span>
    </Modal>
  )
}

export default SetPasswordSuccessModal;
