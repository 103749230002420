import React from "react";
import Avatars from "./elements/avatars";
import Badges from "./elements/badges";
import Chips from "./elements/chips";
import IconBox from "../components/IconBox";
import imageIconBox from "../styles/images/icon-box.svg";
import Indicators from "./elements/indicators";
import Pagination from "./elements/pagination";
import Progress from "./elements/progress";
import Slider from "./elements/sliders";
import Tip from "./elements/tip";
import TipBox from "./elements/tipBox";

const Elements = () => {
  return (
    <section className="mt-5">
      <h2>Elements</h2>
      <div className="row mt-3">
        <div className="col-md-6 col-12 mb-5">
          <Avatars />
        </div>
        <div className="col-md-6  col-12 mb-5">
          <Badges />
        </div>
        <div className="col-12 mb-5">
          <Chips />
        </div>
        <div className="col-md-6  col-12 mb-5">
          <Progress />
        </div>
        <div className="col-md-6  col-12 mb-5">
          <Slider />
        </div>
        <div className="col-md-6  col-12 mb-5">
          <Indicators />
        </div>
        <div className="col-md-6  col-12 mb-5">
          <h3>Icon box</h3>
          <IconBox
            img={imageIconBox}
            imgAlt="Alternative text for image"
            title="Diverse Initiativen"
            text={<>Here is a message that's going to be two lines <strong>so</strong>, we see the text as it <a href="/">wraps</a>.</>}
          />
        </div>
        <div className="col-md-6 col-12 mb-5">
          <h3 className="mb-5">Pagination</h3>
          <Pagination />
        </div>
        <div className="col-12 position-relative">
          <h3>Tip</h3>
          <Tip label="Beispiele">
            <h3 className="mb-4 text-primary">Titel Beispiele: </h3>
            <p className="mb-3">Haustiere sind keine Sachgegenstände! 
              Tiere müssen vom Gesetz als Lebewesen anerkannt werden. 
              <strong>#KeineSachgegenstände</strong>
            </p>
            <p className="mb-5">
              Krankenhäuser sollen Kranke heilen statt Gewinn zu machen.
              Schaffen Sie das Fallpauschalensystem ab! 
            </p>
          </Tip>
        </div>
        <div className="col-md-6 col-12 position-relative">
          <h3 className="mb-5">Tip Box</h3>
          <TipBox>
            <h3 className="mb-4 text-primary">Titel Beispiele: </h3>
            <p className="mb-3">Haustiere sind keine Sachgegenstände! 
              Tiere müssen vom Gesetz als Lebewesen anerkannt werden. 
              <strong>#KeineSachgegenstände</strong>
            </p>
            <p className="mb-5">
              Krankenhäuser sollen Kranke heilen statt Gewinn zu machen.
              Schaffen Sie das Fallpauschalensystem ab! 
            </p>
          </TipBox>
        </div>
      </div>
    </section>
  );
};

export default Elements;
