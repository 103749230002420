import { useMatomo } from "@datapunt/matomo-tracker-react";
import React, { useEffect, useState } from "react";
import { useCookies, withCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faTimes } from "@fortawesome/pro-solid-svg-icons";

const CookieConsent = () => {
  const consentsCookieName = "cookieConsent"
  const defaultConsents = [
    "essential",
    "statistics",
  ];

  const [cookies, setCookie, removeCookie] = useCookies(["innnit"]);
  const [consents, setConsents] = useState();
  const [checkedConsents, setCheckedConsents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { pushInstruction } = useMatomo();

  const handleChange = ({ target }) => {
    const checked = target.checked;
    const value = target.value;

    if (checked) {
      setCheckedConsents([...checkedConsents, value]);
    } else {
      setCheckedConsents(checkedConsents.filter(consent => value !== consent));
    }
  };
  
  const hasCheckedConsent = (consent) => {
    if (
      "all" == checkedConsents ||
      (Array.isArray(checkedConsents) && checkedConsents.includes(consent))
    ) {
      return true;
    }
    return false;
  }
  
  const hasConsent = (consent) => {
    if (
      "all" == consents ||
      (Array.isArray(consents) && consents.includes(consent))
    ) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    (async () => {
      try {
        await cookies;
        const consents = cookies[consentsCookieName]
        if (consents) {
          setConsents(consents);
          setCheckedConsents(consents || []);
        } else {
          setShowModal(true);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (!consents) {
      return;
    }

    if ("all" == consents) {
      setConsents(defaultConsents);
      return;
    }

    if (hasConsent("statistics")) {
      pushInstruction("setConsentGiven");
      pushInstruction("setCookieConsentGiven");
    }

    setCookie(consentsCookieName, consents, {
      path: "/",
      secure: true,
    });

    setShowModal(false);
  }, [consents]);

  return (
    <section>
      {/* Cookie consent modal */}
      <div
        className="modal fade"
        id="cookieConsentModal"
        tabIndex="-1"
        aria-labelledby="cookieConsentModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title text-initiative-primary" id="cookieConsentModalTitle">
                Cookies verwalten
              </h3>
              <button className="btn-close" data-bs-dismiss="modal">
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="modal-body">
              <p className="text-black label">
                Hier findest Du eine Übersicht über alle verwendeten Cookies. Du kannst Deine
                Einwilligung zu ganzen Kategorien geben oder nur bestimmte Cookies
                auswählen. Weitere Informationen findest Du in unseren{" "}
                <a className="text-initiative-primary" href="/datenschutz">Datenschutzhinweisen</a>.
              </p>
              <h4 className="text-initiative-primary">Essenziell</h4>
              <p className="text-black label">
                Essenzielle Cookies ermöglichen grundlegende Funktionen und sind
                für die einwandfreie Funktion der Webseite erforderlich.
              </p>  
              <div className="row">
                <h4 className="text-initiative-primary col">Statistiken</h4>
                <div className="form-check col-auto">
                  <input
                    className="form-check-input form-check-input-initiative"
                    name="consents"
                    type="checkbox"
                    value="statistics"
                    checked={hasCheckedConsent("statistics")}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <p className="text-black label">
                Wir erheben anonymisierte Daten für statistische und analytische
                Zwecke. Diese Angaben helfen uns, das Nutzungsverhalten
                auszuwerten und darauf basierend die Bedienung der Seite zu
                optimieren.
              </p>
            </div>
            <div className="modal-footer justify-content-start justify-content-md-end">
              <button type="button" className="btn btn-petition-secondary text-initiative-primary" onClick={() => setCheckedConsents(defaultConsents)}>
                Alle akzeptieren
              </button>
              <button className="btn btn-initiative-outline" onClick={() => setConsents(checkedConsents)}>
                Speichern
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Cookie consent banner */}
      {showModal &&
        <div className="py-md-5 py-3 fixed-bottom bg-initiative-primary">
          <div className="container petition">
            <div className="row align-items-center">
              <div className="col-md-8 d-flex">
                <div className="align-self-start py-1">
                  <FontAwesomeIcon className="icon icon-left text-petition-secondary" icon={faExclamationCircle} size="xl"/>
                </div>
                <div className="flex-grow-1">
                  <h3 className="text-petition-secondary">Verwendung von Cookies</h3>
                  <p className="text-white label">
                    Neben den essenziellen Cookies
                    verwenden wir Cookies, um die Nutzung unserer Webseite zu
                    analysieren. Hierzu geben wir Informationen an unsere
                    Partner für Analysen weiter. Mit dem Klick auf “Alle akzeptieren”
                    erklärst Du Dich mit der Verwendung der Cookies und der
                    Verarbeitung Deiner Daten für die genannten Zwecke
                    einverstanden. Deine Einwilligung kannst Du jederzeit mit
                    Wirkung für die Zukunft widerrufen. Weitere Informationen
                    findest Du in unseren Datenschutzhinweisen.
                  </p>
                  <p className="text-petition-secondary d-none d-md-inline">
                    <a href="/datenschutz" className="text-petition-secondary">
                      Datenschutzhinweise
                    </a>{" "}
                    |{"  "}
                    <a href="/impressum" className="text-petition-secondary">
                      Impressum
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-md-4 text-center">
                <div className="pt-4 pt-md-0 ps-md-5">
                  <button
                    className="btn btn-petition-secondary text-initiative-primary w-100 mb-2"
                    onClick={() => setConsents(defaultConsents)}
                  >
                    Alle akzeptieren
                  </button>

                  <button
                    className="btn btn-outline-light w-100"
                    onClick={() => setConsents(["essential"])}
                  >
                    Nur essenzielle akzeptieren
                  </button>

                  <button
                    className="btn btn-link text-petition-secondary"
                    data-bs-toggle="modal"
                    data-bs-target="#cookieConsentModal"
                  >
                    Individuelle Einstellungen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </section>
  );
};

export default withCookies(CookieConsent);
