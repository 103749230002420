import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import api from "../helpers/api";
import { getMe } from "../redux/users";
import NotFound from "./NotFound";
import queryString from "query-string";
import { AuthenticationContext } from "../AuthenticationContext";

export const Authentication = () => {
  const {authenticationProperties, setAuthenticationProperties} = useContext(AuthenticationContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [status, setStatus] = useState("");

  let { email, token, action, url } = queryString.parse(location.search);

  useEffect(async () => {
    try {
      console.log(email, token);
      await api.login({email, token}, (_status, _data) => {
        dispatch(getMe());
        setAuthenticationProperties({
          ...authenticationProperties,
          action,
          referrer: location?.pathname,
          email: "verify" != action ? email : null,
          token: "verify" != action ? token : null,
        });
        console.log({
          ...authenticationProperties,
          action,
          referrer: location?.pathname,
          email: "verify" != action ? email : null,
          token: "verify" != action ? token : null,
        });

        history.replace(url);
      });
    } catch(error) {
      console.log(error);
      setStatus(404);
    }
  }, []);

  return 404 == status && (
    <NotFound />
  );
};

export default Authentication;
