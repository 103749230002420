import React from "react";
import PropTypes from "prop-types";
import { useRequiredFields } from "../../../custom-hooks/use-required-field";

const TextField = ({ defaultValue, errors, field, handleChange, fieldLabel, required, type = "text" }) => {

  if (!field) {
    return false;
  };

  return (
    <div className="mb-4">
      <label className="mb-2 text-black" htmlFor={field}>
        {fieldLabel}
        {required}
      </label>
      <input
        className={`form-control${errors[field] ? " is-invalid" : ""}`}
        defaultValue={defaultValue}
        id={field}
        name={field}
        onChange={handleChange}
        placeholder={fieldLabel}
        type={type}
      />
      {errors[field] && (
        <span className="form-text invalid-feedback">{errors[field]}</span>
      )}
    </div>
  );
};

TextField.propTypes = {
  field: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  RequiredFieldSign: PropTypes.func,
};
  

export default TextField;