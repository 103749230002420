import React from "react";
import Start from "./Start";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";

const StepComponent = ({ step, ...rest }) => {
  const STEPS = {
    1: StepOne,
    2: StepTwo,
    3: StepThree,
    4: StepFour,
    5: StepFive,
  };
  const CurrentComponent = STEPS[step] || Start;

  return <CurrentComponent {...rest} />;
};

export default StepComponent;
