import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getMe } from "./redux/users";

const DashboardRoute = ({ component: Component, ...props }) => {
  const users = useSelector(state => state.users);
  const role = users.me?.role;
  const dispatch = useDispatch();

  const isAdmin = () => {
    return role && "admin" === role;
  };

  return (
    <Route
      {...props}

      render={(props) => {
        if (!users.me) {
          dispatch(getMe());
          return <Component {...props} />;
        }

        return isAdmin()
          ? <Component {...props} />
          : users.me
            ? <Redirect to={{ pathname: "/" }} />
            : <Redirect to={{ pathname: "/login", state: { referrer: props.location?.pathname } }} />;
      }}
    />
  );
};

export default DashboardRoute;
