import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import api from "../../../helpers/api";
import annikaAvatar from "../../../styles/images/annika-bubble.svg";

const FIELD_LABELS = {
  name: "Name",
  email: "E-Mail Adresse",
  phone: "Telefonnummer"
};

const REQUIRED_FIELDS = {
  any: ["body", "email", "name"],
};

const Contact = ({ setOpenContactForm }) => {
  return (
    <section className="petition-starten-contact">
      <CloseButton setOpenContactForm={setOpenContactForm} />
      <section className="d-flex flex-column flex-md-row justify-content-between">
        <Heading />
        <Aside />
      </section>
      <Form />
    </section>
  )
};

const Aside = () => {
  return (
    <div className="d-md-block d-none mb-0">
      <img alt="Annika Heintz-Saad" className="img-fluid w-75" src={annikaAvatar} />
      <h4 className="mb-2 mt-3 fs-6">
        Annika Heintz-Saad und das Team von innn.it e.V.
      </h4>
    </div>
  );
};

const CloseButton = ({ setOpenContactForm }) => {
  return (
    <div className="d-flex justify-content-end mt-2 me-2">
      <FontAwesomeIcon 
        className="text-primary" 
        icon={faTimes} 
        onClick={()=> setOpenContactForm(false)}
        size="2x"
      />
    </div>
  );
};

const Form = () => {
  const fieldsetRef = useRef();
  const successMsgRef = useRef();

  const [errors, setErrors] = useState({});
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [contactMessage, setContactMessage] = useState({
    category: "petition",
  });

  const getRequiredFields = () => {
    const globalRequiredFields = REQUIRED_FIELDS?.any;
    return [...globalRequiredFields];
  };

  const handleChange = ({ target }) => {
    setContactMessage((prev) => ({
      ...prev,
      [target.name]: target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors({});

    if (!validateForm()) {
      return;
    }

    if (fieldsetRef.current) {
      fieldsetRef.current.setAttribute("disabled", "disabled");
    }

    try {
      await api.contact(contactMessage, () => {
        setIsSuccessful(true);
        if (fieldsetRef.current) {
          fieldsetRef.current.removeAttribute("disabled");
        }
      });
    } catch (error) {
      if (fieldsetRef.current) {
        fieldsetRef.current.removeAttribute("disabled");
      }
    }
  };

  const isFieldRequired = (field) => {
    if (!requiredFields) {
      return false;
    }

    if (requiredFields.includes(field)) {
      return true;
    }

    return false;
  };

  const validateForm = () => {
    let errors = {};

    requiredFields.forEach((field) => {
      if (!contactMessage[field] || contactMessage[field] === "") {
        errors[field] = "Bitte fülle dieses Feld aus";
      }
    });

    if (
      contactMessage.email &&
      !contactMessage.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      errors = { ...errors, email: "Bitte gib eine gültige E-Mail ein" };
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const requiredFields = getRequiredFields();

  const RequiredFieldSign = (field) => {
    if (isFieldRequired(field)) {
      return "*";
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length && fieldsetRef.current.first) {
      validateForm();
    }
    fieldsetRef.current.first = true;
  }, [contactMessage]);

  useEffect(() => {
    let fieldsetCollapse = Collapse.getInstance(fieldsetRef.current);
    let successMsgCollapse = Collapse.getInstance(successMsgRef.current);

    if (!fieldsetCollapse) {
      fieldsetCollapse = new Collapse(fieldsetRef.current, { toggle: false });
      fieldsetRef.current.addEventListener("hidden.bs.collapse", () => {
        successMsgCollapse.show();
      });
    }

    if (!successMsgCollapse) {
      successMsgCollapse = new Collapse(successMsgRef.current, {
        toggle: false,
      });
    }

    if (isSuccessful) {
      fieldsetCollapse.hide();
    }
  }, [isSuccessful]);

  return (
    <form className="form px-2 pb-2">
      <fieldset className="row collapse show" ref={fieldsetRef}>
        <div className="col-md-9 col-12 mb-2">
          <TextField
            field="name"
            errors={errors}
            handleChange={handleChange}
            RequiredFieldSign={RequiredFieldSign}
          />
        </div>
        <div className="d-flex flex-wrap">
          <div className="col-md-6 col-12 mb-2 pe-md-3">
            <TextField
              field="email"
              errors={errors}
              handleChange={handleChange}
              RequiredFieldSign={RequiredFieldSign}
            />
          </div>
          <div className="col-md-6 col-12 mb-2">
            <TextField
              field="phone"
              errors={errors}
              handleChange={handleChange}
              RequiredFieldSign={RequiredFieldSign}
            />
          </div>
        </div>
        <div className="mb-3">
          <label className="text-black">
            Deine Nachricht{RequiredFieldSign("body")}
          </label>
          <textarea
            className={`form-control ${errors.body ? "is-invalid" : ""}`}
            error={errors.body}
            name="body"
            onChange={(e) => handleChange(e)}
            placeholder=""
            type="text"
          />
          {errors.body && (
            <span className="form-text invalid-feedback">{errors.body}</span>
          )}
        </div>
        <div className="align-items-start d-flex flex-column flex-md-row mb-2 mt-3">
          <button
            className="btn btn-primary me-5"
            onClick={handleSubmit}
            type="submit"
          >
            Absenden
          </button>
          <div className="mt-3 m-md-0">
            <label className="small text-black">*Diese Felder müssen ausgefüllt werden.</label>
            <label className="small text-black">
              Wir verarbeiten Deine Daten gemäß unserer {" "}
              <Link to="/datenschutz">Datenschutzhinweise</Link>.
            </label>
          </div>
        </div>
      </fieldset>
      <div className="collapse fade" ref={successMsgRef}>
        <h3 className="text-primary">Danke für Deine Nachricht!</h3>
      </div>
    </form>
  );
};

const Heading = () => {
  const heroTitle = "Möchtest Du Unterstützung für Deine Kampagne?";
  const heroBody = `Wir beraten Dich gern beim Start Deiner Petition. 
    Hinterlasse uns eine kurze Nachricht und Deine Kontaktdaten, wir melden uns zietnah bei Dir zurück.`;

  return (
    <div className="hero-holder p-md-2">
      <div className="hero-container">
        <div className="hero-row">
          <div className="hero-body content">
            <h3 className="text-primary mb-3 mt-2">{heroTitle}</h3>
            <p className="h4 fw-light">{heroBody}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const TextField = ({ errors, field, handleChange, RequiredFieldSign }) => {
  if (!field) {
    return false;
  }

  return (
    <div>
      <label className="text-black" htmlFor={field}>
        {FIELD_LABELS?.[field]}
        {RequiredFieldSign(field)}
      </label>
      <input
        className={`form-control${errors[field] ? " is-invalid" : ""}`}
        id={field}
        name={field}
        onChange={handleChange}
        placeholder={FIELD_LABELS?.[field]}
        type="text"
      />
      {errors[field] && (
        <span className="form-text invalid-feedback">{errors[field]}</span>
      )}
    </div>
  );
};

export default Contact;