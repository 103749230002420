import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import Modal from "./Modal";
import TextField from "./TextField";
import validateForm from "../../custom-hooks/use-user-validation";
import api from "../../helpers/api";
import { FIELD_LABELS, FIELD_PLACEHOLDERS } from "./content";
import { hideModal, showModal } from "./modalFunctions";
import { AuthenticationContext } from "../../AuthenticationContext";

const GetUserModal = ({
  handleChange,
  user, setUser
}) => {
  const { authenticationProperties, _setAuthenticationProperties } = useContext(AuthenticationContext);
  const [formErrors, setFormErrors] = useState({});
  const fieldsetRef = useRef();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const modalElement = document.getElementById("getUserModal");
    if (!modalElement) return;

    modalElement.addEventListener("hidden.bs.modal", handleModalClose);
  }, [location]);

  const handleModalClose = () => {
    if (authenticationProperties?.required) {
      history.push("/");
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});

    const [isValid, errors] = validateForm("getUser", user);

    if (!isValid) {
      setFormErrors(errors);
      return;
    }

    if (fieldsetRef.current) {
      fieldsetRef.current.setAttribute("disabled", true);
    }

    try {
      await api.getUser(user.email, async (_status, data) => {
        setUser(data);
        if (data && !data.hasPassword) {
          await api.sendToken(
            { email: user.email, type: "password", url: history.location.pathname },
            (_status, _data) => {
              hideModal("getUserModal");
              showModal("setPasswordMessageModal");
            }
          );
        } else if (data && data.hasPassword) {
          hideModal("getUserModal");
          showModal("signInModal");
        }
        setFormErrors({});
      });
    } catch (error) {
      hideModal("getUserModal");
      showModal("signUpModal");
    } finally {
      if (fieldsetRef.current) {
        fieldsetRef.current.removeAttribute("disabled");
      }
    }
  }

  return (
    <Modal id="getUserModal">
      <form onSubmit={(e) => handleSubmit(e)} noValidate>
        <fieldset ref={fieldsetRef}>
          <h3 className="mb-5">Registrieren oder anmelden, damit Du die Petition veröffentlichen kannst</h3>
          <p>Um eine Petition auf innn.it zu veröffentlichen, ist ein Nutzer:innen-Konto erforderlich. Bitte registriere dich oder melde dich an, wenn Du bereits ein Konto auf innn.it hast.</p>
          <TextField
            autoFocus
            field="email"
            id="email"
            name="email"
            required={true}
            type="email"
            user={user}
            formErrors={formErrors}
            fieldLabels={FIELD_LABELS}
            handleChange={handleChange}
            placeholder={FIELD_PLACEHOLDERS}
          />

          <div className="text-center text-md-end mb-4">
            <button
              className="btn btn-lg btn-primary"
              type="submit"
            >
              Weiter
            </button>
          </div>
          <p className="small text-black">
            Mit der Registrierung bzw. Veröffentlichung einer Petition akzeptierst Du die{" "}
            <Link className="text-primary" target="_blank" to="/nutzungsbedingungen">Nutzungsbedingungen</Link>{" "}
            von innn.it. Wir verarbeiten Deine Daten gemäß unseren{" "}
            <Link className="text-primary" target="_blank" to="/datenschutz">Datenschutzbestimmungen.</Link>
          </p>
        </fieldset>
      </form>
    </Modal>
  );
}

export default GetUserModal;
