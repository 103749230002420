import React from "react";
import Step from "./Step";

const Pagination = (props) => {
  const STEPS = [1,2,3,4,5];
  return(
    <nav aria-label="..." className="mb-5">
      <ul className="pagination pagination-petition">
        {
          STEPS.map((step)=> <Step step={step} {...props}  key={step.toString()}/>)
        }
      </ul>
    </nav>
  )
};
  
export default Pagination;