import { useRef, useState } from "react";
import Modal from "./Modal"
import TextField from "./TextField";
import { FIELD_LABELS, FIELD_PLACEHOLDERS } from "./content";
import { hideModal, showModal } from "./modalFunctions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import api from "../../helpers/api";
import validateForm from "../../custom-hooks/use-user-validation";

const ResetPasswordModal = ({
  handleChange,
  token,
  user
}) => {
  const fieldsetRef = useRef();
  const [formErrors, setFormErrors] = useState({});
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});

    const [isValid, errors] = validateForm("resetPassword", user);

    if (!isValid) {
      setFormErrors(errors);
      return;
    }

    if (fieldsetRef.current) {
      fieldsetRef.current.setAttribute("disabled", true);
    }

    try {
      await api.setPassword(
        user.email,
        { password: user.password, token },
        (_status, _data) => {
          hideModal("resetPasswordModal");
          showModal("resetPasswordSuccessModal");
        }
      );
    } catch (error) {
    } finally {
      if (fieldsetRef.current) {
        fieldsetRef.current.removeAttribute("disabled");
      }
    }
  };

  return (
    <Modal id="resetPasswordModal">
      <form onSubmit={(e) => handleSubmit(e)} noValidate>
        <fieldset ref={fieldsetRef}>
          <h3 className="mb-5">Passwort zurück setzen</h3>
          <p>Bitte gib ein neues Passwort ein:</p>
          <TextField
            field="password"
            name="password"
            id="password"
            type="password"
            formErrors={formErrors}
            fieldLabels={FIELD_LABELS}
            handleChange={handleChange}
            placeholder={FIELD_PLACEHOLDERS}
            user={user}
          />
          <div className="text-center text-md-end mb-4">
            <button
              className="btn btn-lg btn-primary"
              type="submit"
            >
              Weiter
            </button>
          </div>
        </fieldset>
      </form>
    </Modal>
  )
}

export default ResetPasswordModal;
