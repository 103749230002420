import React from "react";

const Spacings = () => {
  return (
    <section className="spacings">
      <h2>Spacing</h2>
      <div className="p-1 bg-primary d-table mb-4" style={{opacity: .1}}></div>
      <div className="p-2 bg-primary d-table mb-4" style={{opacity: .1}}></div>
      <div className="p-3 bg-primary d-table mb-4" style={{opacity: .1}}></div>
      <div className="p-4 bg-primary d-table mb-4" style={{opacity: .1}}></div>
      <div className="p-5 bg-primary d-table mb-4" style={{opacity: .1}}></div>
      <div className="p-6 bg-primary d-table mb-4" style={{opacity: .1}}></div>
      <div className="p-7 bg-primary d-table mb-4" style={{opacity: .1}}></div>
      <div className="p-8 bg-primary d-table mb-4" style={{opacity: .1}}></div>
      <div className="p-9 bg-primary d-table mb-4" style={{opacity: .1}}></div>
    </section>
  );
};

export default Spacings;
